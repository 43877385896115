import React, { useContext } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { ActionsMultiSelectMenuButton } from '../hooks/useMultiSelectActionsMenu';
import { AlertDialog } from '@terragotech/gen5-shared-components';
import { AssetType } from '../contexts/AggregatesContext/types';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export interface MultiActionDialogProps {
  show: boolean;
  validRecords: Array<AssetType>;
  exceptionCount: number;
  actionsMultiSelectMenuButton?: ActionsMultiSelectMenuButton;
  reset: () => void;
}

const MultiActionDialog = (props: MultiActionDialogProps) => {
  const { show, actionsMultiSelectMenuButton, reset, validRecords, exceptionCount } = props;
  const { selectedRecordTypeDefinition } = useRecordType();
  const { translate } = useContext(LanguageContext);

  return show ? (
    <AlertDialog
      title={`${translate('Caution, $__LABEL__$ is not available for some of the selected records', {
        LABEL: actionsMultiSelectMenuButton?.label || '',
      })}`}
      okText={'Continue'}
      cancelText={'Cancel'}
      onOkPress={() => {
        setTimeout(() => {
          actionsMultiSelectMenuButton?.onClick(this, validRecords as any);
        });
        reset();
      }}
      disabled={!validRecords.length}
      onCancelPress={reset}
      translate={translate}>
      <div>
        {translate("'$__LABEL__$' will run for $__VALID_RECORDS__$ out of the $__COUNT__$ selected $__RECORD_TYPE__$", {
          LABEL: actionsMultiSelectMenuButton?.label || '',
          VALID_RECORDS: validRecords?.length || 0,
          COUNT: (validRecords?.length || 0) + exceptionCount,
          RECORD_TYPE: selectedRecordTypeDefinition?.plural,
        })}
      </div>
    </AlertDialog>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default MultiActionDialog;
