import React, { useState, useCallback, useEffect, useContext } from 'react';
import { initFormRootRenderer, OnSubmit } from '@terragotech/form-renderer';
import ControlProvider from './ControlProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import _ from 'lodash';
import {
  CommandAction,
  useConfig,
  FieldHeader,
  FIELD_HEADER_HEIGHT,
  InfoBarCollection,
  InfoBarTypeEnum,
  InfoBar,
  getErrorsAndWarnings,
} from '@terragotech/gen5-shared-components';
import { v4 } from 'uuid';
import useSubmitCommands, { CommandType } from '../../hooks/useSubmitCommand';
import { useWorkflowDataMapping } from '../../hooks/useWorkflowDataMapping';
import { AssetType } from '../../contexts/AggregatesContext/types';
import { useFileUpload } from '../../hooks/useFileUpload';
import { FormParentValueContext } from '../../contexts/formParentValueContext';
import { useSelectedLocation } from '../../contexts/selectedLocationContext';
import { useAlert } from '../../contexts/AlertModalContext';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const FormRootRendererInitiated = initFormRootRenderer(ControlProvider);

interface WorkflowPageProps {
  title: string;
  openWorkflow: boolean;
  target?: AssetType | AssetType[];
  onCancelPress: () => void;
  onDonePress: () => void;
  command: CommandAction;
  aggregateType: string;
  creationAction?: boolean;
}

interface ErrorsAndWarnings {
  formErrors: string[];
  fieldErrors: string[];
  formWarnings: string[];
  fieldWarnings: string[];
}

const WorkflowPage: React.FC<WorkflowPageProps> = props => {
  const {
    openWorkflow,
    target,
    onCancelPress,
    command: commandAction,
    onDonePress,
    aggregateType,
    title,
    creationAction,
  } = props;
  const { handleFormParentValueChange } = useContext(FormParentValueContext);
  const { translate } = useContext(LanguageContext);
  const { submitCommands } = useSubmitCommands();
  const { functionDefinitions } = useConfig();
  const dataMapping = useWorkflowDataMapping(target);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const styles = useStyles();
  const { uploadFile } = useFileUpload();
  const { setSelectedLocation } = useSelectedLocation();
  const { openConfirmation } = useAlert();

  const handleFormSubmission = useCallback<OnSubmit>(
    args => {
      const caller: OnSubmit = async ({ data, files }) => {
        if (!submitted && !submitting) {
          try {
            setSubmitting(true);
            const commands: CommandType[] = [];
            //If we have any files at all, let's just upload them all
            if (files && files.length > 0) {
              await Promise.all(files.map(file => uploadFile(file)));
            }
            if (creationAction) {
              commands.push({
                target: v4(),
                aggregateType: aggregateType,
                commandName: commandAction.commandName,
                version: _.toNumber(commandAction.commandVersion),
                commandData: data,
                isSynchronous: commandAction.isSynchronous,
                hasNoAggregateIdCommand: commandAction.hasNoAggregateIdCommand,
              });
            } else {
              const targetArray = Array.isArray(target) ? target : [target || { id: v4() }];
              commands.unshift(
                ...targetArray.map(tgt => {
                  return {
                    target: tgt.id,
                    aggregateType: aggregateType,
                    commandName: commandAction.commandName,
                    version: _.toNumber(commandAction.commandVersion),
                    commandData: data,
                    isSynchronous: commandAction.isSynchronous,
                    hasNoAggregateIdCommand: commandAction.hasNoAggregateIdCommand,
                  };
                })
              );
            }
            //if target is undefined, then we are likely in a creation command
            // create a command for each target
            await submitCommands(commands);
            setSubmitted(true);
          } catch (e) {
            console.error(e);
          } finally {
            setSubmitting(false);
            // Fixes component unmounted error
            setSelectedLocation(undefined);
            setTimeout(() => onDonePress());
          }
        }
      };
      caller(args);
    },
    [
      aggregateType,
      commandAction.commandName,
      commandAction.commandVersion,
      commandAction.isSynchronous,
      commandAction.hasNoAggregateIdCommand,
      creationAction,
      onDonePress,
      submitCommands,
      target,
      uploadFile,
    ]
  );
  const setErrorAlert = (
    title: string,
    errors: { formErrors: string[]; fieldErrors: string[]; formWarnings: string[]; fieldWarnings: string[] }
  ) => {
    openConfirmation({
      type: 'error',
      title,
      description: <>{renderContent(errors)}</>,
      showSubmit: false,
    });
  };
  const setWarningAlert = async (errors: ErrorsAndWarnings) => {
    return openConfirmation({
      type: 'error',
      title: 'Warning!',
      description: <>{renderContent(errors)}</>,
      confirmationText: 'Continue anyway',
    });
  };
  const getBars = (errors: any, warnings: any, fieldWarnings: any) => {
    const bars: InfoBar[] = [];
    if (!_.isEmpty(errors)) {
      Object.keys(errors).map(key => bars.push({ title: 'Error!', message: errors[key], type: InfoBarTypeEnum.ERROR }));
    }
    if (!_.isEmpty(warnings)) {
      Object.keys(warnings).map(key =>
        bars.push({ title: 'Form Level Warning!', message: warnings[key], type: InfoBarTypeEnum.WARNING })
      );
    }
    return bars;
  };

  const renderContent = (errorsAndWarnings: ErrorsAndWarnings) => {
    const { formErrors, fieldErrors, formWarnings, fieldWarnings } = errorsAndWarnings;

    // Build a list of errors & warnings to show to the user if there's a problem when they click "done"
    let alertMessages: string[] = [];
    const formHasErrors = !!formErrors.length || !!fieldErrors.length;

    // if we have errors then show those errors
    if (formHasErrors) {
      alertMessages = [...formErrors, ...fieldErrors];
    }

    // if we have no errors but we have warnings then show those warnings
    if (!formHasErrors) {
      alertMessages = [...formWarnings, ...fieldWarnings];
    }

    return (
      <Box>
        {[...alertMessages].map((value, index) => (
          <Typography className={styles.alertText} key={index}>
            - {translate(value)}
          </Typography>
        ))}
      </Box>
    );
  };

  useEffect(() => {
    if (target) {
      const temp: any = target;
      handleFormParentValueChange(temp?.geom);
    }
    return function cleanup() {
      handleFormParentValueChange(null);
    };
  }, []);

  const handleDonePress = async ({
    handleSubmit,
    errorData,
  }: {
    handleSubmit: () => void;
    errorData: ErrorsAndWarnings;
  }) => {
    if (errorData.formErrors.length > 0 || errorData.fieldErrors.length > 0) {
      return setErrorAlert('Error', errorData);
    } else if (errorData.formWarnings.length > 0 || errorData.fieldWarnings.length > 0) {
      const warnStatus = await setWarningAlert(errorData);
      if (warnStatus !== 'confirm') {
        return false;
      }
    }
    if (commandAction.confirmationText) {
      const status = await openConfirmation({
        title: 'Are you sure?',
        question: commandAction.confirmationText,
        confirmationText: 'Continue',
      });
      if (status !== 'confirm') {
        return false;
      }
    }
    handleSubmit();
  };

  const handleCancelPress = async (formChanged: boolean) => {
    if (formChanged) {
      const status = await openConfirmation({
        title: 'Unsaved Changes',
        question: 'Your workflow has unsaved changes. If you continue, your changes will be discarded.',
        confirmationText: 'Discard',
      });
      if (status === 'confirm') {
        setSelectedLocation(undefined);
        onCancelPress();
      }
    } else {
      onCancelPress();
    }
  };

  return (
    <Dialog open={openWorkflow} maxWidth={'xl'} classes={{ paper: styles.dialogPaper }}>
      {commandAction.command && (
        <FormRootRendererInitiated
          onSubmit={handleFormSubmission}
          dataMappingContext={dataMapping}
          template={commandAction.command.template}
          functionDefinitions={functionDefinitions ?? {}}
          render={({ handleSubmit, renderForm, formChanged, errors, warnings, fieldLevelWarnings }) => {
            const errorData = getErrorsAndWarnings(errors, warnings, fieldLevelWarnings);
            const { formErrors, formWarnings, fieldWarnings } = errorData;
            return (
              <FormRendererWrapper
                submitting={submitting}
                title={title}
                onDonePress={() => handleDonePress({ handleSubmit, errorData })}
                onCancelPress={() => handleCancelPress(formChanged)}
                canSave
                bars={getBars(formErrors, formWarnings, fieldWarnings)}>
                {renderForm()}
              </FormRendererWrapper>
            );
          }}
        />
      )}
    </Dialog>
  );
};

interface FomrRendererWrapperProps {
  title: string;
  children: React.ReactNode;
  onDonePress: () => void;
  onCancelPress?: () => void;
  canSave?: boolean;
  submitting: boolean;
  bars: InfoBar[];
}
const INFO_BAR_HEIGHT = 35;
const INFO_BAR_PADDING = 43;
export const FormRendererWrapper: React.FC<FomrRendererWrapperProps> = ({
  title,
  children,
  canSave,
  onDonePress,
  onCancelPress,
  submitting,
  bars,
}) => {
  const barHeight = bars.length * INFO_BAR_HEIGHT + INFO_BAR_PADDING;
  const containerHeight = !_.isEmpty(bars) ? FIELD_HEADER_HEIGHT + barHeight : FIELD_HEADER_HEIGHT;
  const wrapperStyles = useStyles({ containerHeight });
  const { translate } = useContext(LanguageContext);

  return (
    <div className={wrapperStyles.wrapperContainer}>
      <FieldHeader
        title={title}
        canSave={canSave}
        submitting={submitting}
        onDonePress={onDonePress}
        onCancelPress={onCancelPress}
        cancelButtonText={'Cancel'}
        doneButtonText={'Submit'}
        disableCloseButton={true}
        translate={translate}
      />
      <InfoBarCollection bars={bars} fullwidth={false} />
      <div className={wrapperStyles.content}>{children}</div>
    </div>
  );
};

export const useStyles = makeStyles(theme => ({
  wrapperContainer: {
    height: '100%',
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: colors.lotionWhite,
  },
  content: (props?: { containerHeight?: number }) => ({
    padding: '22px 30px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${props?.containerHeight}px)`,
    overflowY: 'auto',
    backgroundColor: colors.lotionWhite,
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 22,
    },
  }),
  dialogPaper: {
    height: '90vh',
    maxHeight: '100%',
    width: '680px',
    maxWidth: '680px',
    borderRadius: 5,
    boxShadow: 'none',
    overflow: 'hidden',
    '@media (max-width: 650px)': {
      width: '100%',
      margin: 0,
      height: '100%',
      borderRadius: 0,
    },
  },
  alertText: {
    color: colors.black75,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    marginBottom: 3,
  },
}));

export default WorkflowPage;
