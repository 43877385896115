"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldComputedMapEvaluator = exports.fieldDisplayMapEvaluator = exports.fieldWarningMapEvaluator = exports.formWarningMapEvaluator = exports.fieldErrorMapEvaluator = exports.formErrorMapEvaluator = exports.evaluator = void 0;
const gen5_datamapping_lib_1 = require("@terragotech/gen5-datamapping-lib");
// Common generic function used for all evaluation types
const evaluator = (accessors, evaluate, key, configFunctions, maps, skipFilter) => {
    if (!maps) {
        return Promise.resolve([]);
    }
    const promises = maps.map(currentMap => evaluate(currentMap, accessors, configFunctions));
    return Promise.all(promises).then(promiseResults => {
        return promiseResults.map(result => result[key]).filter(msg => skipFilter || typeof msg === 'string');
    });
};
exports.evaluator = evaluator;
// Error maps
const formErrorMapEvaluator = (accessors, configFunctions, errorMaps) => {
    return (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.FORM_LEVEL_ERROR.evaluate, 'errorMessage', configFunctions, errorMaps);
};
exports.formErrorMapEvaluator = formErrorMapEvaluator;
const fieldErrorMapEvaluator = (accessors, configFunctions, errorMaps) => {
    return (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.FIELD_LEVEL_ERROR.evaluate, 'errorMessage', configFunctions, errorMaps);
};
exports.fieldErrorMapEvaluator = fieldErrorMapEvaluator;
//Warning Maps
const formWarningMapEvaluator = (accessors, configFunctions, warningMaps) => {
    return (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.FORM_LEVEL_WARNING.evaluate, 'warningMessage', configFunctions, warningMaps);
};
exports.formWarningMapEvaluator = formWarningMapEvaluator;
const fieldWarningMapEvaluator = (accessors, configFunctions, warningMaps) => {
    return (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.FIELD_LEVEL_WARNING.evaluate, 'warningMessage', configFunctions, warningMaps);
};
exports.fieldWarningMapEvaluator = fieldWarningMapEvaluator;
const fieldDisplayMapEvaluator = (accessors, configFunctions, displayMap) => {
    return (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.DISPLAY_OPTIONS.evaluate, 'displayOptions', configFunctions, [displayMap], true);
};
exports.fieldDisplayMapEvaluator = fieldDisplayMapEvaluator;
const fieldComputedMapEvaluator = async (accessors, configFunctions, computedMap, skipFilter) => {
    return (await (0, exports.evaluator)(accessors, gen5_datamapping_lib_1.mapScenarios.COMPUTED_MAPPING.evaluate, 'result', configFunctions, [computedMap], skipFilter))[0];
};
exports.fieldComputedMapEvaluator = fieldComputedMapEvaluator;
