import React, { useState, useContext } from 'react';
import SlideDialog from '../components/SlideDialog';
import { TGCarousel } from '../components/Carousel/TGCarousel';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { colors } from '../styles/theme';
import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { DOC_API_URL } from '../../../../apps/web-app/src/utils/docApiURL';
import {
  AuthConnector,
  PhotoViewerParameters,
  photoViewerContext,
  usePhotoViewer,
} from '@terragotech/gen5-shared-components';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

let callback: ((images: string[]) => void) | undefined;

const PhotoViewerProvider = (props: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [photoIndexToRemove, setPhotoIndexToRemove] = useState<number | undefined>(undefined);
  const [isPhotoUploadFailed, setIsPhotoUploadFailed] = useState(false);
  const { openSnackbar } = useInfoContext();

  const open = ({ images, selectedPhoto, editMode, onClose }: PhotoViewerParameters) => {
    editMode && setEditMode(editMode);
    callback = onClose;
    setImages(images);
    setSelectedPhoto(selectedPhoto);
    setIsOpen(true);
  };

  const close = () => {
    images && callback?.(images);
    setIsOpen(false);
    setImages([]);
    setSelectedPhoto(undefined);
    callback = undefined;
    setEditMode(false);
  };

  const handlePhotoRemove = () => {
    if (photoIndexToRemove === undefined) return;
    setIsConfirmPopupOpen(false);
    const photos = cloneDeep(images);
    photos?.splice(photoIndexToRemove, 1);
    setImages(photos);
  };

  const handleCancelPhotoRemove = () => {
    setPhotoIndexToRemove(undefined);
    setIsConfirmPopupOpen(false);
  };

  const confirmPhotoRemove = (photoIndex: number) => {
    setPhotoIndexToRemove(photoIndex);
    setIsConfirmPopupOpen(true);
  };

  const handleOnPhotoChange = (index: number) => {
    setSelectedPhoto(images[index]);
  };

  const handlePhotoAdd = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (!file) return;
    const token = await AuthConnector.getToken();
    const formData = new FormData();
    const imageName = v4();
    formData.append('file', file);

    fetch(DOC_API_URL + imageName, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(() => {
        setIsLoading(false);
        openSnackbar({ title: 'Photo successfully uploaded', type: 'SUCCESS' });
        setImages([...images, imageName]);
        setSelectedPhoto(imageName);
      })
      .catch(error => {
        setIsLoading(false);
        setIsPhotoUploadFailed(true);
        console.error(error);
      });
  };

  return (
    <photoViewerContext.Provider
      value={{
        open,
      }}
      {...props}>
      {props.children}
      <SlideDialog maxWidth="xl" open={isOpen} onClose={close}>
        {/* TODO: Copied from AssetDataContainer, refactor repetition */}
        <div className={classes.header}>
          <Button className={classes.button} onClick={close} style={{ justifyContent: 'left', paddingLeft: 20 }}>
            <> {translate('Cancel')}</>
          </Button>
          <div className={classes.headerText}>{selectedPhoto || images[0]}</div>
          <Button className={classes.button} onClick={close} style={{ justifyContent: 'right', paddingRight: 20 }}>
            <> {translate('Done')}</>
          </Button>
        </div>
        <TGCarousel
          imagesIds={images}
          baseURL={DOC_API_URL}
          onPhotoChange={handleOnPhotoChange}
          selectedPhoto={selectedPhoto}
          editMode={editMode}
          onPhotoRemove={confirmPhotoRemove}
          onPhotoAdd={handlePhotoAdd}
        />
      </SlideDialog>
      <Dialog open={isConfirmPopupOpen} onClose={() => setIsConfirmPopupOpen(false)}>
        <DialogTitle>{translate('Are you sure you want to delete this image?')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate('This action can not be undone.')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelPhotoRemove} color="primary">
            {translate('NO, CANCEL')}
          </Button>
          <Button onClick={handlePhotoRemove} color="secondary" autoFocus>
            {translate('YES, DELETE')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isPhotoUploadFailed} onClose={() => setIsPhotoUploadFailed(false)}>
        <DialogTitle>{translate('Upload Failed')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('Oops, the selected file is over the 50mb limit. Please choose a smaller file.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPhotoUploadFailed(false)} color="primary">
            {translate('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </photoViewerContext.Provider>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    height: 46,
    minHeight: 46,
    backgroundColor: colors.white,
    display: 'grid',
    gridTemplate: '1fr / 200px 1fr 200px',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerText: {
    color: colors.black,
    textTransform: 'none',
    fontSize: 18,
    textAlign: 'center',
  },
  button: {
    color: colors.black,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  backdrop: {
    zIndex: 1400,
    color: colors.white,
  },
}));

export { usePhotoViewer, PhotoViewerProvider };
