import React, { MouseEventHandler, useRef, useState, useContext } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button, makeStyles, Paper, useTheme } from '@material-ui/core';
import { ImageWithHeaders } from '../ImageWithHeaders';
import { DOC_API_URL } from '../../utils/docApiURL';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { ReactComponent as NextButton } from './NextButton.svg';
import { ReactComponent as PrevButton } from './PrevButton.svg';
import { useCallback } from 'react';
import { getFileExtensionFromBlob } from '../../utils/fileHelper';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface TGCarouselProps {
  imagesIds: string[];
  imagesNames?: string[];
  baseURL: string;
  onPhotoChange: (index: number) => void;
  selectedPhoto?: string;
  editMode?: boolean;
  onPhotoRemove?: (photoIndex: number) => void;
  onPhotoAdd?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TGCarousel: React.FC<TGCarouselProps> = ({
  imagesIds,
  imagesNames,
  selectedPhoto,
  onPhotoChange,
  editMode,
  onPhotoAdd,
  onPhotoRemove,
}) => {
  const classes = useStyles();
  const [selectedPhotoIndex, setSelectedPhoto] = useState(selectedPhoto ? imagesIds.indexOf(selectedPhoto) : 0);
  const [requestedPhoto, setRequestedPhoto] = useState(selectedPhotoIndex);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const { translate } = useContext(LanguageContext);

  const downloadFile = async (fileUrl: string, fileName: string) => {
    const token = await AuthConnector.getToken();
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    });
    const blob = await response.blob();
    const fileExtension = await getFileExtensionFromBlob(blob);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension && !fileName.includes('.') ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  const handleClick = () => {
    imageInputRef &&
      imageInputRef.current &&
      typeof imageInputRef.current === 'object' &&
      imageInputRef.current?.click();
  };
  const handlePhotoChanged = useCallback(
    (index: number) => {
      setSelectedPhoto(index);
      onPhotoChange && onPhotoChange(index);
    },
    [onPhotoChange, setSelectedPhoto]
  );

  return (
    <div className={classes.wholeContainer}>
      <div className={classes.imageContainer}>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible={imagesIds?.length > 1 ? true : false}
          indicators={false}
          animation="slide"
          index={requestedPhoto}
          className={classes.carouselRoot}
          onChange={handlePhotoChanged}
          NavButton={({ onClick, className, style, next, prev }) => {
            return (
              <Button
                onClick={onClick as MouseEventHandler<HTMLButtonElement>}
                className={className}
                style={{ ...style, backgroundColor: 'unset' }}
              >
                {next && <NextButton />}
                {prev && <PrevButton />}
              </Button>
            );
          }}
        >
          {imagesIds?.length !== 0 ? (
            imagesIds.map((slide, index) => (
              <Paper key={index} className={classes.paper}>
                <ImageWithHeaders url={DOC_API_URL + slide} className={classes.image} />
                <Button
                  onClick={() => downloadFile(DOC_API_URL + slide, imagesNames ? imagesNames[index] : slide)}
                  variant="contained"
                  className={classes.imageDownloadButton}
                >
                  {translate('DOWNLOAD')}
                </Button>
              </Paper>
            ))
          ) : (
            <NoPhotosIndicator />
          )}
        </Carousel>
      </div>
      <div className={classes.thumbnailSection}>
        <div className={classes.thumbnailRow}>
          {imagesIds.map((slide, index) => (
            <div
              className={classes.thumbnailContainer}
              style={{ border: selectedPhotoIndex === index ? `${theme.palette.primary.main} solid` : '' }}
              key={index}
            >
              <ImageWithHeaders
                url={DOC_API_URL + slide}
                className={classes.thumbnail}
                onClick={() => setRequestedPhoto(index)}
              />
              {editMode && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    onPhotoRemove?.(index);
                  }}
                  className={classes.deleteButton}
                >
                  <DeleteIcon style={{ color: 'white', fontSize: 18 }} />
                </div>
              )}
            </div>
          ))}
        </div>
        {editMode && (
          <div className={classes.addImageSection}>
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={onPhotoAdd}
              ref={imageInputRef}
              style={{ display: 'none' }}
            />
            <Button
              style={{ position: 'relative', top: 150 / 2 - 18 }}
              variant="contained"
              startIcon={<AddPhotoAlternateIcon />}
              onClick={handleClick}
            >
              {translate('ADD IMAGE')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const NoPhotosIndicator: React.FC = () => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <div id="noImages" className={classes.noPhotosIndicator}>
      {translate('No images available')}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  noPhotosIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 22,
  },
  paper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'black',
  },
  wholeContainer: { height: '100%', display: 'flex', flexDirection: 'column' },
  imageContainer: {
    flex: '1 0 auto',
    height: 'calc(100vh - 330px)',
    padding: 20,
    '& .CarouselItem': { height: '100%' },
    '& .CarouselItem div': { height: '100%' },
  },
  thumbnailSection: {
    minHeight: 150,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: '50px',
    marginRight: '50px',
  },
  addImageSection: {
    minWidth: 140,
    marginLeft: 12,
  },
  thumbnailRow: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    marginBottom: 30,
    overflowY: 'hidden',
    paddingTop: 5,
    minWidth: 131,
  },
  thumbnailContainer: {
    boxSizing: 'border-box',
    position: 'relative',
    height: 150,
    width: 150,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    cursor: 'pointer',
    marginBottom: 10,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  },
  carouselRoot: { height: '100%', backgroundColor: 'black' },
  image: { maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' },
  imageDownloadButton: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 300,
  },
  deleteButton: {
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: '#eb5757',
    top: -5,
    right: -5,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '-2px 2px 3px 0px rgba(0,0,0,0.75)',
  },
}));
