import { gql } from '@apollo/client';

export const GET_API_MESSAGES = gql`
  query apiMessagesConnection(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $filter: ApiMessageFilter
    $condition: ApiMessageCondition
    $orderBy: [ApiMessagesOrderBy!]
  ) {
    apiMessagesConnection(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      condition: $condition
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          direction
          integrationType
          responseCode
          requestDate
          responseDate
          requestData
          responseData
          relatedData
          processTime
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
