import { useContext, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import RecordsTab from './RecordsTab';
import AssetRecord, { RECORD_WIDTH } from './AssetRecord';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { colors } from '../styles/theme';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';


interface Props {
  types: {
    typeLabel: string;
    type: string;
    attachedItems: MapAssetType[];
    excludeLink?: boolean;
  }[];
  onSeeAll: (name: string) => void;
  onBack: () => void;
  isSeeAll: string;
}

const TGAssetRecords = (props: Props) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [activeTab, setActiveTab] = useState(translate(props.types[0]?.typeLabel) || '');

  const updateActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const records = props.types.find(o => o.typeLabel === activeTab)?.attachedItems;
  return (
    <Box className={classes.container}>
      <RecordsTab
        tabItems={props.types.map(type => ({ label: type.typeLabel, count: type.attachedItems.length }))}
        activeTab={activeTab}
        updateActiveTab={updateActiveTab}
      />
      {_.size(records) > 0 ? (
        <Box className={classes.items}>
          {records?.map((record, i) => {
            return <AssetRecord key={i} record={record} />;
          })}
        </Box>
      ) : (
        <Box className={classes.emptyContainer}>
          <Box className={classes.content}>
            <Typography className={classes.title}>{translate('No Records Attached')}</Typography>
            <Typography className={classes.description}>
              {translate('There are currently no records attached.')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => {
  const mobileBreakpoint = theme.breakpoints.down(MOBILE_BREAKPOINT);
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      height: '100%',
      [mobileBreakpoint]: {
        gap: 12,
      },
    },
    emptyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 5,
      color: colors.black54,
      fontWeight: 400,
    },
    title: {
      fontSize: 18,
      fontWeight: 400,
      color: colors.black54,
      [mobileBreakpoint]: {
        fontSize: 16,
      },
    },
    description: {
      fontSize: 14,
    },
    items: {
      gap: 20,
      display: 'grid',
      gridTemplate: `1fr / repeat(auto-fill, minMax(${RECORD_WIDTH}px, 1fr))`,
      padding: '0px 80px',
      [mobileBreakpoint]: {
        padding: '0px 18px',
        gap: 12,
      },
    },
  };
});

export default TGAssetRecords;
