import React, { useContext} from 'react';
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

interface ButtonProps {
  onReset: () => void;
  onCancel: () => void;
  onDone: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      padding: '14px 30px',
      boxShadow: `0px -2px 4px 0px ${colors.black05}`,
      background: colors.white,
      zIndex: 1,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '14px 22px',
      },
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 14,
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        gap: 20,
      },
    },
    resetButton: {
      color: theme.palette.primary.main,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 500,
      padding: '10px 3px',
      lineHeight: 'normal',
      '&:hover': {
        background: 'none',
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        textAlign: 'left',
        fontSize: 15,
        fontWeight: 400,
      },
    },
    applyBtn: {
      padding: '10px 50px',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 'normal',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '10px 36px',
        height: 40,
      },
    },
    cancelBtn: {
      padding: '10px 30px',
      borderRadius: 5,
      background: colors.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      textTransform: 'capitalize',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 'normal',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: 40,
      },
    },
    groupBtn: {
      display: 'flex',
      gap: 14,
      alignItems: 'center',
    },
  })
);

const BottomBarButtons: React.FC<ButtonProps> = ({ onReset, onCancel, onDone }) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  return (
    <div className={classes.container}>
      <div className={classes.buttonGroup}>
        <Button className={classes.resetButton} onClick={onReset}>
          {translate('Reset to Default')}
        </Button>
        <div className={classes.groupBtn}>
          <Button onClick={onCancel} className={classes.cancelBtn}>
            {translate('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onDone} className={classes.applyBtn}>
            {translate('Apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BottomBarButtons;
