import { Role, User } from './types';
import { Column } from '../../hooks/tableHooks/useColumns';
import React from 'react';
import StyledTableSelectEditor from '../../components/StyledTable/Editors/StyledTableSelectEditor';
import AdminPermissionsEditorModal from './AdminPermissionsEditorModal';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';

const roleMapper = (_role: unknown) => {
  const role = _role as Role;
  return { value: role.id.toString(), name: role.name };
};

export const generateColumns: (
  roles: readonly Role[],
  numberOfUsersWithAnalyticsPermissions: number,
  translate: (key: string, params?: Record<string, string | number | boolean>) => string
) => Column<User>[] = (roles, numberOfUsersWithAnalyticsPermissions, translate) => [
  { name: 'Email', key: 'email', dataType: 'String' },
  { name: 'First Name', key: 'firstName', minWidth: 200, dataType: 'String' },
  { name: 'Last Name', key: 'lastName', minWidth: 200, dataType: 'String' },
  { name: 'Username', key: 'username', editable: false, dataType: 'String' },
  {
    name: 'Roles',
    key: 'roles',
    minWidth: 200,
    cellRenderer: ({ row }) => (
      (
        <div>
          <>
            {row.roles.length > 0 && row.roles.length === roles.length ? translate('All Roles') : row.roles.join(', ')}
          </>
        </div>
      )
    ),
    cellEditor: React.forwardRef((editorProps, ref) => (
      <StyledTableSelectEditor {...editorProps} ref={ref} mapper={roleMapper} lookup={[...roles]} label="Select Role" />
    )),
    filterInputOptions: {
      valueDelimiter: ',',
    },
  },
  {
    name: 'Permissions',
    key: 'adminPermissions',
    cellRenderer: ({ row }) => <div>{permissionLabel(row.adminPermissions).join(', ')}</div>,
    cellEditor: React.forwardRef((editorProps, ref) => (
      <AdminPermissionsEditorModal
        {...editorProps}
        numberOfUsersWithAnalyticsPermissions={numberOfUsersWithAnalyticsPermissions}
      />
    )),
    filterInputOptions: {
      valueDelimiter: ', ',
    },
  },
];
