import { createPageControlProvider, colors, useConfig } from '@terragotech/gen5-shared-components';
import {
  FieldProps,
  initPageRootRenderer,
  NonRepeatableFieldProps,
  PageControlProps,
  V2LinkComponent,
  V2ListComponent,
  V2MapComponent,
  V2StreetViewComponent,
} from '@terragotech/page-renderer';
import React, { useContext, useEffect, useMemo } from 'react';
import { AssetType } from '../../contexts/AggregatesContext/types';
import { FormParentValueContext } from '../../contexts/formParentValueContext';
import { useWorkflowDataMapping } from '../../hooks/useWorkflowDataMapping';
import TGPageMapField, { TGMapFieldProps } from './TGMapField';
import TGPageStreetViewField, { TGStreetViewFieldProps } from './TGStreetViewField';
import PageFABContainer from '../../pages/PageFABContainer';
import { useInfoContext } from '../../contexts/InfoContext/infoContext';
import { SelectedLocationProvider } from '../../contexts/selectedLocationContext';
import { ActionsExecutorProvider } from '../../hooks/useActionsExecutor';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import TGListField, { TGListFieldProps } from './TGListField';
import { useRecordType } from '../../contexts/recordTypeContext';
import useRouteParams from '../Common/useRouteParams';
import TGLinkField, { TGPageLinkFieldProps } from './TGLinkField';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const HEADER_HEIGHT = 260;

const useFilterMapFieldProps = (props: NonRepeatableFieldProps<V2MapComponent>, type?: string) => {
  const { value, controlDefinition, readOnly } = props;
  const {
    label,
    info,
    oneLine,
    aggregates,
    height,
    symbols,
    includeWMS,
    latSplit,
    lonSplit,
    limitPerSection,
    showLocationSearch,
  } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    value,
    label,
    info,
    readOnly,
    oneLine,
    aggregates,
    height,
    symbols,
    includeWMS,
    latSplit,
    lonSplit,
    limitPerSection,
    showLocationSearch,
  };
  return filteredProps;
};
export const withPageMapProps = (Component: React.FC<TGMapFieldProps>) => {
  return (props: NonRepeatableFieldProps<V2MapComponent>) => {
    const filteredProps = useFilterMapFieldProps(props);
    return <Component {...filteredProps} hideVisibility={true} />;
  };
};

const useFilterStreetViewFieldProps = (props: NonRepeatableFieldProps<V2StreetViewComponent>, type?: string) => {
  const { value, controlDefinition, readOnly } = props;
  const { label, info, oneLine, lat, lon, height } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    value,
    label,
    info,
    readOnly,
    oneLine,
    lat,
    lon,
    height,
  };
  return filteredProps;
};
export const withPageStreetViewProps = (Component: React.FC<TGStreetViewFieldProps>) => {
  return (props: NonRepeatableFieldProps<V2StreetViewComponent>) => {
    const filteredProps = useFilterStreetViewFieldProps(props);
    return <Component {...filteredProps} />;
  };
};
const useListFieldProps = (props: PageControlProps<V2ListComponent>, type?: string) => {
  const { controlDefinition, value } = props;
  const { label, height, columns, computedMap, info, oneLine } = controlDefinition;
  const filteredProps = {
    type: type || 'text',
    value,
    label,
    info,
    oneLine,
    height,
    columns,
    computedMap,
  };
  return filteredProps;
};
export const withPageListProps = (Component: React.FC<TGListFieldProps>) => {
  return (props: PageControlProps<V2ListComponent>) => {
    const filteredProps = useListFieldProps(props);
    return <Component {...filteredProps} />;
  };
};

export const withLinkFieldProps = (Component: React.FC<TGPageLinkFieldProps>) => {
  return (props: FieldProps<V2LinkComponent>) => {
    const { value, controlDefinition, readOnly } = props;
    const { label, placeholder, info, oneLine, truncate } = controlDefinition;
    const filteredProps = {
      value,
      label,
      placeholder,
      info,
      readOnly,
      oneLine,
      truncate,
    };
    return <Component {...filteredProps} />;
  };
};

interface PagePageProps {
  page: string;
  target?: AssetType;
  noPadding?: boolean;
  fabContainerKey?: string;
  isAssetCard?: boolean;
  noMargin?: boolean;
  showLoader?: boolean;
  isMobileView?: boolean;
  display?: string;
}

const PagePage: React.FC<PagePageProps> = props => {
  const { target, page, noPadding, fabContainerKey, isAssetCard, noMargin, showLoader, isMobileView, display } = props;
  const { translate } = useContext(LanguageContext);
  const { handleFormParentValueChange } = useContext(FormParentValueContext);
  const { functionDefinitions, pageDefinitions, defaultDateTimeFormat } = useConfig();
  const dataMapping = useWorkflowDataMapping(target);
  const { selectedRecordType } = useRecordType();
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType,
  });
  const displayStyle = {
    display: isMobileView && isAssetCard ? 'block' : display || 'flex',
    ...(display && {
      paddingTop: 0,
      paddingRight: 0,
    }),
  };

  const classes = useStyles({ isAssetCard });
  useEffect(() => {
    if (target) {
      const temp: any = target;
      handleFormParentValueChange(temp?.geom);
    }
    return function cleanup() {
      handleFormParentValueChange(null);
    };
  }, []);

  const { openSnackbar } = useInfoContext();
  useEffect(() => {
    if (pageDefinitions && pageDefinitions[page] && pageDefinitions[page].infoPopover) {
      const isTouch =
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 || //@ts-ignore
        navigator.msMaxTouchPoints > 0;
      openSnackbar({
        title: pageDefinitions[page].infoPopover
          ?.replaceAll(/(\.\s+|^\s*)\$__SELECT_METHOD__\$/g, (isTouch ? '$1Long press' : '$1Right click') + ' the map')
          .replaceAll('$__SELECT_METHOD__$', (isTouch ? 'long press' : 'right click') + ' the map'),
        type: 'INFO',
        horizontalPosition: 'center',
        autoHideDuration: null,
        disableClickAway: true,
      });
    }
  }, []);

  const Loader = () => {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  };

  const PageControlProvider = useMemo(() => createPageControlProvider({ translate }), [translate]);

  const PageRootRendererInitiated = useMemo(
    () =>
      initPageRootRenderer({
        ...PageControlProvider,
        aggregateMap: withPageMapProps(TGPageMapField),
        streetView: withPageStreetViewProps(TGPageStreetViewField),
        list: withPageListProps(TGListField),
        link: withLinkFieldProps(TGLinkField),
      }),
    [PageControlProvider]
  );

  return pageDefinitions && pageDefinitions[page] ? (
    <SelectedLocationProvider>
      <ActionsExecutorProvider>
        {!isAssetCard && (
          <PageFABContainer
            containerKey={fabContainerKey}
            // @ts-ignore TODO: Fix typings for FabActions with and without gql transform.
            buttons={pageDefinitions[page].fabActions}
          />
        )}
        <div
          style={displayStyle}
          className={
            !isMobileView
              ? isAssetDetailsOpen
                ? [classes.assetAttributeContainer, classes.editPage].join(' ')
                : classes.assetAttributeContainer
              : ''
          }>
          <div className={noPadding ? '' : classes.assetCard}>
            <PageRootRendererInitiated
              dataMappingContext={dataMapping}
              template={pageDefinitions[page]}
              functionDefinitions={functionDefinitions ?? {}}
              defaultDateTimeFormat={defaultDateTimeFormat}
              render={({ renderPage }) => renderPage()}
              disableStreetView={isAssetCard}
              noMargin={noMargin}
              loader={showLoader ? Loader : undefined}
            />
          </div>
        </div>
      </ActionsExecutorProvider>
    </SelectedLocationProvider>
  ) : (
    <></>
  );
};
const useStyles = makeStyles(theme => ({
  assetCard: ({ isAssetCard }: { isAssetCard?: boolean }) => ({
    backgroundColor: colors.white,
    padding: '12px 18px',
    overflowY: 'auto',
    flex: 1,
    height: 'auto',
    minHeight: 100,
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      flex: 'none',
      height: isAssetCard ? '100px' : '100%',
      minHeight: '100px',
      width: 'calc(100% - 36px)'
    },
  }),
  assetAttributeContainer: {
    paddingRight: 0,
    minHeight: 100,
    background: colors.white,
    paddingTop: 0,
  },
  editPage: {
    display: 'block',
  },
  loader: {
    width: '100%',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default React.memo(PagePage);
