import { Typography, makeStyles, Box, Popover, IconButton, Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuthContext } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { colors } from '../../styles/theme';
import React, { useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { middleTruncate } from '@terragotech/gen5-shared-utilities';
import { setDisclaimerState } from '../../utils/utilityHelper';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { useLanguage } from '../../hooks/useLanguage';

export interface UserInfoSectionProps {
  firstName: string;
  lastName: string;
  email: string;
  clearToken?: () => void;
}

const Header = ({ handleClose }: { handleClose: () => void }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.popoverHeader}`}>
      <IconButton onClick={handleClose} className={classes.closeRoot}>
        <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

const PopupHeader = ({ handleClose, title }: { title: string; handleClose: () => void }) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <div className={`${classes.languageHeader}`}>
      <Typography className={classes.header}>{translate(title)}</Typography>
      <IconButton onClick={handleClose} className={classes.closeRoot}>
        <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

const InnerContainer = ({ initial, props }: { initial: string | undefined; props: UserInfoSectionProps }) => {
  const { firstName, email, lastName } = props;
  const { translate, language } = useContext(LanguageContext);
  const classes = useStyles();
  const { clearToken } = useAuthContext();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const truncateTxtNumber = isMobileView ? 26 : 37;
  const handleLogout = () => {
    setDisclaimerState(false);
    clearToken();
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { items: languages } = useLanguage();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const Icon = useMemo(() => {
    return <FontAwesomeIcon icon={anchorEl ? faAngleDown : faAngleUp} className={classes.icon} />;
  }, [anchorEl]);
  const paperMenuProps = {
    style: {
      width: 175,
      maxHeight: 225,
    },
  };

  return (
    <>
      <Box
        className={isMobileView ? [classes.deskUserLogoroot, classes.userLogoMob].join(' ') : classes.deskUserLogoroot}>
        <Typography className={[classes.initial, classes.initialRoot].join(' ')}>{initial}</Typography>
      </Box>
      <Box className={[isMobileView ? {} : classes.innerRoot].join(' ')}>
        <Typography className={!isMobileView ? classes.username : [classes.username, classes.usernameMob].join(' ')}>
        <>{`${firstName}${lastName?.length > 0 ? ` ${lastName}` : ''}`}</>
        </Typography>
        <Typography className={isMobileView ? [classes.usermail, classes.useremailMob].join(' ') : classes.usermail}>
        <>{middleTruncate(email, truncateTxtNumber) as string}</>
        </Typography>
        <Box className={classes.bottomBar}>
          <span className={classes.lgbtn} onClick={handleLogout}>
            {translate('Logout')}
          </span>
          <Button
            className={isMobileView ? classes.languageContainerMob : classes.languageContainer}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}>
            <div className={classes.language}>
              <FontAwesomeIcon icon={faGlobe as IconProp} className={classes.languageIcon} />
              <Typography className={classes.titleTxt}>{translate(language)}</Typography>
              {Icon}
            </div>
          </Button>
          {anchorEl && (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={paperMenuProps}
              classes={{ paper: classes.menuRoot }}>
              <>
                <PopupHeader handleClose={handleClose} title={'Language'} />
                <Box style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <div className={classes.languageOptions}>{languages.content}</div>
                </Box>
              </>
            </Popover>
          )}
        </Box>
      </Box>
    </>
  );
};

const NewContent = (props: UserInfoSectionProps) => {
  const classes = useStyles();
  const { firstName, email } = props;
  const initial = firstName ? _.toUpper(_.first(firstName)) : _.toUpper(_.first(email));
  const { isMobileView } = useContext(AssetsDashboardContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const paperMenuProps = {
    style: {
      width: 289,
      minHeight: 215,
    },
  };
  return isMobileView ? (
    <Box>
      <InnerContainer initial={initial} props={props} />
    </Box>
  ) : (
    <>
      <Box className={classes.deskUserLogo} onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}>
        <Typography className={classes.initial}>{initial}</Typography>
      </Box>
      <>
        {anchorEl && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={paperMenuProps}
            classes={{ paper: classes.menuRoot }}>
            <Header handleClose={handleClose} />
            <InnerContainer initial={initial} props={props} />
          </Popover>
        )}
      </>
    </>
  );
};

export const userInfoSection = (props: UserInfoSectionProps) => ({
  title: 'authSection',
  content: <NewContent {...props} />,
  icon: <ExitToAppIcon />,
  iconClickHandler: props.clearToken,
});

const centerFlex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 0,
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: 14,
  },
  firstName: {
    paddingRight: 7,
  },
  innerRoot: {
    marginInline: 19,
    marginTop: 17,
  },
  firstPart: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  item: {
    padding: 0,
    cursor: 'pointer',
  },
  innerRootitem: {
    marginInline: 1,
  },
  itemText: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: '13px',
  },
  header: {
    color: colors.black0,
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  languageOptions: {
    position: 'relative',
  },
  languageIcon: {
    height: 18.857,
    width: 22,
    color: colors.black54,
  },
  titleTxt: {
    fontSize: 14,
    fontWeight: 450,
    padding: '2px 5px',
    color: colors.black54,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  languageContainer: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  languageContainerMob: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
    paddingLeft: 25,
  },
  language: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  icon: {
    color: colors.black54,
    position: 'relative',
    width: 11,
  },
  languageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    zIndex: 999,
    top: 0,
    paddingTop: 17,
    paddingLeft: 24,
    paddingRight: 14,
    backgroundColor: colors.white,
  },
  itemIcon: { minWidth: 0, paddingRight: 5, marginBottom: 0.5 },
  userLogo: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.primary.main,
    ...centerFlex,
  },
  deskUserLogo: {
    width: 34,
    height: 34,
    borderRadius: 34 / 2,
    backgroundColor: theme.palette.primary.main,
    ...centerFlex,
    cursor: 'pointer',
  },
  deskUserLogoroot: {
    width: 53,
    height: 53,
    borderRadius: 53 / 2,
    backgroundColor: theme.palette.primary.main,
    ...centerFlex,
    marginTop: 30,
    marginLeft: 19,
  },
  userLogoMob: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 10,
    height: 30,
    width: 30,
  },
  useremailMob: {
    marginBottom: '20px !important',
  },
  initial: {
    color: colors.white,
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 'normal',
  },
  initialRoot: {
    fontSize: 24,
  },
  logoutContainer: {
    marginTop: 16,
    cursor: 'pointer',
  },
  logoutText: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: 'normal',
  },
  menuRoot: {
    borderRadius: 5,
    background: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    transform: 'translate(3px, -10px) !important',
  },
  closeRoot: {
    width: 24,
    height: 24,
  },
  popoverHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    paddingTop: 10,
    paddingRight: 10,
  },
  username: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    color: colors.black0,
    lineHeight: 'normal',
  },
  usernameMob: {
    fontSize: 16,
  },
  usermail: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 300,
    color: colors.black0,
    lineHeight: 'normal',
    marginTop: 6,
    marginBottom: 23,
  },
  bottomBar: {
    paddingBottom: 30,
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lgbtn: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: 'normal',
    cursor: 'pointer',
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
    cursor: 'pointer',
  },
  userContainer: {
    height: 80,
    width: 70,
    ...centerFlex,
  },
}));
