import { useState, useMemo, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import CompletedJobRow from './CompletedJobRow';
import PendingJobRow from './PendingJobRow';
import FileToView from './FileToView';
import { QueryResult } from '../../containers/ImportContainer';
import { SortDirection } from '@material-ui/core/TableCell';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { colors } from '../../styles/theme';
import ImportTable from './ImportTable';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const MobileBreakpoint = 577;

export interface SelectedImportType {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  success?: boolean;
  filename: string;
  metadata?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
}

type UserInfo = Record<string, any>;

export interface Import {
  [key: string]: any;
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  success?: boolean;
  isintegration?: boolean;
  filename: string;
  metadata?: {
    userInfo?: UserInfo;
  };
  target: string;
  timestamp: Date;
  type: string;
  version: string;
  commandError?: number;
  commandErrorMessage?: string;
}
const TABLE_WRAPPER_HEIGHT = 108;
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 800,
  },
  fileCell: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    maxWidth: 300,
    padding: '11px 0px 11px 11px',
  },

  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    backgroundColor: colors.white,
    height: '100%',
  },
  body: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(MobileBreakpoint)]: {
      overflowY: 'scroll',
    },
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down(MobileBreakpoint)]: {
      height: `calc(100% - ${TABLE_WRAPPER_HEIGHT}px)`,
    },
  },
  tableFlexContainer: {
    width: '99%',
    display: 'flex',
  },
}));

interface Props {
  data?: QueryResult | undefined;
  commit: (x: string) => void;
  fileIdToView: string | undefined;
  setFileIdToView: (x: string | undefined) => void;
  buttons: UseConditionalImport[] | undefined;
  loading?: boolean;
}

type SortConfig = {
  [key: string]: SortDirection | undefined;
};

const ImportHistory = (props: Props) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const { data, commit, fileIdToView, setFileIdToView, buttons, loading } = props;
  const [selectedImport, setSelectedImport] = useState<SelectedImportType>();
  const [pendingSortingOrder, setPendingSortingOrder] = useState<SortConfig>({ timestamp: 'desc' });
  const [completedSortingOrder, setCompletedSortingOrder] = useState<SortConfig>({ timestamp: 'desc' });
  const [isPendingJobsEmpty, setIsPendingJobsEmpty] = useState(true);
  const [isCompletedJobsEmpty, setIsCompletedJobsEmpty] = useState(true);
  const sortedPendingJobs = useMemo(() => {
    if (data?.imports.length === 0 || !data?.imports) return [];
    const tempOrder: Import[] = [...data.imports];
    const pendingJobs: Import[] = tempOrder.filter(x => x?.success === null);
    let sortableItems = [...pendingJobs];
    const sortingColumn = Object.keys(pendingSortingOrder)[0];
    const sortDirection = pendingSortingOrder[sortingColumn];
    if (!sortDirection) {
      return sortableItems;
    }
    const getValue = (value: Import) => {
      if (sortingColumn === 'status') {
        if (value?.commandError != null) {
          return 'Error';
        } else if (value?.committed === null) {
          return 'Uploading';
        } else if (value?.committed === true) {
          return 'Importing';
        } else if (value?.errorsWarnings.length) {
          return 'Needs Action';
        } else {
          return 'Pending';
        }
      }
      if (sortingColumn === 'email') {
        return value.metadata?.userInfo?.email;
      }
      return value[sortingColumn];
    };
    sortableItems.sort((a, b) => {
      if (sortingColumn === 'timestamp') {
        if (sortDirection === 'asc') {
          return String(b.timestamp).localeCompare(String(a.timestamp));
        } else {
          String(a.timestamp).localeCompare(String(b.timestamp));
        }
      }
      let aValue = getValue(a);
      let bvalue = getValue(b);
      if (sortDirection === 'asc') {
        return aValue.localeCompare(bvalue);
      }
      if (sortDirection === 'desc') {
        return bvalue.localeCompare(aValue);
      }
      return 0;
    });
    return sortableItems;
  }, [data?.imports, pendingSortingOrder]);

  const sortedCompletedJobs = useMemo(() => {
    if (data?.imports.length === 0 || !data?.imports) return [];
    const tempOrder: Import[] = [...data.imports];
    const completedJobs: Import[] = tempOrder.filter(x => x?.success !== null);
    let sortableItems = [...completedJobs];
    const sortingColumn = Object.keys(completedSortingOrder)[0];
    const sortDirection = completedSortingOrder[sortingColumn];
    if (!sortDirection) {
      return sortableItems;
    }
    const getValue = (value: Import) => {
      if (sortingColumn === 'status') {
        if (value?.commandError != null) {
          return 'Error';
        } else if (value?.success) {
          return 'Imported';
        } else if (value?.errorsWarnings.filter(e => e.is_error).length || value?.committed) {
          return 'Failed';
        } else {
          return 'Cancelled';
        }
      }
      if (sortingColumn === 'email') {
        return value.metadata?.userInfo?.email;
      }
      return value[sortingColumn];
    };
    sortableItems.sort((a, b) => {
      if (sortingColumn === 'timestamp') {
        if (sortDirection === 'asc') {
          return String(b.timestamp).localeCompare(String(a.timestamp));
        } else {
          String(a.timestamp).localeCompare(String(b.timestamp));
        }
      }
      let aValue = getValue(a);
      let bvalue = getValue(b);
      if (sortDirection === 'asc') {
        return aValue.localeCompare(bvalue);
      }
      if (sortDirection === 'desc') {
        return bvalue.localeCompare(aValue);
      }
      return 0;
    });
    return sortableItems;
  }, [data?.imports, completedSortingOrder]);
  useEffect(() => {
    if (sortedPendingJobs.length > 0) {
      setIsPendingJobsEmpty(false);
    } else {
      setIsPendingJobsEmpty(true);
    }
  }, [sortedPendingJobs]);
  useEffect(() => {
    if (sortedCompletedJobs.length > 0) {
      setIsCompletedJobsEmpty(false);
    } else {
      setIsCompletedJobsEmpty(true);
    }
  }, [sortedCompletedJobs]);

  const tableHeaders = [
    {
      label: 'File',
      key: 'filename',
    },
    {
      label: 'Type',
      key: 'type',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Date',
      key: 'timestamp',
    },
    {
      label: 'Actions',
      key: 'actions',
    },
  ];

  const sortingOrder = (exsistingOrder: SortDirection | undefined) => {
    if (exsistingOrder === 'asc') {
      return 'desc';
    }
    if (exsistingOrder === 'desc') {
      return undefined;
    }
    return 'asc';
  };

  const handleSort = (key: string, completedTable?: boolean) => {
    if (!completedTable) {
      setPendingSortingOrder(prev => ({
        [key]: sortingOrder(prev[key]),
      }));
    } else {
      setCompletedSortingOrder(prev => ({
        [key]: sortingOrder(prev[key]),
      }));
    }
  };

  const commonFields = { tableHeaders, handleSort, setFileIdToView, setSelectedImport, selectedImport, loading };
  const importData = [
    {
      ...commonFields,
      sortOrder: pendingSortingOrder,
      sortedJobs: sortedPendingJobs,
      emptyLabel: 'No pending jobs',
      type: 'Pending' as const,
      JobRow: PendingJobRow,
      isEmpty: isPendingJobsEmpty,
    },
    {
      ...commonFields,
      sortOrder: completedSortingOrder,
      sortedJobs: sortedCompletedJobs,
      emptyLabel: 'No completed jobs',
      type: 'Completed' as const,
      JobRow: CompletedJobRow,
      isEmpty: isCompletedJobsEmpty,
    },
  ];

  return (
    <div className={classes.root}>
      {!fileIdToView ? (
        <div className={classes.body}>
          <div className={classes.tableFlexContainer}>
            <div className={classes.tableWrapper}>
              {importData.map(importItem => (
                <ImportTable key={importItem.type} {...importItem} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.body}>
          <FileToView fileIdToView={fileIdToView} setFileIdToView={setFileIdToView} commit={commit} buttons={buttons} />
        </div>
      )}
    </div>
  );
};

export default ImportHistory;
