import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import logoIcon from '../../images/streetlightsLogo.svg';
import LoginForm from './LoginForm';
import TempPasswordDialog from './TempPasswordModal';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { colors } from '../../../src/styles/theme';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';

interface LoginScreenProps {
  email: string;
  password: string;
  code: string;
  errorMessage?: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  attemptLogin: () => void;
  codeNeeded: boolean;
  emailNeeded: boolean;
  submitAuthCode: () => void;
  newPasswordNeeded: boolean;
  completeNewPassword: (password: string) => void;
  selectEmailLogin: () => void;
  selectLogin: () => void;
  validateUser?: boolean;
  validateformUser?: any;
  isCompleteLogin?: boolean;
}

/**
 * Login Screen
 *
 * @remarks Login Screen is a form for manipulating and submitting state in LoginScreenContainer.
 *
 * props.attemptLogin called for email user authentication handling in container.
 * props.submitAuthCode called for code user authentication handling in container.
 * props.selectEmailLogin called for switching to email context in container.
 *
 * @param email - string
 * @param password - string
 * @param code - string
 * @param errorMessage - string
 * @param loginFailed - boolean
 * @param onEmailChange - handleEmailChange (function)
 * @param onCodeChange - handleCodeChange (function)
 * @param onPasswordChange - handlePasswordChange (function)
 * @param attemptLogin - attemptLogin (function)
 * @param codeNeeded - boolean
 * @param emailNeeded - boolean
 * @param submitAuthCode -submitAuthCode (function)
 * @param newPasswordNeeded - boolean
 * @param completeNewPassword - completeNewPassword (function)
 * @param selectEmailLogin - selectEmailLogin (function)
 * @param selectLogin - selectLogin (function)
 * @param validateUser - boolean
 * @param validateformUser - validateformUser (function)
 * @param isCompleteLogin - boolean
 */

const LoginScreen: React.FunctionComponent<LoginScreenProps> = props => {
  const {
    email,
    password,
    code,
    onEmailChange,
    onCodeChange,
    onPasswordChange,
    attemptLogin,
    codeNeeded,
    emailNeeded,
    errorMessage,
    submitAuthCode,
    newPasswordNeeded,
    completeNewPassword,
    selectEmailLogin,
    validateUser,
    validateformUser,
    selectLogin,
    isCompleteLogin,
  } = props;
  const [haslegalDisclaimer, setHasLegalDisclaimer] = useState<boolean>(true);
  const classes = useStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const uiConfig = useConfig();
  const styles = {
    width: !haslegalDisclaimer && isMobileView ? '100%' : isMobileView ? 344 : '100%',
    top: !haslegalDisclaimer && isMobileView ? 58 : haslegalDisclaimer ? 95 : 55,
  };
  return (
    <>
      <div className={classes.titleContainer}>
        <Typography className={classes.headerTitle}>{uiConfig && uiConfig.applicationName}</Typography>
      </div>
      <div className={classes.loginScreenWrapper} style={styles}>
        {haslegalDisclaimer && (
          <Box className={classes.root}>
            <div className={classes.logoContainer}>
              <img
                src={(uiConfig.theme.logoUrl.length > 0 && uiConfig.theme.logoUrl) || logoIcon}
                className={classes.logo}
                alt="logoIcon"
              />
            </div>
            <div className={classes.rootTitleContainer}>
              <Typography className={classes.innerHeaderTitle}>{'Login to Your Account'}</Typography>
              <Typography className={classes.subTitle}>
                {'Please login to your organizational account or email to continue.'}
              </Typography>
            </div>
          </Box>
        )}
        <div>
          {newPasswordNeeded && (
            <TempPasswordDialog title={'Reset your password'} onOkPress={completeNewPassword} />
          )}
          <LoginForm
            attemptLogin={attemptLogin}
            code={code}
            codeNeeded={codeNeeded}
            emailNeeded={emailNeeded}
            errorMessage={errorMessage}
            onCodeChange={onCodeChange}
            onPasswordChange={onPasswordChange}
            onEmailChange={onEmailChange}
            password={password}
            secondButtonCB={attemptLogin}
            submitAuthCode={submitAuthCode}
            email={email}
            selectEmailLogin={selectEmailLogin}
            hasOpenDisclaimer={(val: boolean) => setHasLegalDisclaimer(val)}
            validateUser={validateUser}
            validateformUser={validateformUser}
            selectLogin={selectLogin}
            isCompleteLogin={isCompleteLogin}
          />
        </div>
      </div>
    </>
  );
};

const transitionTime = '0.25s';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginScreenWrapper: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 750,
      position: 'absolute',
      top: '94px',
      left: '50%',
      transform: 'translate(-50%,0px)',
    },
    logoContainer: {
      border: `1px solid ${colors.black10}`,
      borderRadius: 4,
      padding: 10,
      background: colors.white,
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '17px 0px 18px 0px',
      background: colors.soapStone,
      borderBottom: `1px solid ${colors.black05}`,
    },
    headerTitle: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 'normal',
      textAlign: 'center',
      textTransform: 'none',
      color: colors.black85,
    },
    innerHeaderTitle: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 'normal',
      textAlign: 'center',
      textTransform: 'none',
      color: colors.black85,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        fontSize: 24,
      },
    },
    subTitle: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 'normal',
      textAlign: 'center',
      textTransform: 'none',
      color: colors.black75,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        fontSize: 15,
      },
    },
    rootTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    loginBoxContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    loginBox: {
      width: 360,
      borderRadius: 5,
      top: 100,
      backgroundColor: colors.white,
      position: 'absolute',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    loginHeader: {
      height: 68,
      color: colors.black0,
      width: '100%',
    },
    loginTitle: {
      padding: '27px 45px',
      display: 'inline-flex',
    },
    lockIcon: {
      height: 16,
      width: 16,
    },
    logoDiv: {
      top: 22,
      right: 43,
      position: 'absolute',
      width: 94,
      height: 94,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.primary.main}`,
      transition: transitionTime,
    },
    logo: {
      borderRadius: '50%',
      height: 55,
      width: 55,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        gap: 16,
      },
    },
  })
);

export default LoginScreen;
