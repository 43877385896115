/* Color names are referenced from https://www.schemecolor.com/sample */
import { createTheme } from '@material-ui/core/styles';

// Using our own 'colors' value because MaterialUI's 'PaletteOptions' type def doesn't allow us to add custom colors to the palette
export const colors = {
  success: '#3B953E',
  grayPlaceholder: '#CCCCCC',
  danger: '#EB5757',
  red: '#FF0000',
  pureRed: '#F00',
  white: '#FFFFFF',
  grayLine: '#EEEEEE',
  info: '#58C8FD',
  backgroundLight: '#F8F8F8',
  black: '#1D1D1F',
  grayDescription: '#8C8C8C',
  warning: '#EEA345',
  title: '#AAAAAA',
  lightGray: '#EFEFEF',
  black0: '#000000',
  black04: 'rgba(0, 0, 0, 0.04)',
  black05: 'rgba(0, 0, 0, 0.05)',
  black10: 'rgba(0, 0, 0, 0.10)',
  black12: 'rgba(0, 0, 0, 0.12)',
  black14: 'rgba(0, 0, 0, 0.14)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(0, 0, 0, 0.20)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black85: 'rgba(0, 0, 0, 0.85)',
  softShadow: 'rgba(0, 0, 0, 0.04)',
  white95: 'rgba(255, 255, 255, 0.95)',
  veryLightGray: '#E0E0E0',
  veryLightBlack: 'rgba(0, 0, 0, 0.05)',
  snowWhite: '#FBFBFB',
  cultured: '#f4f4f4',
  ghostWhite: '#f9f9f9',
  grayBorder: '#D4D4D4',
  scrollBar: '#DDD',
  flashWhite: '#F3F3F3',
  whiteSmoke: '#F2F2F2',
  lightSilver: '#d9d9d9',
  cultured7: '#f7f7f7',
  lotion: '#FAFAFA',
  whisper: '#E9E9E9',
  pending: '#FF9330',
  shadedWhite: '#EFEFEF',
  silver: '#B2B2B2',
  dimGray: '#656565',
  defaultPrimary: '#56CCF2',
  mistyRose: '#FFE1E1',
  cornSilk: '#FFF7DD',
  darkSilver: '#CFCFCF',
  romanSilver: '#848E97',
  lavenderGray: '#C2CACC',
  platinum: '#E5E5E5',
  ivoryMist: '#FEFEFE',
  vividOrange: '#FF9800',
  peachPuff: '#FEF5E5',
  warmYellow: '#FFD557',
  linen: '#FFECEC',
  coral: '#FDA2A3',
  soapStone: '#fcfcfc',
  chineseSilver: '#c7c7c7',
  gray: '#7C7C7C',
  grayGoose: '#D2D2D2',
  alabaster: '#EBEBEB',
  lightGray2: '#D7D7D7',
  gainsboro: '#DADADA',
  pastalGray: '#DCDBDC',
  philpphineSilver: '#B1B1B1',
  royalBlue: '#2F80ED',
  lightGoldenrodYellow: '#FEF7E0',
  monsoon: '#888888',
  brightOrange: '#fbb03b',
  cyan: '#00FFFF',
  pureWhite: '#FFFFFF4D',
  transparentWhite: '#FFFFFF0D',
  greenTick: '#21A136',
  veryLightGrey: '#f6f6f6',
  coralRed: '#FF5151',
  lavenderBlush: '#FFECEC',
  whisperGray: '#ECECEC',
  semiTransparentBlack: '#000000BF',
  dimGrey: '#616161',
  lotionWhite: '#FDFDFD',
  Malachite: '#05C769',
  paleSlate: '#bfbfbf',
};

const theme = (primaryColor: string = '#56CCF2', secondaryColor: string = '#455157') => {
  return createTheme({
    //@ts-ignore
    listIconWidth: 43,
    palette: {
      primary: {
        main: primaryColor,
      },
      info: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      grey: {
        50: '#f8f8f8',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      },
      error: {
        main: colors.danger,
        contrastText: colors.white,
      },
      success: {
        main: colors.success,
        contrastText: colors.white,
      },
    },
    typography: {
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 20,
      },
      h3: {
        fontSize: 16,
      },
      h4: {
        fontSize: 14,
        lineHeight: 'normal',
      },
      h5: {
        fontSize: 12,
      },
      fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
    overrides: {
      MuiListItem: {
        root: {
          '&:hover': {
            color: 'unset',
          },
        },
      },
      MuiTypography: {
        h3: {
          color: 'unset',
        },
      },
      MuiListItemText: {
        root: {
          padding: '0 5px',
        },
      },
      MuiInputAdornment: {
        positionStart: {
          marginRight: '4px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'none',
          },
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: colors.white,
        },
      },
      MuiFormLabel: {
        root: {
          '& .MuiFormLabel-asterisk': {
            display: 'none',
          },
        },
      },
    },
  });
};

export default theme;
