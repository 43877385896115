import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UsersTable from './UsersContainer/UsersTableContainer';
import { QueryResult as QueryType, User, UsersTableRef } from './UsersContainer/types';
import { EditModeContext, EditModeData } from '../contexts/editModeContext';
import RolesTable from './RolesContainer/RolesTableContainer';
import { RolesTableRef } from './RolesContainer/types';
import { USERS_AND_ROLES_Z_INDEX, LEFT_NAVIGATION_BAR_WIDTH } from '../utils/layers';
import { ErrorDialogProvider } from '../contexts/errorDialogContext';
import { isArray } from 'lodash';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { colors } from '../styles/theme';
import { Typography, Box, Tab, Tabs, withStyles, Theme, TabProps, Divider, Button } from '@material-ui/core';
import BottomChangesBar from '../components/StyledTable/BottomChangesBar';
import ProceedConfirmationDialog from '../components/UsersAndRoles/ProceedConfirmationDialog';
import { useQuery } from '@apollo/client';
import { QUERY } from './UsersContainer/graphql';
import { mapQueryResultToUsers } from './UsersContainer/utils';
import { QueryResult } from './RolesContainer/graphql';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CommonUserAndRoles from './UsersContainer/commonUserAndRoles';
import { useUserInfo } from '@terragotech/gen5-shared-components';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};
const StyledTabs = withStyles((theme: Theme) => ({
  root: {
    minHeight: 39,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: '3px',
  },
}))(Tabs);

const StyledTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 74,
    fontWeight: 400,
    color: colors.black0,
    minHeight: 39,
    height: 19,
    padding: 10,
    fontSize: 16,
    opacity: 1,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
      fontSize: 14,
    },
  },
  selected: {},
}))((props: TabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    left: 70,
    width: `calc(100vw - ${LEFT_NAVIGATION_BAR_WIDTH}px)`,
    height: '100vh',
    backgroundColor: colors.white,
    [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
      width: '-webkit-fill-available',
      padding: '15px 18px 0px 19px',
      height: '100%',
      left: 0,
    },
    padding: '42px 55px 0px 49px',
  },
  initRoot: {
    minWidth: 0,
    textTransform: 'capitalize',
    color: colors.black0,
  },
  title: {
    color: colors.black0,
    fontStyle: 'normal',
    fontSize: 24,
    fontWeight: 500,
  },
  divider: {
    background: colors.black10,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 19,
  },
  buttonContainer: {
    display: 'flex',
    width: 'auto',
    padding: '5px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 'normal',
    right: 6,
  },
  iconSize: {
    fontSize: '24px !important',
  },
}));

interface UserAndRolesProps {
  onBack: () => void;
  isMobileView?: boolean;
}

const BOTTOM_BAR_HEIGHT = 48;
const TOP_BAR_HEIGHT = 46;

const UsersAndRoles = (props: UserAndRolesProps) => {
  const { isRoleAndDataAdmin } = useUserInfo();
  const { translate, language } = useContext(LanguageContext);
  const options = useMemo(
    () => [
      { label: 'Users', value: 'users' },
      ...(isRoleAndDataAdmin ? [{ label: 'Roles', value: 'roles' }] : []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [isRoleAndDataAdmin, language]
  );
  const [currentTab, setCurrentTab] = useState(options[0].value);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [version, setVersion] = useState(0); //It is used to reload table
  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);
  const TABLE_HEIGHT = window.innerHeight - BOTTOM_BAR_HEIGHT - TOP_BAR_HEIGHT;
  const { setEditModeActive, editModeData, setEditModeData, editModeActive, isEditModeOnUser, setIsEditModeOnUser } =
    useContext(EditModeContext);
  const { isAddDialogOpen, setIsAddDialogOpen } = useContext(AssetsDashboardContext);
  const classes = useStyles({ isEditModeOn });
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: React.SetStateAction<number>) => {
    const target = event.target as HTMLElement;
    const eLabel = target.innerText.split('(')[0].trim();
    const selectVal = options.find(k => k.label === eLabel);
    setCurrentTab(selectVal?.value as string);
    setValue(newValue);
  };
  const usersTableRef = useRef<UsersTableRef>(null);
  const rolesTableRef = useRef<RolesTableRef>(null);

  const handleUpdate = useCallback(
    (numChanges: number, changedData?: EditModeData) => {
      setNumberOfChanges(numChanges);

      if (changedData) {
        if (editModeData) {
          if (isArray(editModeData)) {
            !editModeData.some(x => x.id === changedData.id) && setEditModeData([...editModeData, changedData]);
          } else {
            editModeData.id !== changedData.id && setEditModeData([editModeData, changedData]);
          }
        } else {
          setEditModeData([changedData]);
        }
      }
    },
    [setNumberOfChanges, editModeData, setEditModeData]
  );

  useEffect(() => {
    handleUpdate(0);
  }, [version]); // It resets the number of changes of every table reload

  useEffect(() => {
    // TODO: Needs to be implemented along with fixes for downstream editor controls (e.g. StyledTableSelectEditor).
    setEditModeActive(isEditModeOn);
    setIsEditModeOnUser(isEditModeOn);
  }, [isEditModeOn, setEditModeActive, setIsEditModeOnUser]);

  const handleEditModeOn = useCallback(() => {
    setIsEditModeOn(true);
  }, [setIsEditModeOn]);

  const handleEditModeOff = () => {
    setIsEditModeOn(false);
    setEditModeData(null);
    setEditModeActive(false);
    setIsEditModeOnUser(false);
  };

  const handleDiscard = () => {
    handleEditModeOff();
    setVersion(version + 1);
  };
  const handleProceed = () => {
    handleDiscard();
    setTimeout(props.onBack, 1);
  };

  const handleRefresh = () => {
    setVersion(version + 1);
    setTimeout(() => setIsEditModeOn(false), 1);
  };

  const handleSave = () => {
    if (currentTab === 'users') {
      if (usersTableRef.current) {
        usersTableRef.current.save();
        handleEditModeOff();
      }
    } else if (currentTab === 'roles') {
      if (rolesTableRef.current) {
        setNumberOfChanges(0);
        rolesTableRef.current.save();
        handleEditModeOff();
      }
    }
  };
  const extraSpace = props.isMobileView ? 100 : 75;
  const [users, setUsers] = useState<User[]>([]);
  const { data: RoleData } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'network-only' });
  const { data: UserData } = useQuery<QueryType>(QUERY, { fetchPolicy: 'network-only' });
  const initialUsers: User[] = useMemo(
    () => mapQueryResultToUsers(UserData).map(user => ({ ...user, id: user.username })),
    [UserData]
  );
  useEffect(() => {
    setUsers([
      ...initialUsers.map(user => ({
        ...user,
        roles: [...user.roles],
      })),
    ]);
  }, [initialUsers]);
  const RecordCount = RoleData?.roles.length !== undefined ? RoleData?.roles.length : 0;
  const openUserAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  return (
    <div className={classes.root}>
      <ErrorDialogProvider onConfirm={handleRefresh}>
        {!props.isMobileView && (
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>{translate('Users Administration')}</Typography>
            <Button
              startIcon={<FontAwesomeIcon icon={faPlus as IconProp} className={classes.iconSize} />}
              variant="outlined"
              className={classes.buttonContainer}
              onClick={openUserAddDialog}
              disabled={isEditModeOn}>
              {translate('Add')}
            </Button>
          </div>
        )}
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {options.map((option, index) => (
            <StyledTab
              label={
                `${translate(option.label)} ${
                  option.label === 'Users' ? `(${users.length})` : `(${RecordCount})`
                }` as string
              }
              key={option.value}
            />
          ))}
        </StyledTabs>
        <Divider className={classes.divider} />
        {options.map((option, index) => (
          <TabPanel value={value} index={index} key={option.value}>
            {option.value === 'users' && (
              <UsersTable
                key={version}
                ref={usersTableRef}
                height={TABLE_HEIGHT - extraSpace}
                onUpdate={handleUpdate}
                onEditModeOn={handleEditModeOn}
                refresh={handleRefresh}
                isEditModeOn={isEditModeOn}
                isAddDialogOpen={isAddDialogOpen}
                setIsAddDialogOpen={setIsAddDialogOpen}
              />
            )}
            {option.value === 'roles' && (
              <RolesTable
                key={version}
                ref={rolesTableRef}
                height={TABLE_HEIGHT - extraSpace}
                onUpdate={handleUpdate}
                onEditModeOn={handleEditModeOn}
                refresh={handleRefresh}
                isEditModeOn={isEditModeOn}
                isAddDialogOpen={isAddDialogOpen}
                setIsAddDialogOpen={setIsAddDialogOpen}
              />
            )}
            <CommonUserAndRoles
              ref={currentTab === 'users' ? usersTableRef : rolesTableRef}
              refresh={handleRefresh}
              isAddDialogOpen={isAddDialogOpen}
              setIsAddDialogOpen={setIsAddDialogOpen}
            />
            <BottomChangesBar
              open={editModeActive && isEditModeOnUser}
              numberOfChanges={numberOfChanges}
              singularName={currentTab === 'users' ? 'User(s)' : 'Role(s)'}
              onDiscard={handleDiscard}
              onSave={handleSave}
            />
          </TabPanel>
        ))}
        <ProceedConfirmationDialog
          open={isBackDialogOpen}
          onCancel={() => setIsBackDialogOpen(false)}
          onProceed={handleProceed}
        />
      </ErrorDialogProvider>
    </div>
  );
};

export default UsersAndRoles;
