"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultServiceSettings = exports.defaultProjectRecordsLimit = exports.permissionLabel = exports.getDisplayNameForServiceType = exports.DEFAULT_DATETIME_FORMAT = exports.DEFAULT_DATE_FORMAT = exports.DATETIME_TOKEN_CONVERSION = exports.TIME_FORMAT_FORM = exports.TIME_FORMAT = exports.DATE_SEPARATOR_LABEL = exports.DATE_SEPARATOR_NAME = exports.DATE_SEPARATOR = exports.DATE_FORMAT_TYPE = exports.DATE_FORMAT_ISO = exports.DATE_FORMAT_FORM = exports.MAP_SERVICE_TYPE = exports.MAP_SERVICE_DEFAULT_KEYS = exports.REDIS_CLIENT_EVENT = exports.MEMCACHE_KEYS = exports.MEMCACHE_PUBSUB_CHANNEL = exports.MEMCACHE_ENV_KEY = exports.PG_NOTIFY_CHANNEL = exports.AGGREGATE_DEFAULT_KEYS = exports.OBJECT_STORE_KEY = exports.Gen5SchemaNames = exports.Gen5ServiceType = exports.REFERENCE_TYPE_AGGREGATE = exports.pusherChannelNotificationName = exports.NOT_MAP_ASSETS_NAMES = void 0;
exports.NOT_MAP_ASSETS_NAMES = ['Project', 'Folder'];
exports.pusherChannelNotificationName = {
    AGGREGATE: 'aggregates',
    PROJECT_CHANGED: 'projectChanged',
};
exports.REFERENCE_TYPE_AGGREGATE = 'AGGREGATE_REFERENCE_TYPE';
var Gen5ServiceType;
(function (Gen5ServiceType) {
    Gen5ServiceType["WEB"] = "Web";
    Gen5ServiceType["MOBILE"] = "Mobile";
    Gen5ServiceType["GRAPHQLAPI"] = "GraphQl";
    Gen5ServiceType["COMMAND_PROCESSOR"] = "CommandProcessor";
    Gen5ServiceType["SNAPSHOT_HANDLER"] = "SnapshotHandler";
    Gen5ServiceType["AGGREGATE_MANAGER"] = "AggregateManager";
    Gen5ServiceType["QUEUE_MANAGER"] = "QueueManager";
})(Gen5ServiceType = exports.Gen5ServiceType || (exports.Gen5ServiceType = {}));
var Gen5SchemaNames;
(function (Gen5SchemaNames) {
    Gen5SchemaNames["ANALYTIC"] = "analytic";
    Gen5SchemaNames["GEN5"] = "gen5";
    Gen5SchemaNames["LOG"] = "log";
})(Gen5SchemaNames = exports.Gen5SchemaNames || (exports.Gen5SchemaNames = {}));
var OBJECT_STORE_KEY;
(function (OBJECT_STORE_KEY) {
    OBJECT_STORE_KEY["READWRITE"] = "OBJECT_STORE_URL";
    OBJECT_STORE_KEY["READONLY"] = "OBJECT_STORE_READ_URL";
})(OBJECT_STORE_KEY = exports.OBJECT_STORE_KEY || (exports.OBJECT_STORE_KEY = {}));
var AGGREGATE_DEFAULT_KEYS;
(function (AGGREGATE_DEFAULT_KEYS) {
    AGGREGATE_DEFAULT_KEYS["labelProperty"] = "title";
})(AGGREGATE_DEFAULT_KEYS = exports.AGGREGATE_DEFAULT_KEYS || (exports.AGGREGATE_DEFAULT_KEYS = {}));
var PG_NOTIFY_CHANNEL;
(function (PG_NOTIFY_CHANNEL) {
    PG_NOTIFY_CHANNEL["NEW_COMMAND"] = "new_command";
    PG_NOTIFY_CHANNEL["NEW_EVENT"] = "new_event";
    PG_NOTIFY_CHANNEL["INITIAL_PROJECTIONS"] = "initial_projections";
})(PG_NOTIFY_CHANNEL = exports.PG_NOTIFY_CHANNEL || (exports.PG_NOTIFY_CHANNEL = {}));
// #region Memcache
var MEMCACHE_ENV_KEY;
(function (MEMCACHE_ENV_KEY) {
    MEMCACHE_ENV_KEY["READWRITE"] = "MEMCACHE_URL";
    MEMCACHE_ENV_KEY["READONLY"] = "MEMCACHE_READ_URL";
})(MEMCACHE_ENV_KEY = exports.MEMCACHE_ENV_KEY || (exports.MEMCACHE_ENV_KEY = {}));
var MEMCACHE_PUBSUB_CHANNEL;
(function (MEMCACHE_PUBSUB_CHANNEL) {
    MEMCACHE_PUBSUB_CHANNEL["COMMAND_CREATED"] = "command:created";
    MEMCACHE_PUBSUB_CHANNEL["EVENT_CREATED"] = "event:created";
    MEMCACHE_PUBSUB_CHANNEL["INITIAL_PROJECTIONS"] = "initial_projections:created";
})(MEMCACHE_PUBSUB_CHANNEL = exports.MEMCACHE_PUBSUB_CHANNEL || (exports.MEMCACHE_PUBSUB_CHANNEL = {}));
var MEMCACHE_KEYS;
(function (MEMCACHE_KEYS) {
    MEMCACHE_KEYS["HEARTBEAT"] = "heartbeat";
    MEMCACHE_KEYS["PROCESSING"] = "processing";
    MEMCACHE_KEYS["QUEUE"] = "queue";
    MEMCACHE_KEYS["SERVICE"] = "service";
})(MEMCACHE_KEYS = exports.MEMCACHE_KEYS || (exports.MEMCACHE_KEYS = {}));
var REDIS_CLIENT_EVENT;
(function (REDIS_CLIENT_EVENT) {
    REDIS_CLIENT_EVENT["CONNECT"] = "connect";
    REDIS_CLIENT_EVENT["READY"] = "ready";
    REDIS_CLIENT_EVENT["END"] = "end";
    REDIS_CLIENT_EVENT["ERROR"] = "error";
    REDIS_CLIENT_EVENT["RECONNECTING"] = "reconnecting";
    REDIS_CLIENT_EVENT["SHARDED_CHANNEL_MOVED"] = "sharded-channel-moved";
    REDIS_CLIENT_EVENT["CLOSE"] = "close";
})(REDIS_CLIENT_EVENT = exports.REDIS_CLIENT_EVENT || (exports.REDIS_CLIENT_EVENT = {}));
// #endregion
// #region MapService
var MAP_SERVICE_DEFAULT_KEYS;
(function (MAP_SERVICE_DEFAULT_KEYS) {
    MAP_SERVICE_DEFAULT_KEYS["permissionKey"] = "Map Services";
    MAP_SERVICE_DEFAULT_KEYS["typeName"] = "MapService";
    MAP_SERVICE_DEFAULT_KEYS["recordTypeKey"] = "mapServices";
    MAP_SERVICE_DEFAULT_KEYS["visibilityPreferenceKey"] = "mapServiceLayerVisibility";
})(MAP_SERVICE_DEFAULT_KEYS = exports.MAP_SERVICE_DEFAULT_KEYS || (exports.MAP_SERVICE_DEFAULT_KEYS = {}));
var MAP_SERVICE_TYPE;
(function (MAP_SERVICE_TYPE) {
    MAP_SERVICE_TYPE["WMS"] = "WMS";
    MAP_SERVICE_TYPE["ArcGIS"] = "ArcGIS";
})(MAP_SERVICE_TYPE = exports.MAP_SERVICE_TYPE || (exports.MAP_SERVICE_TYPE = {}));
// #endregion
// #region DefaultDateTimeFormat
var DATE_FORMAT_FORM;
(function (DATE_FORMAT_FORM) {
    DATE_FORMAT_FORM["DD_MMM_YY"] = "DD MMM YY";
    DATE_FORMAT_FORM["DD_MMM_YYYY"] = "DD MMM YYYY";
    DATE_FORMAT_FORM["DD_MM_YY"] = "DD MM YY";
    DATE_FORMAT_FORM["DD_MM_YYYY"] = "DD MM YYYY";
    DATE_FORMAT_FORM["D_MMM_YY"] = "D MMM YY";
    DATE_FORMAT_FORM["D_MMM_YYYY"] = "D MMM YYYY";
    DATE_FORMAT_FORM["D_MM_YY"] = "D MM YY";
    DATE_FORMAT_FORM["D_MM_YYYY"] = "D MM YYYY";
    DATE_FORMAT_FORM["MMM_YY"] = "MMM YY";
    DATE_FORMAT_FORM["MMM_YYYY"] = "MMM YYYY";
    DATE_FORMAT_FORM["MM_YY"] = "MM YY";
    DATE_FORMAT_FORM["MM_YYYY"] = "MM YYYY";
})(DATE_FORMAT_FORM = exports.DATE_FORMAT_FORM || (exports.DATE_FORMAT_FORM = {}));
var DATE_FORMAT_ISO;
(function (DATE_FORMAT_ISO) {
    DATE_FORMAT_ISO["YYYY_MM_DDTHH_MM_SSZ"] = "YYYY-MM-DDThh:mm:ssZ";
    DATE_FORMAT_ISO["YYYY_MM_DDTHH_MM_SS"] = "YYYY-MM-DDThh:mm:ss";
    DATE_FORMAT_ISO["YYYY_MM_DD_HH_MM_SSZ"] = "YYYY-MM-DD hh:mm:ssZ";
    DATE_FORMAT_ISO["YYYY_MM_DD_HH_MM_SS"] = "YYYY-MM-DD hh:mm:ss";
    DATE_FORMAT_ISO["YYYY_MM_DD"] = "YYYY-MM-DD";
    DATE_FORMAT_ISO["YYYY_MM_DDTHH_MM_SS_SSSZ"] = "YYYY-MM-DDThh:mm:ss.SSSZ";
})(DATE_FORMAT_ISO = exports.DATE_FORMAT_ISO || (exports.DATE_FORMAT_ISO = {}));
var DATE_FORMAT_TYPE;
(function (DATE_FORMAT_TYPE) {
    DATE_FORMAT_TYPE["ISO8601"] = "ISO 8601";
    DATE_FORMAT_TYPE["GeneralForm"] = "General Form";
})(DATE_FORMAT_TYPE = exports.DATE_FORMAT_TYPE || (exports.DATE_FORMAT_TYPE = {}));
var DATE_SEPARATOR;
(function (DATE_SEPARATOR) {
    DATE_SEPARATOR["None"] = "";
    DATE_SEPARATOR["Space"] = " ";
    DATE_SEPARATOR["Dash"] = "-";
    DATE_SEPARATOR["Colon"] = ":";
    DATE_SEPARATOR["ForwardSlash"] = "/";
    DATE_SEPARATOR["Comma"] = ",";
    DATE_SEPARATOR["Period"] = ".";
})(DATE_SEPARATOR = exports.DATE_SEPARATOR || (exports.DATE_SEPARATOR = {}));
var DATE_SEPARATOR_NAME;
(function (DATE_SEPARATOR_NAME) {
    DATE_SEPARATOR_NAME["None"] = "None";
    DATE_SEPARATOR_NAME["Space"] = "Space";
    DATE_SEPARATOR_NAME["Dash"] = "Dash";
    DATE_SEPARATOR_NAME["Colon"] = "Colon";
    DATE_SEPARATOR_NAME["ForwardSlash"] = "Forward Slash";
    DATE_SEPARATOR_NAME["Comma"] = "Comma";
    DATE_SEPARATOR_NAME["Period"] = "Period";
})(DATE_SEPARATOR_NAME = exports.DATE_SEPARATOR_NAME || (exports.DATE_SEPARATOR_NAME = {}));
var DATE_SEPARATOR_LABEL;
(function (DATE_SEPARATOR_LABEL) {
    DATE_SEPARATOR_LABEL["None"] = "None";
    DATE_SEPARATOR_LABEL["Space"] = "Space";
    DATE_SEPARATOR_LABEL["Dash"] = "Dash -";
    DATE_SEPARATOR_LABEL["Colon"] = "Colon :";
    DATE_SEPARATOR_LABEL["ForwardSlash"] = "Forward Slash /";
    DATE_SEPARATOR_LABEL["Comma"] = "Comma ,";
    DATE_SEPARATOR_LABEL["Period"] = "Period .";
})(DATE_SEPARATOR_LABEL = exports.DATE_SEPARATOR_LABEL || (exports.DATE_SEPARATOR_LABEL = {}));
var TIME_FORMAT;
(function (TIME_FORMAT) {
    TIME_FORMAT["Clock12Hr"] = "12hr clock";
    TIME_FORMAT["Clock24Hr"] = "24hr clock";
})(TIME_FORMAT = exports.TIME_FORMAT || (exports.TIME_FORMAT = {}));
var TIME_FORMAT_FORM;
(function (TIME_FORMAT_FORM) {
    TIME_FORMAT_FORM["HH_MM_A"] = "hh:mm a";
    TIME_FORMAT_FORM["HH_MM"] = "HH:mm";
})(TIME_FORMAT_FORM = exports.TIME_FORMAT_FORM || (exports.TIME_FORMAT_FORM = {}));
var DATETIME_TOKEN_CONVERSION;
(function (DATETIME_TOKEN_CONVERSION) {
    DATETIME_TOKEN_CONVERSION[DATETIME_TOKEN_CONVERSION["MomentJS"] = 0] = "MomentJS";
    DATETIME_TOKEN_CONVERSION[DATETIME_TOKEN_CONVERSION["MuiPicker"] = 1] = "MuiPicker";
})(DATETIME_TOKEN_CONVERSION = exports.DATETIME_TOKEN_CONVERSION || (exports.DATETIME_TOKEN_CONVERSION = {}));
exports.DEFAULT_DATE_FORMAT = {
    ISO: DATE_FORMAT_ISO.YYYY_MM_DDTHH_MM_SSZ,
    Form: DATE_FORMAT_FORM.DD_MMM_YY,
};
exports.DEFAULT_DATETIME_FORMAT = {
    DateFormatType: DATE_FORMAT_TYPE.ISO8601,
    DateFormat: exports.DEFAULT_DATE_FORMAT.ISO,
    DateSeperator: DATE_SEPARATOR_LABEL.Dash,
    TimeFormat: TIME_FORMAT.Clock12Hr,
    TimeFormatForm: TIME_FORMAT_FORM.HH_MM_A,
    IsSwitch: false,
};
// #endregion
const getDisplayNameForServiceType = (serviceType) => {
    switch (serviceType) {
        case Gen5ServiceType.WEB:
            return 'Web';
        case Gen5ServiceType.MOBILE:
            return 'Mobile';
        case Gen5ServiceType.GRAPHQLAPI:
            return 'GraphQL API';
        case Gen5ServiceType.COMMAND_PROCESSOR:
            return 'Command Processor';
        case Gen5ServiceType.SNAPSHOT_HANDLER:
            return 'Snapshot Handler';
        case Gen5ServiceType.AGGREGATE_MANAGER:
            return 'Aggregate Manager';
        case Gen5ServiceType.QUEUE_MANAGER:
            return 'Queue Manager';
        default:
            return 'N/A';
    }
};
exports.getDisplayNameForServiceType = getDisplayNameForServiceType;
const permissionLabel = (user) => {
    return [
        (user === null || user === void 0 ? void 0 : user.isUserAdmin) ? 'Users' : undefined,
        (user === null || user === void 0 ? void 0 : user.isRoleAndDataAdmin) ? 'All Data' : undefined,
        (user === null || user === void 0 ? void 0 : user.isAnalyticsUser) ? 'Analytics' : undefined,
        (user === null || user === void 0 ? void 0 : user.isMapServicesUser) ? MAP_SERVICE_DEFAULT_KEYS.permissionKey : undefined,
        (user === null || user === void 0 ? void 0 : user.hasAdvancedLoggingAccess) ? 'Advanced Logging' : undefined
    ].filter(val => !!val);
};
exports.permissionLabel = permissionLabel;
// Replicate any changes to docker-compose.yml
exports.defaultProjectRecordsLimit = {
    WEB: 75000,
    MOBILE: 80000, // docker-compose.yml: SELECTED_MOBILE_PROJECTS_MAX_RECORDS
};
exports.defaultServiceSettings = {
    /** Time between service confirmations in milliseconds. */
    HEARTBEAT_INTERVAL: 60000,
    /** Number of missed service confirmations allowed before action should be taken. */
    MAX_SKIPPED_HEARTBEATS: 1,
};
