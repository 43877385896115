import { Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import { colors } from '../styles/theme';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';
import { useContext } from 'react';

interface Props {
  tabItems: { label: string; count: number }[];
  activeTab: string;
  updateActiveTab: (value: string) => void;
}

const RecordsTab = ({ tabItems, activeTab, updateActiveTab }: Props) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  return (
    <Box className={classes.container}>
      <Tabs
        variant="scrollable"
        value={activeTab}
        onChange={(_, newValue) => updateActiveTab(newValue)}
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        TabIndicatorProps={{ className: classes.tabIndicator }}
        classes={{ root: classes.tabs, flexContainer: classes.tabsContainer, scrollButtons: classes.scrollButton }}>
        {tabItems.map((item, index) => (
          <Tab value={item.label} key={index} label={`${translate(item.label)} (${item.count})`} className={classes.tab} />
        ))}
      </Tabs>
    </Box>
  );
};

const useStyles = makeStyles(theme => {
  const primary = theme.palette.primary.main;
  return {
    tabs: {
      minHeight: 0,
    },
    tabsContainer: {
      gap: 7,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '0px 18px',
      },
    },
    scrollButton: {
      color: colors.black54,
      '&.Mui-disabled': {
        width: 0,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        display: 'none',
      },
    },
    tab: {
      minWidth: 'unset',
      maxWidth: 'unset',
      minHeight: 'unset',
      maxHeight: 'unset',
      textTransform: 'none',
      padding: '6px 10px',
      borderRadius: 5,
      backgroundColor: colors.black05,
      cursor: 'pointer',
      boxSizing: 'border-box',
      border: `1px solid transparent`,
      fontSize: 14,
      fontWeight: 400,
      opacity: 1,
      color: colors.black54,
      '& .MuiTab-wrapper': {
        padding: '2px 5px',
      },
      '&.Mui-selected': {
        border: `1px solid ${primary}`,
        color: primary,
        fontWeight: 500,
      },
    },
    tabIndicator: {
      display: 'none',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 7,
      padding: '0px 80px',
      paddingRight: '40px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: 0,
      },
    },
  };
});

export default RecordsTab;
