import React, { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { colors } from '../../../styles/theme';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

interface MiddleCropTextProps {
  label?: string;
  children: string;
  className: string;
  onClick?: () => void;
}

const MiddleCropText = (props: MiddleCropTextProps) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const allText = (typeof props.children === 'string' && props.children) || '';

  let maxTitleLength = 30;
  //remove title length from max length.
  maxTitleLength = maxTitleLength - (props.label?.length || 0);

  const firstText = allText.slice(0, -maxTitleLength);
  const secondText = allText.slice(-maxTitleLength);

  return (
    <div className={clsx(classes.container)} onClick={props.onClick}>
      <Typography className={clsx(props.className, classes.cropable)}>{translate(firstText)}</Typography>
      <Typography className={clsx(props.className, classes.static)}>{translate(secondText)}</Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
  },
  cropable: {
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  static: {
    flex: '1 0 auto',
  },
  label: {
    fontWeight: 600,
    fontSize: 13,
    paddingRight: '5px',
    alignSelf: 'center',
    color: colors.black,
  },
}));

export default MiddleCropText;
