import React, { useContext, useRef, useEffect } from 'react';
import { Checkbox, useTheme } from '@material-ui/core';
import { SizeMapContext } from './SetFilterDialog';
import { makeStyles } from '@material-ui/core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const useStyles = makeStyles(theme => ({
  filterItemOption: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  lowerPadding: {
    padding: 0,
    width: 24,
    height: 24,
    alignSelf: 'start',
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  textField: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

export const Row = ({ index, value, isSelected, setSelected, field }: any) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const { setSize } = useContext(SizeMapContext);
  const theme: { palette: { primary: { main: string } } } = useTheme();
  const BLANK_FIELD = '(Blanks)';
  const root = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setSize(index, root.current.getBoundingClientRect().height);
  }, []);

  const isBlank = !value || value === '' || value === BLANK_FIELD;
  const rowData = {
    value: isBlank ? BLANK_FIELD : value,
    renderValue: isBlank ? translate('(Blanks)') : value,
  };

  return (
    <div ref={root} style={styles.root}>
      <div className={classes.filterItemOption}>
        <Checkbox
          value={rowData.value}
          checked={isSelected(rowData.value)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelected(rowData.value, e.target.checked);
          }}
          classes={{ root: classes.lowerPadding }}
        />
        <div className={classes.textField}>{rowData.renderValue}</div>
      </div>
    </div>
  );
};
const styles = {
  root: {
    minHeight: 42,
  },
};
export default Row;
