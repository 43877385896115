import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LabelWrapper, {
  TGLabelWrapperProps,
} from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageLabelWrapper';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export type TGPageLinkFieldProps = TGLabelWrapperProps & { value?: { link: string; linkLabel: string } };

const TGPageLinkField: React.FC<TGPageLinkFieldProps> = ({ value, label, required, info, oneLine, truncate }) => {
  const history = useHistory();
  const { translate } = useContext(LanguageContext);
  const handleLinkClick = () => {
    if (value?.link) history.push(value.link);
  };

  return (
    <Typography
      component="div"
      style={{
        wordWrap: 'break-word',
        fontFamily: 'Roboto,Roboto-Light',
      }}>
      <p
        style={{
          margin: 0,
          fontWeight: 300,
          fontSize: 14,
          lineHeight: '130%',
          ...(truncate && {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }),
        }}>
        <LabelWrapper required={required} readOnly label={label} info={info} oneLine={oneLine} translate={translate} />
        <span
          style={{
            color: value?.link ? '#1a0dab' : '#AAAAAA',
            textDecoration: value?.link ? 'underline' : 'none',
            cursor: value?.link ? 'pointer' : 'default',
          }}
          onClick={handleLinkClick}>
          {translate(value?.linkLabel || '')}
        </span>
      </p>
    </Typography>
  );
};

export default TGPageLinkField;
