import { User, Role, QueryResult, UserWithoutId, QueryUser } from './types';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';

export const diff = <T extends unknown>(a: T[], b: T[], isEqual: (a: T, b: T) => boolean) =>
  a.filter((x) => b.find((y) => isEqual(x, y)) === undefined);

export const saveData = <T extends unknown>(
  data: T[],
  initialData: T[],
  add: (record: T) => Promise<any>,
  remove: (record: T) => Promise<any>,
  diff: (a: T[], b: T[]) => T[]
): boolean => {
  const recordsToAdd = diff(data, initialData);
  const recordsToDelete = diff(initialData, data);

  recordsToAdd.forEach((record) => add(record));
  recordsToDelete.forEach((record) => remove(record));
  return recordsToAdd.length > 0 || recordsToDelete.length > 0;
};

export const saveUsers = (
  users: readonly User[],
  initialUsers: readonly User[],
  addRoleToUser: (username: string, roleId: number) => Promise<any>,
  removeRoleFromUser: (username: string, roleId: number) => Promise<any>,
  updateUserAttributes: (
    username: string,
    attributes: { firstName: string; lastName: string; email: string; phoneNumber: string }
  ) => Promise<any>,
  updatePermissions: (
    username: string,
    isRoleAndDataAdmin: boolean,
    isUserAdmin: boolean,
    isAnalyticsUser: boolean,
    isMapServicesUser: boolean,
    advancedLoggingAccess: boolean
  ) => Promise<any>,
  analyticsSync: (
    added: Array<string>,
    removed: Array<string>
  ) => Promise<void>
): boolean => {
  const rolesDiff = (roles1: Role[], roles2: Role[]) => diff(roles1, roles2, (a, b) => a.id === b.id);
  let hadChanges = false;
  let usersToAddAnalytics: Array<string> = [];
  let usersToRemoveAnalytics: Array<string> = [];
  users.forEach((user, index) => {
    if (
      user.firstName !== initialUsers[index].firstName ||
      user.lastName !== initialUsers[index].lastName ||
      user.email !== initialUsers[index].email
    ) {
      hadChanges = true;
      updateUserAttributes(user.username, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: '',
      });
    }
    if (
      user.adminPermissions?.isRoleAndDataAdmin !== initialUsers[index].isRoleAndDataAdmin ||
      user.adminPermissions?.isUserAdmin !== initialUsers[index].isUserAdmin ||
      user.adminPermissions?.isAnalyticsUser !== initialUsers[index].isAnalyticsUser ||
      user.adminPermissions?.isMapServicesUser !== initialUsers[index].isMapServicesUser ||
      user.adminPermissions?.hasAdvancedLoggingAccess !== initialUsers[index].hasAdvancedLoggingAccess
    ) {
      hadChanges = true;
      updatePermissions(
        user.username,
        user.adminPermissions?.isRoleAndDataAdmin,
        user.adminPermissions?.isUserAdmin,
        user.adminPermissions?.isAnalyticsUser,
        user.adminPermissions?.isMapServicesUser,
        user.adminPermissions?.hasAdvancedLoggingAccess
      );
    }

    if (user.adminPermissions?.isAnalyticsUser !== initialUsers[index].isAnalyticsUser) {
      user.adminPermissions?.isAnalyticsUser
        ? usersToAddAnalytics.push(user.username)
        : usersToRemoveAnalytics.push(user.username);
    }

    hadChanges =
      saveData(
        user.roles,
        initialUsers[index].roles,
        (role) => addRoleToUser(user.username, role.id),
        (role) => removeRoleFromUser(user.username, role.id),
        rolesDiff
      ) || hadChanges;
  });

  analyticsSync(usersToAddAnalytics,usersToRemoveAnalytics);

  return hadChanges;
};

export const mapQueryResultToRoles = (queryResult: QueryResult | undefined): Role[] =>
  queryResult?.roles.map((role) => ({
    ...role,
    toString() {
      return this.name;
    },
  })) || [];

const getUserPermissions = (queryUser: QueryUser): UserWithoutId['adminPermissions'] => {
  return {
    isUserAdmin: queryUser.isUserAdmin,
    isRoleAndDataAdmin: queryUser.isRoleAndDataAdmin,
    isAnalyticsUser: queryUser.isAnalyticsUser,
    isMapServicesUser: queryUser.isMapServicesUser,
    hasAdvancedLoggingAccess: queryUser.hasAdvancedLoggingAccess,
    toString: () => permissionLabel(queryUser).join(', '),
  };
};

export const mapQueryResultToUsers = (queryResult: QueryResult | undefined): UserWithoutId[] => {
  if (!queryResult) {
    return [];
  }
  const roles = mapQueryResultToRoles(queryResult);
  const mapIdsToRoles = (ids: number[]) =>
    ids.map((id) => roles.find((role) => role.id === id)!).filter((role) => !!role);
  return queryResult.users.map((queryUser) => ({
    ...queryUser,
    roles: mapIdsToRoles(queryUser.roles.map(({ id }) => id)),
    adminPermissions: getUserPermissions(queryUser),
  }));
};

