import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { Input, InputAdornment, Grow, makeStyles, Button, IconButton } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PlacesAutocomplete from 'react-places-autocomplete';
import { handleAddressSelected } from './boundsUtils';
import { colors } from '@terragotech/gen5-shared-components';
import { Close } from '@material-ui/icons';
import { colors as themeColors } from '../../../../styles/theme';
import GlobalSearchSuggestion from './GlobalSearchSuggestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/pro-solid-svg-icons';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { SEARCH_BAR_WIDTH } from '../../../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../../../contexts/assetsDashboardContext';
import { LanguageContext } from '../../../../contexts/LanguageContext/languageContext';

interface TopSearchBarProps {
  setBounds: (bounds: FitBounds | undefined) => void;
  setVisibilityModal: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  mapContainerRef: HTMLDivElement | null;
  hideVisibility?: boolean;
}

const TopSearchBar: FunctionComponent<TopSearchBarProps> = props => {
  const { setBounds, setVisibilityModal, isModalOpen, mapContainerRef } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [searchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = React.useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { verticalPercentage, setHideMapCard, toggleMapView } = useContext(AssetsDashboardContext);
  const [screenResized, setScreenResized] = useState(false);
  const containerHeight = useMemo(() => {
    return mapContainerRef?.clientHeight;
  }, [verticalPercentage, mapContainerRef, screenResized]);

  const handleResize = () => {
    setScreenResized(prev => !prev);
  };
  useEffect(() => {
    if (searchActive) {
      setHideMapCard(searchText.length > 0);
    }
  }, [searchText, searchActive]);
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onAddressClick = async (address: string, placeId: string) => {
    toggleMapView(true);
    const bounds = (await handleAddressSelected(address, placeId, setSearchText)) as FitBounds | undefined;
    setBounds(bounds);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };
  // see here for implementation details https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook

  return (
    <Grow in style={styles.growRoot}>
      <div className={classes.searchBarContainer}>
        <div className={classes.mapSearchBar}>
          <PlacesAutocomplete
            // Changing map search to table or location depending on user selection
            value={searchText}
            onChange={setSearchText}
            onSelect={onAddressClick}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              const inputProps = getInputProps({
                placeholder: translate('Search'),
              });
              return (
                <div className={classes.searchContainer}>
                  <Input
                    {...inputProps}
                    onKeyDown={e => (e.key === 'Enter' ? e.stopPropagation() : inputProps.onKeyDown(e))}
                    inputRef={inputRef}
                    disableUnderline={true}
                    onFocus={() => setSearchActive(true)}
                    onBlur={() => {
                      setSearchActive(false);
                      setHideMapCard(false);
                    }}
                    className={classes.filter}
                    style={searchText !== '' ? { width: '100%' } : {}}
                    startAdornment={
                      <InputAdornment position="start">
                        <Search className={classes.searchIcon} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {searchText.length > 0 ? (
                          <IconButton onClick={() => setSearchText('')} onMouseDown={e => e.preventDefault()}>
                            <Close className={classes.clearIcon} />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </InputAdornment>
                    }
                  />
                  {!_.isEmpty(searchText) && searchActive && (
                    <GlobalSearchSuggestion
                      setSearchText={setSearchText}
                      inputRef={inputRef.current}
                      containerHeight={containerHeight}
                      searchText={searchText}
                      getSuggestionItemProps={getSuggestionItemProps}
                      suggestions={suggestions}
                      toggleMapView={toggleMapView}
                    />
                  )}
                </div>
              );
            }}
          </PlacesAutocomplete>
        </div>
        {!isModalOpen && !props.hideVisibility && (
          <Button
            variant="contained"
            onClick={() => setVisibilityModal(true)}
            className={classes.visibilityBtn}
            startIcon={<FontAwesomeIcon icon={faSliders} className={classes.tuneIcon} />}
          >
            {translate('Visibility')}
          </Button>
        )}
      </div>
    </Grow>
  );
};
const styles = {
  growRoot: {
    transformOrigin: '0 0 0',
  },
};
const useStyles = makeStyles(theme => ({
  searchBarContainer: {
    display: 'flex',
    maxWidth: '90vw',
  },
  searchContainer: { width: '100%' },
  mapSearchBar: {
    backgroundColor: colors.white,
    color: theme.palette.grey[200],
    borderRadius: '5px',
    width: SEARCH_BAR_WIDTH,
    height: 38,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxShadow: `0px 2px 4px 0px ${themeColors.black15}`,
  },
  searchIcon: {
    height: 22,
    width: 22,
    color: themeColors.black54,
    marginLeft: '15px',
  },
  clearIcon: {
    height: 22,
    width: 22,
    color: themeColors.black54,
  },
  filter: {
    fontSize: 15,
    color: themeColors.black0,
    width: '100%',
    '& .MuiInputAdornment-root': {
      marginRight: 2,
    },
    '& input::placeholder': {
      fontSize: 15,
      color: themeColors.black54,
      opacity: 1,
    },
  },
  searchResultsBox: {
    position: 'relative',
    marginTop: '5px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#aaaaaa',
    backgroundColor: '#ffffff',
    width: 496,
    paddingTop: '7px',
    paddingBottom: '7px',
    maxWidth: '90vw',
  },
  suggestionItem: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    color: '#666666',
    paddingTop: '13px',
    paddingBottom: '13px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    borderTop: '1px solid #eeeeee',
    width: 496,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suggestionItemActive: {
    backgroundColor: theme.palette.primary.main,
    color: colors.white,
  },
  numberOfResults: {
    position: 'absolute',
    right: '5px',
    fontSize: '16px',
    color: colors.grayDescription,
    paddingLeft: '7px',
    borderLeft: '1px solid',
    borderLeftColor: colors.grayLine,
  },
  visibilityBtn: {
    backgroundColor: colors.white,
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    textTransform: 'none',
    color: themeColors.black75,
    borderRadius: 5,
    boxShadow: `0px 2px 4px 0px ${themeColors.black15}`,
  },
  tuneIcon: {
    color: themeColors.black54,
  },
}));

export default React.memo(TopSearchBar);
