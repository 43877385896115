import React, { useState, useCallback, useMemo, useContext } from 'react';
import TGSnackBar, { TGSnackBarProps } from '../../components/TGSnackBar/TGSnackBar';
import { LanguageContext } from '../LanguageContext/languageContext';

export interface SnackbarConfig {
  title: TGSnackBarProps['title'];
  type: TGSnackBarProps['type'];
  autoHideDuration?: TGSnackBarProps['autoHideDuration'];
  horizontalPosition?: TGSnackBarProps['horizontalPosition'];
  verticalPosition?: TGSnackBarProps['verticalPosition'];
  disableClickAway?: TGSnackBarProps['disableClickAway'];
}

export const useSnackbar = () => {
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig | null>(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { translate } = useContext(LanguageContext);
  const openSnackbar = useCallback((config: SnackbarConfig) => {
    setSnackbarConfig(config);
    setIsSnackbarOpen(true);
  }, []);
  const closeSnackbar = useCallback(() => setIsSnackbarOpen(false), []);

  const component = useMemo(
    () => (
      <TGSnackBar
        isOpen={isSnackbarOpen}
        close={closeSnackbar}
        title={snackbarConfig?.title || ''}
        type={snackbarConfig?.type}
        autoHideDuration={snackbarConfig?.autoHideDuration}
        horizontalPosition={snackbarConfig?.horizontalPosition}
        verticalPosition={snackbarConfig?.verticalPosition}
        disableClickAway={snackbarConfig?.disableClickAway}
      />
    ),
    [isSnackbarOpen, closeSnackbar, snackbarConfig]
  );

  return {
    openSnackbar,
    closeSnackbar,
    component,
  };
};
