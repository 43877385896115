import React, { useContext } from 'react';
import {
  FormRendererComponentMap,
  FieldProps,
  FormControlProps,
  FileFieldProps,
  FileFieldPropsWithName,
} from '@terragotech/form-renderer';
import TGLocationField from '../FormFields/TGLocationField/TGLocationField';
import TGPolylineField from '../FormFields/TGLocationField/TGPolylineField';
import {
  withTextFieldProps,
  withTextHeaderProps,
  withCheckboxProps,
  withTimeFieldProps,
  withRadioFieldProps,
  withNumberFieldProps,
  withCurrencyFieldProps,
  withTextareaFieldProps,
  withSelectFieldProps,
  withDateFieldProps,
  withLocationFieldProps,
  withMediaFieldProps,
  withBarcodeFieldProps,
  withAggregateSelectorFieldProps,
  withPolylineFieldProps,
  withTextFieldPropsComputed,
  withFileFieldProps,
  withGroupProps,
  withRepeatWrapperProps,
} from './FormHelpers';
import { V2FormComponentDef, V2TextHeaderComponent } from '@terragotech/form-renderer';
import TGMapAggregateSelectorField from '../FormFields/TGMapAggregateSelectorField';
import {
  TGBarCodeFields,
  TGDateFieldWeb,
  TGFileUploadFieldWeb,
  TGImageUploadFieldWeb,
  TGRadioFieldWeb,
  TGSelectFieldWeb,
  TGTextFieldWeb,
  TGTextHeader,
  TGTimeFieldWeb,
  GroupWrapperWeb,
  RepeatWrapperWeb,
  RepeatFieldWrapperWeb,
  RootWrapperWeb,
  WorkflowFieldWrapper,
} from '@terragotech/gen5-shared-components';
import { colors } from '../../styles/theme';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const withNoRepeatableWorkflowFieldWrapper = <T extends FormControlProps<V2TextHeaderComponent>>(
  Component: React.FC<T>
) => {
  return (props: T) => {
    const { controlDefinition } = props;

    return (
      <WorkflowFieldWrapper controlDefinition={controlDefinition} repeatable={false}>
        <Component {...props} />
      </WorkflowFieldWrapper>
    );
  };
};

/**
 * This is a higher order component that wraps a repeatable component instance and determines its visibility based on the current context and props
 * Its main responsibility is to hide repeated elements if the repeat is collapsed
 * @param Component The UI component to wrap
 */
const withRepeatable = (Component: React.ElementType) => {
  return (
    props: 
      | FieldProps<V2FormComponentDef>
      | FileFieldProps<V2FormComponentDef>
      | FileFieldPropsWithName<V2FormComponentDef>
  ) => {
    const {
      // multiline, we will reuse it maybe later
      repeatable,
      index,
      repeatCount,
      controlDefinition,
      onAddClick,
      onRemoveClick,
      moveItems,
      isOriginal,
    } = props;
    const styles = {
      border: props.errors.length === 0 ? `1px solid ${colors.black10}` : `1px solid ${colors.pureRed}`,
      padding: '18px 20px', 
      borderRadius: 5
    }
    const { translate } = useContext(LanguageContext);
    return repeatable ? (
      <RepeatFieldWrapperWeb
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
        controlDefinition={controlDefinition}
        repeatable={repeatable}
        index={index}
        repeatCount={repeatCount}
        moveItems={moveItems}
        isOriginal={isOriginal}
        translate={translate}
      >
        <Component {...props} />
      </RepeatFieldWrapperWeb>
    ) : (
      <div style={styles}>
        <Component {...props} />
      </div>
    );
  };
};

/**
 * This is a higher order component that wraps a repeatable component instance and determines its visibility based on the current context and props
 * Its main responsibility is to hide repeated elements if the repeat is collapsed
 * @param Component The UI component to wrap
 */
// const withFileRepeatable = (Component: React.ElementType) => {
//   return (props: FieldProps<V2FormComponentDef> | FileFieldProps<V2FormComponentDef>) => {
//     const {
//       // multiline, we will reuse it maybe later
//       repeatable,
//       index,
//       repeatCount,
//       controlDefinition,
//       onAddClick,
//       onRemoveClick,
//       moveItems,
//       isOriginal,
//     } = props;
//     return repeatable ? (
//       <RepeatFieldWrapper
//         onAddClick={onAddClick}
//         onRemoveClick={onRemoveClick}
//         controlDefinition={controlDefinition}
//         repeatable={repeatable}
//         index={index}
//         repeatCount={repeatCount}
//         moveItems={moveItems}
//         isOriginal={isOriginal}
//       >
//         <Component {...props} />
//       </RepeatFieldWrapper>
//     ) : (
//       <div style={{ marginBottom: 10 }}>
//         <Component {...props} />
//       </div>
//     );
//   };
// };

const ControlProvider: FormRendererComponentMap = {
  //Can't use filterFieldProps on the textheader, because it isn't a field.
  RootWrapper: RootWrapperWeb,
  GroupWrapper: withGroupProps(GroupWrapperWeb),
  RepeatWrapper: withRepeatWrapperProps(RepeatWrapperWeb),
  textheader: withNoRepeatableWorkflowFieldWrapper(withTextHeaderProps(TGTextHeader)),
  computed: withRepeatable(withTextFieldPropsComputed(TGTextFieldWeb)),
  textInput: withRepeatable(withTextFieldProps(TGTextFieldWeb)),
  radio: withRepeatable(withRadioFieldProps(TGRadioFieldWeb)),
  numberInput: withRepeatable(withNumberFieldProps(TGTextFieldWeb)),
  select: withRepeatable(withSelectFieldProps(TGSelectFieldWeb)),
  checkbox: withRepeatable(withCheckboxProps(TGSelectFieldWeb)),
  time: withRepeatable(withTimeFieldProps(TGTimeFieldWeb)),
  date: withRepeatable(withDateFieldProps(TGDateFieldWeb)),
  textArea: withRepeatable(withTextareaFieldProps(TGTextFieldWeb)),
  currency: withRepeatable(withCurrencyFieldProps(TGTextFieldWeb)),
  imageupload: withRepeatable(withMediaFieldProps(TGImageUploadFieldWeb)),
  fileupload: withRepeatable(withFileFieldProps(TGFileUploadFieldWeb)),
  barcode: withRepeatable(withBarcodeFieldProps(TGBarCodeFields)),
  location: withRepeatable(withLocationFieldProps(TGLocationField)),
  polyline: withRepeatable(withPolylineFieldProps(TGPolylineField)),
  mapAggregateSelector: withRepeatable(withAggregateSelectorFieldProps(TGMapAggregateSelectorField)),
};

export default ControlProvider;
