import { Box, Typography } from '@material-ui/core';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { MapAssetType } from '../../../../contexts/AggregatesContext/types';
import { CSSProperties, useContext } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { LanguageContext } from '../../../../contexts/LanguageContext/languageContext';

const RECORD_INNER_HEIGHT = 36;
const TEXT_LENGTH = 40;

const RecordItem = ({
  asset,
  handleAssetClick,
  getRecordType,
  classes,
  style,
}: {
  asset: MapAssetType & { title?: string };
  handleAssetClick: (targetAsset: MapAssetType, recordType: string) => void;
  getRecordType: (key: string) => string;
  classes: ClassNameMap<string>;
  style: CSSProperties;
}) => {
  const { translate } = useContext(LanguageContext);
  const getRecordLabel = () => {
    if (!asset.label) {
      if (asset.title) {
        return asset.title.length > TEXT_LENGTH ? `${asset.title.slice(0, TEXT_LENGTH)}...` : asset.title;
      }
    }
    return asset?.label?.length > TEXT_LENGTH ? `${asset.label.slice(0, TEXT_LENGTH)}...` : asset.label;
  };
  return (
    <Box
      key={asset.id}
      className={classes.suggestion}
      style={{ ...style, height: RECORD_INNER_HEIGHT }}
      onClick={() => handleAssetClick(asset, getRecordType(asset.recordTypeKey))}
    >
      {asset.symbolKey && (
        <img
          className={classes.assetIcon}
          src={getSvgImageString(asset.symbolKey, {
            globalScaleFactor: 1,
            embedFont: true,
          })}
        />
      )}
      <Box>
        <Typography className={classes.recordType}>{translate(getRecordType(asset.recordTypeKey))}</Typography>
        <Typography className={classes.assetLabel}>{translate(getRecordLabel())}</Typography>
      </Box>
    </Box>
  );
};

export default RecordItem;
