import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { filterNonMapAggregates, filterMapServiceAssets, reduceMapServicesToLayers } from './aggregateUtils';
import { AggregatesContextType } from './types';
import { useConfig } from '@terragotech/gen5-shared-components';
import { record, useAssetsLoader } from './useAssetsLoader';
import { useVisibleAggregatesState } from './useVisibleAggregatesState';
import { useAssetsCount } from './useAssetsCount';
import { usePusher } from '../pusherContext';
import { filter, findIndex, union } from 'lodash';
import { useSelectedProject } from '../selectedProjectContext';
import { useMapServiceState } from './useMapServiceState';
import { pusherChannelNotificationName, REFERENCE_TYPE_AGGREGATE } from '@terragotech/gen5-shared-utilities';
import { FilterContext } from '../FilterContext/filterContext';
import { useRecoilCallback } from 'recoil';
import { aggregateUpdateCountIncrementCallback } from '../../recoil/atoms';

type ProjectAggregateUpdates = {
  [proj: string]: string[];
};

const DELAY_BETWEEN_REFRESH_SECONDS = { Min: 10, Max: 60, Increment: 10 } as const;
const AggregatesContext = createContext<AggregatesContextType | undefined>(undefined);

export const AggregatesContextProvider: React.FC<{ children?: React.ReactNode }> = props => {
  const { aggregateDefinitions } = useConfig();
  const { subscribe, unSubscribe } = usePusher();
  const { selectedProjects } = useSelectedProject();
  const [multiSelectCommandsLoading, setMultiSelectCommandsLoading] = useState(false);
  const mapAggregateDefinitions = useMemo(() => filterNonMapAggregates(aggregateDefinitions), [aggregateDefinitions]);
  const lastScrolledRef = useRef(null);
  const [visibleRecordTypes, setVisibleRecordTypes] = useState<record[] | undefined>(undefined);
  const pluralProjectName = useMemo(
    () => aggregateDefinitions.find(aggregateDef => aggregateDef.name === 'Project')?.plural ?? 'Projects',
    []
  );
  const filters = useContext(FilterContext);

  const {
    visibleAggregateTypesKeys,
    visibleAggregateTypesNames,
    setVisibleAggregateTypesNames,
  } = useVisibleAggregatesState({
    aggregateDefinitions: mapAggregateDefinitions,
  });
  const {
    refetchAll,
    isDataLoading,
    assets,
    referenceTypeAssets,
    recordTypesData,
    fetchNextDataSet,
    updateScrollPosition,
    update,
    deleteAssets,
    deleteAssetsById,
    fetchAssetsForFilter,
    getSearchResults,
    getAssetsForMultiSelect
  } = useAssetsLoader({
    aggregateDefinitions,
    mapAggregateDefinitions,
    visibleAggregateTypesNames,
  });

  const { allAssetsCount, currentTypeAssetsCount } = useAssetsCount({
    assets,
  });

  const incrementAggregateUpdateCounts = useRecoilCallback(aggregateUpdateCountIncrementCallback);

  const mapServices = useMemo(() => filterMapServiceAssets(referenceTypeAssets), [referenceTypeAssets]);
  const mapServiceLayers = useMemo(() => reduceMapServicesToLayers(mapServices), [mapServices]);

  const { visibleMapServiceKeys, visibleMapServiceNames, setVisibleMapServiceKeys } = useMapServiceState({
    mapServices: mapServices,
    mapServiceLayers: mapServiceLayers,
  });

  const toRefresh = useRef<ProjectAggregateUpdates>({});
  const lastTimestamp = useRef<number>(Date.now());
  const timeoutRef = useRef<{ timeout: NodeJS.Timeout | null, delayCount: number }>({ timeout: null, delayCount: 0 });

  const resetRefreshTimeout = (resetCount?: boolean) => {
    if (timeoutRef.current.timeout) {
      clearTimeout(timeoutRef.current.timeout);
      timeoutRef.current.timeout = null;
    }
    if (resetCount) {
      timeoutRef.current.delayCount = 0;
    }
  }

  const setRefreshTimeout = () => {
    const incrementedDelaySeconds = Math.min(
      DELAY_BETWEEN_REFRESH_SECONDS.Min + DELAY_BETWEEN_REFRESH_SECONDS.Increment * timeoutRef.current.delayCount++,
      DELAY_BETWEEN_REFRESH_SECONDS.Max,
    );
    resetRefreshTimeout();
    timeoutRef.current.timeout = setTimeout(() => {
      executeRefresh();
    }, incrementedDelaySeconds * 1000 - Date.now() + lastTimestamp.current);
  }

  const executeRefresh = useCallback(() => {
    lastTimestamp.current = Date.now();
    resetRefreshTimeout(true);
    for (const proj in toRefresh.current) {
      update(toRefresh.current[proj], proj);
      incrementAggregateUpdateCounts([proj], toRefresh.current[proj]);
    }
    toRefresh.current = {};
    lastTimestamp.current = Date.now();
    checkRefresh();
  }, [update, incrementAggregateUpdateCounts]);

  const checkRefresh = useCallback(() => {
    if (Object.keys(toRefresh.current).length) {
      const elapsed = Date.now() - lastTimestamp.current;
      if (
        !timeoutRef.current.timeout && elapsed > DELAY_BETWEEN_REFRESH_SECONDS.Min * 1000
        || timeoutRef.current.timeout && elapsed > DELAY_BETWEEN_REFRESH_SECONDS.Max * 1000
      ) {
        executeRefresh();
      } else {
        setRefreshTimeout();
      }
    }
  }, [toRefresh, lastTimestamp, executeRefresh, timeoutRef]);

  const updateData = useCallback(
    (updates: ProjectAggregateUpdates) => {
      for (const [proj, aggTypes] of Object.entries(updates)) {
        toRefresh.current[proj] = union(toRefresh.current[proj], aggTypes);
      }
      checkRefresh();
    },
    [toRefresh, checkRefresh]
  );

  useEffect(() => {
    subscribe(pusherChannelNotificationName.AGGREGATE, 'delete', (m: Record<string, string[]>) => {
      deleteAssets(m);
    });
  }, [subscribe]);

  useEffect(() => {
    subscribe(pusherChannelNotificationName.PROJECT_CHANGED, 'change', (m: any) => {
      const toDelete: string[] = [];
      for (const item of m) {
        const [id, from, to] = item.split(',');
        if (id && from && to) {
          if (selectedProjects.includes(from) && !selectedProjects.includes(to)) {
            toDelete.push(id);
          }
        }
      }
      if (toDelete.length) {
        deleteAssetsById(toDelete);
      }
    });
  }, [subscribe, selectedProjects]);

  useEffect(() => {
    const selectedProjectsWithAdhoc = [...selectedProjects, ...[REFERENCE_TYPE_AGGREGATE]];

    subscribe(pusherChannelNotificationName.AGGREGATE, 'update', (m: string[]) => {
      const updates = m.reduce<ProjectAggregateUpdates>((acc, curr) => {
        const [proj, aggType] = curr.split('::');
        return selectedProjectsWithAdhoc.some(p => p === proj) ? { ...acc, [proj]: union(acc[proj], [aggType]) } : acc;
      }, {});
      updateData(updates);
    });

  }, [selectedProjects, subscribe, unSubscribe]);

  useEffect(() => {
    const mapVisibleRecordTypes = filter(recordTypesData, ({ value }) => {
      const recordDef = aggregateDefinitions.find(def => def.name === value)
      if(recordDef && recordDef.hiddenFromTableView){
        return false;
      }
      return (
        findIndex(visibleAggregateTypesNames, val => {
          return value == val;
        }) >= 0
      );
    });
    setVisibleRecordTypes(() => (mapVisibleRecordTypes.length != 0 ? mapVisibleRecordTypes : undefined));
  }, [visibleAggregateTypesNames, recordTypesData]);

  const value: AggregatesContextType = useMemo(() => {
    return {
      assets: assets,
      loading: isDataLoading,
      refetchAll,
      setVisibleAggregateTypesNames,
      visibleAggregateTypesNames,
      assetsCount: allAssetsCount,
      currentTypeAssetsCount,
      mapServices: mapServices,
      mapServiceLayers: mapServiceLayers,
      visibleMapServiceKeys,
      visibleMapServiceNames,
      setVisibleMapServiceKeys,
      recordTypesData: visibleRecordTypes,
      fetchNextDataSet,
      updateScrollPosition,
      lastScrolledRef,
      fetchAssetsForFilter,
      multiSelectCommandsLoading,
      setMultiSelectCommandsLoading,
      getSearchResults,
      pluralProjectName,
      getAssetsForMultiSelect,
    };
  }, [
    assets,
    isDataLoading,
    refetchAll,
    setVisibleAggregateTypesNames,
    visibleAggregateTypesNames,
    allAssetsCount,
    currentTypeAssetsCount,
    mapServices,
    mapServiceLayers,
    visibleMapServiceKeys,
    visibleMapServiceNames,
    setVisibleMapServiceKeys,
    visibleRecordTypes,
    fetchNextDataSet,
    updateScrollPosition,
    lastScrolledRef,
    fetchAssetsForFilter,
    multiSelectCommandsLoading,
    setMultiSelectCommandsLoading,
    getSearchResults,
    pluralProjectName,
    getAssetsForMultiSelect
  ]);

  return <AggregatesContext.Provider value={value} {...props} />;
};

export const useAggregates = (): AggregatesContextType => {
  const context = useContext(AggregatesContext);
  if (!context) {
    throw new Error('useAggregates must be used within an MapAssetProvider');
  }

  return context;
};
