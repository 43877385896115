import React, { useState, useMemo, useCallback, useContext, useEffect, useRef } from 'react';
import { makeStyles, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import { useDialog } from '../../hooks/useDialog';
import FilterDialogWrapper from './FilterDialogWrapper';
import { debounce, uniq } from 'lodash';
import Search from '@material-ui/icons/Search';
import { TableDataContext } from '../../hooks/useTableData';
import { ColumnFilterInputOptions } from '../../hooks/tableHooks/useColumns';
import { ValueType } from '../../hooks/useTable';
import { colors } from '../../styles/theme';
import { useCommonStyles } from '../../styles/commonStyles';
import { useUtils } from '../../contexts/utilContext';
import clsx from 'clsx';
import ActiveFilterIcon from './columnSearchFilter';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const useStyles = makeStyles(theme => ({
  filterInput: {
    width: '100%',
    height: '39px',
    boxSizing: 'content-box',
    '& input': {
      fontSize: '13px',
      height: '100%',
      boxSizing: 'content-box',
      lineHeight: '100%',
      color: colors.black0,
    },
    '& .MuiInput-root': {
      height: 39,
    },
    backgroundColor: colors.white,
    color: colors.black0,
    marginLeft: '-16px',
    padding: '0 16px',
  },
  filterInputIcon: {
    width: '14px',
    height: '14px',
    '& path': {
      fill: colors.black35,
    },
  },
  focusButton: {
    padding: 0,
  },
}));

export interface StyledTableFilterInputProps {
  name: string;
  keyName: string;
  options?: ColumnFilterInputOptions;
  fieldType?: string;
}

const StyledTableFilterInput = (props: StyledTableFilterInputProps) => {
  const classes = useStyles();
  const txtFieldRef = useRef<null | HTMLInputElement>(null);
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const { keyName: key, name, options } = props;
  const { setFilter, data, filters, setFiltersApplied, filtersApplied } = useContext(TableDataContext);
  const { translate, language } = useContext(LanguageContext);
  const dialog = useDialog();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (txtFieldRef.current && value.length > 0) {
      txtFieldRef.current?.focus();
    }
  }, [value]);

  const updateFilter = () => {
    const values = filters[key];
    if (typeof values === 'string') {
      setValue(values);
    }
  };

  useEffect(() => {
    updateFilter();
  }, [filters]);

  const lookup = useMemo(
    () =>
      uniq(
        data.reduce(
          (acc, row) => [
            ...acc,
            ...(row[key]
              ? options?.valueDelimiter
                ? row[key].toString().split(options.valueDelimiter)
                : Array.isArray(row[key]) && (row[key] as ValueType[]).length
                ? (row[key] as ValueType[]).map(x => x.toString())
                : [row[key].toString()]
              : ['']),
          ],
          [] as string[]
        )
      ),
    [data, key]
  );
  const textValue = useMemo(() => (Array.isArray(value) ? value.join(', ') : value), [value]);

  const areFiltersActive = useMemo(() => {
    const filterValue = filters[key];
    return Array.isArray(filterValue) && filterValue.length >= 0 && filterValue.length !== lookup.length;
  }, [filters, key]);

  const onInputChange = useCallback(
    debounce((val: string) => {
      setFilter(key, val);
      setFiltersApplied(true);
    }, 300),
    [setFilter, key]
  );
  const handleDialogClose = useCallback(
    (values: ReadonlyArray<string>) => {
      setFiltersApplied(true);
      setFilter(key, [...values]);
      dialog.closeDialog();
    },
    [dialog, setFilter, key, setFiltersApplied]
  );
  const handleClose = () => {
    dialog.closeDialog();
  };
  const handleIconClick = useCallback(() => {
    dialog.openDialog(
      <FilterDialogWrapper
        title={`${translate('$__NAME__$ Dialog', { NAME: name })}`}
        elements={lookup}
        selectedElements={
          Array.isArray(filters[key]) && filters[key].length > 0
            ? (filters[key] as string[])
            : filters.hasOwnProperty(key) && Array.isArray(filters[key]) && filters[key].length === 0
            ? []
            : lookup
        }
        onClose={handleDialogClose}
        inputOptions={options}
        onCancel={handleClose}
        filtersApplied={filtersApplied}
        setFiltersApplied={setFiltersApplied}
        fieldType={props.fieldType}
      />
    );
  }, [lookup, dialog, handleDialogClose, value, name]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;
      setValue(value);
      onInputChange(value);
    },
    [onInputChange]
  );

  useEffect(() => {
    if (value && !filters[key]) {
      setValue('');
      onInputChange('');
    }
  }, [filters, key]); // Excluding value from dependencies; we only want to update when filters change first.

  const endAdornment = useMemo(
    () => (
      <Tooltip title={translate('Filter')}>
        <InputAdornment position="end">
          <ActiveFilterIcon onClick={handleIconClick} showIndicator={areFiltersActive || undefined} />
        </InputAdornment>
      </Tooltip>
    ),
    [classes, handleIconClick, areFiltersActive, language]
  );

  return (
    <TextField
      className={clsx(classes.filterInput, isIOSSafari && commonClasses.safariInnerInputFontSize)}
      value={textValue}
      inputRef={txtFieldRef}
      onChange={handleChange}
      placeholder={translate('Search')}
      inputProps={{ 'aria-label': `filter data` }}
      InputProps={{
        endAdornment,
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.filterInputIcon} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

export default StyledTableFilterInput;
