import React, { useState, useMemo, useContext } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import useStyles, { getClasses } from './Common';
import TGOptionSelectorModal from '../../Common/Modals/TGOptionSelectorModal';
import { StyledTableEditorRef, StyledTableEditorProps } from '../../../hooks/tableHooks/useColumns';
import { ValueType } from '../../../hooks/useTable';
import { has } from 'lodash';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

const StyledTableSelectEditor = React.forwardRef<
  StyledTableEditorRef,
  StyledTableEditorProps & {
    mapper: (row: unknown) => { value: string; name: string };
    additionalMapper?: (row: unknown, value: unknown) => Record<string,unknown>;
    lookup: ValueType[];
    label: string;
    retainUnavailableValues?: boolean;
  }
>((props, ref) => {
  const { onRowChange, column, row, onClose, retainUnavailableValues } = props;
  const { mapper, lookup, additionalMapper } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  const initialValue = row[column.key];

  if (!Array.isArray(initialValue)) {
    throw new Error('Value of StyledTableSelectEditor should be an array');
  }

  const [value, setValue] = useState(initialValue.map((x) => mapper(x).value));

  const [version, setVersion] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const options = useMemo(() => lookup.map((x) => mapper(x)), [lookup, mapper]);

  const textValue = useMemo(() => {
    const findRecord = (value: string) => {
      const found = lookup.find((x) => {
        return mapper(x).value === value;
      });
      if (found) {
        return found;
      }
      console.log(`No record found in lookup for value [${value}]`);
    };

    const valuesMappedToNames = value.reduce((acc, curr, i, a) => {
      const record = findRecord(curr);
      if (record) {
        acc.push(mapper(record).name);
      }
      return acc;
    }, [] as string[]);
    return valuesMappedToNames.join(', ');
  }, [mapper, value, lookup]);

  const handleDone = (value: unknown) => {
    setValue(value as string[]);
    setIsModalOpen(false);
    setVersion(version + 1);
    if (value) {
      const getOriginalValue = (value: unknown) => lookup.find((x) => mapper(x).value === value);
      const option = (() => {
        if (Array.isArray(value)) {
          const values = value.map((v) => getOriginalValue(v)!).filter((v) => !!value);
          if (retainUnavailableValues) {
            const unavailableValues = initialValue.filter(x => !values.find(y => mapper(y).value === mapper(x).value) && !lookup.find(y => mapper(y).value === mapper(x).value));
            return [...values, ...(lookup.length && has(lookup[0], 'id') ? unavailableValues.map(x => ({id: mapper(x).value})) : unavailableValues)];
          } else {
            return values;
          }
        };
        return getOriginalValue(value);
      })();
      if (option) {
        const additionalChanges = (additionalMapper ? additionalMapper(row,option) : {})
        onRowChange({ ...row, [column.key]: option, ...(additionalChanges), __changes: { [column.key]: option, ...(additionalChanges) } }, true);
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose(false);
  };

  return (
    <div className={getClasses(props, classes).join(' ')}>
      <TextField
        value={textValue}
        placeholder={translate('Search')}
        onClick={handleOnClick}
        inputProps={{ 'aria-label': `change data`, className: classes.input }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown color="primary" className={classes.icon} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
      <TGOptionSelectorModal
        open={isModalOpen}
        filterPlaceHolder={'Search'}
        pathToLabel="name"
        pathToValue="value"
        multiSelect={true}
        onDone={handleDone}
        onCancel={handleCancel}
        options={options || []}
        title={props.label}
        value={value}
        setOpen={setIsModalOpen}
      />
    </div>
  );
});

export default StyledTableSelectEditor;
