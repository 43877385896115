import React, { MouseEvent, useContext } from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';
import { Action } from './AssetCardAction';
import { colors } from '../../../styles/theme';
import Color from 'color';
import TGTooltip from '../../TGTooltip';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

interface AssetCardPrimaryActionProps {
  action: Action;
  onClick?: () => void;
}

const AssetCardPrimaryAction = (props: AssetCardPrimaryActionProps) => {
  const { action } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick();
    if (props.onClick) {
      props.onClick();
    }
  };

  const color: Color = (() => {
    const base = Color(action.color).isLight() ? Color(colors.black) : Color(colors.white);
    if (action.disabled) {
      return base.fade(0.4);
    }
    return base;
  })();

  const styles: React.CSSProperties | undefined = action.color
    ? {
        backgroundColor: action.color,
        color: `rgba(${color.rgb().array().join(',')})`,
      }
    : undefined;

  return (
    <TGTooltip title={translate(action.tooltipText || '')}>
      <span>
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={action.icon}
          color="primary"
          className={classes.button}
          disabled={action.disabled}
          style={styles}
        >
          <Typography className={classes.buttonText}>{translate(action.label || '')}</Typography>
        </Button>
      </span>
    </TGTooltip>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    color: colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    gap: 5,
    padding: 10,
    height: 37,
    '& .MuiButton-startIcon': {
      '& div':{
        marginRight: '0px !important',
        '& svg':{
        height: '16px !important',
        width: '16px !important',
        padding: '1px 0px',
        marginRight: '0px !important',
        },
      },
      marginRight: 5,
    },
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: -0.5,
    display: 'flex',
    alignItems: 'center',
  }
}));

export default AssetCardPrimaryAction;
