import { Box, Drawer, IconButton, Typography, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { useConfig } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import { colors } from '../styles/theme';
import AppLogo from './AppLogo';
import { useSections } from '../hooks/useSections';

interface Props {
  open: boolean;
  toggleDrawer: (value: boolean) => void;
}

const MobileDrawer = ({ toggleDrawer, open }: Props) => {
  const classes = useStyles();
  const config = useConfig();
  const { items } = useSections();
  const navigationItem = _.first(items.filter(o => o.title === 'Navigation'));
  const authItem = _.first(items.filter(o => o.title === 'authSection'));

  return (
    <Drawer open={open} onClose={() => toggleDrawer(false)} className={classes.container}>
      <Box className={classes.root}>
        <Box>
          <Box className={classes.header}>
            <Box className={classes.logoContainer}>
              <AppLogo className={classes.logo} />
              <Typography className={classes.title}>{config.applicationName}</Typography>
            </Box>
            <IconButton className={classes.closeButton} onClick={() => toggleDrawer(false)}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
          <Box className={classes.body}>{navigationItem?.content}</Box>
        </Box>
        <Box className={classes.footer}>{authItem?.content}</Box>
      </Box>
    </Drawer>
  );
};

const DRAWER_PADDING = 26;
const useStyles = makeStyles(theme => ({
  container: {
    '& .MuiPaper-root.MuiDrawer-paper': {
      padding: DRAWER_PADDING,
      width: '55%',
      height: '100%',
      paddingBottom: 0,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(100% - ${DRAWER_PADDING * 2}px)`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    width: '75%',
    alignItems: 'center',
  },
  logo: {
    width: 36.5,
    height: 36.5,
  },
  title: {
    color: colors.black0,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginLeft: 9,
  },
  closeButton: {
    width: 30,
    height: 30,
  },
  body: {
    marginTop: 30,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 31,
  },
}));
export default MobileDrawer;
