import { useContext } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useTheme } from '@material-ui/core';
import { languageSelection } from '../components/MapRightDrawer/LanguageSection';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export const useLanguage = () => {
  const { languages } = useConfig();
  const { language, setLanguage, supportedLanguages } = useContext(LanguageContext); 
  const theme = useTheme();

  const items = languageSelection({
    allowedLanguages: supportedLanguages,
    currentLanguage: language,
    onChange: setLanguage, 
    theme,
  });

  return { items };
};

