import { Column } from "../../hooks/tableHooks/useColumns";
import { TableData } from "../../hooks/useTable";

export const moveFromRightToLeft = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => [
  ...columns.slice(0, destinationIndex),
  columns[sourceIndex],
  ...columns.slice(destinationIndex, sourceIndex),
  ...columns.slice(sourceIndex + 1),
];

export const moveFromLeftToRight = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => [
  ...columns.slice(0, sourceIndex),
  ...columns.slice(sourceIndex + 1, destinationIndex + 1),
  columns[sourceIndex],
  ...columns.slice(destinationIndex + 1),
];

export const moveColumns = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => {
  return sourceIndex > destinationIndex
    ? moveFromRightToLeft(columns, sourceIndex, destinationIndex)
    : moveFromLeftToRight(columns, sourceIndex, destinationIndex);
};

export const getNumberOfStickyColumns = <Data extends TableData>(columns: ReadonlyArray<Column<Data>>) => {
  return columns.reduce<number>((r, x) => r + (x.sticky ? 1 : 0), 0);
};

export const handleColumnsMove = <Data extends TableData>(
  from: number,
  to: number,
  columns: ReadonlyArray<Column<Data>>
) => {
  if (from === 0) {
    return;
  }
  const newColumns = moveColumns(columns, from, to);
  const numberOfPinnedColumns = getNumberOfStickyColumns(columns);
  const numberOfPinnedColumnsDelta = (() => {
    if (from >= numberOfPinnedColumns && to < numberOfPinnedColumns) return 1;
    if (from < numberOfPinnedColumns && to >= numberOfPinnedColumns) return -1;
    return 0;
  })();
  return newColumns.map((column, index) => ({
    ...column,
    sticky: index < numberOfPinnedColumns + numberOfPinnedColumnsDelta,
  }));
};
