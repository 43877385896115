import React, { forwardRef, useContext, useState } from 'react';
import { Select, MenuItem, makeStyles, InputBase, withStyles, createStyles, Theme } from '@material-ui/core';
import { Editor2Props } from '@terragotech/react-data-grid';
import { TableData } from '../../hooks/useTable';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface DropdownEditorProps {
  options: { value: string; label: string }[];
}
const useStyles = makeStyles({
  listItem: {
    minHeight: 36,
  },
});

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: 13,
      padding: '3px 0px 3px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use fonts to match ag-grid
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen-Sans',
        'Ubuntu',
        'Cantarell',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);
/**
 * DropDownEditor - Displays List of options as a drop down
 *
 * @param value
 * @param options
 */
export default forwardRef((props: DropdownEditorProps & Editor2Props<TableData>, ref) => {
  const { options, row, column, onRowChange } = props;
  const value = row[column.key];
  const [picked, setPicked] = useState(value || '');
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    if (!open) setOpen(true);
  };

  return (
    <div
      onClick={handleOnClick}
      style={{
        width: props.column.width,
        height: props.rowHeight,
      }}
    >
      <Select
        autoWidth={true}
        value={picked}
        onChange={(e) => {
          const value = e.target.value as string;
          setPicked(value);
          onRowChange({ ...row, [column.key]: value, __changes: { [column.key]: value } });
        }}
        onClose={() => setOpen(false)}
        open={open}
        innerRef={ref}
        input={<CustomInput />}
      >
        {options.map((option: { value: string; label: string }) => (
          <MenuItem key={option.value} value={option.value} className={classes.listItem}>
            {translate(option.label)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
});
