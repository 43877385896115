import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useState } from 'react';
import { colors } from '../../styles/theme';
import { useActionsExecutor } from '../../hooks/useActionsExecutor';
import WorkflowCommandButton from './WorkflowCommandButton';
import { useConditionalEditWorkflows } from '../../hooks/useConditionalEditWorkflows';
import MoreMenu from './MoreMenu';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { AssetType } from '../../contexts/AggregatesContext/types';

interface Props {
  aggregateType: string;
  aggregateId: string;
  isMobileView?: boolean;
  asset?: AssetType;
}
const WorkflowCommands: FunctionComponent<Props> = props => {
  const { aggregateId, aggregateType, isMobileView = false, asset } = props;
  const classes = useStyles();
  const { processAction } = useActionsExecutor();
  const [workflowElements, setWorkflowElements] = useState<null | HTMLElement>(null);
  const visibleWorkflows = useConditionalEditWorkflows(asset, aggregateType);

  if (!asset) {
    return null;
  }

  const actionsButtons = visibleWorkflows.map(workflow => {
    const action = processAction(workflow, [asset], aggregateId, undefined, undefined, aggregateType);
    if (action) {
      return {
        ...workflow,
        ...action,
      };
    }
    return null;
  });

  const onClickMore = (event: React.MouseEvent<HTMLElement>) => {
    setWorkflowElements(event.currentTarget);
  };

  const closeMore = () => {
    setWorkflowElements(null);
  };
  const moreIndex = isMobileView ? 0 : 4;
  const [visibleButtons, moreButtons] = [actionsButtons.slice(0, moreIndex), actionsButtons.slice(moreIndex)];
  return (
    <>
      <div className={classes.commandContainer}>
        {visibleButtons.length
          ? visibleButtons.map(workflow => {
              return (
                workflow && (
                  <WorkflowCommandButton
                    key={workflow.label}
                    label={workflow.label || ''}
                    disabled={workflow.state !== 'enabled'}
                    icon={workflow.icon}
                    disabledMessage={workflow.disabledMessage as string | undefined}
                    onClick={workflow.onClick}
                  />
                )
              );
            })
          : null}
        {moreButtons.length > 0 && <MoreMenu {...{ onClickMore, closeMore, workflowElements, moreButtons, isMobileView }} />}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
      color: colors.black,
    },
    commandContainer: {
      display: 'flex',
      gap: 11,
      whiteSpace: 'nowrap',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      paddingTop: 15,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        justifyContent: 'flex-start',
        paddingTop: 0,
      },
    },
    unavailable: {
      color: colors.chineseSilver,
      fontSize: 14,
    },
  })
);

export default WorkflowCommands;
