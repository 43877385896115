import _ from 'lodash';
import { pages } from '../contexts/assetsDashboardContext';
import { AssetType } from '@terragotech/gen5-shared-components';
import { colors } from '../styles/theme';

export const isLatitudeLongitudeValid = (latitude?: number, longitude?: number): boolean => {
  if (!latitude || !longitude || latitude > 90 || latitude < -90 || longitude > 180 || longitude < -180) {
    return false;
  }
  return true;
};

export const midPointFinder = (coords1: [number, number], coords2: [number, number]): [number, number] => {
  return [(coords1[0] + coords2[0]) / 2, (coords1[1] + coords2[1]) / 2];
};
export function getLighterColorCode(color: string, opacity: number) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color: any) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + opacity)).toString(16)).substr(-2)
      )
  );
}
export const getLightColor = (color: string, percent: number) => {
  const hex = color.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  r = Math.min(255, Math.round(r + (255 - r) * (percent / 100)));
  g = Math.min(255, Math.round(g + (255 - g) * (percent / 100)));
  b = Math.min(255, Math.round(b + (255 - b) * (percent / 100)));
  const newColor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  return newColor;
};

export const MOBILE_BREAKPOINT = 768;
export const MOBILE_HEADER_HEIGHT = 64;
export const LEFT_PANEL_WIDTH = 70;
export const ASSET_TYPE_BAR_SIZE = 55;
export const DEFAULT_SYMBOL_OPTION = { globalScaleFactor: 1, embedFont: true };
export const SEARCH_BAR_WIDTH = 360;
export const MAP_CARD_WIDTH = 360;
export const BOTTOM_GAP = 8;
export const BOTTOM_GAP_WEB = 7;
export const END_USER_AGREEMENT_URL =
  'https://knowledge.terragotech.com/hc/en-us/articles/219587847-End-User-License-Agreement';

export const PageDetails: { key: pages; label: string }[] = [
  {
    key: 'about',
    label: 'About',
  },
  {
    key: 'analytics',
    label: 'Analytics',
  },
  {
    key: 'import',
    label: 'Import',
  },
  {
    key: 'usersAndRoles',
    label: 'Users Administration',
  },
  {
    key: 'apiMessages',
    label: 'API Messages'
  }
];

export const PROJECT_SELECTED_KEY = 'projectsSelected';
export const isValidLocation = (coordinate: Array<number | undefined>): boolean => {
  if (_.isArray(coordinate) && coordinate.length >= 2) {
    const lat = coordinate[1];
    const lon = coordinate[0];
    return _.isNumber(lat) && _.isNumber(lon) && !_.isEqual(lat, 0) && !_.isEqual(lon, 0);
  }
  return false;
};
export const itemHasValidPrimaryLocation = (item: AssetType, fieldtype?: string) => {
  const location = _.get(item, fieldtype as string);
  if (_.isObject(location) && _.get(location, 'type') === 'Point') {
    return isValidLocation((location as GeoJSON.Point)?.coordinates);
  }
  return !!location;
};
const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="200" height="200" fill="white" stroke="${colors.paleSlate}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><defs><filter id="shadow" x="-50%" y="-50%" width="200%" height="200%"><feDropShadow dx="0" dy="0" stdDeviation="1" flood-color="${colors.black20}" /></filter></defs><path filter="url(#shadow)" d="M21 10c0 6.627-9 13-9 13s-9-6.373-9-13a9 9 0 0118 0z"/></svg>`;
export const whiteDropPinSVG = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgString)}`;

export const hasIncludes = (content: string, searchText: string) => _.toLower(content).includes(_.toLower(searchText));

const DISCLAIMERKEY = 'legalDisclaimer'

export const getIsDisclaimerAccepted = ():boolean =>{
  const state = localStorage.getItem(DISCLAIMERKEY)
  if(state){
  return JSON.parse(state?.toLowerCase())
  }
  return false
}

export const setDisclaimerState = (value:boolean) =>{
  localStorage.setItem(DISCLAIMERKEY,String(value))
}
