import React, { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

const ErrorDisplay = ({ error }: { error: unknown }) => {
  const { translate } = useContext(LanguageContext);
  return (
    <Tooltip
      title={`${translate('Error')}: ${translate(error as string)}`}
      aria-label={`${translate('Error')}: ${translate(error as string)}`}>
      <ErrorIcon />
    </Tooltip>
  );
};

export default ErrorDisplay;
