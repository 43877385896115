import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, Input, InputAdornment, Box, Divider, IconButton } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../styles/theme';
import BottomBar from '../BottomBar';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import _ from 'lodash';
import clsx from 'clsx';
import { useUtils } from '../../../contexts/utilContext';
import { useCommonStyles } from '../../../styles/commonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

const HEADER_FOOTER_HEIGHT_WITH_PADDING = 271;
const DIALOG_TOP_MARGIN = 32;
const MOBILE_HEADER_FOOTER_HEIGHT_WITH_PADDING = 209;
const DIALOG_BODY_PADDING = 42;
const DIALOG_VERTICAL_MARGIN = 16;
export interface DialogWrapperProps {
  title: string;
  open: boolean;
  filterPlaceHolder: string;
  onCancel: () => void;
  onDone?: () => void;
  children: React.ReactNode;
  searchText: string;
  handleSearchText: (text: string) => void;
  actions: React.ReactNode;
  isRoles?: boolean;
  isUsersAndRolesDialog?: boolean;
}
/**
 DialogWrapper - wraps lazy loaded lists and has search functionality
 Different from DialogueWrapper which is used for Media Fields and Location Modals
 */
const DialogWrapper = (props: DialogWrapperProps) => {
  const {
    open,
    onDone,
    onCancel,
    filterPlaceHolder,
    title,
    searchText,
    handleSearchText,
    isRoles,
    isUsersAndRolesDialog,
  } = props;
  const isCheckTitleRoles = _.isEqual(title, 'Roles');
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [localSearchText, setLocalSearchText] = useState(searchText);
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);
  return (
    <Dialog
      open={open}
      className={clsx(classes.dialog, isUsersAndRolesDialog && !isMobileView && classes.usersAndRolesDialog)}
      onClose={onCancel}
      classes={{ paperScrollPaper: classes.paperRoot }}
    >
      <div className={classes.dialogTitleBar}>
        <Box className={classes.titleContainer}>
          <DialogTitle className={classes.dialogTitle}>{translate(title)}</DialogTitle>
          <IconButton onClick={() => onCancel && onCancel()} className={classes.closeRoot}>
            <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
          </IconButton>
        </Box>
        <div className={classes.searchBar}>
          <Input
            startAdornment={
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            }
            placeholder={translate(filterPlaceHolder)}
            className={clsx(classes.input, isIOSSafari && commonClasses.safariMuiInputFontSize)}
            value={localSearchText}
            onChange={e => {
              setLocalSearchText(e.target.value);
              handleSearchText(e.target.value);
            }}
            disableUnderline
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <Box className={classes.body}>
        <DialogActions className={classes.dialogActions}>{props.actions}</DialogActions>
        <div className={classes.content}>{props.children}</div>
      </Box>
      <BottomBar onCancelClick={onCancel} onDoneClick={onDone} buttonText={isCheckTitleRoles ? 'Add' : 'Done'} />
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    height: `calc(100vh - ${HEADER_FOOTER_HEIGHT_WITH_PADDING}px - ${DIALOG_TOP_MARGIN}px)`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: `calc(100% - ${DIALOG_BODY_PADDING}px)`,
    },
  },
  dialog: {
    "& [role='dialog']": {
      width: 650,
      marginBottom: 0,
      maxHeight: `calc(100vh - ${DIALOG_TOP_MARGIN}px)`,
      padding: '6px 0px 6px 0px',
      boxShadow: 'none',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        margin: 0,
        maxHeight: '100%',
        width: '100%',
        minWidth: 0,
        height: '99%',
      },
    },
  },
  usersAndRolesDialog: {
    "& [role='dialog']": {
      maxHeight: '100%',
      height: `calc(95vh - ${DIALOG_VERTICAL_MARGIN}px)`,
      maxWidth: '100%',
      marginTop: 0,
    },
  },
  dialogTitleBar: {
    backgroundColor: colors.white,
    color: colors.black0,
    boxSizing: 'border-box',
    padding: '18px  30px',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 14,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '18px  22px',
    },
  },
  dialogTitle: {
    padding: 0,
    '& h2': {
      fontSize: 19,
      fontWeight: 500,
      color: colors.black0,
    },
  },
  button: {
    color: colors.white,
  },
  radioButtonSpacer: {
    width: '68px', //width of done button
  },
  dialogActions: {
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    padding: 0,
    marginBottom: 3,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colors.cultured7,
    color: colors.black0,
    padding: '8px 12px',
    width: '100%',
    height: 38,
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 4,
    '& :before': {
      display: 'none',
      borderBottom: 0,
    },
  },
  searchIcon: {
    height: 22,
    width: 22,
    color: colors.black35,
  },
  input: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: 0,
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.black0,
      '&::placeholder': {
        color: colors.black35,
        opacity: 1,
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
  },
  paperRoot: {
    overflow: 'hidden',
  },
  body: {
    padding: '22px 0px 0px 34px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '22px',
      paddingRight: 0,
      paddingBottom: 0,
      height: `calc(100% - ${MOBILE_HEADER_FOOTER_HEIGHT_WITH_PADDING}px)`,
    },
  },
  divider: {
    background: colors.black10,
  },
  closeRoot: {
    width: 24,
    height: 24,
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export default DialogWrapper;
