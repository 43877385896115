import React, { FunctionComponent, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { withStyles, WithStyles, createStyles, Theme, Input, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import SetFilter from '../Filters/SetFilter';
import CalendarFilter from '../Filters/CalendarFilter';
import { FilterContext } from '../../contexts/FilterContext/filterContext';
import _ from 'lodash';
import clsx from 'clsx';
import { ValueType } from '../../hooks/useTable';
import { useAggregates } from '../../contexts/AggregatesContext';
import { useRecordType } from '../../contexts/recordTypeContext';
import { colors } from '../../styles/theme';
import { FilterState } from '../../contexts/FilterContext/types';
import { useUtils } from '../../contexts/utilContext';
import { useCommonStyles } from '../../styles/commonStyles';
import ActiveFilterIcon from '../StyledTable/columnSearchFilter';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export const FIELDS_FETCH_SIZE = 300;

const DATETIME = 'DateTime' as const;
const DATE = 'Date' as const;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    assetSearchBar: {
      display: 'inline-flex',
      width: '100%',
    },
    searchIcon: {
      height: 14,
      width: 14,
      color: colors.black35,
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 13,
      fontWeight: 400,
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: colors.black35,
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 400,
          opacity: 1,
        },
      },
    },
    filterIcon: {
      cursor: 'pointer',
      height: 11,
      width: 13,
      color: colors.black35,
    },
    focusButton: {
      padding: 0,
    },
  });
const getValueFromValueType = (value: ValueType | null, key: 'id' | 'label' | 'title') => {
  return value && typeof value === 'object' && !Array.isArray(value) ? value[key] : value;
};
export interface ColumnSearchProps extends WithStyles<typeof styles> {
  displayName: string;
  field: string;
  isNonAsset?: boolean;
  type?: string;
  customFetchFields?: (field: string, searchText?: string) => string[];
}
/**
 * ColumnSearch - Lies at the top of each column in AgGrid.
 *
 * Has the functionality to set the filter state for each column
 *
 * Contains logic for obtaining unique values to filter by...
 *
 * @param displayName
 * @param field
 * @param classes
 */

let focusedFilter = '';
const ColumnSearch: FunctionComponent<ColumnSearchProps> = props => {
  const { displayName, field, isNonAsset, type, customFetchFields, classes } = props;
  const [filterOpen, setFilterOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [dayFilter, setDayFilter] = useState('');

  const { selectedRecordTypeDefinition } = useRecordType();
  const assetInfo = useAggregates();
  let unfilteredAssets = assetInfo.assets;

  const fieldType: string = useMemo(() => {
    if (!isNonAsset) {
      const temp = selectedRecordTypeDefinition.propertyDefinitions.find(column => column.field === field);
      return temp?.type || '';
    }
    return type || '';
  }, [isNonAsset, selectedRecordTypeDefinition, type, field]);

  const filterContext = useContext(FilterContext);
  const { translate } = useContext(LanguageContext);
  const filterState = filterContext.filterState;
  const isMapExtentEnabled = filterContext.mapFilterState.extentFilterEnabled;
  const drawFilter = filterContext.drawFilter;
  const setFilterState = filterContext.setFilterState;
  const isQuickSearchFocus = filterContext.isQuickSearchFocus;
  const setQuickSearchFocus = filterContext.setQuickSearchFocus;
  focusedFilter = isQuickSearchFocus ? '' : focusedFilter;
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  useEffect(() => {
    setIsLoading(false);
  }, [filterContext]);

  const [quickFilterText, setQuickFilterText] = useState(filterState[`${field}-QuickFilter`] || '');
  useEffect(() => {
    if (Object.keys(filterState).length === 0) {
      setQuickFilterText('');
    }
  }, [filterState]);

  const isFilterActive = (field: string) => {
    return filterState[field] !== undefined;
  };

  const fetchFilterFields = async (field: string, limit?: number, offset?: number, searchText?: string) => {
    let fields = [];
    if (isNonAsset) {
      if (!customFetchFields) return [];
      return customFetchFields(field, searchText);
    }
    const fetchedData = await assetInfo.fetchAssetsForFilter(field, limit, offset, searchText);
    fields = _.uniqBy(fetchedData, JSON.stringify);
    return fields;
  };

  const shouldFetchLocalFields = (): boolean => {
    return (
      !isNonAsset &&
      (selectedRecordTypeDefinition.isReferenceType ||
        isMapExtentEnabled ||
        (drawFilter.apply && !_.isEmpty(drawFilter.features)))
    );
  };

  const findFilterFields = (field: string) => {
    const displayKey = shouldFetchLocalFields() ? 'title' : 'label';
    const fieldValues = unfilteredAssets
      .filter(asset => {
        return (
          asset.recordTypeKey === selectedRecordTypeDefinition.queryKey &&
          asset[field] !== undefined &&
          asset[field] !== ' '
        );
      })
      .map(filteredAsset => filteredAsset[field]);
    return _.sortBy(
      _.uniqBy(fieldValues, value => getValueFromValueType(value, 'id')),
      value => getValueFromValueType(value, displayKey)
    );
  };

  const handleQuickFilterText = useCallback(
    _.debounce((text: string) => {
      focusedFilter = field;
      setQuickSearchFocus(false);
      setIsLoading(true);
      if (text === '') {
        setFilterState((prevState: FilterState) => {
          if (prevState[`${field}-QuickFilter`]) {
            delete prevState[`${field}-QuickFilter`];
          }
          return { ...prevState };
        });
      } else {
        setFilterState((prevState: FilterState) => {
          return { ...prevState, [`${field}-QuickFilter`]: text };
        });
      }
    }, 1000),
    []
  );
  const onChangeText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(e.target.value);
    handleQuickFilterText(e.target.value);
  }, []);

  return (
    <div className={classes.root}>
      {fieldType === DATETIME || fieldType === DATE ? (
        filterOpen && (
          <CalendarFilter
            open={filterOpen}
            setFilterOpen={setFilterOpen}
            displayName={displayName}
            setFilterState={setFilterState}
            field={field}
            filterState={filterState}
            filterValue={dayFilter}
            setDayFilter={setDayFilter}
          />
        )
      ) : (
        <SetFilter
          open={filterOpen}
          setFilterOpen={setFilterOpen}
          displayName={displayName}
          field={field}
          uniqueFieldGetter={() =>
            shouldFetchLocalFields() ? findFilterFields(field) : fetchFilterFields(field, FIELDS_FETCH_SIZE)
          }
          shouldFetchLocalFields={shouldFetchLocalFields()}
          fetchMoreFields={fetchFilterFields}
        />
      )}
      <div className={classes.assetSearchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          placeholder={translate('Search')}
          className={clsx(classes.input, isIOSSafari && commonClasses.safariDefaultFontSize)}
          disableUnderline={true}
          value={quickFilterText}
          onChange={onChangeText}
          classes={{ root: classes.input }}
        />
      </div>
      <div className={classes.filterIcon}>
        <ActiveFilterIcon onClick={() => setFilterOpen(true)} showIndicator={isFilterActive(field) || undefined} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ColumnSearch);
