import React, { useContext, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Typography, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import warningCheckFilled from '../../images/importPage/warningCheckFilled.svg';
import errorCheckFilled from '../../images/importPage/errorCheckFilled.svg';
import successCheckFilled from '../../images/importPage/successCheckFilled.svg';
import { ErrorWarningType } from './FileToView';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles/theme';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface AlertDialogProps {
  open: boolean;
  title: string;
  warningIcon?: boolean;
  bodySubtext?: string;
  children?: React.ReactNode;
  successErrorsOrWarnings: 'Success' | 'Errors' | 'Warnings';
  onConfirm: () => void;
  onCancel?: () => void;
  rowSuccess: string[];
  rowWarnings: ErrorWarningType[];
  rowErrors: ErrorWarningType[];
}

const useStyles = makeStyles(theme => ({
  dialogText: {
    marginLeft: '10px',
    fontFamily: 'Roboto',
    fontWeight: 200,
    fontSize: 16,
    lineHeight: '16px',
    color: colors.black0,
  },
  titleText: {
    marginLeft: '20px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 30,
    lineHeight: '35px',
    color: 'black',
  },
  bodyText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 16,
    maxWidth: 800,
    lineHeight: '25px',
    color: colors.black0,
  },
}));

const styles = {
  rootDialogue: {
    marginTop: '20px',
    display: 'flex',
    height: 66,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  right: {
    marginRight: '20px',
  },
  top: {
    marginTop: '20px',
  },
  hidden: {
    overflow: 'hidden',
  },
  contentContainer: {
    display: 'flex',
    width: 750,
    marginTop: 15,
    backgroundColor: colors.lotion,
  },
  contentWidth: {
    width: 500,
  },
  messageItem: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  confirmButton: {
    marginLeft: '10px',
    color: colors.white,
    borderRadius: 7,
    width: 142,
    height: 40,
    marginRight: 10,
  },
};

export default function SuccessErrorsAndWarningsDialog({
  open,
  title,
  children,
  onConfirm,
  onCancel,
  rowSuccess,
  rowWarnings,
  rowErrors,
  successErrorsOrWarnings,
}: AlertDialogProps) {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const calculatedTitle = useMemo(() => {
    if (title === 'Success') {
      return 'No Issues Found';
    } else if (title === 'Warnings') {
      return 'Warning(s)';
    } else if (title === 'Errors') {
      return 'Error(s)';
    }
  }, [successErrorsOrWarnings, title]);

  const calculatedBodyText = useMemo(() => {
    if (successErrorsOrWarnings === 'Success')
      return translate('There are no issues found on the record. No action needed.');
    else if (successErrorsOrWarnings === 'Warnings')
      return translate(
        '$__WARNINGS__$ warning(s) found. Please correct and reupload corrected file to import records without warning.',
        { WARNINGS: rowWarnings?.length }
      );
    else if (successErrorsOrWarnings === 'Errors')
      return translate('$__ERRORS__$ errors(s) found. Please correct and reupload corrected file to import file.', {
        ERRORS: rowErrors?.length,
      });
    return '';
  }, [successErrorsOrWarnings, rowWarnings, rowErrors]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={false} maxWidth={'md'}>
      <div style={styles.rootDialogue}>
        <Typography className={classes.titleText}>{translate(calculatedTitle ?? '')}</Typography>
        <IconButton style={styles.right} aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={styles.hidden}>
        <div>
          <Typography className={classes.bodyText}>{calculatedBodyText}</Typography>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.contentWidth}>
            {successErrorsOrWarnings === 'Success' &&
              rowSuccess?.map((successString: string, index: number) => (
                <div key={index} style={styles.messageItem}>
                  <img src={successCheckFilled} alt="Success Icon" />
                  <Typography className={classes.dialogText}>{translate(successString)}</Typography>
                </div>
              ))}
            {successErrorsOrWarnings === 'Warnings' &&
              rowWarnings?.map((warningObj: ErrorWarningType, index: number) => (
                <div key={index} style={styles.messageItem}>
                  <img src={warningCheckFilled} alt="Warning Icon" />
                  <Typography className={classes.dialogText}>
                    {translate(warningObj?.column_name)} {'>'} {translate(warningObj?.message)}
                  </Typography>
                </div>
              ))}
            {successErrorsOrWarnings === 'Errors' &&
              rowErrors?.map((errorObj: ErrorWarningType, index: number) => (
                <div key={index} style={styles.messageItem}>
                  <img src={errorCheckFilled} alt="Error Icon" />
                  <Typography className={classes.dialogText}>
                    {translate(errorObj?.column_name)} {'>'} {translate(errorObj?.message)}
                  </Typography>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={styles.top}>
          <Button onClick={onConfirm} style={styles.confirmButton} color="primary" variant="contained">
            {translate('Close')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
