import React, { useContext, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { colors } from '.././../../../../styles/theme';
import { MOBILE_BREAKPOINT, SEARCH_BAR_WIDTH } from '../../../../../utils/utilityHelper';
import LayersTab from './LayersTab';
import ProjectTab from './ProjectTab';
import { AssetsDashboardContext } from '../../../../../contexts/assetsDashboardContext';
import { searchBarProps } from './Accordions';
import { useAggregates } from '../../../../../contexts/AggregatesContext';
import { isEmpty } from 'lodash';
import RecordTypeTab from './RecordsTypeTab';
import { LanguageContext } from '../../../../../contexts/LanguageContext/languageContext';

interface TabPanelProps {
  children: ReactElement;
  value: number;
  index: number;
  isMobileView: boolean;
}

interface VisibilityModalProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  mapContainerRef?: HTMLDivElement | null;
  isHidden?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles({ isMobileView: props.isMobileView });
  const { children, value, index } = props;
  return (
    <Box className={classes.tabDetail} role="tabpanel" hidden={value !== index} key={index}>
      {value === index && children}
    </Box>
  );
};

const VisibilityModal = (props: VisibilityModalProps) => {
  const { translate } = useContext(LanguageContext);
  const { modalOpen, setModalOpen, mapContainerRef, isHidden } = props;
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [activeTab, setActiveTab] = useState(0);
  const modalBottomMargin = 46;
  const { isMobileView } = React.useContext(AssetsDashboardContext);
  const { mapServices, pluralProjectName } = useAggregates();
  const isEmptyMapServices = isEmpty(mapServices);
  const classes = useStyles({ isMobileView });

  const tabs = useMemo(() => {
    const tabItems = [pluralProjectName, 'Records'];
    if (!isEmptyMapServices) tabItems.push('Layers');
    return tabItems;
  }, [isEmptyMapServices]);

  const SearchbarSection = (props: searchBarProps) => {
    const { searchValue, focusOut, searchOnChange } = props;

    useEffect(() => {
      if (focusOut) {
        inputRef.current?.blur();
      }
    }, [focusOut]);

    return (
      <Box className={classes.searchContainer}>
        <Input
          className={classes.searchbar}
          inputRef={inputRef}
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          disableUnderline
          placeholder={translate('Search')}
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            searchOnChange(e.target.value);
          }}
        />
      </Box>
    );
  };

  const styles = {
    display: isHidden ? 'none' : 'flex',
  };
  return modalOpen ? (
    <Box
      className={classes.modalContainer}
      style={{
        height: mapContainerRef ? mapContainerRef?.clientHeight - modalBottomMargin : 'auto',
        minHeight: 250,
        ...styles,
      }}
    >
      <Box className={classes.modalHeader}>
        <Typography className={classes.title}>{translate('Visibility')}</Typography>
        <IconButton
          className={classes.closeIconBtn}
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Box className={classes.tabWrapper}>
        <Tabs
          value={activeTab}
          onChange={(_, i) => setActiveTab(i)}
          indicatorColor="primary"
          classes={{ root: classes.tabContainer, indicator: classes.tabIndicator }}
        >
          {tabs.map((tab, i) => (
            <Tab key={`${tab}-${i}`} label={translate(tab)} classes={{ root: classes.tab, selected: classes.selectedTab }} />
          ))}
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0} isMobileView={isMobileView}>
        <ProjectTab SearchbarSection={SearchbarSection} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} isMobileView={isMobileView}>
        <RecordTypeTab SearchbarSection={SearchbarSection} />
      </TabPanel>
      <TabPanel value={activeTab} index={2} isMobileView={isMobileView}>
        <LayersTab SearchbarSection={SearchbarSection} />
      </TabPanel>
    </Box>
  ) : null;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrapper: {
      zIndex: 100,
      display: 'flex',
      padding: '0 25px',
      borderBottom: `1px solid ${colors.black10}`,
    },
    tabDetail: {
        overflow: 'hidden',
        height: '100%',
    },
    mainView: {
      marginTop: 0,
    },
    modalContainer: {
      backgroundColor: colors.white,
      borderRadius: 5,
      position: 'absolute',
      left: 23,
      top: 26,
      zIndex: 1000,
      width: SEARCH_BAR_WIDTH,
      flexDirection: 'column',
      overflow: 'hidden',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: '100% !important',
        width: '100%',
        maxWidth: '100%',
        top: 0,
        left: 0,
        borderRadius: 0,
      },
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 'normal',
      color: colors.black0,
    },

    tabContainer: {
      minHeight: 38,
    },
    tabIndicator: {
      height: 3,
      bottom: 0,
    },
    selectedTab: {
      color: `${colors.black0} !important`,
    },
    tab: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: 64,
      lineHeight: 'normal',
      minHeight: 18,
      textTransform: 'none',
      color: colors.black75,
      fontSize: 15,
      fontWeight: 400,
    },
    tabText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: colors.black0,
    },
    activeTab: {
      backgroundColor: colors.white,
      borderRadius: 4,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    },
    activeTabText: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    modalHeader: {
      padding: '28px 25px 5px 28px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchContainer: {
      zIndex: 10,
      padding: '10px 25px',
      borderBottom: `1px solid ${colors.black10}`,
      borderRadius: 4,
    },
    searchbar: ({ isMobileView }: { isMobileView: boolean }) => ({
      padding: '8px 12px',
      backgroundColor: colors.cultured7,
      fontSize: isMobileView ? 16 : 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: colors.black0,
      borderRadius: 4,
      '&::placeholder': {
        color: colors.black35,
      },
      width: '100%',
      '& .MuiInputBase-input': {
        padding: '2px 0',
      },
    }),
    searchIcon: {
      color: colors.black35,
      height: 22,
      width: 22,
    },
    closeIconBtn: {
      width: 22,
      height: 22,
    },
  })
);
export default VisibilityModal;
