import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { TableData } from '../../hooks/useTable';
import { Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import FluidStyledTable from './FluidStyledTable';
import { handleColumnsMove } from './utils';
import { DialogProvider } from '../../hooks/useDialog';
import { TableDataContext } from '../../hooks/useTableData';
import StyledTableFilterInput from './StyledTableFilterInput';
import { StyledTableProps } from './StyledTable';
import { CheckCellIsEditableEvent } from '@terragotech/react-data-grid';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
import StyledTableFilterInputFromQuery from './StyledTableFilterInputFromQuery';

export interface FluidStyledTableWithDataProps<Data extends TableData> {
  columns: readonly Column<Data>[];
  data: readonly Data[];
  height: number;
  searchBar: () => JSX.Element;
  searchBarHeight: number;
  onEditModeOn?: StyledTableProps<Data>['onEditModeOn'];
  onChange?: StyledTableProps<Data>['onChange'];
  onLoad?: StyledTableProps<Data>['onLoad'];
  editRowComparator?: (row: Data) => boolean;
  onCheckCellIsEditable?: (event: CheckCellIsEditableEvent<Data, unknown>) => boolean;
  disableSort?: boolean
  schema?: string;
  tableName?: string;
}

const FluidStyledTableWithData = <Data extends TableData>(props: FluidStyledTableWithDataProps<Data>) => {
  const { columns, data, searchBarHeight, height, onEditModeOn, onChange, editRowComparator, tableName, schema } = props;
  const filterRenderer = useCallback(
    ({ column }: FilterRendererProps<Data>): JSX.Element => {
      return schema && tableName ? (
        <StyledTableFilterInputFromQuery
          tableName={tableName}
          columnName={column.key}
          fieldType={column.dataType || ''}
          schema={schema}
        />
      ) : (
        <StyledTableFilterInput
          name={column.name}
          keyName={column.key}
          options={column.filterInputOptions}
          fieldType={column.dataType}
        />
      );
    },
    [schema, tableName]
  );

  const { setData, filteredData } = useContext(TableDataContext);
  const { setSortColumn, setSortDirection } = useAssetsTableState();

  useEffect(() => setData(data), [data, setData]);

  const [localColumns, setLocalColumns] = useState(columns);
  const localData = useMemo(() => filteredData as Data[], [filteredData]);

  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const handleEditModeOn = useCallback(
    (row: Data) => {
      if (!isEditModeOn) {
        setIsEditModeOn(true);
        if (onEditModeOn) onEditModeOn(row);
      }
    },
    [onEditModeOn, isEditModeOn]
  );

  const onColumnsMove = useCallback(
    (from: number, to: number) => {
      const newColumns = handleColumnsMove(from, to, localColumns);
      if (newColumns) {
        setLocalColumns(newColumns);
      }
    },
    [localColumns]
  );

  useEffect(() => setLocalColumns(columns), [columns]);

  return (
    <DialogProvider>
      <props.searchBar />
      <FluidStyledTable
        columns={localColumns}
        data={localData}
        height={height - searchBarHeight}
        onColumnsMove={onColumnsMove}
        filterRenderer={filterRenderer}
        onEditModeOn={handleEditModeOn}
        onChange={onChange}
        onLoad={props.onLoad}
        editRowComparator={editRowComparator}
        setSortColumn={setSortColumn}
        setSortDirection={setSortDirection}
        disableSort={props.disableSort}
      />
    </DialogProvider>
  );
};

export default FluidStyledTableWithData;
