import { forEach } from "lodash";
import { atomFamily, CallbackInterface } from "recoil";

export const aggregateUpdateCountState = atomFamily<number, { project: string, aggregateType: string }>({
  key: 'aggregate.updateCount',
  default: 0,
});

export const aggregateUpdateCountIncrementCallback = ({ set }: CallbackInterface) => async (projects: string[], aggregateTypes: string[]) => {
  forEach(projects, project => {
    forEach(aggregateTypes, aggregateType => {
      set(aggregateUpdateCountState({ project, aggregateType }), (prev) => ++prev);
    });
  });
};
