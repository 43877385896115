import { Input, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import _ from 'lodash';
import { CSSProperties, useEffect, useState, useContext } from 'react';
import Draggable, { ControlPosition, DraggableEventHandler } from 'react-draggable';
import { colors } from '../styles/theme';
import ActionIcon from './ActionIcon';
import { Action, FABProps } from './FloatingActionButton';
import FloatingActionButtonIcon from './FloatingActionButtonIcon';
import { useSelectedLocation } from '../contexts/selectedLocationContext';
import { useCommonStyles } from '../styles/commonStyles';
import { useUtils } from '../contexts/utilContext';
import clsx from 'clsx';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

const FAB_BUTTON_SIZE = 64;

// Makes sure that a position request is within the view of the current page
const getVisiblePosition = (position?: ControlPosition): ControlPosition => {
  const maxWidth = window.innerWidth - FAB_BUTTON_SIZE;
  const maxHeight = window.innerHeight - FAB_BUTTON_SIZE;
  return {
    x: position ? Math.min(position.x, maxWidth) : maxWidth,
    y: position ? Math.min(position.y, maxHeight) : maxHeight,
  };
};

const MapFab = (
  props: FABProps & {
    requestOpen?: boolean;
    setMapFabOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileView?: boolean;
  }
) => {
  const { setMapFabOpen, isMobileView } = props;
  const [open, setOpen] = useState(false);
  const { translate } = useContext(LanguageContext);
  const [forceOpen, setForceOpen] = useState(props.requestOpen);
  const [searchText, setSearchText] = useState('');
  const [, setDragDelta] = useState({ x: 0, y: 0 });
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const [position, setPosition] = useState<ControlPosition | undefined>(getVisiblePosition(props.defaultPosition));
  const [filteredAssetsArray, setFilteredAssetsArray] = useState<Action[]>([]);
  const theme = useTheme();
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
        });
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  };
  const { width } = useWindowSize();
  const getGridTemplateColumns = () => {
    return width > MOBILE_BREAKPOINT ? 'repeat(auto-fill, minmax(100px, 1fr))' : 'repeat(3, minmax(100px, 1fr))';
  };

  const fabContainerRootMob = {
    margin: '0px 22px',
    gridRowGap: '2em',
    gridColumnGap: '0em',
    gridTemplateColumns: getGridTemplateColumns(),
    justifyItems: 'center',
  };
  const { setSelectedLocation } = useSelectedLocation();
  useEffect(() => {
    const searched = _.filter(props.actions, o => {
      return o.name?.toLowerCase().includes(searchText.toLowerCase());
    }) as Action[];
    setFilteredAssetsArray(searched);
  }, [searchText]);
  useEffect(() => {
    setForceOpen(props.requestOpen);
  }, [props.requestOpen]);

  useEffect(() => {
    if (!open) {
      setSearchText('');
    }
  }, [open]);

  useEffect(() => {
    function handleResize() {
      setPosition(curPosition => getVisiblePosition(curPosition));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setPosition]);

  const classes = useStyles();
  const handleActionOnClose = (action: Action) => {
    setOpen(false);
    setMapFabOpen && setMapFabOpen(false);
    setForceOpen(false);
    action.onClick();
  };

  const handleDragEnd: DraggableEventHandler = (event, data) => {
    const oldPosition = position ?? { x: 0, y: 0 };
    const newPosition = { x: data.x, y: data.y };

    if (oldPosition.x === newPosition.x && oldPosition.y === newPosition.y) {
      setOpen(previous => !previous);
      setMapFabOpen && setMapFabOpen(previous => !previous);
    }
    setDragDelta({ x: oldPosition.x - newPosition.x, y: oldPosition.y - newPosition.y });
    setPosition(getVisiblePosition(newPosition));
    if (props.onPositionChange) props.onPositionChange(newPosition);
  };

  return (
    <>
      {/*@ts-ignore*/}
      <Draggable
        axis="both"
        scale={1}
        position={position}
        onStop={handleDragEnd}
        defaultClassName={classes.draggable}
        bounds={'html'}
        handle={`.${classes.fabButton}`}
      >
        <div
          style={{ ...styles.fabButtoncontainer, background: theme.palette.primary.main }}
          className={classes.fabButton}
        >
          <FloatingActionButtonIcon baseIcon={props.icon} isOpen={open} />
        </div>
      </Draggable>
      {(open || forceOpen) && (
        <>
          <div style={styles.fabMainContainer}>
            <div style={{ height: 95 }}>
              <div
                style={{ position: 'absolute', right: 16, top: 15, cursor: 'pointer' }}
                onClick={() => {
                  setOpen(false);
                  setMapFabOpen && setMapFabOpen(false);
                  setForceOpen(false);
                  setSelectedLocation(undefined);
                }}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div style={styles.mapSearchBarContainer}>
                <div className={classes.mapSearchBar}>
                  <div>
                    <div style={styles.innerBody}>
                      <Input
                        onChange={e => setSearchText(e.target.value)}
                        placeholder={translate('Search')}
                        disableUnderline={true}
                        className={clsx(classes.filter, isIOSSafari && commonClasses.safariDefaultFontSize)}
                        style={searchText !== '' ? { width: '100%' } : {}}
                        startAdornment={
                          <InputAdornment position="start">
                            <Search className={classes.searchIcon} />
                          </InputAdornment>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={
                isMobileView
                  ? { ...styles.fabContainer, ...(fabContainerRootMob as CSSProperties) }
                  : styles.fabContainer
              }
            >
              {(searchText ? filteredAssetsArray : props.actions).map((item: Action) => {
                return (
                  <div key={item.name} style={isMobileView ? styles.iconContainerRoot : {}}>
                    <div onClick={() => handleActionOnClose(item)} style={styles.IconTitleContainer}>
                      <div style={styles.fabIconContainer}>
                        <ActionIcon name={item.icon} color="#455A64" />
                      </div>
                      <div style={styles.fabTitle}>{translate(item.name || '')}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  draggable: {
    position: 'fixed',
    zIndex: 1200,
  },
  fab: {
    width: FAB_BUTTON_SIZE,
    height: FAB_BUTTON_SIZE,
  },
  fabButton: {
    width: FAB_BUTTON_SIZE,
    height: FAB_BUTTON_SIZE,
    position: 'absolute',
  },
  actions: {
    marginBottom: '24px !important',
    display: 'inline-block',
  },
  tooltip: {
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    margin: '0 !important',
  },
  tooltipDisabled: {
    backgroundColor: colors.grayDescription,
  },
  searchBar: {
    backgroundColor: '#fff',
    color: theme.palette.grey[200],
    padding: '3px 0px',
    display: 'inline-flex',
    width: 300,
    height: 34,
    borderTop: '1px solid #eee',
    borderBottom: '1px solid #eee',
    position: 'absolute',
    top: 50,
  },
  searchIcon: {
    height: 15,
    width: 15,
    paddingLeft: '12px',
    color: theme.palette.grey[400],
  },
  input: {
    position: 'unset',
    width: '100%',
    fontSize: 16,
    fontWeight: 'normal',
  },
  filter: {
    position: 'absolute',
    left: '55%',
    width: '40%',
    transform: 'translateX(-50%)',
    transition: 'all 300ms',
    fontFamily: 'Roboto' as const,
    fontWeight: 400,
    fontSize: 15,
    '&:focus-within': {
      left: 0,
      transform: 'translateX(0)',
      width: '100%',
    },
  },
  mapSearchBar: {
    '& .MuiInputBase-input': {
      color: colors.white,
      padding: 0,
    },
    color: colors.white,
    width: 300,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: colors.transparentWhite,
    border: `1px solid ${colors.pureWhite}`,
    borderRadius: 4,
    '&::placeholder': {
      color: colors.white,
    },
  },
}));
const styles: { [key: string]: React.CSSProperties } = {
  fabTitle: {
    fontFamily: 'Roboto' as const,
    fontWeight: 400,
    fontSize: 14,
    fontStyle: 'normal' as const,
  },
  fabIconContainer: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    background: 'white' as const,
    display: 'flex' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    marginBottom: 8,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  fabContainer: {
    display: 'grid' as const,
    flexWrap: 'wrap' as const,
    margin: '0px 100px',
    position: 'relative' as const,
    top: 50,
    justifyContent: 'start',
    gridRowGap: '5em',
    gridColumnGap: '4em',
    gridTemplateColumns: 'repeat(auto-fill, minMax(125px, 1fr))',
    paddingBottom: 30,
  },
  iconContainerRoot: {
    width: '100px',
  },
  mapSearchBarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 50,
  },
  innerBody: {
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    display: 'inline-flex' as const,
    float: 'right' as const,
    marginRight: 16,
    cursor: 'pointer' as const,
    position: 'absolute' as const,
    right: 0,
    top: 15,
  },
  mainContainer: {
    height: 95,
  },
  IconTitleContainer: {
    textAlign: 'center' as const,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fabButtoncontainer: {
    height: 60,
    width: 60,
    borderRadius: '50%',
    display: 'flex' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    cursor: 'pointer' as const,
  },
  fabMainContainer: {
    left: 0,
    top: 0,
    zIndex: 1200,
    background: colors.black35,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)' /* For Safari */,
    height: '100%',
    width: '100%',
    position: 'absolute' as const,
    color: '#FFFFFF',
    overflowY: 'scroll',
  },
};

export default MapFab;
