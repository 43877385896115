import { Box, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useContext, useCallback, useMemo, useEffect, useState } from 'react';
import { EditModeContext } from '../../../contexts/editModeContext';
import { TableDataContext } from '../../../hooks/useTableData';
import SearchInput from './SearchInput';
import { colors } from '../../../styles/theme';
import AntSwitch from '../../TGAntSwitch';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import InfoSection from './InfoSection';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import ActionsMenuUI, { ActionsMenuButton } from '../../ActionsMenuUI';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useCommonStyles } from "../../../styles/commonStyles";
import { useUtils } from "../../../contexts/utilContext";
import { TableData } from '../../../hooks/useTable';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

const styles = (theme: Theme) =>
  createStyles({
    mainDiv: {
      display: 'flex',
      flexDirection: 'row',
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        flexDirection: 'column',
        rowGap: 10,
      },
    },
    spacer: {
      flex: 1,
      display: 'flex',
    },
    selectSwitch: {
      display: 'flex',
      marginRight: 10,
      color: colors.black54,
      border: `1px solid ${colors.black10}`,
      alignItems: 'center',
      gap: 4,
      borderRadius: 5,
      backgroundColor: colors.white,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        width: 'fit-content',
        height: 34,
      },
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 8px',
      height: 24,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        width: '71px',
      },
    },
    menuType: {
      marginLeft: 12,
      color: colors.black54,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    text: { marginLeft: 4, textTransform: 'capitalize' },
    container: {
      background: colors.lotion,
      borderRadius: '5px 5px 0px 0px',
      marginTop: 19,
      padding: '10px 18px',
      border: `1px solid ${colors.black10}`,
      borderBottom: 'none',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        padding: '10px 15px',
        marginTop: 15,
      },
    },
    arrowIcon: {
      color: theme.palette.primary.main,
    },
    actionsButton: {
      whiteSpace: 'nowrap',
      zIndex: 5,
      backgroundColor: colors.white,
      display: 'flex',
      alignItems: 'center',
      borderLeft: `1px solid ${colors.black10}`,
      borderRadius: '0px 5px 5px 0px',
      cursor: 'pointer',
      padding: '5px 8px',
    },
    actionLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
  });
interface SearchBarProps extends WithStyles<typeof styles> {
  height: number;
  checked: boolean;
  setChecked: (value: boolean) => void;
  isLoadingData: boolean;
  resetSelectedRows: () => void;
  statusText: string;
  actions: { exportAction: ActionsMenuButton; deleteUserAction: ActionsMenuButton };
  areAnyActionsSelected: boolean;
}

const isFilterActive = (filter: string | string[], filteredData: readonly TableData[]) => filter && (Array.isArray(filter) ? filter.length >= 0 && filter.length === filteredData.length : true);

const SearchBar: FunctionComponent<SearchBarProps> = props => {
  const {
    classes,
    checked,
    setChecked,
    isLoadingData,
    resetSelectedRows,
    statusText,
    actions,
    areAnyActionsSelected,
  } = props;
  const { exportAction, deleteUserAction } = actions;
  const { data, filteredData, setFilter, filters, globalFilter, setFiltersApplied } = useContext(TableDataContext);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<Element | null>(null);
  const { editModeActive } = useContext(EditModeContext);
  const { translate } = useContext(LanguageContext);
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const setSearchText = useCallback(
    (value: string) => {
      setFilter(null, value);
      value === '' ? setFiltersApplied(false) : setFiltersApplied(true);
    },
    [setFilter, setFiltersApplied]
  );
  const { isMobileView } = useContext(AssetsDashboardContext);
  const numberOfFilters = useMemo(
    () =>
      Object.keys(filters).reduce((result, key) => result + (isFilterActive(filters[key], filteredData) ? 1 : 0), 0) +
      (globalFilter === '' ? 0 : 1),
    [filters, globalFilter]
  );
  const handleFilterReset = () => {
    Object.keys(filters).forEach(key => setFilter(key, ''));
    setSearchText('');
    setFiltersApplied(false);
  };

  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setActionsMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    // reset all our selections if the user toggles the multiselect button state to off
    if (!checked) {
      resetSelectedRows();
    }
  }, [checked]);

  return (
    <div className={[classes.mainDiv, classes.container].join(' ')}>
      <div className={classes.searchSection}>
        {isMobileView && numberOfFilters > 0 && (
          <InfoSection
            isLoadingData={isLoadingData}
            numberOfElements={data.length}
            numberOfVisible={filteredData.length}
            handleFilterReset={handleFilterReset}
            numberOfFilters={numberOfFilters}
          />
        )}
          {!editModeActive && <div className={classes.selectSwitch}>
            <div className={classes.switchContainer}>
              <AntSwitch
                disabled={isLoadingData}
                checked={checked}
                onChange={e => {
                  setChecked(e.target.checked);
                }}
                value="checked"
                color="primary"
              />
              <Typography className={[classes.menuType, classes.text].join(' ')}>
                <>{translate('Select')}</>
              </Typography>
            </div>
            {areAnyActionsSelected && (
              <Box className={classes.actionsButton} onClick={handleClick}>
                <Typography className={classes.actionLabel}>
                  <>{translate('Actions')}</>
                </Typography>
                {Boolean(actionsMenuAnchor) ? (
                  <ArrowDropUp className={classes.arrowIcon} />
                ) : (
                  <ArrowDropDown className={classes.arrowIcon} />
                )}
              </Box>
            )}
          </div>}
        {Boolean(actionsMenuAnchor) && (
          <ActionsMenuUI
            statusText={statusText}
            actions={[exportAction, deleteUserAction].filter((i: ActionsMenuButton) => !!i)}
            anchorEl={actionsMenuAnchor}
            onClose={() => setActionsMenuAnchor(null)}
          />
        )}
        <SearchInput value={String(globalFilter)} setValue={setSearchText} isIOSSafari={isIOSSafari} commonClasses={commonClasses}/>
        {!isMobileView && (
          <InfoSection
            isLoadingData={isLoadingData}
            numberOfElements={data.length}
            numberOfVisible={filteredData.length}
            handleFilterReset={handleFilterReset}
            numberOfFilters={numberOfFilters}
          />
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(SearchBar);
