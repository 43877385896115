import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';

const AssetCardBackdrop = (props: {
  children: React.ReactNode;
  multiple?: boolean;
  mapEditor?: boolean;
  classes?: string;
  isMainMap?: boolean;
}) => {
  const classes = useStyles({isMainMap: props.isMainMap});
  return <div className={clsx(classes.dialog, props.classes || '')}>{props.children}</div>;
};

const useStyles = makeStyles(theme => ({
  dialog: (props: {isMainMap?: boolean}) => ({
    position: 'absolute',
    top: props.isMainMap ? 73 : 20,
    left: 23,
    zIndex: 12,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      bottom: 18,
      transform: 'translateX(-50%)',
      left: '50%',
      top: 'unset',
}})
}));
export default AssetCardBackdrop;
