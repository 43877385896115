import React, { FunctionComponent, useContext, useCallback } from 'react';
import { EditModeContext } from '../../contexts/editModeContext';
import { useRecordType } from '../../contexts/recordTypeContext';
import { useConfig } from '@terragotech/gen5-shared-components';
import * as changeCase from 'change-case';
import { Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface LinkedCellRendererProps {
  value: string | { id: string; label: string } | { id: string; label: string }[];
  data: {
    id: string;
  };
  handleTitleClick: (
    rowAssetId: string,
    rowRecordType: string,
    clickedAssetId: string,
    clickedRecordType: string
  ) => void;
  aggregateTypeName: string;
  titlestyle?: React.CSSProperties;
}

/**
 * LinkedCellRenderer - Renders a cell that links to another aggregate.
 *
 * Linked cells differ from normal text cells in that they contain a clickable value that can be used to navigate to an asset.
 *
 * @param value
 * @param data
 * @param handleTitleClick
 */
const LinkedCellRenderer: FunctionComponent<LinkedCellRendererProps> = props => {
  const { value, data, handleTitleClick, aggregateTypeName } = props;
  const { selectedRecordType } = useRecordType();
  const { editModeActive } = useContext(EditModeContext);
  const { translate } = useContext(LanguageContext);
  const { aggregateDefinitions } = useConfig();
  const theme = useTheme();

  // For dropdown menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  // The value to show inside the cell
  const renderValue = () => {
    if (Array.isArray(value)) {
      // Single item in array
      if (value.length === 1) {
        return value[0].label;
      } else if (value.length > 1) {
        // Multiple items in array
        // #TODO: Remove changeCase when plural property fixed
        return `${changeCase.capitalCase(
          String(aggregateDefinitions.find((d: any) => d.name === aggregateTypeName)?.plural)
        )} (${value.length})`;
      }
      return '';
      // Single string
    } else if (typeof value === 'string') {
      return value || '';
    }
    // Single object
    return value?.label || '';
  };
  const recordType = aggregateTypeName || selectedRecordType;
  const isMultipleRecords = Array.isArray(value) && value.length > 1;

  const handleClick = useCallback(
    (item?: any) => {
      let renderId = data.id;
      if (Array.isArray(value)) {
        let firstValue = value[0];
        if (firstValue) {
          renderId = typeof firstValue === 'string' ? firstValue : firstValue.id;
        }
      } else {
        if (typeof value !== 'string') {
          renderId = value.id;
        }
      }
      // If click event is from multi record dropdown
      if (item.id) {
        setAnchorEl(null);
        //HandleTitleClick is just function in tablecolumncontext that routes like history.push(`/${rowRecordType}/${rowAssetId}/edit`);
        handleTitleClick(data.id, selectedRecordType, item.id, aggregateTypeName);
        return;
      }
      if (!editModeActive) {
        handleTitleClick(data.id, selectedRecordType, renderId, recordType);
      }
    },
    [aggregateTypeName, data.id, handleTitleClick, recordType, value, selectedRecordType]
  );
  const getColor = () => {
    if (aggregateTypeName !== 'Project' && !editModeActive) {
      return theme.palette.primary.main;
    }
    if (editModeActive) {
      return 'black';
    }
  };
  return (
    <div
      style={{
        color: getColor(),
        fontWeight: aggregateTypeName !== 'Project' && !editModeActive ? 500 : 400,
        cursor: editModeActive || aggregateTypeName === 'Project' ? 'default' : 'pointer',
      }}
    >
      {/* Project gets special handling because it does not have a detail page */}
      <div
        style={{
          marginRight: '15px',
          width: '85%',
          overflow: 'hidden',
          paddingRight: 10,
          position: 'absolute',
          top: 0,
        }}
        onClick={aggregateTypeName !== 'Project' ? (isMultipleRecords ? handleMenuClick : handleClick) : undefined}
      >
        {renderValue()}
      </div>
      {/* Dropdown menu for selecting a record when multiple exist in a cell */}
      {isMultipleRecords && (
        <Menu
          id="multi-record-menu"
          autoFocus={false}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {Array.isArray(value) &&
            value.map((item, index) => (
              <MenuItem key={index} onClick={() => handleClick(item)}>
                {translate(item.label)}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  );
};

export default LinkedCellRenderer;
