import { useQuery } from '@apollo/client';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ROW_HEIGHT } from '../../components/Common/Modals/TGOptionLazyModal/TGOptionModalRowRenderer';
import FluidStyledTableWithData from '../../components/StyledTable/FluidStyledTableWithDataManagement';
import UnauthorizedDisplay from '../../components/UnauthorizedDisplay';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
import { Column } from '../../hooks/tableHooks/useColumns';
import { TableData } from '../../hooks/useTable';
import { TableDataContext } from '../../hooks/useTableData';
import { colors } from '../../styles/theme';
import { LEFT_NAVIGATION_BAR_WIDTH, USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { GET_API_MESSAGES } from './graphql';
import { Filters, FilterValue } from '../../contexts/AggregatesContext/useAssetsLoader';

interface Props {
    onBack: () => void;
}

interface IntegrationMessage extends TableData {
    id: string;
    direction: string;
    integrationType: string;
    responseCode: number;
    requestDate: Date;
    responseDate: Date;
    relatedData: string;
    requestData: string;
    responseData: string;
    processTime: number;
}

const HEADER_HEIGHT = 109;
const QUERY = GET_API_MESSAGES;
const NUM_ROWS = 40;

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: USERS_AND_ROLES_Z_INDEX,
        position: 'fixed',
        left: 70,
        width: `calc(100vw - ${LEFT_NAVIGATION_BAR_WIDTH}px)`,
        height: '100%',
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '42px 50px',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            left: 0,
            width: '100vw',
            padding: '20px 16px',
        },
    },
    title: {
        color: colors.black0,
        fontStyle: 'normal',
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 10,
    },
    divider: {
        background: colors.black10,
        marginBottom: 20,
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
}));

/**
 * Container to get API Integrations Message data and format into table
 * 
 * @param onBack - onBack (function)
 */
const APIMessages: React.FC<Props> = () => {
    const [afterCursor, setAfterCursor] = useState<string | null>(null);
    const [apiSort, setApiSort] = useState<string[]>(['NATURAL']);
    const [hasMore, setHasMore] = useState(true);
    const [messages, setMessages] = useState<IntegrationMessage[]>([]);
    const { defaultDateTimeFormat } = useConfig();
    const classes = useStyles();
    const tableHeight = window.innerHeight - HEADER_HEIGHT - 39;
    const [rowsRendered, setRowsRendered] = useState<number>(NUM_ROWS);
    const loadingMore = useRef(false);
    const { hasAdvancedLoggingAccess } = useUserInfo();
    const { sortColumn, sortDirection } = useAssetsTableState();
    const { filters, partialFilters } = useContext(TableDataContext);

    const { loading, fetchMore } = useQuery(QUERY, {
        variables: {
            first: NUM_ROWS,
            after: null,
            filter: { 'id': { isNull: false } },
            orderBy: apiSort,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const edges = data?.apiMessagesConnection?.edges || [];
            const newMessages = edges.map((edge: any) => {
                const { requestData, responseData } = edge.node as { requestData: JSON, responseData: JSON };

                return {
                    ...edge.node,
                    requestData: requestData ? JSON.stringify(requestData) : null,
                    responseData: responseData ? JSON.stringify(responseData) : null,
                };
            });

            setMessages(newMessages);
            setAfterCursor(data?.apiMessagesConnection?.pageInfo?.endCursor || null);
            setHasMore(data?.apiMessagesConnection?.pageInfo?.hasNextPage || false);
        },
    });

    const getCurrFilter = (): Filters => {
        return {
            id: { isNull: false },
            ...Object.fromEntries(
                Object.entries(filters).reduce((acc, [x, y]) => {
                    const filterColumn = columns?.find((column) => column.key === x);

                    if (!filterColumn?.dataType || filterColumn.dataType !== "DateTime") {
                        const value: FilterValue = {
                            in: Array.isArray(y) ? y : [y],
                        };
                        acc.push([x, value]);
                    }

                    return acc;
                }, [] as [string, FilterValue][])
            ),
            and: Object.entries(filters).reduce((acc, [x, y]) => {
                const filterColumn = columns?.find((column) => column.key === x);

                if (filterColumn?.dataType === "DateTime" && Array.isArray(y) && y.length === 4) {
                    acc.push({ [x]: { greaterThanOrEqualTo: y[1] } });
                    acc.push({ [x]: { lessThanOrEqualTo: y[3] } });
                }

                return acc;
            }, [] as Filters[]),
            ...Object.fromEntries(
                Object.entries(partialFilters).reduce((acc, [x, y]) => {
                    const filterColumn = columns?.find((column) => column.key === x);

                    if (filterColumn?.dataType === "String") {
                        acc.push([
                            x,
                            { includesInsensitive: y as string },
                        ]);
                    }

                    return acc;
                }, [] as [string, FilterValue][])
            ),
        };
    };

    useEffect(() => {
        setAfterCursor(null);
        setMessages([]);
        setRowsRendered(NUM_ROWS);
        setHasMore(true);

        const currFilter: Filters = getCurrFilter();

        const sortingColumn = columns?.find(column => column.key === sortColumn);
        if (sortingColumn?.sortable) {
            const normalizedSortColumn = sortColumn?.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
            const sortOutput = sortDirection === 'NONE' || !sortColumn
                ? 'NATURAL'
                : `${normalizedSortColumn}_${sortDirection}`;

            setApiSort([sortOutput, 'ID_ASC']);
        }

        fetchMore({
            variables: {
                first: NUM_ROWS,
                after: null,
                filter: currFilter,
                order: apiSort
            },
            updateQuery: (_, { fetchMoreResult }) => {
                const edges = fetchMoreResult?.apiMessagesConnection?.edges || [];
                const newMessages = edges.map((edge: any) => {
                    const { requestData, responseData } = edge.node as {
                        requestData: JSON;
                        responseData: JSON;
                    };

                    return {
                        ...edge.node,
                        requestData: requestData ? JSON.stringify(requestData) : null,
                        responseData: responseData ? JSON.stringify(responseData) : null,
                    };
                });

                setMessages(newMessages);
                setAfterCursor(fetchMoreResult?.apiMessagesConnection?.pageInfo?.endCursor || null);
                setHasMore(fetchMoreResult?.apiMessagesConnection?.pageInfo?.hasNextPage || false);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sortColumn, sortDirection, partialFilters]);


    const loadMoreData = useCallback(async (input: number) => {
        if (!hasMore || loading || loadingMore.current) return;
        if (!(input + Math.floor(tableHeight / ROW_HEIGHT) >= rowsRendered - 5)) return;
        loadingMore.current = true;
        const currFilter: Filters = getCurrFilter();
        await fetchMore({
            variables: {
                first: NUM_ROWS,
                after: afterCursor,
                filter: currFilter,
                order: apiSort,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                const edges = fetchMoreResult?.apiMessagesConnection?.edges || [];
                const newMessages = edges.map((edge: any) => {
                    const { requestData, responseData } = edge.node as { requestData: JSON, responseData: JSON };

                    return {
                        ...edge.node,
                        requestData: requestData ? JSON.stringify(requestData) : null,
                        responseData: responseData ? JSON.stringify(responseData) : null,
                    };
                });

                setMessages(prev => [...prev, ...newMessages]);
                setAfterCursor(fetchMoreResult?.apiMessagesConnection?.pageInfo?.endCursor || null);
                setHasMore(fetchMoreResult?.apiMessagesConnection?.pageInfo?.hasNextPage || false);
                setRowsRendered(prevRows => prevRows + NUM_ROWS);
                loadingMore.current = false;
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMore, fetchMore, loading, rowsRendered, loadingMore, afterCursor, filters]);

    const columns: Column<IntegrationMessage>[] = useMemo(
        () => [
            {
                key: 'statusIndicator',
                name: 'Status',
                width: 50,
                resizable: false,
                sortable: false,
                frozen: false,
                filterRenderer: false,
                cellRenderer: ({ row }) => (
                    <div style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCircle}
                            color={row.responseCode < 200 || row.responseCode >= 300 ? colors.red : colors.success}
                            size='sm'
                            style={{
                                fontSize: '0.75rem',
                            }}
                        />
                    </div>
                ),
            },
            { key: 'id', name: 'ID', resizable: false, sortable: false, width: 275 },
            { key: 'direction', name: 'Direction', resizable: true, sortable: true, dataType: 'String' },
            { key: 'integrationType', name: 'Integration Type', resizable: true, sortable: true, dataType: 'String' },
            {
                key: 'responseCode',
                name: 'Response Code',
                resizable: true,
                sortable: true,
                dataType: 'String',
                cellRenderer: ({ row }) => {
                    const style = row.responseCode < 200 || row.responseCode >= 300 ? { color: colors.red } : undefined;
                    return <div style={style}>{row.responseCode}</div>;
                },
            },
            {
                key: 'requestDate', name: 'Request Date', dataType: 'DateTime', resizable: true, sortable: true, cellRenderer: ({ row }) => <div>
                    {row.requestDate &&
                        moment(row.requestDate).format(
                            getDateTimeFormat(
                                defaultDateTimeFormat?.dateFormatType,
                                defaultDateTimeFormat?.dateFormat,
                                defaultDateTimeFormat?.dateSeperator,
                                defaultDateTimeFormat?.timeFormat,
                                { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                            )
                        )}
                </div>
            },
            {
                key: 'responseDate', name: 'Response Date', dataType: 'DateTime', resizable: true, sortable: true, cellRenderer: ({ row }) =>
                    <div>
                        {row.responseDate &&
                            moment(row.responseDate).format(
                                getDateTimeFormat(
                                    defaultDateTimeFormat?.dateFormatType,
                                    defaultDateTimeFormat?.dateFormat,
                                    defaultDateTimeFormat?.dateSeperator,
                                    defaultDateTimeFormat?.timeFormat,
                                    { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                                )
                            )}
                    </div>
            },
            {
                key: 'requestData', name: 'Request Data', resizable: true, sortable: true, filterRenderer: false,
            },
            {
                key: 'responseData', name: 'Response Data', resizable: true, sortable: true, filterRenderer: false,
            },
            { key: 'processTime', name: 'Process Time (ms)', resizable: true, sortable: true },
            { key: 'relatedData', name: 'Related Data', resizable: true, sortable: true, dataType: 'String' },
        ],
        [defaultDateTimeFormat]
    );

    return (
        <>{hasAdvancedLoggingAccess ?
            <div className={classes.root}>
                <Typography className={classes.title}>API Messages</Typography>
                <Divider className={classes.divider} />
                {loading && !messages.length ? (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                ) : (
                    <FluidStyledTableWithData<IntegrationMessage>
                        columns={columns}
                        data={messages}
                        searchBar={() => <></>}
                        searchBarHeight={0}
                        onLoad={loadMoreData}
                        height={tableHeight}
                        disableSort
                        schema={"log"}
                        tableName="api_messages"
                    />
                )}
            </div> : <UnauthorizedDisplay />
        }
        </>
    );
};

export default APIMessages;
