import React, { useContext, useState } from 'react';
import SlideDialog from '../components/SlideDialog';
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { colors } from '../styles/theme';
import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { DOC_API_URL } from '../../../../apps/web-app/src/utils/docApiURL';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import { downloadFile } from '../components/Import/FileToView';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

interface FileViewerParameters {
  images: { id: string; name: string }[];
  selectedFile?: { id: string; name: string };
  editMode?: boolean;
  onClose?: (images: { id: string; name: string }[]) => void;
}

const fileViewerContext = React.createContext({
  open: (parameters: FileViewerParameters) => {},
});

const useFileViewer = () => {
  const context = useContext(fileViewerContext);
  if (!context) {
    throw Error('useFileViewer must be within a UserProvider');
  }
  return context;
};
let callback: ((images: { id: string; name: string }[]) => void) | undefined;

const FileViewerProvider = (props: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<{ id: string; name: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{ id: string; name: string } | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const { translate } = useContext(LanguageContext);
  const classes = useStyles();
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [fileIndexToRemove, setFileIndexToRemove] = useState<number | undefined>(undefined);
  const [isFileUploadFailed, setIsFileUploadFailed] = useState(false);
  const { openSnackbar } = useInfoContext();

  const open = ({ images, selectedFile, editMode, onClose }: FileViewerParameters) => {
    editMode && setEditMode(editMode);
    callback = onClose;
    setImages(images);
    setSelectedFile(selectedFile);
    setIsOpen(true);
  };

  const close = () => {
    images && callback?.(images);
    setIsOpen(false);
    setImages([]);
    setSelectedFile(undefined);
    callback = undefined;
    setEditMode(false);
  };

  const handleFileRemove = () => {
    if (fileIndexToRemove === undefined) return;
    setIsConfirmPopupOpen(false);
    const files = cloneDeep(images);
    files?.splice(fileIndexToRemove, 1);
    setImages(files);
  };

  const handleCancelFileRemove = () => {
    setFileIndexToRemove(undefined);
    setIsConfirmPopupOpen(false);
  };

  const confirmFileRemove = (fileIndex: number) => {
    setFileIndexToRemove(fileIndex);
    setIsConfirmPopupOpen(true);
  };

  const handleOnFileChange = (index: number) => {
    setSelectedFile(images[index]);
  };

  const handleFileAdd = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (!file) return;
    const token = await AuthConnector.getToken();
    const formData = new FormData();
    const imageId = v4();
    formData.append('file', file);

    fetch(DOC_API_URL + imageId, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(() => {
        setIsLoading(false);
        openSnackbar({ title: 'File successfully uploaded', type: 'SUCCESS' });
        setImages([...images, { id: imageId, name: file.name }]);
        setSelectedFile({ id: imageId, name: file.name });
      })
      .catch(error => {
        setIsLoading(false);
        setIsFileUploadFailed(true);
        console.error(error);
      });
  };

  return (
    <fileViewerContext.Provider
      value={{
        open,
      }}
      {...props}>
      {props.children}
      <SlideDialog maxWidth="xl" open={isOpen} onClose={close}>
        {/* TODO: Copied from AssetDataContainer, refactor repetition */}
        <div className={classes.header}>
          <Button className={classes.button} onClick={close} style={{ justifyContent: 'left', paddingLeft: 20 }}>
            <> {translate('Cancel')}</>
          </Button>
          <div className={classes.headerText}>{selectedFile?.name || images[0]?.name}</div>
          <Button className={classes.button} onClick={close} style={{ justifyContent: 'right', paddingRight: 20 }}>
            <> {translate('Done')}</>
          </Button>
        </div>
        {images.map(slide => (
          <div onClick={() => downloadFile(DOC_API_URL + slide.id, slide.name)}>{slide.name}</div>
        ))}
      </SlideDialog>
      <Dialog open={isConfirmPopupOpen} onClose={() => setIsConfirmPopupOpen(false)}>
        <DialogTitle>{translate('Are you sure you want to delete this image?')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate('This action can not be undone.')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelFileRemove} color="primary">
            {translate('NO, CANCEL')}
          </Button>
          <Button onClick={handleFileRemove} color="secondary" autoFocus>
            {translate('YES, DELETE')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isFileUploadFailed} onClose={() => setIsFileUploadFailed(false)}>
        <DialogTitle>{translate('Upload Failed')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('Oops, the selected file is over the 50mb limit. Please choose a smaller file.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFileUploadFailed(false)} color="primary">
            {translate('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </fileViewerContext.Provider>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    height: 46,
    minHeight: 46,
    backgroundColor: theme.palette.secondary.main,
    display: 'grid',
    gridTemplate: '1fr / 200px 1fr 200px',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerText: {
    color: colors.white,
    textTransform: 'none',
    fontSize: 18,
    textAlign: 'center',
  },
  button: {
    color: colors.white,
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: 1400,
    color: colors.white,
  },
}));

export { useFileViewer, FileViewerProvider };
