import { createStyles, makeStyles, Slide, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useContext } from 'react';
import { EditModeContext } from '../contexts/editModeContext';
import { colors } from '../styles/theme';
import _ from 'lodash';
import { useLayoutContext } from './LayoutContext';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtonBar: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      backgroundColor: colors.white,
      color: colors.black,
      width: '100%',
    },
    bottomButtonBar2: {
      left: 0,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tab: {
      borderRight: `2px solid ${colors.grayLine}`,
      textTransform: 'capitalize',
      color: colors.black54,
      minHeight: 16,
      minWidth: 0,
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      padding: '10px 15px',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
    },
    tabs: {
      '&.MuiTabs-root': {
        minHeight: 24,
      },
      '& .MuiTabs-flexContainer': {
        height: 40,
      },
      '& .MuiButtonBase-root.Mui-disabled': {
        display: 'none',
      },
    },
    indicator: {
      height: 3,
    },
    divider: {
      height: 5,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.25)',
    },
    button: {
      height: 38,
      fontWeight: 500,
    },
    saveButton: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      marginRight: 10,
      marginLeft: 10,
    },
    discardButton: {
      backgroundColor: colors.white,
      color: colors.black,
      border: `1px solid ${colors.grayPlaceholder}`,
    },
  })
);
function a11yProps(index: unknown) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export interface BottomSelectionBarProps {
  options: { value: string; label: string; count: number; plural: string; }[];
  onSelect: (option: string) => void;
  value: string | false;
}

const BottomSelectionBar: React.FC<BottomSelectionBarProps> = props => {
  const classes = useStyles();
  const { options, onSelect, value } = props;
  const { assetEditModeActive } = useContext(EditModeContext);
  const { layout } = useLayoutContext();
  const { translate } = useContext(LanguageContext);
  return (
    <Slide in={!assetEditModeActive} direction="up" timeout={500}>
      <div className={`${classes.bottomButtonBar} ${layout === 'Horizontal' ? classes.bottomButtonBar2 : ''}`}>
        <div className={classes.buttonContainer}>
          <AppBar position="static" color="default">
            <Tabs
              variant="scrollable"
              value={value}
              onChange={(_, newValue) => {
                onSelect(newValue);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              classes={{ root: classes.tabs }}
              TabIndicatorProps={{ className: classes.indicator }}
            >
              {options?.map(option => {
                return (
                  <Tab
                    classes={{ root: classes.tab }}
                    key={option.value}
                    value={option.value}
                    label={`${translate(option.plural)} (${option.count})`}
                    {...a11yProps(0)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </div>
      </div>
    </Slide>
  );
};

export default BottomSelectionBar;
