import {
  Button,
  makeStyles,
  Input,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
  IconButton,
} from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Search } from '@material-ui/icons';
import { colors } from '../../styles/theme';
import { sortBy } from 'lodash';
import clsx from 'clsx';
import { ColumnFilterInputOptions } from '../../hooks/tableHooks/useColumns';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import BottomBar from '../Common/BottomBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { useCommonStyles } from '../../styles/commonStyles';
import { useUtils } from '../../contexts/utilContext';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';
import CalendarFilter from '../Filters/CalendarFilter';
import { TableDataContext } from '../../hooks/useTableData';

const HEADER_FOOTER_HEIGHT = 202;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '6px 0px 6px 0px',
    borderRadius: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    backgroundColor: colors.white,
    padding: '18px 30px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '18px 22px',
    },
  },
  headerText: {
    fontSize: 19,
    fontWeight: 500,
    color: colors.black0,
    lineHeight: 'normal',
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingBottom: 9,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colors.cultured7,
    color: colors.black0,
    padding: '8px 12px',
    width: '100%',
    height: 38,
    boxSizing: 'border-box',
    fontSize: 16,
    borderRadius: 4,
    '& :before': {
      display: 'none',
      borderBottom: 0,
    },
  },
  searchIcon: {
    height: 22,
    width: 22,
    color: colors.black35,
  },
  input: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: 0,
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.black0,
      '&::placeholder': {
        color: colors.black35,
        opacity: 1,
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
  },
  button: {
    color: colors.white,
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  filterItems: {
    height: '100%',
  },
  text: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: 0,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  checkbox: {
    paddingLeft: '0px',
    '& .MuiTypography-body1': {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black0,
      lineHeight: 'normal',
    },
  },
  divider: {
    background: colors.black10,
  },
  body: {
    padding: '22px 0px 0px 34px',
    height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 15,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: 7,
      },
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      border: `1px solid ${colors.black10}`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        border: 'none',
      },
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: colors.scrollBar,
      backgroundClip: 'padding-box',
      border: '4px solid transparent',
      boxShadow: `inset 0 0 0 1px ${colors.black10}`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        borderRadius: 5,
        boxShadow: 'none',
        border: 'none',
      },
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '22px',
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
  closeRoot: {
    width: 24,
    height: 24,
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
  },
  headerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface FilterDialogWrapperProps {
  onClose: (selectedElements: Array<string>, isDateTime?: boolean) => void;
  onCancel: () => void;
  title: string;
  elements: Array<string>;
  selectedElements: Array<string>;
  inputOptions?: ColumnFilterInputOptions;
  filtersApplied?: boolean;
  setFiltersApplied?: (val: boolean) => void;
  fieldType?: string;
  columnName?: string;
}

const FilterDialogWrapper = (props: FilterDialogWrapperProps) => {
  const { translate } = useContext(LanguageContext);
  const classes = useStyles();
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const [selectedElements, setSelectedElements] = useState<string[]>(() => [...props.selectedElements]);
  const [dateFilterValue, setDateFilterValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const [dateFilterOpen, setDateFilterOpen] = useState(true);
  const { filters } = useContext(TableDataContext);
  
  const defaultDateFilter = useMemo(() => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
      if (
        Array.isArray(value) &&
        value.length === 4 &&
        value.includes("startDate") &&
        key === props.columnName
      ) {
        const [, greaterThanOrEqualTo, , lessThanOrEqualTo] = value;
        acc[key] = [greaterThanOrEqualTo, lessThanOrEqualTo];
      }
      return acc;
    }, {} as { [x: string]: string[] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);

  useEffect(() => {
    if (!dateFilterOpen) {
      if (props.columnName && dateFilter[props.columnName] && dateFilter[props.columnName].length > 0) {
        return props.onClose([dateFilter[props.columnName][0], dateFilter[props.columnName][1]], true);
      }
      return props.onClose([], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterOpen]);

  useEffect(() => {
    if (!props.filtersApplied) {
      setSelectedElements([...props.selectedElements]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.elements, props.filtersApplied]);
  const toggleCheckbox = (element: string) => {
    if (selectedElements.includes(element)) {
      setSelectedElements(selectedElements.filter(x => x !== element));
    } else {
      setSelectedElements([...selectedElements, element]);
    }
  };

  const checkbox = (element: string) => (
    <Checkbox checked={selectedElements.includes(element)} onChange={() => toggleCheckbox(element)} color="primary" />
  );

  const regexp = (() => {
    try {
      return new RegExp(searchValue, 'gi');
    } catch (e) {
      console.error(e);
      return '';
    }
  })();

  const getElementLabel = (element: string) => props.inputOptions?.labelConverter?.(element) || element || '(Blanks)';

  const filteredElements = sortBy(
    props.elements.filter(x => selectedElements.includes(x) || x.match(regexp)),
    v => getElementLabel(v)
  );
  const allSelected = selectedElements.length === props.elements.length;

  const selectAll = () => {
    if (allSelected) setSelectedElements([]);
    else setSelectedElements([...props.elements]);
  };
  return (
    <>
      {props.fieldType !== 'DateTime' ? (
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.headerRoot}>
              <div className={classes.headerText}>{props.title}</div>
              <IconButton onClick={props.onCancel} className={classes.closeRoot}>
                <FontAwesomeIcon icon={faClose} className={classes.closeIcon} />
              </IconButton>
            </div>
            <div className={classes.searchBar}>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <Search className={classes.searchIcon} />
                  </InputAdornment>
                }
                disableUnderline
                placeholder={translate('Search')}
                className={clsx(classes.input, isIOSSafari && commonClasses.safariMuiInputFontSize)}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <Divider className={classes.divider} />
          <Box className={classes.body}>
            <div className={classes.selectAll}>
              <Button className={classes.text} onClick={selectAll}>
                {translate(allSelected ? 'Deselect All' : 'Select All')}
              </Button>
            </div>

            <div className={classes.filterItems}>
              {filteredElements.map(element => (
                <FormGroup key={`filter-dialog-${element}`} row className={classes.checkbox}>
                  <FormControlLabel control={checkbox(element)} label={translate(getElementLabel(element))} />
                </FormGroup>
              ))}
            </div>
          </Box>
          <BottomBar
            onCancelClick={props.onCancel}
            onDoneClick={() => {
              props.onClose(selectedElements);
              props.setFiltersApplied && props.setFiltersApplied(true);
            }}
            isRoles={true}
            buttonText={'Apply'}
          />
        </div>
      ) :
        <CalendarFilter
          open={true}
          setFilterOpen={setDateFilterOpen}
          displayName={props.title}
          setFilterState={setDateFilter}
          field={props.columnName}
          filterState={dateFilter}
          filterValue={dateFilterValue}
          setDayFilter={setDateFilterValue} />}
    </>
  );
};

export default FilterDialogWrapper;
