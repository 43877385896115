import React, { useContext } from 'react';
import { InputAdornment, TextField, createStyles, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VideocamIcon from '@material-ui/icons/Videocam';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import MicIcon from '@material-ui/icons/Mic';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FileIcon from '@material-ui/icons/FileCopy';
import { colors } from '../../styles/theme';
import _ from "lodash";
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export type IconType =
  | 'imageupload'
  | 'fileupload'
  | 'videoupload'
  | 'audioupload'
  | 'barcode'
  | 'signature'
  | 'location'
  | 'aggregateSelector';
export interface WorkFlowButtonFieldProps {
  mode: 'edit' | 'add';
  type: IconType;
  onClick: () => void;
  readOnly: boolean;
}

const Icons: { [index in IconType]: React.ReactElement } = {
  fileupload: <FileIcon />,
  imageupload: <AddPhotoAlternateIcon />,
  videoupload: <VideocamIcon />,
  audioupload: <MicIcon />,
  barcode: <CropFreeIcon />,
  signature: <EditIcon />,
  location: <LocationOnIcon />,
  aggregateSelector: <LocationOnIcon />,
};
const getIcon = (type: IconType): React.ReactNode | null => {
  return Icons[type] || null;
};
const createLabel: { [index in IconType]: string } = {
  fileupload: 'File',
  imageupload: 'Image',
  videoupload: 'Video',
  audioupload: 'Audio',
  barcode: 'Barcode',
  signature: 'Signature',
  location: 'Location',
  aggregateSelector: 'Item',
};

const EditComponentButton: React.FC<WorkFlowButtonFieldProps> = props => {
  const { mode, type, onClick, readOnly } = props;
  const { translate } = useContext(LanguageContext);
  const classes = useStyles();
  return (
    <TextField
      placeholder={translate(`${_.capitalize(mode)} ${createLabel[type]}`)}
      variant="outlined"
      className={classes.textField}
      onClick={onClick}
      InputProps={{
        startAdornment: <InputAdornment position="start" className='start'>{getIcon(type)}</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 1.5L1.5 0L8 6.5L1.5 13L0 11.5L5 6.5L0 1.5Z" fill="#AAAAAA" />
            </svg>
          </InputAdornment>
        ),
        readOnly: true,
        disabled: readOnly,
      }}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: `1px solid ${colors.black10}`,
        borderRadius: 5,
      },
      '&:hover fieldset': {
          borderColor: `${colors.black10} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${colors.black10} !important`,
        },
      '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.black10}`,
        borderRadius: 5,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 8,
      paddingLeft: 2,
      cursor: 'pointer',
        '&::placeholder': {
            color: colors.black54,
            opacity: 1,
            fontSize: 14,
            fontFamily: 'Roboto',
            fontWeight: 400,
            lineHeight: 'normal',
        },
    },
    '& .start': {
      '& svg':{
        height: '20px',
        width: '20px',
        color: colors.black54,
        },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 12,
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 12,
    },
    },
  })
);

export default EditComponentButton;
