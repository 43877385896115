import React, { useContext } from 'react';
import { FormControlLabel, Tooltip } from '@material-ui/core';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import { TGIosSwitch } from './IosSwitch';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface OnOffSectionProps {
  onIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  title: string;
  onToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isOn: boolean;
  tooltip?: string;
  disable?: boolean;
  translate?: (key: string, params?: Record<string, string | number | boolean>) => string;
}

export const OnOffSection = (props: OnOffSectionProps): DrawerItem => {
  const { translate = (key: string) => key } = props;
  return {
    icon: props.onIcon && props.offIcon && (props.isOn ? props.onIcon : props.offIcon),
    title: props.title,
    content: (
      <React.Fragment>
        <Tooltip title={translate(props.tooltip || '')} placement="top-start">
          <FormControlLabel
            control={<TGIosSwitch value={props.isOn} onChange={props.onToggle} disabled={props.disable} />}
            style={{ textTransform: 'capitalize', marginLeft: 0 }}
            label=""
          />
        </Tooltip>
      </React.Fragment>
    ),
    iconClickHandler: props.onToggle,
  };
};
