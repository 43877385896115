import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { DocumentNode } from 'graphql';
import _ from 'lodash';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelectedProject } from '../selectedProjectContext';
import {
  checkForExistingData,
  createWithAggregateNameObject,
  extractDataFromResult,
  getAssetsFromResults,
} from './aggregateUtils';
import { MAX_CHUNK_SIZE } from './projectStateUtils';
import QUERY, { FilterQuery, GlobalSearchQueryCreator, RecordTypeAssetCountQuery } from './query';
import {
  AggregatesContextType,
  AssetType,
  GraphQlAssetType,
  MapAssetType,
  pageInfo,
  QueryResultDataType,
  recordData,
  WithAggregateName,
} from './types';
import { VariablesRecord, useLazyQueries } from './useLazyQueries';
import { useRecordType } from '../recordTypeContext';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { pascalCase } from 'change-case';
import { FilterContext } from '../FilterContext/filterContext';
import { SortDirection } from '@terragotech/react-data-grid';
import { useProjects } from '../projectsContext';
import {
  createSearchFilterArray,
  getSortKey,
  getTimeZoneDifference,
  MAX_ASSET_LIMIT_MULTISELECT,
  PAGE_SIZE_MULTISELECT,
} from './queryUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { cardIdsState, forceMapReloadState, mapBoundsState } from '../../recoil/atoms/mapMaintenance';
import { useAssetCards } from '../assetCardContext';
import { FilterContextType } from '../FilterContext/types';
import { NOT_MAP_ASSETS_NAMES } from '@terragotech/gen5-shared-utilities';
import { LanguageContext } from '../LanguageContext/languageContext';
import { initialTableLoadState, selectedRecordTypeState, sortSelector } from '../../recoil/atoms';

export interface UseAssetsLoaderArgs {
  aggregateDefinitions: AggregateDefinition[];
  mapAggregateDefinitions: AggregateDefinition[];
  visibleAggregateTypesNames: string[];
}
export interface assetsLoaderFromQueryArgs {
  mapAggregateDefinitions: AggregateDefinition[];
  queriesProp: WithAggregateName<DocumentNode>;
}
export interface QueryPagePayload {
  [key: string]: { after: string; first: number; date: Date };
}

export interface UseAssetsLoaderReturnType {
  refetchAll: () => void;
  isDataLoading: boolean;
  assets: MapAssetType[];
  referenceTypeAssets: MapAssetType[];
  recordTypesData: record[] | undefined;
  fetchNextDataSet: (fetchAfter: boolean) => Promise<number | undefined>;
  updateScrollPosition: (recordType: string, index: number) => void;
  update: (recordType: string[], updatedProject: string) => Promise<void>;
  deleteAssets: (deletedAssets: Record<string, string[]>) => void;
  deleteAssetsById: (ids: string[]) => void;
  fetchAssetsForFilter: (
    field: string,
    limit?: number,
    offset?: number,
    searchText?: string
  ) => Promise<string[] | undefined>;
  getSearchResults: (searchText: string) => Promise<MapAssetType[]>;
  getAssetsForMultiSelect: () => Promise<never[] | AssetType[]>;
}

export interface AssetsLoaderFromQueryReturnType {
  assets: MapAssetType[];
  isDataLoading: boolean;
  refetchSince: (since: string) => void;
}

interface queryVariables {
  filters?: Filters;
  conditions?: Conditions;
  first?: number;
  after?: string;
  before?: string;
  orderBy?: string;
  last?: number;
}
type ValueRecord = Record<string, Object | Array<string> | boolean>;
type Conditions = Record<string, { [key: string]: string | { [key: string]: string } }>;
type fetchedResult = {
  [key: string]: { data: { edges: { node: GraphQlAssetType; cursor: string }[]; pageInfo: pageInfo } };
};
export type FilterValue =
  | { in: string[] | number[] }
  | { notIn: string[] | number[] }
  | { includesInsensitive: string }
  | { greaterThan: number | string }
  | { lessThan: number | string }
  | { label: { includesInsensitive: string } }
  | { label: { inInsensitive: string[] } }
  | { greaterThanOrEqualTo: string; lessThanOrEqualTo: string }
  | Array<{ [key: string]: { [fieldKey: string]: string } }>
  | { in: string[] | number[]; isNull: boolean }
  | { equalTo: number | string | boolean | null }
  | { notEqualTo: number | string | boolean | null }
  | { greaterThanOrEqualTo: number | string }
  | { lessThanOrEqualTo: number | string }
  | { isNull: boolean }
  | { startsWith: string }
  | { notStartsWith: string }
  | { endsWith: string }
  | { notEndsWith: string }
  | { like: string }
  | { notLike: string }
  | { distinctFrom: number | string | null }
  | { notDistinctFrom: number | string | null }
  | { dateContains: string }
  | {
      label?: {
        includesInsensitive?: string;
        inInsensitive?: string[];
      };
    }
  | {
      and?: FilterValue[];
      or?: FilterValue[];
      not?: FilterValue;
    }
  | { [key: string]: FilterValue };

export interface Filters {
  and?: Filters[];
  [key: string]: FilterValue | Filters[] | Filters | undefined;
}

export interface record {
  label: string;
  value: string;
  count: number;
  plural: string;
}

interface recordTypeCountResult {
  data: {
    [key: string]: { totalCount: number };
  };
}

export const getQuery = (
  aggregateDefinition: AggregateDefinition,
  filters: FilterContextType | undefined,
  selectedProjects: string[],
  assetRecord?: AggregatesContextType,
  allProjectIds?: string[]
) => {
  const filterState = filters?.getFilterState(aggregateDefinition.name) || {};
  if (assetRecord && !assetRecord.visibleAggregateTypesNames.includes(aggregateDefinition.name)) {
    return undefined;
  }

  return {
    type: 'AggregateLoader',
    aggregateType: aggregateDefinition.name,
    mandatoryFilter: {
      filter: {
        type: 'GroupFilter',
        conjunction: 'AND',
        condition: [
          ...((!allProjectIds || selectedProjects.length !== allProjectIds.length) &&
          !aggregateDefinition.isReferenceType
            ? !allProjectIds || selectedProjects.length < allProjectIds.length / 2
              ? [
                  {
                    type: 'SimpleFilter',
                    key: 'project',
                    operator: 'INCLUDE',
                    options: selectedProjects,
                  },
                ]
              : [
                  {
                    type: 'SimpleFilter',
                    key: 'project',
                    operator: 'EXCLUDE',
                    options: allProjectIds.filter(id => !selectedProjects.includes(id)),
                  },
                ]
            : []),
          ...Object.keys(filterState)
            .map(field => {
              const actualField = field.split('-')[0];
              const currType = aggregateDefinition?.propertyDefinitions.find(obj => obj.field === actualField);

              // Date only filtering
              if (currType && (currType.type === 'Date' || currType.type === 'DateTime')) {
                return {
                  type: 'SimpleFilter',
                  key: actualField,
                  operator: 'BETWEEN',
                  lowerBound: new Date(filterState[field][0]),
                  upperBound: new Date(filterState[field][1]),
                };
              }
              if (field.endsWith('-QuickFilter')) {
                return {
                  type: 'SimpleFilter',
                  key: currType && currType.relationshipType === 'ONE_TO_ONE' ? 'label' : actualField,
                  foreignTable:
                    currType && currType.relationshipType === 'ONE_TO_ONE' ? `"ref${actualField}"` : undefined,
                  operator: 'LIKE',
                  options: `%${filterState[field]}%`,
                };
              } else {
                return {
                  type: 'SimpleFilter',
                  key: currType && currType.relationshipType === 'ONE_TO_ONE' ? 'label' : actualField,
                  foreignTable:
                    currType && currType.relationshipType === 'ONE_TO_ONE' ? `"ref${actualField}"` : undefined,
                  operator: 'INCLUDE',
                  options: filterState[field].length ? filterState[field] : ['INVALIDVALUEDONOTRETURN'],
                };
              }
            })
            .filter(a => a),
        ],
      },
    },
  } as any;
};
const INITIAL_CHUNK_SIZE = 300;
const NEXT_CHUNK_SIZE = 200;

export const useAssetsLoaderFromQuery = (props: assetsLoaderFromQueryArgs): AssetsLoaderFromQueryReturnType => {
  const { mapAggregateDefinitions, queriesProp } = props;

  const [assets, setAllAssets] = useState<Array<MapAssetType>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [fullyLoaded, setFullyLoaded] = useState<boolean>(false);
  const [vs, setVs] = useState<QueryPagePayload | null>();

  const { loading, data, variables, error, called, refetch } = useLazyQueries<QueryResultDataType>(
    queriesProp,
    {
      fetchPolicy: 'no-cache',
    },
    {}
  );

  // any time we aren't loading data, check to see if we should be
  useEffect(() => {
    if (!loading && !error && !loaded) {
      setLoaded(true);
    }
    if (loaded && !error && !loading && vs) {
      setVs(null);
    }
  }, [mapAggregateDefinitions, called, error, loading, refetch, vs]);

  // Now handle any new data
  useEffect(() => {
    // when we have new data, add it to the corresponding projectState
    if (variables && data) {
      const aggregateKeys = Object.keys(data);
      const result: MapAssetType[] = [];
      const newVars: QueryPagePayload = {};
      aggregateKeys.forEach(key => {
        Object.keys(data[key]).forEach(agg => {
          const newData = data[key][agg];
          if (newData.pageInfo.hasNextPage) {
            newVars[key] = {
              after: newData.pageInfo.endCursor,
              first: MAX_CHUNK_SIZE,
              date: variables[key].date as Date,
            };
          }
          const edges = newData?.edges || [];
          edges.forEach(edge => {
            result.push({
              ...edge.node,
              recordTypeKey: agg,
              primaryLocation: edge.node.primaryLocation?.geojson || undefined,
            });
          });
        });
      });
      if (Object.keys(newVars).length) {
        setVs(newVars);
      } else {
        setVs(null);
        setFullyLoaded(true);
      }
      if (result.length) {
        setAllAssets(a => {
          result.forEach(k => {
            const idx = _.findIndex(a, itm => itm.id === k.id);
            if (idx > 0) {
              a[idx] = k;
            } else {
              a.push(k);
            }
          });
          return _.cloneDeep(a);
        });
      }
    } else {
      setFullyLoaded(true);
    }
  }, [data, variables]);

  const refetchSince = useCallback(
    (since: string) => {
      if (!vs) {
        const vars = Object.keys(queriesProp).reduce(
          (prev, k) => ({
            ...prev,
            [k]: {
              date: since,
              first: MAX_CHUNK_SIZE,
            },
          }),
          {}
        );
        setVs(vars);
      }
    },
    [mapAggregateDefinitions]
  );

  return {
    assets,
    isDataLoading: !fullyLoaded,
    refetchSince,
  };
};

export const useAssetsLoader = (props: UseAssetsLoaderArgs): UseAssetsLoaderReturnType => {
  const { aggregateDefinitions, mapAggregateDefinitions, visibleAggregateTypesNames } = props;
  const { translate, language } = useContext(LanguageContext);
  const [assetStates, setAssetStates] = useState<{ [recordType: string]: recordData }>(
    createWithAggregateNameObject(mapAggregateDefinitions, () => ({}))
  );
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { selectedProjects, getAllProjectIds } = useSelectedProject();
  const allProjectIds = useMemo(() => getAllProjectIds(), [getAllProjectIds]);
  const [assets, setAllAssets] = useState<Array<MapAssetType>>([]);
  const [recordTypesData, setRecordTypesData] = useState<record[]>();
  const { refetch: refetchProjects } = useProjects();
  const [referenceTypeAssets, setReferenceTypeAssets] = useState<MapAssetType[]>([]);
  const client = useApolloClient();
  const setForceMapReload = useSetRecoilState(forceMapReloadState);
  const setInitialTableLoadState = useSetRecoilState(initialTableLoadState);
  const mapViewBox = useRecoilValue(mapBoundsState);
  const cardIds = useRecoilValue(cardIdsState);
  const { setRefresh } = useAssetCards();
  const filterContext = useContext(FilterContext);
  const doesRecordHasNextPage = useRef<boolean | undefined>(false);
  const isMapExtentEnabled = useMemo(() => filterContext.mapFilterState.extentFilterEnabled, [filterContext]);
  const drawFilter = filterContext.drawFilter;
  const searchKey = useMemo(
    () => (_.isEmpty(filterContext.filterState) ? null : filterContext.filterState),
    [filterContext.filterState]
  );
  const sort = useRecoilValue(sortSelector);
  const selectedRecordType = useRecoilValue(selectedRecordTypeState);
  const selectedRecordTypeDefinition = mapAggregateDefinitions.find(d => d.name === selectedRecordType);
  const filterValues = useMemo(() => (searchKey ? Object.keys(searchKey).map(key => searchKey[key]) : []), [searchKey]);
  const filteredSearchKeys = useMemo(() => _.keys(searchKey).map(item => item), [searchKey]);
  const mapViewBoxRef = useRef(mapViewBox);
  const prevQueryKey = useRef('');
  const cardIdsRef = useRef(cardIds);

  const assetsRef = useRef(assets);

  const projectsFilter = useMemo(() => {
    return !allProjectIds || selectedProjects.length < allProjectIds.length / 2
      ? {
          in: selectedProjects,
        }
      : {
          notIn: allProjectIds.filter(id => !selectedProjects.includes(id)),
        };
  }, [allProjectIds, selectedProjects]);

  useEffect(() => {
    assetsRef.current = assets;
  }, [assets]);

  useEffect(() => {
    mapViewBoxRef.current = mapViewBox;
  }, [mapViewBox]);

  useEffect(() => {
    cardIdsRef.current = cardIds;
    setRefresh(true);
  }, [cardIds]);

  useEffect(() => {
    doesRecordHasNextPage.current = assetStates[selectedRecordTypeDefinition?.queryKey || '']?.pageInfo?.hasNextPage;
  }, [assetStates]);

  const [assetSortState, setAssetSortState] = useState<{
    [key: string]: {
      sortKey: string;
      columnKey: string;
    };
  }>({});

  const getQueryVariable = (queryVariables?: queryVariables, isReferenceType?: boolean) => {
    return {
      filters:
        queryVariables?.filters ||
        (isReferenceType
          ? { label: { notEqualTo: '' } }
          : {
              projectId: projectsFilter,
            }),
      conditions: queryVariables?.conditions || null,
      first: queryVariables?.first || null,
      after: queryVariables?.after || null,
      before: queryVariables?.before || null,
      orderBy: queryVariables?.orderBy || null,
      last: queryVariables?.last || null,
    };
  };

  // Generates query for every aggregate definition
  const queries = useMemo(
    () => createWithAggregateNameObject(mapAggregateDefinitions, definition => QUERY(definition)),
    [mapAggregateDefinitions]
  );
  const recordTypeCountQueries = useMemo(
    () => createWithAggregateNameObject(mapAggregateDefinitions, definition => RecordTypeAssetCountQuery(definition)),
    [mapAggregateDefinitions]
  );

  const getQuickSearchFromKey = useCallback(
    (aggregateName: string) => {
      return filterContext.getQuickSearchForRecordType(aggregateName) ?? '';
    },
    [filterContext]
  );

  const getFilterValueForArray = (
    value: Array<number | string | null>,
    filterKey: string,
    isRelationship?: boolean
  ) => {
    const nonNullArray = Array.isArray(value) ? value.filter(val => val !== null) : [];
    const valueArray: Array<Record<string, ValueRecord>> = [];
    if (isRelationship) {
      if ((value as Array<string | null>).includes(null)) {
        valueArray.push({
          [filterKey]: { label: { isNull: true } },
        });
      }
      if (nonNullArray.length) {
        valueArray.push({
          [filterKey]: { label: { inInsensitive: nonNullArray } },
        });
      }
    } else {
      if ((value as Array<string | null>).includes(null)) {
        valueArray.push({
          [filterKey]: { isNull: true },
        });
      }
      if (nonNullArray.length) {
        valueArray.push({
          [filterKey]: { in: nonNullArray },
        });
      }
    }
    return valueArray;
  };

  const getFilterValue = (
    filterKey: string,
    value: string | string[],
    fieldType?: string,
    isRelationship?: boolean,
    field?: string
  ) => {
    if (isRelationship && field) {
      let constraintObject = null;
      if (Array.isArray(value)) {
        if ((value as Array<string | null>).includes(null)) {
          return getFilterValueForArray(value, filterKey, isRelationship);
        }
        return (constraintObject = { label: { inInsensitive: value as string[] } });
      } else {
        return (constraintObject = { label: { includesInsensitive: value as string } });
      }
    }

    if (Array.isArray(value)) {
      if ((value as Array<string | null>).includes(null)) {
        return getFilterValueForArray(value, filterKey, isRelationship);
      }
      return { in: value };
    }
    switch (fieldType) {
      case 'String':
        return { includesInsensitive: value };
      default:
        return { includesInsensitive: value };
    }
  };

  const getCondition = () => {
    const dateTimeCondition: Record<string, string> = {};
    const intAndFloatCondition: Record<string, string> = {};
    const result: Conditions = {};
    if (filterValues.length) {
      filteredSearchKeys.forEach((item, index) => {
        const key = item.split('-')[0];
        const fieldDef = selectedRecordTypeDefinition?.propertyDefinitions.find(def => def.field === key);
        const filterKey = fieldDef?.field;
        const filterValue = filterValues[index];
        if (fieldDef?.type === 'DateTime' && filterKey && item.includes('-')) {
          dateTimeCondition[filterKey] = filterValue;
        } else if (
          (fieldDef?.type === 'Float' || fieldDef?.type === 'Int') &&
          filterKey &&
          !Array.isArray(filterValue)
        ) {
          intAndFloatCondition[filterKey] = filterValue;
        }
      });
    }

    if (Object.keys(dateTimeCondition).length) {
      result.dateTimeStartsWith = { timezoneDifference: getTimeZoneDifference(), filters: dateTimeCondition };
    }

    if (Object.keys(intAndFloatCondition).length) {
      result.numberContains = intAndFloatCondition;
    }
    return result;
  };

  const getFilters = (isReferenceType: boolean): any => {
    const filters: Filters = isReferenceType
      ? { label: { notEqualTo: '' } }
      : {
          projectId: projectsFilter,
        };

    const ands = [];

    const searchTextForSelected = selectedRecordTypeDefinition
      ? getQuickSearchFromKey(selectedRecordTypeDefinition.name)
      : '';
    if (searchTextForSelected !== '') {
      ands.push({ or: createSearchFilterArray(selectedRecordTypeDefinition!, searchTextForSelected) });
    }

    if (drawFilter.apply && !_.isEmpty(drawFilter.features)) {
      const ors = drawFilter.features.map((f: any) => ({
        primaryLocation: { coveredBy: { type: 'Polygon', coordinates: [[...f.coordinates[0], f.coordinates[0][0]]] } },
      }));
      ands.push({ or: ors });
    }

    if (isMapExtentEnabled) {
      const regionMinLat = mapViewBoxRef.current?.minLat || -200;
      const regionMaxLat = mapViewBoxRef.current?.maxLat || 200;
      const regionMinLon = mapViewBoxRef.current?.minLon || -200;
      const regionMaxLon = mapViewBoxRef.current?.maxLon || 200;
      filters['primaryLocation'] = {
        coveredBy: {
          type: 'Polygon',
          coordinates: [
            [
              [regionMinLon, regionMinLat],
              [regionMaxLon, regionMinLat],
              [regionMaxLon, regionMaxLat],
              [regionMinLon, regionMaxLat],
              [regionMinLon, regionMinLat],
            ],
          ],
        } as FilterValue,
      };
    }

    if (filterValues.length) {
      filteredSearchKeys.forEach((item, index) => {
        const key = item.split('-')[0];
        const fieldDef = selectedRecordTypeDefinition?.propertyDefinitions.find(def => def.field === key);
        const value = filterValues[index];
        const filterKey = key;
        if (fieldDef?.type !== 'DateTime' && fieldDef?.type !== 'Float' && fieldDef?.type !== 'Int') {
          const filterValue = getFilterValue(
            filterKey,
            value,
            fieldDef?.type,
            fieldDef?.isRelationship,
            fieldDef?.field
          );
          if (Array.isArray(filterValue)) {
            ands.push({ or: filterValue as FilterValue });
          } else {
            filters[filterKey] = filterValue as FilterValue;
          }
        } else if ((fieldDef?.type === 'Float' || fieldDef?.type === 'Int') && Array.isArray(value)) {
          const convertedArray = value.map(strValue => {
            const numericValue = Number(strValue);
            return isNaN(numericValue) ? strValue : numericValue;
          });
          filters[filterKey] = { in: convertedArray };
        } else if (fieldDef?.type === 'DateTime' && Array.isArray(value) && !item.includes('-')) {
          const startTime = value[0];
          const endTime = value[1];
          filters[filterKey] = { greaterThanOrEqualTo: startTime, lessThanOrEqualTo: endTime };
        }
      });
    }
    if (ands.length) {
      filters['and'] = ands;
    }
    return filters;
  };

  const fetchRecordTypeCount = async () => {
    const queries = Object.keys(recordTypeCountQueries).map(
      (key: string): Promise<{ key: string; result: ApolloQueryResult<recordTypeCountResult> }> => {
        const isReferenceType = mapAggregateDefinitions.find(def => def.queryKey === key)?.isReferenceType;
        const queryVariable = getQueryVariable(undefined, isReferenceType);
        return client
          .query({
            fetchPolicy: 'no-cache',
            query: recordTypeCountQueries[key],
            variables: queryVariable,
          })
          .then(result => {
            return { key, result };
          })
          .catch(e => {
            return { key, result: e };
          });
      }
    );
    Promise.all(queries).then(results => {
      let recordData: record[] = [];
      results.map(recordType => {
        const data = recordType.result.data as unknown as recordTypeCountResult['data'];
        const record = mapAggregateDefinitions.find(
          mapAggregateDefinitions => mapAggregateDefinitions.queryKey === recordType.key
        );
        if (record && data && data[record.queryKey].totalCount !== 0) {
          let obj = {
            value: record.name,
            label: `${translate(record.plural)} (${data[record.queryKey].totalCount})`,
            plural: record.plural,
            count: data[record.queryKey].totalCount,
          };
          recordData.push(obj);
        }
      });
      setRecordTypesData(recordData);
    });
  };

  useEffect(() => {
    fetchRecordTypeCount();
    fetchRefernceTypeAggregates();
  }, [projectsFilter, language]);

  useEffect(() => {
    initialAssetsFetch(false, true);
  }, [isMapExtentEnabled, drawFilter, projectsFilter, filterContext.quickSearch]);

  useEffect(() => {
    if (isMapExtentEnabled) {
      initialAssetsFetch(false, true);
    }
  }, [mapViewBox]);

  useEffect(() => {
    initialAssetsFetch(false);
  }, [searchKey]);

  useEffect(() => {
    initialAssetsFetch(true);
  }, [sort]);

  const { refetch, fetchRecordData } = useLazyQueries<QueryResultDataType>(
    queries,
    {
      fetchPolicy: 'no-cache',
    },
    {}
  );

  const columnSorting = ({ sortDirection, sortColumn }: { sortColumn?: string; sortDirection?: SortDirection }) => {
    let sortKey = undefined;
    const fieldDef = selectedRecordTypeDefinition?.propertyDefinitions.find(def => def.field === sortColumn);
    if (sortColumn && sortDirection !== 'NONE') {
      const pascal = getSortKey(pascalCase(sortColumn)).toUpperCase();
      if (fieldDef?.isRelationship) {
        if (fieldDef?.relationshipType !== 'ONE_TO_MANY')
          sortKey = `${fieldDef.type.toUpperCase()}_BY_${pascal}_ID__LABEL_${sortDirection}`;
        else {
          const foreignColumn = getSortKey(pascalCase(fieldDef.foreignColumn || '')).toUpperCase();
          sortKey = `${fieldDef.type.toUpperCase()}S_BY_${foreignColumn}_ID__MIN_LABEL_${sortDirection}`;
        }
      } else {
        sortKey = `${pascal}_${sortDirection}`;
      }
    } else if (sortColumn !== '') {
      sortKey = '';
    }
    return sortKey;
  };
  const fetchRefernceTypeAggregates = async () => {
    const referenceTypeAggregatesDef = mapAggregateDefinitions.filter(def => def.isReferenceType);
    const referenceTypeVariable = {
      filters: { label: { notEqualTo: '' } },
    };
    const allQueries = referenceTypeAggregatesDef.map(record => {
      const queryKey = record.queryKey;
      return client
        .query({
          fetchPolicy: 'no-cache',
          query: queries[queryKey],
          variables: referenceTypeVariable,
        })
        .then(result => {
          return { queryKey, result };
        })
        .catch(e => {
          return { queryKey, result: e };
        });
    });
    Promise.all(allQueries).then(results => {
      let assetData: MapAssetType[] = [];
      results.map(result => {
        const querykey = result.queryKey;
        if (result.result.data && result.result.data[querykey]) {
          const modifiedData = extractDataFromResult(result.result.data[querykey], querykey);
          assetData.push(...modifiedData[querykey].assetData);
        }
      });
      setReferenceTypeAssets(assetData);
    });
  };

  const getQueryKey = (connectionString: string) => {
    const suffix = 'Connection';
    if (connectionString.endsWith(suffix)) {
      return connectionString.slice(0, -suffix.length);
    }
    return connectionString;
  };

  const getSearchResults = async (searchText: string) => {
    try {
      const validDefinitions = mapAggregateDefinitions.filter(def =>
        recordTypesData?.some(data => data.value === def.name && data.count > 0)
      );
      const visibleAggregateDefinitions = validDefinitions.filter(def => visibleAggregateTypesNames.includes(def.name));
      const query = GlobalSearchQueryCreator(visibleAggregateDefinitions, selectedProjects, searchText);
      const fetchedData = await client.query({
        fetchPolicy: 'no-cache',
        query: query,
        errorPolicy: 'ignore',
      });
      const result: MapAssetType[] = [];
      Object.keys(fetchedData.data).map((aggregateType: string) => {
        result.push(...getAssetsFromResults(fetchedData.data[aggregateType].edges, getQueryKey(aggregateType)));
      });
      return result;
    } catch (e) {
      console.error('Cross Origin Error', e);
      return [];
    }
  };

  const initialAssetsFetch = async (checkExsistingData: boolean, clearExistingData?: boolean) => {
    if (selectedProjects.length === 0) {
      setIsDataLoading(false);
      setAllAssets([]);
      setAssetStates(createWithAggregateNameObject(mapAggregateDefinitions, () => ({})));
      return 0;
    }
    setIsDataLoading(true);
    const querykey: string | undefined = selectedRecordTypeDefinition?.queryKey;
    if (querykey) {
      const sortKey = columnSorting({ sortColumn: sort?.sortKey, sortDirection: sort?.sortDirection });
      const variables: queryVariables = {
        filters: getFilters(!!selectedRecordTypeDefinition!.isReferenceType),
        conditions: getCondition(),
        first: INITIAL_CHUNK_SIZE,
        orderBy: sortKey,
      };
      if (sortKey !== undefined) {
        const queryVariable = getQueryVariable(variables, !!selectedRecordTypeDefinition!.isReferenceType);
        const existingData = assetStates[querykey] as recordData;
        const isSameSort = assetSortState[querykey]?.sortKey === sortKey;
        const columnKey = sortKey !== '' ? sortKey.split(/(_ASC|_DESC)/)[0] : '';
        const storedColumnKey = assetSortState[querykey]?.columnKey;
        const doesDataExists = checkForExistingData(checkExsistingData, existingData);
        setInitialTableLoadState(querykey !== prevQueryKey.current);
        if (!doesDataExists || !isSameSort) {
          const data = await fetchRecordData(queries[querykey], queryVariable as unknown as VariablesRecord, querykey);
          if (!_.isEmpty(data)) {
            const modifiedData = extractDataFromResult((data as fetchedResult)[querykey].data, querykey);
            setAssetStates(prev => ({
              ...(clearExistingData ? {} : prev),
              [querykey]: modifiedData[querykey],
            }));
            setAllAssets(modifiedData[querykey].assetData);

            setAssetSortState(prev => ({
              ...prev,
              [querykey]: {
                sortKey,
                columnKey,
              },
            }));
          } else {
            const isSameColumn = storedColumnKey === columnKey;
            if (isSameColumn) {
              setAssetStates(prev => ({
                ...(clearExistingData ? {} : prev),
                [querykey]: {},
              }));
              setAssetSortState(prev => ({
                ...(clearExistingData ? {} : prev),
                [querykey]: { ...prev.querykey, sortKey: '' },
              }));
              setAllAssets([]);
            }
          }
          setIsDataLoading(false);
        } else {
          setAllAssets(existingData?.assetData as MapAssetType[]);
          setIsDataLoading(false);
        }
        setInitialTableLoadState(false);
        prevQueryKey.current = querykey;
      }
    }
  };

  const fetchNextDataSet = async (fetchAfter: boolean) => {
    if (isMapExtentEnabled || (drawFilter.apply && !_.isEmpty(drawFilter.features))) return;
    const querykey: string | undefined = mapAggregateDefinitions.find(def => def.name === selectedRecordType)?.queryKey;
    if (querykey) {
      const currentData = assetStates[querykey] as recordData;
      const hasFetchableData = () => {
        if (!fetchAfter) {
          if (currentData.pageInfo?.hasPreviousPage) {
            return currentData.pageInfo?.hasPreviousPage;
          }
        } else if (currentData.pageInfo?.hasNextPage) {
          return currentData.pageInfo?.hasNextPage;
        }
        return false;
      };

      if (currentData?.assetData && currentData.pageInfo && hasFetchableData()) {
        const startCursor = currentData?.assetData[0].cursor as string;
        const endCursor = _.last(currentData.assetData)?.cursor as string;
        const variable: queryVariables = {
          filters: getFilters(!!selectedRecordTypeDefinition?.isReferenceType),
          conditions: getCondition(),
          first: fetchAfter ? NEXT_CHUNK_SIZE : undefined,
          after: fetchAfter ? endCursor : undefined,
          orderBy: assetSortState[querykey]?.sortKey,
          before: fetchAfter ? undefined : startCursor,
          last: fetchAfter ? undefined : NEXT_CHUNK_SIZE,
        };
        const queryVariable = getQueryVariable(variable, !!selectedRecordTypeDefinition?.isReferenceType);
        const data = await fetchRecordData(queries[querykey], queryVariable as unknown as VariablesRecord, querykey);
        const modifiedData = extractDataFromResult((data as fetchedResult)[querykey].data, querykey);
        const fetchedAsset = modifiedData[querykey!].assetData;
        let newData = [...(currentData.assetData as MapAssetType[])];
        if (fetchAfter) {
          newData.splice(0, fetchedAsset.length);
          newData.push(...fetchedAsset);
        } else {
          newData.splice(newData.length - fetchedAsset.length, newData.length);
          newData.unshift(...fetchedAsset);
        }
        setAssetStates(prev => ({
          ...prev,
          [querykey]: {
            assetData: newData,
            pageInfo: modifiedData[querykey].pageInfo,
          },
        }));
        setAllAssets(newData);
        return fetchedAsset.length;
      }
      return 0;
    }
  };

  const updateScrollPosition = (recordType: string, index: number) => {
    const recordKey: string | undefined = mapAggregateDefinitions.find(def => def.name === recordType)?.queryKey;
    if (recordKey) {
      setAssetStates(prev =>
        prev[recordKey]
          ? {
              ...prev,
              [recordKey]: {
                assetData: prev[recordKey].assetData,
                pageInfo: prev[recordKey].pageInfo,
                scrollInfo: index,
              },
            }
          : prev
      );
    }
  };

  const updateAssetStates = (recordType: string, modifiedAssetData: MapAssetType[]) => {
    const regionMinLat = mapViewBoxRef.current?.minLat || -200;
    const regionMaxLat = mapViewBoxRef.current?.maxLat || 200;
    const regionMinLon = mapViewBoxRef.current?.minLon || -200;
    const regionMaxLon = mapViewBoxRef.current?.maxLon || 200;
    if (
      modifiedAssetData.find(a => {
        if (!mapViewBoxRef.current) {
          return true;
        }
        if (a.primaryLocation.type === 'Point') {
          return (
            regionMinLat < a.primaryLocation.coordinates[1] &&
            regionMaxLat > a.primaryLocation.coordinates[1] &&
            regionMinLon < a.primaryLocation.coordinates[0] &&
            regionMaxLon > a.primaryLocation.coordinates[0]
          );
        }
        const minLat = Math.min(...a.primaryLocation.coordinates.map(c => c[1])),
          minLong = Math.min(...a.primaryLocation.coordinates.map(c => c[0])),
          maxLat = Math.max(...a.primaryLocation.coordinates.map(c => c[1])),
          maxLong = Math.max(...a.primaryLocation.coordinates.map(c => c[0]));
        return regionMinLat < maxLat && regionMaxLat > minLat && regionMinLon < maxLong && regionMaxLon > minLong;
      })
    ) {
      setForceMapReload(true);
    }
    if (modifiedAssetData.find(a => cardIdsRef.current.includes(a.id))) {
      setRefresh(true);
    }
    setAssetStates(prev => {
      if (!prev[recordType]) {
        return prev;
      }
      let assetData: MapAssetType[] = [];
      const tempAssetData = prev[recordType].assetData;
      if (Array.isArray(tempAssetData)) {
        assetData = [...tempAssetData];
      } else if (typeof tempAssetData === 'object' && tempAssetData !== undefined) {
        assetData = Object.values(tempAssetData);
      }
      return {
        ...prev,
        [recordType]: {
          assetData: assetData.map(a => {
            const newData = modifiedAssetData.find(d => d.id === a.id);
            return newData || a;
          }),
          pageInfo: prev[recordType].pageInfo,
          scrollInfo: prev[recordType].scrollInfo,
        },
      };
    });
    setAllAssets((prev: MapAssetType[]) => {
      let foundChanges = false;
      const newData = prev.map(a => {
        const newData = modifiedAssetData.find(d => d.id === a.id);
        foundChanges = foundChanges || !!newData;
        return newData || a;
      });
      return foundChanges ? newData : prev;
    });
  };
  const update = async (recordType: string[], updatedProject: string) => {
    if (recordType.some(type => NOT_MAP_ASSETS_NAMES.some(name => type === name))) {
      refetchProjects();
    }
    if (!selectedProjects.includes(updatedProject)) {
      return;
    }
    const currentTIme = Date.now() - 30 * 1000;
    const sinceTime = new Date(currentTIme).toJSON();
    let allQueries: Record<string, DocumentNode> = {};
    recordType.map((record: string) => {
      const key = mapAggregateDefinitions.find(def => def.name === record)?.queryKey;
      if (key) {
        allQueries[key] = queries[key];
      }
    });
    const queryVariable = {
      filters: { projectId: projectsFilter, lastModelRefresh: { greaterThan: sinceTime } },
    };
    if (_.isEmpty(allQueries)) return;
    try {
      refetch(queryVariable, allQueries).then(data => {
        Object.keys(data as fetchedResult).map((recordType: string) => {
          const modifiedData = extractDataFromResult((data as fetchedResult)[recordType].data, recordType);
          updateAssetStates(recordType, modifiedData[recordType].assetData);
        });
      });
    } catch (error) {
      console.error('Error while fetching updated data', error);
    }
  };

  const deleteAssets = (deletedAssets: Record<string, string[]>) => {
    const deletedAssetTypes = Object.keys(deletedAssets);
    const allIds = deletedAssetTypes.reduce<string[]>((prev, recordType: string) => {
      return prev.concat(...deletedAssets[recordType]);
    }, []);
    if (cardIdsRef.current.find(id => allIds.includes(id))) {
      setRefresh(true);
    }
    setForceMapReload(true);
    deletedAssetTypes.map((recordType: string) => {
      const aggregateDefinition = mapAggregateDefinitions.find(def => def.name === recordType);
      if (aggregateDefinition?.queryKey) {
        setAssetStates(prev => {
          if (prev[aggregateDefinition.queryKey]?.assetData) {
            const assetData = [...(prev[aggregateDefinition.queryKey].assetData as MapAssetType[])];
            const modifiedData = assetData.filter(asset => !deletedAssets[recordType].includes(asset.id));
            return {
              ...prev,

              [aggregateDefinition.queryKey]: {
                assetData: modifiedData,
                pageInfo: prev[aggregateDefinition.queryKey].pageInfo,
                scrollInfo: prev[aggregateDefinition.queryKey].scrollInfo,
              },
            };
          }
          return prev;
        });
        if (aggregateDefinition.name === selectedRecordType) {
          setAllAssets(prev => {
            const modifiedData = prev.filter(asset => !deletedAssets[recordType].includes(asset.id));
            return modifiedData;
          });
        }
      }
    });
    fetchRecordTypeCount();
    if (deletedAssetTypes.some(type => NOT_MAP_ASSETS_NAMES.some(name => type === name))) {
      refetchProjects();
    }
  };
  const deleteAssetsById = (allIds: string[]) => {
    if (cardIdsRef.current.find(id => allIds.includes(id))) {
      setRefresh(true);
    }
    setForceMapReload(true);
    for (const aggregateDefinition of mapAggregateDefinitions) {
      if (aggregateDefinition?.queryKey) {
        setAssetStates(prev => {
          if (prev[aggregateDefinition.queryKey]?.assetData) {
            const assetData = [...(prev[aggregateDefinition.queryKey].assetData as MapAssetType[])];
            const modifiedData = assetData.filter(asset => !allIds.includes(asset.id));
            return {
              ...prev,

              [aggregateDefinition.queryKey]: {
                assetData: modifiedData,
                pageInfo: prev[aggregateDefinition.queryKey].pageInfo,
                scrollInfo: prev[aggregateDefinition.queryKey].scrollInfo,
              },
            };
          }
          return prev;
        });
        if (aggregateDefinition.name === selectedRecordType) {
          setAllAssets(prev => {
            const modifiedData = prev.filter(asset => !allIds.includes(asset.id));
            return modifiedData;
          });
        }
      }
    }
    fetchRecordTypeCount();
  };

  const fetchAssetsForFilter = async (field: string, limit?: number, offset?: number, searchText?: string) => {
    if (selectedRecordTypeDefinition) {
      const queryVariable = {
        selectedProjects,
      };
      const selectedField = selectedRecordTypeDefinition.propertyDefinitions.find(def => def.field === field);
      const query = FilterQuery(
        selectedRecordTypeDefinition,
        aggregateDefinitions,
        field,
        selectedField?.type || '',
        limit,
        offset,
        searchText
      );
      try {
        const fetchedData = await client.query({
          fetchPolicy: 'no-cache',
          query: query,
          variables: queryVariable,
        });

        if (_.isEmpty(fetchedData)) {
          return [];
        }
        return fetchedData.data.distinctValues.map((value: Record<string, string>) =>
          selectedField?.isRelationship ? value.label : value[field]
        );
      } catch (err) {
        console.error('Filter error', err);
        return [];
      }
    }
  };

  const refetchAll = () => {
    setAllAssets([]);
    setAssetStates(createWithAggregateNameObject(mapAggregateDefinitions, () => ({})));
    initialAssetsFetch(false);
  };

  const fetchNextForMultiSelect = async (cursor: string | undefined) => {
    const variables: queryVariables = {
      filters: getFilters(!!selectedRecordTypeDefinition?.isReferenceType),
      conditions: getCondition(),
      after: cursor,
      first: PAGE_SIZE_MULTISELECT,
    };
    const queryVariable = getQueryVariable(variables, !!selectedRecordTypeDefinition?.isReferenceType);
    const querykey: string | undefined = selectedRecordTypeDefinition?.queryKey;
    if (querykey) {
      const data = await fetchRecordData(queries[querykey], queryVariable as unknown as VariablesRecord, querykey);
      if (!_.isEmpty(data)) {
        const modifiedData = extractDataFromResult((data as fetchedResult)[querykey].data, querykey);
        let { hasNextPage } = (data as fetchedResult)[querykey].data.pageInfo;
        let cursor = _.last(modifiedData[querykey].assetData)?.cursor;
        return {
          nextHasNextPage: hasNextPage,
          nextCursor: cursor,
          moreData: modifiedData[querykey].assetData as AssetType[],
        };
      }
    }
    return { nextHasNextPage: false, nextCursor: '', moreData: [] };
  };

  const getAssetsForMultiSelect = async () => {
    if (selectedProjects.length === 0) {
      return [];
    }

    let {
      moreData: result,
      nextHasNextPage: hasNextPage,
      nextCursor: cursor,
    } = await fetchNextForMultiSelect(undefined);
    while (hasNextPage && cursor && result.length < MAX_ASSET_LIMIT_MULTISELECT) {
      const { moreData, nextHasNextPage, nextCursor } = await fetchNextForMultiSelect(cursor);
      result = result.concat(...moreData);
      hasNextPage = nextHasNextPage;
      cursor = nextCursor;
    }
    return result;
  };

  return {
    refetchAll,
    isDataLoading,
    assets,
    referenceTypeAssets,
    recordTypesData,
    fetchNextDataSet,
    updateScrollPosition,
    update,
    deleteAssets,
    deleteAssetsById,
    fetchAssetsForFilter,
    getSearchResults,
    getAssetsForMultiSelect,
  };
};
