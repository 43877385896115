import React, { useCallback } from 'react';
import { WebUIConfig, useConfig } from '@terragotech/gen5-shared-components';
import { useAggregates } from '../../contexts/AggregatesContext';
import { CheckboxesSection } from '../../components/MapRightDrawer/CheckboxesSection';
import { MapVisibilityIcon } from '../../components/MapRightDrawer/MapVisibilityIcon';

export const getSelectableAggregateDefinitions = (config: WebUIConfig) => {
  return config.aggregateDefinitions
    .filter(definition => !['Folder', 'Project'].includes(definition.name))
    .map(definition => definition);
};

export const useMapVisibilitySection = () => {
  const config = useConfig();
  const aggregateDefinitions = getSelectableAggregateDefinitions(config);
  const { setVisibleAggregateTypesNames, visibleAggregateTypesNames } = useAggregates();
  const mapVisibility = aggregateDefinitions.map(definition => visibleAggregateTypesNames.includes(definition.name));
  const toggleMapVisibility = useCallback(
    (value: string) => {
      const definition = aggregateDefinitions.find(definition => definition.plural === value);
      if (definition) {
        if (visibleAggregateTypesNames.includes(definition.name)) {
          setVisibleAggregateTypesNames(visibleAggregateTypesNames.filter(name => name !== definition.name));
        } else {
          setVisibleAggregateTypesNames([...visibleAggregateTypesNames, definition.name]);
        }
      }
    },
    [setVisibleAggregateTypesNames, visibleAggregateTypesNames]
  );

  const toggleAllMapVisibility = useCallback(
    (selectAll: boolean) => {
      if (selectAll) {
        setVisibleAggregateTypesNames(aggregateDefinitions.map(definition => definition.name));
      } else {
        setVisibleAggregateTypesNames([]);
      }
    },
    [setVisibleAggregateTypesNames, aggregateDefinitions]
  );

  const section = CheckboxesSection({
    title: 'Map Visibility',
    icon: <MapVisibilityIcon />,
    options: aggregateDefinitions.map(definition => definition.plural),
    values: mapVisibility,
    onToggle: toggleMapVisibility,
    showToggleAll: true,
    onToggleAll: toggleAllMapVisibility,
  });

  return { section };
};
