import React, { useContext } from 'react';
import TGAlertDialog from '../TGAlertDialog';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface DeleteDialogProps {
  open: boolean;
  resourceNameKey: string;
  toDelete: string[];
  onDelete: () => void;
  onCancel: () => void;
}

export default function DeleteDialog({ open, resourceNameKey, toDelete, onDelete, onCancel }: DeleteDialogProps) {
  const isPlural = toDelete.length > 1;
  const { translate } = useContext(LanguageContext);
  const name = translate(isPlural ? resourceNameKey + 's' : resourceNameKey);
  return (
    <TGAlertDialog
      open={open}
      title={`${translate('Delete $__NAME__$', { NAME: name })}`}
      onCancel={onCancel}
      onConfirm={onDelete}
      confirmButtonTitle={translate('Delete')}>
      {`${translate(`Are you sure you want to delete ${isPlural ? 'those' : 'this'} $__NAME__$?`, { NAME: name })}`}:
      <ul>
        {toDelete.map(name => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </TGAlertDialog>
  );
}
