import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import AssetCardBackdrop from './AssetCardBackdrop';
import AssetCardContainer from './AssetCardContainer';
import { useState } from 'react';
import { useCommonStyles } from '../../../styles/commonStyles';

const PositionedAssetCardContainer = (props: {
  assetId: string;
  mapEditor?: boolean;
  asset?: MapAssetType;
  relative?: boolean;
  onFormSubmit?: () => void;
  onCloseClick?: () => void;
  fetchingAssets?: boolean;
  isMainMap?: boolean;
}) => {
  const { relative, isMainMap, ...rest } = props;
  const [cardLoaded, setCardLoaded] = useState(false);
  const commonClasses = useCommonStyles();
  return !props.mapEditor ? (
    <AssetCardBackdrop mapEditor={props.mapEditor} classes={cardLoaded ? commonClasses.show : commonClasses.hide} isMainMap={isMainMap}>
      <AssetCardContainer
        {...rest}
        elevation
        refreshCard={() => {
          setCardLoaded(true);
        }}
      />
    </AssetCardBackdrop>
  ) : null;
};

export default PositionedAssetCardContainer;
