import React, { useState, useMemo, useCallback } from 'react';
import { TableData, ValueType } from './useTable';

type FiltersType = Record<string, string | string[]>;

const TableDataContext = React.createContext({
  setData: (data: readonly TableData[]) => {},
  setFilter: (key: string | null, value: string | string[], clear?: boolean) => {},
  filteredData: [] as readonly TableData[],
  data: [] as readonly TableData[],
  filters: {} as FiltersType,
  partialFilters: {} as FiltersType,
  setPartialFilter: (key: string, value: string | string[], clear?: boolean) => { },
  globalFilter: '' as string | string[],
  filtersApplied: false,
  setFiltersApplied: (val: boolean) => { },
});

const filterDataByKey = (key: string, value: string | string[], data: readonly TableData[]) => {
  if (Array.isArray(value)) {
    return data.filter(row =>
      value.reduce<boolean>(
        (result, pattern) =>
          result ||
          row[key].toString() === pattern ||
          (Array.isArray(row[key])
            ? (row[key] as ValueType[]).some(x => x.toString() === pattern)
            : key === 'adminPermissions' // Special case to avoid pattern search.
            ? row[key]
                .toString()
                .split(', ')
                .some(x => x === pattern)
            : false),
        false
      )
    );
  } else {
    const pattern = value.toLowerCase();
    return data.filter(row => row[key].toString().toLowerCase().includes(pattern));
  }
};

const filterUsingGlobalFilter = (data: readonly TableData[], globalFilter: string) =>
  globalFilter === ''
    ? data
    : data.filter(row =>
        Object.values(row).reduce<boolean>(
          (result, value) => result || value.toString().toLowerCase().includes(globalFilter.toLowerCase()),
          false
        )
      );

const filterData = (
  data: readonly TableData[],
  globalFilter: string,
  filters: FiltersType,
  filtersApplied: boolean
) => {
  if (!filtersApplied) {
    return data;
  }
  return Object.entries(filters).reduce<readonly TableData[]>(
    (result, filterRecord) => filterDataByKey(filterRecord[0], filterRecord[1], result),
    filterUsingGlobalFilter(data, globalFilter)
  );
};

const TableDataProvider = (props: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState({} as FiltersType);
  const [partialFilters, setPartialFilters] = useState({} as FiltersType);
  const [globalFilter, setGlobalFilter] = useState('' as string);
  const [data, setData] = useState([] as readonly TableData[]);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const setFilter = useCallback(
    (key: string | null, value: string | string[], clear?: boolean) => {
      if (key === null) {
        if (Array.isArray(value)) {
          throw new Error('Global filter must be string');
        }
        setGlobalFilter(value);
      } else {
        setFilters(prevFilters => {
          const newFilters = { ...prevFilters };
          if (clear) {
            return Object.fromEntries(Object.entries(newFilters).filter(([currKey]) => currKey !== key));
          }
          else if (Array.isArray(value) && value.length === 0) {
            newFilters[key] = [];
          } else {
            newFilters[key] = value;
          }
          return newFilters;
        });
      }
    },
    [setFilters, setGlobalFilter]
  );

  const setPartialFilter = useCallback((key: string, value: string | string[], clear?: boolean) => {
    setPartialFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (clear) {
        return Object.fromEntries(Object.entries(newFilters).filter(([currKey]) => currKey !== key));
      }
      else if (Array.isArray(value) && value.length === 0) {
        newFilters[key] = [];
      } else {
        newFilters[key] = value;
      }
      return newFilters;
    });
  },
    [setPartialFilters]);

  const filteredData = useMemo(() => filterData(data, globalFilter, filters, filtersApplied), [
    data,
    filters,
    globalFilter,
    filtersApplied,
  ]);

  const value = useMemo(
    () => ({ setFilter, setPartialFilter, setData, filteredData, data, filters, partialFilters, globalFilter, setFiltersApplied, filtersApplied }),
    [setFilter, filteredData, data, filters, partialFilters, setPartialFilter, globalFilter, setFiltersApplied, filtersApplied]
  );

  return (
    <TableDataContext.Provider value={value} {...props}>
      {props.children}
    </TableDataContext.Provider>
  );
};

export { TableDataContext, TableDataProvider };
