import React, { useContext, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import LegalDisclaimerView from './LegalDisclaimerView';
import LoginCodeView from './LoginCodeView';
import LoginButtonView from './LoginButtonView';
import LoginEmailView from './LoginEmailView';
import { useConfig, ServerPublicMetaContext } from '@terragotech/gen5-shared-components';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT, setDisclaimerState } from '../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';

interface LoginFormProps {
  attemptLogin: () => void;
  code: string;
  codeNeeded?: boolean;
  emailNeeded?: boolean;
  errorMessage?: string;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  secondButtonCB?: () => void;
  selectEmailLogin: () => void;
  selectLogin: () => void;
  submitAuthCode: () => void;
  email: string;
  hasOpenDisclaimer: (val: boolean) => void;
  validateUser?: boolean;
  validateformUser?: () => void;
  isCompleteLogin?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const {
    attemptLogin,
    code,
    codeNeeded,
    emailNeeded,
    errorMessage,
    onCodeChange,
    onPasswordChange,
    onEmailChange,
    password,
    secondButtonCB,
    selectEmailLogin,
    submitAuthCode,
    email,
    hasOpenDisclaimer,
    validateUser,
    validateformUser,
    selectLogin,
    isCompleteLogin,
  } = props;
  const { samlProviders, legalDisclaimer } = useContext(ServerPublicMetaContext);
  const [disclaimerNeeded, setDisclaimerNeeded] = useState(false);
  const classes = useStyles();
  const uiConfig = useConfig();
  const showLogin = uiConfig.enabledFeatures?.showLogin || 'both';
  const { isMobileView } = useContext(AssetsDashboardContext);
  const onDisclaimerAccepted = () => {
    selectEmailLogin();
    setDisclaimerState(true);
  };
  const styles = {
    width: disclaimerNeeded ? '100%' : 344,
  };
  useEffect(() => {
    if (validateUser && !emailNeeded) {
      setDisclaimerNeeded(true);
      hasOpenDisclaimer(false);
    }
  }, [validateUser, emailNeeded, hasOpenDisclaimer]);
  return (
    <form
      className={classes.auth}
      style={isMobileView && disclaimerNeeded ? styles : {}}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isCompleteLogin) {
          validateformUser && validateformUser();
          return;
        }
        if (codeNeeded) {
          submitAuthCode();
          return;
        }
        if (emailNeeded) {
          attemptLogin();
          return;
        }
      }}>
      {disclaimerNeeded && <LegalDisclaimerView onDisclaimerAccepted={onDisclaimerAccepted} />}
      {samlProviders && !disclaimerNeeded && !codeNeeded && (
        <LoginButtonView samlProviders={samlProviders} selectEmailLogin={selectEmailLogin} showLogin={showLogin} />
      )}
      {!disclaimerNeeded && (
        <>
          <div className={classes.seperator}>
            <hr className={classes.line} />
            <span className={classes.orText}>{'Or'}</span>
            <hr className={classes.line} />
          </div>
          <LoginEmailView
            errorMessage={errorMessage}
            fields={[
              {
                label: 'Email',
                value: email,
                onChange: onEmailChange,
                placeholder: 'username@example.com',
              },
              {
                label: 'Password',
                value: password,
                onChange: onPasswordChange,
                type: 'password',
                placeholder: 'Password',
              },
            ]}
            onClick={() => {
              if (legalDisclaimer) {
                selectLogin();
              } else {
                selectEmailLogin();
              }
            }}
          />
        </>
      )}
      {codeNeeded && (
        <LoginCodeView
          errorMessage={errorMessage || ''}
          fields={[{ label: 'Code', value: code, onChange: onCodeChange }]}
          handleResend={secondButtonCB}
        />
      )}
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    auth: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: 400,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: 344,
      },
    },
    seperator: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 15,
      marginTop: 18,
      marginBottom: 14,
    },
    line: {
      display: 'block',
      width: '100%',
      height: 1,
      border: 0,
      background: colors.black35,
    },
    orText: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.black54,
      lineHeight: 'normal',
    },
  })
);

export default LoginForm;
