import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, createStyles, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { colors } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface PasswordChangeViewProps {
  title: string;
  okText?: string;
  onOkPress?: (password: string) => void;
  // This will only be called with the currently valid password, or with undefined which denotes that there is no password
  onPasswordChange: (password?: string) => void;
  classesStyle?: string;
}

export interface ParsedRules {
  validRegexPatterns?: Array<string>;
  validText?: Array<string>;
}

interface ReqsObj {
  [index: string]: boolean | number;
  length: number;
  requireNumber: boolean;
  requireUppercase: boolean;
  requireLowercase: boolean;
  requireSpecChar: boolean;
}
type PwPoliciesObj<T> = {
  // this is handy, checks the keys on the generic and only allows indexing with keys on the generic
  [Policy in keyof T]: { description: string; tester: (testStr: string) => boolean };
};
// TODO: config me
export const passwordReqs: ReqsObj = {
  length: 10,
  requireNumber: true,
  requireUppercase: true,
  requireLowercase: true,
  requireSpecChar: true,
};
const passwordLength = (testStr: string) => {
  return testStr.length > passwordReqs.length - 1;
};
const hasNumber = (testStr: string) => {
  const lowercaseTest = new RegExp('[0-9]');
  return lowercaseTest.test(testStr);
};
const hasUppercase = (testStr: string) => {
  const uppercaseTest = new RegExp('[A-Z]');
  return uppercaseTest.test(testStr);
};
const hasLowercase = (testStr: string) => {
  const lowercaseTest = new RegExp('[a-z]');
  return lowercaseTest.test(testStr);
};
const hasSpecChar = (testStr: string) => {
  // special character list from Cognito, ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `, = + and - are allowed but do not count towards requirement. Spaces are not allowed. Min is 6 chars, max is 99
  const specCharTest = /[\^$*.[\]{}()?"!@#%&\\/,><':;|_~`]/;
  return specCharTest.test(testStr);
};

export const Policies: PwPoliciesObj<ReqsObj> = {
  length: {
    description: `Password should be at least ${passwordReqs.length} characters`,
    tester: passwordLength,
  },
  requireNumber: {
    description: 'Must contain at least 1 number',
    tester: hasNumber,
  },
  requireUppercase: {
    description: 'Must contain at least 1 uppercase character',
    tester: hasUppercase,
  },
  requireLowercase: {
    description: 'Must contain at least 1 lowercase character',
    tester: hasLowercase,
  },
  requireSpecChar: {
    description: 'Must contain at least 1 special character',
    tester: hasSpecChar,
  },
};

const PasswordChangeView: React.FC<PasswordChangeViewProps> = props => {
  const { onPasswordChange, classesStyle } = props;
  const classes = useStyles();
  const [parsedPasswordRules, setParsedPasswordRules] = useState<ParsedRules>();

  useEffect(() => {
    try {
      const stringPasswordRules: string | null = localStorage.getItem('passwordRules');
      const objectPasswordRules: ParsedRules = JSON.parse(stringPasswordRules || '');
      setParsedPasswordRules(objectPasswordRules);
    } catch (e) {}
  }, []);

  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [newError, setNewError] = useState(false);
  const [repeatedError, setRepeatedError] = useState(false);

  const handleClickShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowRepeatedPassword = () => {
    setShowRepeatedPassword(!showRepeatedPassword);
  };
  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasErrors: boolean | undefined = parsedPasswordRules?.validRegexPatterns?.reduce<boolean>(
      (existingErrors: boolean, element: string) => {
        const tempRegExp = new RegExp(element);
        let result: boolean = false;
        if (tempRegExp) {
          result = tempRegExp.test(e.target.value);
        } else {
          result = true;
        }
        return existingErrors || !result;
      },
      false
    );
    setNewError(hasErrors || false);
    setNewPassword(e.target.value);
  };

  useEffect(() => {
    setRepeatedError(newPassword !== repeatedPassword);
    const isValid = newPassword !== '' && newPassword === repeatedPassword && !newError && !repeatedError;
    onPasswordChange(isValid ? newPassword : undefined);
  }, [newPassword, repeatedPassword, newError, repeatedError, onPasswordChange]);

  const parsedRules = useMemo(() => {
    if (parsedPasswordRules?.validText) {
      const spans = parsedPasswordRules.validText.map((ruleText: string, index: number) => {
        let regExpString = parsedPasswordRules?.validRegexPatterns?.[index];
        if (!ruleText || !regExpString || typeof regExpString !== 'string') return;
        let ruleRegExp = new RegExp(regExpString);
        const getClassName = () => {
          if (newPassword === '') {
            return `${classes.normalText}`;
          } else if (!ruleRegExp.test(newPassword)) {
            return `${classes.errorText}`;
          }
          return '';
        };
        return (
          <span className={classes.iconText} key={index}>
            <>
              {newPassword !== '' ? (
                ruleRegExp.test(newPassword) ? (
                  <Check className={classes.check} />
                ) : (
                  <Close className={classes.close} />
                )
              ) : (
                <span className={classes.seperator}>-</span>
              )}
              <span className={getClassName()}>{ruleText}</span>
            </>
          </span>
        );
      });
      return spans || <></>;
    } else {
      return <></>;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedPasswordRules, newPassword]);

  return (
    <div className={classes.rootContainer}>
      <TextField
        required
        className={classesStyle}
        placeholder={'New Password'}
        value={newPassword}
        onChange={handleNewPassword}
        error={newError}
        helperText={<>{parsedRules}</>}
        FormHelperTextProps={{ style: styles.formHelperTextStyles }}
        type={showNewPassword ? 'text' : 'password'}
        variant="outlined"
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={'toggle password visibility'}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showNewPassword ? (
                  <FontAwesomeIcon icon={faEye as IconProp} className={classes.icon} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash as IconProp} className={classes.icon} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        required
        className={classesStyle}
        placeholder={'Re-enter New Password'}
        value={repeatedPassword}
        onChange={e => setRepeatedPassword(e.target.value)}
        error={repeatedError}
        helperText={<>{repeatedError ? 'Password must match.' : ''}</>}
        FormHelperTextProps={{ style: styles.formHelperTextStylesMb }}
        type={showRepeatedPassword ? 'text' : 'password'}
        variant="outlined"
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={'toggle password visibility'}
                onClick={handleClickShowRepeatedPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showRepeatedPassword ? (
                  <FontAwesomeIcon icon={faEye as IconProp} className={classes.icon} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash as IconProp} className={classes.icon} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const styles = {
  formHelperTextStyles: { margin: '0px', padding: '15px 0px 9px 0px' },
  formHelperTextStylesMb: { marginLeft: 0 },
};
const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
    iconText: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      fontWeight: 400,
      color: colors.black75,
      margin: 0,
      lineHeight: 'normal',
      paddingBottom: 6,
      gap: 4,
    },
    normalText: {
      color: colors.black54,
    },
    errorText: {
      color: colors.red,
      opacity: 0.75,
    },
    check: {
      color: colors.greenTick,
      height: 15,
      width: 15,
    },
    close: {
      color: colors.red,
      opacity: 1,
      height: 15,
      width: 15,
    },
    icon: {
      height: 18,
      width: 22.391,
      color: colors.black35,
      padding: 0,
    },
    rootContainer: { paddingTop: 12, paddingBottom: 20 },
    seperator: {
      marginRight: 2,
    },
  })
);

export default PasswordChangeView;
