import { useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import ImportStatusViewer from './ImportStatusViewer';
import { ErrorWarningType } from './FileToView';
import SuccessErrorsAndWarningsDialog from './SuccessErrorsAndWarningsDialog';
import { colors } from '../../styles/theme';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface IndividualImportResult {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  filename: string;
  metadata?: object;
  data?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
}
const useStyles = makeStyles(theme => ({
  defaultLightGrey: {
    backgroundColor: colors.ivoryMist,
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerDarkGrey: {
    backgroundColor: colors.whisper,
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  columnDarkGrey: {
    backgroundColor: colors.lotion,
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  warningColor: {
    color: colors.vividOrange,
  },
  errorColor: {
    color: colors.red,
  },
  textStyle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 13,
    overflow: 'hidden',
    height: '100%',
  },
  cellWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const WarningTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.peachPuff,
    color: colors.black0,
    maxWidth: 220,
    fontSize: '14px',
    fontWeight: 300,
    border: `1px solid ${colors.warmYellow}`,
    borderRadius: 0,
    marginTop: 0,
    marginRight: -150,
  },
}))(Tooltip);

const ErrorTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.linen,
    color: colors.black0,
    maxWidth: 220,
    fontSize: '14px',
    fontWeight: 300,
    border: `1px solid ${colors.coral}`,
    borderRadius: 0,
    marginTop: 0,
    marginRight: -150,
  },
}))(Tooltip);

type ObjectStringKey = { [key: string]: any };

interface Props {
  sheetNumber: number;
  index: number;
  data: ObjectStringKey;
  tableHeaders: {
    label: string;
    key: string;
  }[];
  columnKey: string;
  columnName: string;
  statusColumnKey: string;
}

const SheetTableRow = (props: Props) => {
  const { sheetNumber, index, data, tableHeaders, columnKey, columnName, statusColumnKey } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);

  const openSuccess = () => {
    setSuccessErrorsOrWarnings('Success');
    setDialogOpen(true);
  };
  const openWarnings = () => {
    setSuccessErrorsOrWarnings('Warnings');
    setDialogOpen(true);
  };
  const openErrors = () => {
    setSuccessErrorsOrWarnings('Errors');
    setDialogOpen(true);
  };

  const [successErrorsOrWarnings, setSuccessErrorsOrWarnings] = useState<'Success' | 'Errors' | 'Warnings'>('Errors');
  const [dialogOpen, setDialogOpen] = useState(false);

  function ImportCellViewer() {
    let warningColor = false;
    let errorColor = false;
    let message: JSX.Element[] = [];
    let messageIndex = 0;
    if (data.warnings) {
      data.warnings?.forEach((warningObj: ErrorWarningType) => {
        if (columnName === warningObj?.column_name) {
          warningColor = true;
          message[messageIndex] = <div>{warningObj?.message}</div>;
          messageIndex++;
        }
      });
    }
    if (data.errors) {
      data.errors?.forEach((errorObj: ErrorWarningType) => {
        if (columnName === errorObj?.column_name) {
          errorColor = true;
          message[messageIndex] = <div>{errorObj?.message}</div>;
          messageIndex++;
        }
      });
    }
    let messageDiv: JSX.Element = <div className={classes.messageContainer}>{message}</div>;
    return (
      <div key={`importCellViewer-${sheetNumber}-${index}-${columnKey}`}>
        {message && warningColor && !errorColor && (
          <WarningTooltip title={messageDiv} placement="bottom-start">
            <Typography className={clsx(classes.warningColor, classes.textStyle)}>
              {translate(data[columnKey])}
            </Typography>
          </WarningTooltip>
        )}
        {message && errorColor && (
          <ErrorTooltip title={messageDiv} placement="bottom-start">
            <Typography className={clsx(classes.errorColor, classes.textStyle)}>
              {translate(data[columnKey])}
            </Typography>
          </ErrorTooltip>
        )}
        {message.length === 0 && <Typography className={classes.textStyle}>{translate(data[columnKey])}</Typography>}
      </div>
    );
  }

  return (
    <div className={classes.cellWrapper}>
      {columnKey === statusColumnKey ? (
        <ImportStatusViewer
          successFlag={data.successFlag}
          warningFlag={data.warningFlag}
          errorFlag={data.errorFlag}
          openSuccess={openSuccess}
          openWarnings={openWarnings}
          openErrors={openErrors}
        />
      ) : (
        <ImportCellViewer />
      )}
      <SuccessErrorsAndWarningsDialog
        open={dialogOpen}
        title={successErrorsOrWarnings}
        rowSuccess={tableHeaders.map(header => header.label)}
        rowWarnings={data.warnings}
        rowErrors={data.errors}
        onConfirm={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
        successErrorsOrWarnings={successErrorsOrWarnings}
      />
    </div>
  );
};

export default SheetTableRow;
