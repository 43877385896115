import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faLayerGroup, faLanguage } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import { useMapTypeSection } from '../../../../hooks/sectionsHooks/useMapTypeSection';
import { colors } from '../../../../styles/theme';
import { useSections } from '../../../../hooks/useSections';
import { MOBILE_BREAKPOINT } from '../../../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../../../contexts/assetsDashboardContext';
import { LanguageContext } from '../../../../contexts/LanguageContext/languageContext';

const POPOVER_WIDTH_WEB_VIEW = 312;
const POPOVER_WIDTH_MOBILE_VIEW = 287;
const POPOVER_HEIGHT_MOBILE_VIEW = 197;
const POPOVER_HEIGHT_WEB_VIEW = 318;
const PopupHeader = ({
  handleClose,
  title,
  isMapType,
}: {
  title: string;
  handleClose: () => void;
  isMapType: boolean;
}) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <div className={`${classes.popoverHeader} ${isMapType ? classes.mapTypePopOverHeader : ''}`}>
      <Typography className={classes.mapHeader}>{translate(title)}</Typography>
      <IconButton onClick={handleClose} className={classes.closeRoot}>
        <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

const MapComponentControl = () => {
  const classes = useStyles();
  const { section } = useMapTypeSection();
  const { items } = useSections();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [activeType, setActiveType] = React.useState<string>();
  const { isMobileView } = React.useContext(AssetsDashboardContext);
  const { translate } = useContext(LanguageContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveType(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isMapTypeActive = activeType === 'MapType';
  const isMapComponentActive = activeType === 'MapComponent';
  const paperMenuProps = {
    style: {
      width: isMapTypeActive ? POPOVER_WIDTH_WEB_VIEW : POPOVER_WIDTH_MOBILE_VIEW,
      minHeight: isMapTypeActive ? POPOVER_HEIGHT_WEB_VIEW : POPOVER_HEIGHT_MOBILE_VIEW,
    },
  };
  useEffect(() => {
    const domElements: Array<HTMLElement> = [];
    const touchHandler = (ev: Event, preventDefault: boolean = true) => {
      // @ts-ignore
      ev?.target?.click?.();
      if (preventDefault) ev?.preventDefault();
    };
    const mapTypeElement = document
      .getElementsByClassName(classes.mapCenterControls)?.[0]
      ?.getElementsByTagName('button')?.[0];
    if (mapTypeElement) {
      domElements.push(mapTypeElement);
      mapTypeElement.addEventListener('touchend', touchHandler);
    }
    const mapCompElement = document
      .getElementsByClassName(classes.mapCenterControls)?.[0]
      ?.getElementsByTagName('button')?.[1];
    if (mapCompElement) {
      domElements.push(mapCompElement);
      mapCompElement.addEventListener('touchend', touchHandler);
    }
    return () => {
      domElements.forEach(domelement => {
        domelement.removeEventListener('touchend', touchHandler);
      });
    };
  }, []);
  const expectedTitles = [
    'Map Labels',
    'Card View',
    'Pin Clustering',
  ];
  return (
    <>
      <div className={classes.mapCenterControls}>
        <div aria-label="Map Type" className={[classes.icon, classes.icon2].join(' ')}>
          <IconButton
            disableRipple
            className={[classes.IconBut, isMapTypeActive && classes.active].join(' ')}
            onClick={e => {
              setActiveType('MapType');
              handleClick(e);
            }}
          >
            <FontAwesomeIcon icon={faMap as IconProp} className={classes.iconSize} />
          </IconButton>
        </div>
        <div aria-label="Map Component" className={[classes.icon, classes.icon2, classes.defIcon].join(' ')}>
          <IconButton
            disableRipple
            className={[classes.IconBut, isMapComponentActive && classes.active].join(' ')}
            onClick={e => {
              setActiveType('MapComponent');
              handleClick(e);
            }}
          >
            <FontAwesomeIcon icon={faLayerGroup as IconProp} className={classes.iconSize} />
          </IconButton>
        </div>
      </div>
      {anchorEl && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={paperMenuProps}
          classes={{ paper: isMobileView ? [classes.menuRoot, classes.menuRootMob].join(' ') : classes.menuRoot }}
        >
          {isMapTypeActive ? (
            <>
              <PopupHeader handleClose={handleClose} title={'Map Type'} isMapType={isMapTypeActive} />
              <div>{section.content}</div>
            </>
          ) : (
            <>
              <PopupHeader handleClose={handleClose} title={'Map Components'} isMapType={isMapTypeActive} />
              <Box className={classes.body}>
                {items.map(item => {
                  if (item.title !== undefined && expectedTitles.includes(item.title)) {
                    return (
                      <div key={item.title} style={styles.root}>
                        <div>{item.content}</div>
                        {item.title && (
                          <Typography variant="h3" className={classes.mapSubHeader}>
                            {translate(item.title)}
                          </Typography>
                        )}
                      </div>
                    );
                  }
                })}
              </Box>
            </>
          )}
        </Popover>
      )}
    </>
  );
};

const styles = {
  root: {
    display: 'flex',
    marginLeft: 22,
    alignItems: 'center',
    marginBottom: 19,
  },
};

const useStyles = makeStyles(theme => ({
  mapCenterControls: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 191,
    marginRight: 0,
    backgroundColor: colors.white,
    borderRadius: 5,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: 188,
    },
  },
  iconActiveColor: {
    color: theme.palette.primary.main,
  },
  IconBut: {
    '&:hover': {
      backgroundColor: `${colors.black05} !important`,
    },
  },
  iconSize: {
    fontSize: 20,
  },
  popoverHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 17,
    paddingLeft: 24,
    paddingRight: 14,
  },
  mapTypePopOverHeader: {
    paddingTop: 22,
    paddingLeft: 24,
    paddingRight: 16,
  },
  header: {
    marginBottom: 22,
  },
  mapHeader: {
    color: colors.black0,
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  mapSubHeader: {
    color: colors.black0,
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
  },
  closeRoot: {
    width: 24,
    height: 24,
    position: 'relative',
    top: -2.5,
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
    cursor: 'pointer',
  },
  icon: {
    backgroundColor: colors.ghostWhite,
    opacity: '0.95',
    transition: 'background-color 0.16s ease-out 0s',
    cursor: 'pointer',
    borderWidth: '0px 0px 0px',
    borderTopStyle: 'initial',
    borderRightStyle: 'initial',
    borderBottomStyle: 'none',
    borderLeftStyle: 'initial',
    borderTopColor: 'initial',
    borderRightColor: 'initial',
    borderLeftColor: 'initial',
    borderImage: 'initial',
    height: '34px !important',
    width: '34px !important',
    backgroundPosition: '0px 0px',
    backgroundSize: '26px 260px',
    outline: 0,
    color: colors.black54,
  },
  defIcon: {
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important',
    borderBottomLeftRadius: '5px !important',
    borderBottomRightRadius: '5px !important',
  },
  icon2: {
    background: colors.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    boxShadow: `0px 2px 4px 0px ${colors.black15} !important`,
    border: 'none !important',
    marginLeft: 0,
    pointerEvents: 'auto',
    right: '10px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: 12,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      '& > button': {
        height: '26px !important',
        width: '26px !important',
        '& svg': {
          pointerEvents: 'none',
        },
      },
    },
  },
  root: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: 159,
    },
  },
  menuRoot: {
    marginLeft: -7,
    borderRadius: 5,
    background: colors.white,
    boxShadow: `0px 4px 4px 0px ${colors.black15}`,
    transform: 'translate(9px, 5px) !important',
    minHeight: '50px !important',
  },
  menuRootMob: {
    transform: 'none !important',
  },
  active: {
    color: theme.palette.primary.main,
  },
  body: {
    marginTop: 22,
  },
}));

export default MapComponentControl;
