import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import WorkflowPage from '../components/Workflow/WorkflowPage';
import { AssetType } from '../contexts/AggregatesContext/types';
import { FormParentValueProvider } from '../contexts/formParentValueContext';
import { CommandAction, useConfig, getAggregateDataFields, graphqlToAssetTransform } from '@terragotech/gen5-shared-components'
import { LanguageContext } from '../contexts/LanguageContext/languageContext';
import { useContext } from 'react';

export interface Workflow {
  label: string;
  action: CommandAction;
  onFormSubmit?: ()=>void
}

interface WorkflowPageContainerProps {
  aggregateType: string;
  aggregateId: string | string[];
  workflow: Workflow;
  onCancelPress: () => void;
  onDonePress: () => void;
  creationAction?: boolean;
}

interface RelationType {
  id: string;
  label: string;
}

export interface AssetDataEntry extends AssetType {
  [relationField: string]: RelationType | string | object | number;
  id: string;
  label: string;
  assetAttributes: Record<string, string>;
  modifiedBy: string;
  modifiedDateTime: string;
}

export interface AssetDataQueryResult {
  [index: string]: Array<AssetDataEntry>;
}

const WorkflowPageContainer: React.FunctionComponent<WorkflowPageContainerProps> = (props) => {
  const { aggregateType, aggregateId, onDonePress, onCancelPress, workflow, creationAction } = props;
  const classes = useStyle();
  const aggregateIds = Array.isArray(aggregateId) ? aggregateId : [aggregateId];
  const { translate } = useContext(LanguageContext);
  const configContext = useConfig();
  const aggregateDefinition = configContext.aggregateDefinitions.find((d) => d.name === aggregateType);
  const aggregateTypeName = aggregateDefinition?.queryKey || '';

  const { data, loading, error, refetch } = useQuery<AssetDataQueryResult>(
    gql`
      query AssetData($aggregateIds: [UUID!]!) {
        ${aggregateTypeName}(filter: {id: {in: $aggregateIds}}) {
          id
          label
          assetAttributes
          modifiedDateTime
          ${getAggregateDataFields(aggregateDefinition)}
        }
      }
    `,
    {
      variables: { aggregateIds },
      fetchPolicy: "cache-and-network"
    },
  );

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  let record: AssetDataEntry | AssetDataEntry[] | undefined;
  if (data && data[aggregateTypeName])
    if (Array.isArray(aggregateId)) {
      record = data[aggregateTypeName].map((agg) =>
        graphqlToAssetTransform({ flattenRelationships: true, result: agg, aggDef: aggregateDefinition })
      );
    } else {
      record = graphqlToAssetTransform({
        flattenRelationships: true,
        result: data[aggregateTypeName][0],
        aggDef: aggregateDefinition,
      });
    }
  if (error || (!creationAction && !record)) {
    return <div>{translate('Failed to load asset')}</div>;
  }

  return (
    <FormParentValueProvider>
      <WorkflowPage
        openWorkflow={true}
        target={record}
        onCancelPress={onCancelPress}
        onDonePress={() => {
          refetch();
          onDonePress();
        }}
        aggregateType={aggregateType}
        command={workflow.action}
        title={workflow.label}
        creationAction={creationAction}
      />
    </FormParentValueProvider>
  );
};

const useStyle = makeStyles(() => ({
  loadingContainer: {
    margin: 'auto',
  },
}));
export default WorkflowPageContainer;
