import { createStyles, Typography, withStyles, WithStyles, Theme, Box } from '@material-ui/core';
import React, { FunctionComponent, useContext } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { colors } from '../../../styles/theme';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import ColumnFilterListElement, { ColumnFilterListElementProps } from './ColumnFilterListElement';
import _ from 'lodash';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

interface ColumnFilterTopListProps extends WithStyles<typeof styles> {
  isSelected: ColumnFilterListElementProps['isSelected'];
  setIsSelected: ColumnFilterListElementProps['setIsSelected'];
  columns: ReadonlyArray<Column<AssetType>>;
  id: string;
  togglePinned: (key: string) => void;
}

const ColumnFilterTopList: FunctionComponent<ColumnFilterTopListProps> = props => {
  const { classes, columns, isSelected, setIsSelected, togglePinned, id } = props;
  const { translate } = useContext(LanguageContext);
  return (
    <Droppable droppableId={id}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <div className={classes.stickyItems}>
            <Typography variant="body1" className={classes.stickyText}>
              <>{translate('Pinned')}</>
            </Typography>

            <div className={classes.pinnedContainer}>
              {columns.length === 0 ? (
                <Typography variant="body1" className={classes.emptyDisplayText}>
                  <>{translate('Nothing is sticky yet')}</>
                </Typography>
              ) : (
                columns.map<React.ReactElement>((column, index: number) => (
                  <ColumnFilterListElement
                    column={column}
                    index={index}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    togglePinned={togglePinned}
                    key={column.key}
                  />
                ))
              )}
            </div>
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    stickyItems: {
      backgroundColor: colors.soapStone,
      minHeight: 65,
      border: `1px solid ${colors.black05}`,
      borderRadius: 3,
      padding: '5px 0',
      position: 'relative',
    },
    stickyText: {
      position: 'relative',
      fontSize: 15,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      lineHeight: 'normal',
      color: colors.black0,
      textTransform: 'capitalize',
      padding: '10px 20px',
    },
    emptyDisplayText: {
      position: 'relative',
      fontSize: 16,
      color: colors.grayDescription,
    },
    pinnedContainer: {
      background: colors.soapStone,
      padding: '11px 18px',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
  });

export default withStyles(styles)(ColumnFilterTopList);
