import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface AlertDialogProps {
  open: boolean;
  onProceed: () => void;
  onCancel: () => void;
}

export default function ProceedConfirmationDialog({ open, onProceed, onCancel }: AlertDialogProps) {
  const { translate } = useContext(LanguageContext);
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">{translate('Changes will be lost')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('Are you sure you want to proceed?')}
            <br />
            {translate('All changes will be lost')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {translate('Cancel')}
          </Button>
          <Button onClick={onProceed} color="primary" autoFocus>
            {translate('Proceed')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
