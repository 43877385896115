import { useContext } from 'react';
import { MapTypeProp, mapTypeSection } from '../../components/MapRightDrawer/MapTypeSection';
import { MapStyleContext, MapTypes } from '../../contexts/mapStyle';
import { useConfig } from '@terragotech/gen5-shared-components';
import Light from '../../../src/components/Map/img/Light.png';
import Dark from '../../../src/components/Map/img/dark.png';
import Saterllite from '../../../src/components/Map/img/Saterllite.png';
import Streets from '../../../src/components/Map/img/Streets.png';
import NearMap from '../../../src/components/Map/img/nearMap.png';
import HybridSatellite from '../../../src/components/Map/img/HybridSatellite.png';
import { useTheme } from '@material-ui/core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export const useMapTypeSection = () => {
  const { setMapType: setMapStyle, mapType } = useContext(MapStyleContext);
  const { integrations } = useConfig();
  const { translate } = useContext(LanguageContext);
  const theme = useTheme();
  const defaultMapStyles: ReadonlyArray<{ label: string; value: MapTypes; img: string }> = [
    { label: 'Light', value: 'light', img: Light },
    { label: 'Dark', value: 'dark', img: Dark },
    { label: 'Satellite', value: 'satellite', img: Saterllite },
    { label: 'Hybrid Satellite', value: 'satellite-hybrid', img: HybridSatellite },
    { label: 'Streets', value: 'streets', img: Streets },
  ];
  const mapStylesPairs: ReadonlyArray<MapTypeProp> = integrations?.nearMaps
    ? [...defaultMapStyles, { label: 'NearMap', value: 'nearmaps', img: NearMap }]
    : defaultMapStyles;
  const setMapType = (label: string) => {
    const value = mapStylesPairs.find(x => x.label === label)?.value;
    if (value) {
      setMapStyle(value);
    }
  };
  const currentMapTypeLabel = mapStylesPairs.find(x => x.value === mapType)?.label;
  const section = mapTypeSection({
    allowedTypes: mapStylesPairs,
    currentType: currentMapTypeLabel ?? '',
    onChange: setMapType,
    theme,
    translate: translate,
  });
  return { section };
};
