import { useConfig, usePhotoViewer } from '@terragotech/gen5-shared-components';
import { DATETIME_TOKEN_CONVERSION, getDateFormat, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterContext } from '../../contexts/FilterContext/filterContext';
import { CellRendererProps, Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import { useFileViewer } from '../../hooks/useFileViewer';
import { ValueType } from '../../hooks/useTable';
import ColumnSearch from '../AssetTable/ColumnSearch';
import FileCellRenderer from '../AssetTable/FileCellRenderer';
import PhotoCellRenderer from '../AssetTable/PhotoCellRenderer';
import FluidStyledTable from '../StyledTable/FluidStyledTable';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export type ColumnDef = {
  field: string;
  type: string;
  headerName: string;
};

export type TGListFieldProps = {
  value: {
    values: Record<string, any>[];
  };
  height?: string;
  columns?: ColumnDef[];
};

type ArrayElementType<T extends any[]> = T extends (infer U)[] ? U : never;

const TGListField: React.FC<TGListFieldProps> = ({ columns: inputColumns, value = { values: [] }, height }) => {
  type inferredValueType = ArrayElementType<typeof value.values>;

  const { defaultDateTimeFormat } = useConfig();
  const photoViewer = usePhotoViewer();
  const fileViewer = useFileViewer();
  const { filterState, resetAllFilters } = useContext(FilterContext);
  const [loadData, setLoadData] = useState<boolean>(true);
  const { translate } = useContext(LanguageContext);
  const history = useHistory();

  useEffect(() => resetAllFilters(), []);

  const filteredData = React.useMemo(() => {
    let fullData = [...value?.values || []];

    Object.keys(filterState).forEach(filterKey => {
      const searchTerm = filterState[filterKey];
      const field = filterKey.replace('-QuickFilter', '');
      const currType = inputColumns?.find(column => column.field === field)?.type;

      if (searchTerm) {
        fullData = fullData.filter(item => {
          const fieldValue = item[field];
          if (!fieldValue) return false;
          switch (currType) {
            case 'DateTime':
            case 'Date':
              if (!Array.isArray(searchTerm)) break;
              const currDateValue = new Date(fieldValue);
              const [firstDate, secondDate] = searchTerm.map(dateStr => new Date(dateStr));
              return currDateValue >= firstDate && currDateValue <= secondDate;
            default:
              const fieldStr =
                currType === 'Link'
                  ? (fieldValue as { link: string; label: string }).label.toLowerCase()
                  : String(fieldValue).toLowerCase();
              if (Array.isArray(searchTerm)) {
                return searchTerm.includes(String(fieldValue));
              }
              return fieldStr.includes(String(searchTerm).toLowerCase());
          }
          return false;
        });
      }
    });

    return fullData;
  }, [filterState, inputColumns, value?.values]);

  const rendererWrapper = useCallback(
    <P extends unknown>(
      { row }: CellRendererProps<inferredValueType>,
      Component: (props: P & { value: ValueType; data: inferredValueType }) => JSX.Element,
      rendererProps: any,
      key: string
    ) => {
      return row[key] ? (
        <div style={{ width: '95%', paddingRight: 10, overflow: 'hidden' }}>
          <Component {...rendererProps} value={row[key]} data={row} />
        </div>
      ) : (
        <div />
      );
    },
    []
  );

  const diffColumns = React.useMemo(() => {
    const handlePhotoClick = (images: any) => photoViewer.open({ images, editMode: false });
    const handleFileClick = (images: any) => fileViewer.open({ images, editMode: false });
    const fetchFilterFields = (field: string, searchText?: string) => {
      const fullData = [...value?.values || []];
      const fields: string[] = [];

      fullData.forEach(row => {
        const toAdd = row[field] as string;
        if (toAdd && !fields.includes(toAdd)) {
          fields.push(toAdd);
        }
      });

      if (searchText) {
        return fields.filter(f => f.toLowerCase().includes(searchText.toLowerCase()));
      }

      return fields;
    };
    const handleLinkClick = (link: string) => {
      if (link) {
        history.push(link);
      }
    };

    return {
      columns:
        inputColumns?.map(currColumn => {
          const outColumn: Column<inferredValueType> = {
            name: currColumn.headerName,
            key: currColumn.field,
            editable: true,
            sortable: true,
            dataType: currColumn.type,
          };

          switch (currColumn.type) {
            case 'DateTime':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => (
                    <div>
                      {props.value &&
                        moment(String(props.value)).format(
                          getDateTimeFormat(
                            defaultDateTimeFormat?.dateFormatType,
                            defaultDateTimeFormat?.dateFormat,
                            defaultDateTimeFormat?.dateSeperator,
                            defaultDateTimeFormat?.timeFormat,
                            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                          )
                        )}
                    </div>
                  ),
                  {},
                  currColumn.field
                );
              break;
            case 'Date':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => (
                    <div>
                      {props.value &&
                        moment
                          .utc(String(props.value))
                          .format(
                            getDateFormat(
                              defaultDateTimeFormat?.dateFormatType,
                              defaultDateTimeFormat?.dateFormat,
                              defaultDateTimeFormat?.dateSeperator
                            )
                          )}
                    </div>
                  ),
                  {},
                  currColumn.field
                );
              break;
            case 'PhotoCollection':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => (
                    <PhotoCellRenderer
                      images={props.value}
                      handlePhotoClick={() => handlePhotoClick(props.value)}
                      editable={false}
                    />
                  ),
                  { handlePhotoClick },
                  currColumn.field
                );
              break;
            case 'FileCollection':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => (
                    <FileCellRenderer
                      editable={!!outColumn.editable}
                      images={props.value}
                      handleFileClick={() => handleFileClick(props.value)}
                    />
                  ),
                  { handleFileClick },
                  currColumn.field
                );
              break;
            case 'JSON':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => <div>{JSON.stringify(props.value)}</div>,
                  {},
                  currColumn.field
                );
              break;
            case 'Link':
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => {
                    const { link, label } = props.value as { link: string; label: string };
                    return (
                      <div
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        onClick={() => handleLinkClick(link)}
                      >
                        {label}
                      </div>
                    );
                  },
                  {},
                  currColumn.field
                );
              break;
            default:
              outColumn.cellRenderer = formatterProps =>
                rendererWrapper(
                  formatterProps,
                  props => (
                    <div>
                      <>{props.value}</>
                    </div>
                  ),
                  {},
                  currColumn.field
                );
              break;
          }

          outColumn.filterRenderer = ({ column }: FilterRendererProps<inferredValueType>) => {
            return (
              <ColumnSearch
                displayName={column.name}
                field={column.key}
                type={column.dataType}
                isNonAsset={true}
                customFetchFields={fetchFilterFields}
              />
            );
          };

          return outColumn;
        }) ?? [],
      fields: fetchFilterFields,
    };
  }, [inputColumns, filteredData, value?.values]);

  useEffect(() => setLoadData(false), []);

  return (
    <FluidStyledTable
      isAssetTable={false}
      columns={diffColumns.columns}
      data={filteredData}
      height={Number(height) ?? 500}
      filterRenderer={({ column }) => (
        <ColumnSearch
          displayName={column.name}
          field={column.key}
          type={column.dataType}
          isNonAsset={true}
          customFetchFields={diffColumns.fields}
        />
      )}
      onColumnsMove={() => {}}
      emptyView={() => (
        <div
          style={{
            width: '100%',
            height: `calc(100% - 78px)`,
            position: 'fixed',
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loadData ? translate('Loading Data...') : translate('No Data to be shown')}
        </div>
      )}
    />
  );
};

export default TGListField;
