import React, { useContext } from 'react';
import { FormGroup, Checkbox, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import { TreeItem, TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import _ from 'lodash';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const useTreeItemStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&:not([aria-expanded]) > $content > .MuiTreeItem-iconContainer': {
        display: 'none',
      },
      '&[aria-expanded] > $content > $label > $labelRoot': {
        padding: theme.spacing(0.875, 0),
      },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: 500,
      '$expanded > &': {
        fontWeight: 400,
      },
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: 0,
      },
    },
    expanded: {},
    selected: {},
    label: {
      marginLeft: -4,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
);

export interface CheckboxOption {
  id: string;
  label: string;
  subOptions?: CheckboxOption[];
}

export interface StyledTreeItemProps {
  option: CheckboxOption;
  values: Record<string, boolean>;
  onToggle: (value: string, checked: boolean) => void;
}

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { option, values, onToggle } = props;
  const { translate } = useContext(LanguageContext);
  // TODO: Handle checkboxes for parents to sync all children. Also prevent parent expand/collapse when toggling.
  return (
    <TreeItem
      nodeId={option.id}
      label={
        <div className={classes.labelRoot}>
          {!option.subOptions?.length && (
            <Checkbox
              checked={values[option.id]}
              onChange={(e, checked) => onToggle(option.id, checked)}
              color="primary"
              style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 0 }}
            />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {translate(option.label)}
          </Typography>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}>
      {option.subOptions?.length &&
        option.subOptions?.map((so, j) => <StyledTreeItem key={so.id} {...props} option={so} />)}
    </TreeItem>
  );
}

export interface CheckboxTreeSectionProps {
  icon: React.ReactNode;
  title: string;
  options: CheckboxOption[];
  values: StyledTreeItemProps['values'];
  onToggle: StyledTreeItemProps['onToggle'];
}

export const checkboxTreeSection = (props: CheckboxTreeSectionProps): DrawerItem => {
  const optionlessProps = _.omit(props, 'options');

  return {
    icon: props.icon,
    closeMenuOnClick: false,
    iconMenu: (
      <List>
        <ListItem>
          <FormGroup>
            <TreeView defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />}>
              {props.options.map((o, i) => (
                <StyledTreeItem key={o.id} {...optionlessProps} option={o} />
              ))}
            </TreeView>
          </FormGroup>
        </ListItem>
      </List>
    ),
    title: props.title,
    content: (
      <FormGroup>
        <TreeView defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />}>
          {props.options.map((o, i) => (
            <StyledTreeItem key={o.id} {...optionlessProps} option={o} />
          ))}
        </TreeView>
      </FormGroup>
    ),
  };
};
