import { useContext, useEffect, useState } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { AssetType } from '../contexts/AggregatesContext/types';
import { useActionsExecutor, ActionButton } from './useActionsExecutor';
import { useDataMapping } from './useDataMapping';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { ConditionalButtonMapping } from '@terragotech/gen5-config-lib';
import _ from 'lodash';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useAggregates } from '../contexts/AggregatesContext';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export type ActionsMenuButton = ActionButton;

interface HookProps {
  closeMenu: () => void;
  isClosed: boolean;
  target: AssetType | Array<AssetType>;
}
interface UseActionsType {
  singleActions: Array<ActionsMenuButton>;
  statusText: string;
}

export interface UseConditionalActionButton extends ActionsMenuButton {
  color?: any;
  canBePrimary?: boolean;
  icon?: string;
  label?: string;
  state: 'loading' | 'disabled' | 'hidden' | 'enabled';
  disabledMessage?: string;
  action?: any;
}
export interface ConditionalButtonResults {
  isDisabled: boolean;
  disabledMessage: string;
  isHidden: boolean;
  button: UseConditionalActionButton;
}

const buildButtonWithState = (
  conditionalButton: UseConditionalActionButton,
  state: UseConditionalActionButton['state']
): UseConditionalActionButton => {
  return {
    color: conditionalButton.color,
    state: state,
    icon: conditionalButton.icon,
    label: conditionalButton.label,
    onClick: conditionalButton.onClick,
    canBePrimary: conditionalButton.canBePrimary,
    action: conditionalButton.action,
    disabledMessage: state === 'disabled' ? conditionalButton.disabledMessage : '',
  };
};

const getStateFromMapResults = (result: ConditionalButtonMapping): UseConditionalActionButton['state'] => {
  return result.isHidden ? 'hidden' : result?.isDisabled ? 'disabled' : 'enabled';
};

const useConditionalTableActionsMenu = ({ closeMenu, isClosed, target }: HookProps): UseActionsType => {
  const { selectedRecordTypeDefinition } = useRecordType();
  const { processAction } = useActionsExecutor();
  const dataMapping = useDataMapping();
  const { functionDefinitions } = useConfig();
  const { assets } = useAggregates();
  const { translate } = useContext(LanguageContext);

  const [buttons, setButtons] = useState<UseConditionalActionButton[]>([]);
  useEffect(() => {
    let unmounted = false;
    if (isClosed) {
      setButtons([]);
      return;
    }
    const buttonPromises = selectedRecordTypeDefinition?.tableActions?.map<Promise<any>>(action => {
      const button = processAction(action, assets, (target as AssetType).id, () => closeMenu && closeMenu());
      if (action?.conditionalMap) {
        const accessors = dataMapping(target as AssetType).accessors;
        return mapScenarios.BUTTON_STATE.evaluate(action.conditionalMap, accessors, functionDefinitions).then(
          //This makes sure all values are properly defined, even though they are technically optional

          result => {
            return { ...button, ...result };
          }
        );
      }
      return Promise.resolve(button);
    });

    Promise.all(buttonPromises || []).then(buttonResults => {
      const newState = _.compact(buttonResults).reduce<UseConditionalActionButton[]>((acc, buttonMappingResult) => {
        const state = getStateFromMapResults(buttonMappingResult);
        return [...acc, buildButtonWithState(buttonMappingResult, state)];
      }, []);
      if (!unmounted && !_.isEqual(newState, buttons)) {
        setButtons(newState);
      }
      return () => {
        unmounted = true;
      };
    });
  }, [closeMenu, isClosed, processAction, selectedRecordTypeDefinition, target, assets]);

  const selectedCount = Array.isArray(target) ? target.length : 1;
  const statusText = translate('$__COUNT__$ $__RECORD_TYPE__$ Selected', {
    COUNT: selectedCount,
    RECORD_TYPE: selectedCount > 1 ? selectedRecordTypeDefinition.plural : selectedRecordTypeDefinition.singular,
  });
  return {
    singleActions: buttons,
    statusText,
  };
};

export default useConditionalTableActionsMenu;
