import React from 'react';
import { Column } from '../hooks/tableHooks/useColumns';
import { TableData } from '../hooks/useTable';

const gearColumn: <DataType extends TableData>(
  cellRenderer: Column<DataType>['cellRenderer'],
  filterRenderer: Column<DataType>['filterRenderer'],
  headerComponent: () => React.ReactElement
) => Column<DataType> = (cellRenderer, filterRenderer, headerComponent) => {
  return {
    key: 'gear',
    name: 'Action',
    minWidth: 30,
    width: 50,
    resizable: false,
    filterRenderer: args => (
      <div style={styles.filterContainer}>
        {filterRenderer && typeof filterRenderer === 'function' && filterRenderer(args)}
      </div>
    ),
    editable: false,
    sticky: true,
    sortable: false,
    headerRenderer: () => headerComponent(),
    cellRenderer: cellRenderer
      ? args => {
          return args?.row?.id ? <div style={styles.container}>{cellRenderer(args)}</div> : <></>;
        }
      : undefined,
  };
};
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
};
export default gearColumn;
