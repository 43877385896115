import { useContext, useMemo } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { AssetType } from '../contexts/AggregatesContext/types';
import { useActionsExecutor, ActionButton } from './useActionsExecutor';
import { useAggregates } from '../contexts/AggregatesContext';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export interface ActionsMultiSelectMenuButton extends ActionButton {
  conditionalMap?: any;
  onClick: (event?: any, arg0?: AssetType | null | undefined) => void | any;
  limitMaxOccurrence?: number;
}

interface HookProps {
  closeMenu: () => void;
  target: Array<AssetType> | (() => Array<AssetType>);
  actionCallback?: any;
}
interface UseActionsType {
  statusText: string;
  multiSelectActions: Array<ActionsMultiSelectMenuButton>;
  selectedCount: number;
}

const useMultiSelectActionsMenu = ({ closeMenu, target, actionCallback }: HookProps): UseActionsType => {
  const { selectedRecordTypeDefinition } = useRecordType();
  const { processAction } = useActionsExecutor();
  const { assets } = useAggregates();
  const { translate } = useContext(LanguageContext);
  //TODO: Add in a context consumer here for showing the workflow modal
  const multiSelectActions: Array<ActionsMultiSelectMenuButton> = useMemo(() => {
    const buttons: Array<ActionsMultiSelectMenuButton> = [];
    selectedRecordTypeDefinition?.multiSelectActions?.forEach(action => {
      const calcValues = () => {
        const values = typeof target === 'function' ? target() : target;
        return values;
      };
      //@ts-ignore
      const commandLimitMaxOccurrence: number | undefined = action?.action?.limitMaxOccurrence;
      action = { ...action, limitMaxOccurrence: commandLimitMaxOccurrence };
      const button = processAction(
        action,
        assets,
        calcValues,
        () => closeMenu && closeMenu()
      ) as ActionsMultiSelectMenuButton;
      if (button) {
        button.conditionalMap = action.conditionalMap;
        button.limitMaxOccurrence = action.limitMaxOccurrence;
        if (actionCallback) {
          let buttonCopy = { ...button };
          buttonCopy.onClick = () => {
            actionCallback(button);
          };
          buttons.push(buttonCopy);
        } else {
          buttons.push(button);
        }
      }
    });
    return buttons;
  }, [closeMenu, processAction, selectedRecordTypeDefinition, target, assets]);
  const selectedCount = Array.isArray(target) ? target.length : 1;
  const statusText = translate('$__COUNT__$ $__RECORD_TYPE__$ Selected', {
    COUNT: selectedCount,
    RECORD_TYPE: selectedCount > 1 ? selectedRecordTypeDefinition.plural : selectedRecordTypeDefinition.singular,
  });
  return {
    statusText,
    multiSelectActions,
    selectedCount,
  };
};

export default useMultiSelectActionsMenu;
