import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Slide,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { colors, trimTrailingSlash, useConfig } from '@terragotech/gen5-shared-components';
import { MAP_SERVICE_TYPE } from '@terragotech/gen5-shared-utilities';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useAggregates } from '../contexts/AggregatesContext';
import { ArcGisMapServerLegendResponse, MapServiceType } from '../contexts/AggregatesContext/types';
import SwipeableTabs, { SwipeableTabPanel } from './Common/SwipeableTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { colors as themeColors } from '../styles/theme';
import { DEFAULT_SYMBOL_OPTION } from '../utils/utilityHelper';
import { useRecordType } from '../contexts/recordTypeContext';
import useRouteParams from './Common/useRouteParams';
import LegendPopover from './MapLegend';
import { isEqual } from 'lodash';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';
import clsx from 'clsx';

export const LEGEND_WIDTH = 145;
const ICON_PIN_SIZE = 20;
const LEGEND_MIN_HEIGHT = 268;
const LAYER_MIN_HEIGHT = 200;
const ASSETDETAIL_MIN_HEIGHT = 253;
const POPOVER_MIN_HEIGHT = 365;
const POPOVER_WIDTH = 320;
const LAYER_ITEM_HORIZONTAL_PADDING = 16;
const HEADER_HORIZONTAL_PADDING = 28;

interface StyleProps {
  isAssetDetailsOpen: boolean;
  selectedTabIndex: number;
  isImgLoading: boolean;
}

const useStyles = makeStyles<Theme,StyleProps>(theme =>({
    legendWrapper: {
      height: '100%',
      boxShadow: `0px 2px ${themeColors.black10}`,
      maxWidth: LEGEND_WIDTH,
    },
    text: {
      margin: 0,
      textAlign: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      color: themeColors.black75,
      whiteSpace: 'nowrap',
    },
    legendListItem: {
      height: 30,
      display: 'flex',
      justifyContent: 'left',
      paddingTop: 7,
      zIndex: 1,
      alignItems: 'center',
      width: `calc(100% - ${LAYER_ITEM_HORIZONTAL_PADDING}px)`,
      paddingLeft: 16,
    },
    icon: {},
    legendList: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      zIndex: 4,
      overflowY: 'auto',
    },
    iconPin: {
      width: ICON_PIN_SIZE,
      height: ICON_PIN_SIZE,
      alignSelf: 'center',
      padding: 5,
    },
    assetsStatus: {
      marginLeft: 8,
    },
    arrow: {
      color: themeColors.black54,
      position: 'relative',
      left: 5,
    },
    legendLine: {
      width: 26,
      height: 0,
      margin: 2,
    },
    legendHeader: {
      padding: '17px 0',
      fontWeight: 600,
      textAlign: 'center',
      width: `calc(100% - ${HEADER_HORIZONTAL_PADDING}px)`,
      paddingRight: 46,
    },
    legendPopover: {
      '& > div.MuiPopover-paper': {
        maxWidth: POPOVER_WIDTH,
        marginTop: -5,
      },
    },
    menuRoot: {
      boxShadow: `0px 1px 4px 2px ${themeColors.black25}`,
    },
    legendItemIndented: {
      marginLeft: 16,
    },
    legendImage: {
      padding: 8,
    },
    progressLabel: {
      color: theme.palette.grey[600],
      padding: '0px 5px',
      marginLeft: 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    legendBoarderSymbolsOnly: {
      boxShadow: `0px 2px 4px -1px ${themeColors.black20}, 0px 4px 5px 0px ${themeColors.black14}, 0px 1px 10px 0px ${themeColors.black12}`,
      padding: 14,
      fontSize: 14,
    },
    legendButton: {
      zIndex: 12,
      display: 'flex',
      padding: '8px 12px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
      background: colors.white,
      borderRadius: 5,
      boxShadow: `0px 2px 4px 0px ${themeColors.black15}`,
      height: 38,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: themeColors.white,
      },
    },
    closeRoot: {
      width: 24,
      height: 24,
    },
    closeIcon: {
      fontSize: 20,
      color: themeColors.black54,
    },
    popoverHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      padding: '15px 10px 14px 18px',
    },
    mapHeader: {
      color: themeColors.black0,
      fontSize: 18,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    listHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      height: 50,
    },
    divider: {
      backgroundColor: colors.black10,
    },
    emptyLegendContainer: {
      height: LEGEND_MIN_HEIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyLegendText: {
      color: colors.black54,
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    assetDetailLegendButton: {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    legendStyles: props => ({
      height: 'auto',
      maxHeight: props.isAssetDetailsOpen
        ? ASSETDETAIL_MIN_HEIGHT
        : isEqual(props.selectedTabIndex, 0)
        ? LEGEND_MIN_HEIGHT
        : LAYER_MIN_HEIGHT,
    }),
    imageContainer: props =>({
      display: !props.isImgLoading ? 'none' : 'inherit'
   }),
   emptyLayers: {
    padding: 0,
  },
  })
);
export interface MapServiceLegendFlatListItem {
  id: string;
  parentId?: string;
  label: string;
  legendUri?: string;
  legendData?: ArcGisMapServerLegendResponse['layers'];
}

export type Symbol = { name: string; symbolKey: string };
export type SymbolComponentProps = { symbol: Symbol; index: number };
export type Base64IconProps = {
  contentType: string;
  imageData: string;
  label: string;
  indent?: boolean;
  index: number;
};

interface LegendProps {
  symbols: Array<Symbol>;
  symbolsOnly?: boolean;
}

const Legend: React.FunctionComponent<LegendProps> = ({ symbols, symbolsOnly }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  function handleLegendClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }
  const { mapServices, visibleMapServiceKeys } = useAggregates();
  const { translate } = useContext(LanguageContext);
  const scrollToLocation = useRef<HTMLUListElement | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isImgLoading, setIsImgLoading] = useState(true);
  const config = useConfig();
  const { selectedRecordType } = useRecordType();
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType,
  });
  const classes = useStyles({isAssetDetailsOpen,selectedTabIndex,isImgLoading})

  const [arcGisLegends, setArcGisLegends] = useState<Record<string, ArcGisMapServerLegendResponse>>({});
  const arcGisMapServices = useMemo(
    () => mapServices.filter(s => s.serviceType === MAP_SERVICE_TYPE.ArcGIS),
    [mapServices]
  );
  const MAP_LAYERS_LEGEND_TAB = 1;

  useEffect(() => {
    const fetchLegends = async () => {
      const newLegends: typeof arcGisLegends = {};
      let updated = false;
      await Promise.all(
        arcGisMapServices.map(async s => {
          if (!arcGisLegends[s.id]) {
            const response = await fetch(
              `${trimTrailingSlash(s.serviceUri)}/legend?f=json&size=${ICON_PIN_SIZE},${ICON_PIN_SIZE}`
            );
            const data = response.ok && (await response.json());
            if (data && data.layers && data.layers[0].legend) {
              newLegends[s.id] = data;
              updated = true;
            }
          }
        })
      );

      if (updated) {
        setArcGisLegends(prevState => ({ ...prevState, ...newLegends }));
      }
    };

    fetchLegends().catch(console.error);
  }, [arcGisMapServices, setArcGisLegends]);

  const mapServicesWithLegends = useMemo(() => {
    const hasLegend = (serviceId: string, layer: MapServiceType['layers'][number]) =>
      !!layer.layerLegendUri ||
      arcGisLegends[serviceId]?.layers.some(ld => layer.layerName.split(',').some(ln => ln === `${ld.layerId}`));
    return mapServices
      .filter(
        s =>
          visibleMapServiceKeys.some(k => k === s.id) ||
          s.layers.some(l => hasLegend(s.id, l) && visibleMapServiceKeys.some(k => k === `${s.id}-${l.layerName}`))
      )
      .map(({ layers, ...rest }) => ({
        layers: layers
          .filter(l => hasLegend(rest.id, l) && visibleMapServiceKeys.some(k => k === `${rest.id}-${l.layerName}`))
          .map(l => ({ ...l, id: `${rest.id}-${l.layerName}` })),
        ...rest,
      }));
  }, [mapServices, visibleMapServiceKeys, arcGisLegends]);

  const mapServiceLegendsFlatList = useMemo(() => {
    const flatList: MapServiceLegendFlatListItem[] = [];
    mapServicesWithLegends.forEach(s => {
      flatList.push({ id: s.id, parentId: undefined, label: s.label });
      s.layers.forEach(l => {
        const layerItem = { id: l.id, parentId: s.id, label: l.layerTitle ?? l.layerName };
        flatList.push(layerItem);
        if (l.layerLegendUri) {
          flatList.push({ ...layerItem, parentId: l.id, legendUri: l.layerLegendUri });
        } else if (s.serviceType === MAP_SERVICE_TYPE.ArcGIS && arcGisLegends[s.id]) {
          flatList.push({
            ...layerItem,
            parentId: l.id,
            legendData: arcGisLegends[s.id].layers.filter(ld =>
              l.layerName.split(',').some(ln => ln === `${ld.layerId}`)
            ),
          });
        }
      });
    });
    return flatList;
  }, [mapServicesWithLegends]);

  const [selectedMapServiceLegend, setSelectedMapServiceLegend] = React.useState<MapServiceLegendFlatListItem>();

  useEffect(() => {
    if (!mapServiceLegendsFlatList.some(x => x.id === selectedMapServiceLegend?.id)) {
      setSelectedMapServiceLegend(undefined);
    }
  }, [mapServiceLegendsFlatList]);

  const handleChangeSelectedMapServiceLegend = useCallback(
    (selected?: MapServiceLegendFlatListItem) => {
      if (!selected && selectedMapServiceLegend?.parentId) {
        const parent = mapServiceLegendsFlatList.find(x => x.id === selectedMapServiceLegend.parentId);
        setSelectedMapServiceLegend(parent);
      } else {
        setSelectedMapServiceLegend(selected);
      }
      scrollToLocation.current?.firstElementChild?.scrollIntoView();
      setIsImgLoading(true);
    },
    [mapServiceLegendsFlatList, selectedMapServiceLegend, setSelectedMapServiceLegend]
  );

  const handleLegendClose = useCallback(() => {
    setAnchorEl(null);
    // setSelectedMapServiceLegend(undefined); // Uncomment to reset selected Map Service Legend when closing legend popup.
  }, [setAnchorEl, setSelectedMapServiceLegend]);

  const Symbol = ({ symbol, index }: SymbolComponentProps) => (
    <div className={classes.legendListItem} key={index}>
      <img
        src={getSvgImageString(symbol.symbolKey, DEFAULT_SYMBOL_OPTION)}
        height={64}
        alt="iconPin"
        className={classes.iconPin}
      />
      <Typography variant="h4" className={classes.assetsStatus}>
        {translate(symbol.name)}
      </Typography>
    </div>
  );

  const Line = ({ symbol, index }: SymbolComponentProps) => {
    const symbolParts = symbol.symbolKey.split('_');
    return (
      <div className={classes.legendListItem} key={index}>
        <div
          className={classes.legendLine}
          style={{
            borderTopStyle: symbolParts[1] as 'solid' | 'dashed',
            borderTopWidth: `${symbolParts[2]}px`,
            borderTopColor: `#${symbolParts[3]}`,
          }}
        />
        <Typography variant="h4" className={classes.assetsStatus}>
          {translate(symbol.name)}
        </Typography>
      </div>
    );
  };

  const Base64Icon = ({ contentType, imageData, label, indent, index }: Base64IconProps) => (
    <div className={classes.legendListItem} key={index}>
      <img
        src={`data:${contentType};base64, ${imageData}`}
        height={64}
        alt={`${translate('Legend image for $__LABEL__$', { LABEL: label })}`}
        className={`${classes.iconPin} ${indent ? classes.legendItemIndented : ''}`}
      />
      <Typography variant="h4" className={classes.assetsStatus}>
        {translate(label)}
      </Typography>
    </div>
  );
  const isLayersEmpty = selectedTabIndex === MAP_LAYERS_LEGEND_TAB && mapServicesWithLegends.length === 0;

  const symbolLegend = (
    <div className={clsx(classes.legendList, classes.legendStyles, isLayersEmpty && classes.emptyLayers)} >
      {symbols.map((symbol: Symbol, index: number) => {
        const isSymbol = symbol.symbolKey.startsWith('symbol');
        const isLine = symbol.symbolKey.startsWith('line');
        if (isSymbol) return <Symbol symbol={symbol} index={index} key={index} />;
        if (isLine) return <Line symbol={symbol} index={index} key={index} />;
        return null;
      })}
    </div>
  );
  const MapLegendButton = () => {
    return (
      <Button
        onClick={anchorEl ? handleLegendClose : handleLegendClick}
        className={clsx(classes.legendButton, isAssetDetailsOpen && classes.assetDetailLegendButton)}>
        <>
          <Typography variant="h4" className={classes.title}>
            <>{translate('Map Legend')}</>
            {anchorEl ? (
              <FontAwesomeIcon icon={faAngleUp as IconProp} className={classes.arrow} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown as IconProp} className={classes.arrow} />
            )}
          </Typography>
        </>
      </Button>
    );
  };

  return (
    <Slide direction="left" in>
      <div className={classes.legendWrapper}>
        {isAssetDetailsOpen && Boolean(anchorEl) ? (
          <>
            <LegendPopover
              handleLegendClose={handleLegendClose}
              symbolLegend={symbolLegend}
              symbolsOnly={symbolsOnly as boolean}
              classes={classes}
            />
            {MapLegendButton()}
          </>
        ) : (
          <>
            {MapLegendButton()}
            <Popover
              id="legend"
              open={Boolean(anchorEl)}
              onClose={handleLegendClose}
              anchorOrigin={{ vertical: 'top', horizontal: isAssetDetailsOpen ? 'left' : 'center' }}
              anchorEl={anchorEl}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              PaperProps={{
                style: { minHeight: POPOVER_MIN_HEIGHT },
              }}
              className={classes.legendPopover}
              classes={{ paper: classes.menuRoot }}>
              <div className={classes.popoverHeader}>
                <Typography className={classes.mapHeader}>
                  <>{translate('Map Legend')}</>
                </Typography>
                <IconButton onClick={handleLegendClose} className={classes.closeRoot}>
                  <FontAwesomeIcon icon={faClose} className={classes.closeIcon} />
                </IconButton>
              </div>
              {!symbolsOnly && (
                <SwipeableTabs
                  hideTabs={!config.integrations?.mapServices}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}>
                  <SwipeableTabPanel label={translate('Symbol')}>{symbolLegend}</SwipeableTabPanel>
                  <SwipeableTabPanel
                    label={translate('Layers')}
                    hideTab={!config.integrations?.mapServices}>
                    {mapServicesWithLegends.length === 0 && (
                      <Box className={classes.emptyLegendContainer}>
                        <Typography className={classes.emptyLegendText}>
                        {translate('No legend information available for currently selected layers.')}
                        </Typography>
                      </Box>
                    )}
                    {selectedMapServiceLegend?.id && (
                      <>
                        <Box className={classes.listHeader}>
                          <IconButton size="small" edge="start" onClick={() => handleChangeSelectedMapServiceLegend()}>
                            <ChevronLeft />
                          </IconButton>
                          <Typography variant="h4" className={classes.legendHeader}>
                          {translate(selectedMapServiceLegend.label)}
                          </Typography>
                        </Box>
                        <Divider className={classes.divider} />
                      </>
                    )}
                    <List ref={scrollToLocation} className={clsx(classes.legendList, classes.legendStyles, isLayersEmpty && classes.emptyLayers)}>
                      {mapServiceLegendsFlatList
                        .filter(msl => msl.parentId === selectedMapServiceLegend?.id)
                        .map(msl =>
                          msl.legendUri ? (
                            <React.Fragment key={`${msl.id}-legendUri`}>
                              <div
                                className={clsx(classes.legendList, classes.legendStyles, classes.imageContainer)}>
                                <img
                                  src={msl.legendUri}
                                  onLoad={e => setIsImgLoading(false)}
                                  alt={`Legend image for ${msl.label}`}
                                  className={classes.legendImage}
                                />
                              </div>
                              <div
                                  className={clsx(classes.legendList, classes.legendStyles, classes.imageContainer)}>
                                  <Typography variant="h4" className={classes.progressLabel}>
                                  <>
                                    {' '}
                                    {translate('Loading')} <CircularProgress size={14} />
                                  </>
                                </Typography>
                              </div>
                            </React.Fragment>
                          ) : msl.legendData && msl.legendData.length ? (
                            <React.Fragment key={`${msl.id}-legendData`}>
                              {msl.legendData.map(ld => (
                                <React.Fragment key={`${msl.id}-legendData-${ld.layerId}`}>
                                  {msl.legendData!.length > 1 && ld.legend.length > 1 ? (
                                    <div className={classes.legendListItem}>
                                      <Typography variant="h3" className={classes.assetsStatus}>
                                      {translate(ld.layerName)}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {ld.legend.map((ldl, ldli) => (
                                    <Base64Icon
                                      key={`${msl.id}-legendData-${ld.layerId}-${ldl.url}-${ldl.values?.join('-')}`}
                                      contentType={ldl.contentType}
                                      imageData={ldl.imageData}
                                      label={ldl.label || ld.layerName || msl.label}
                                      indent={msl.legendData!.length > 1 && ld.legend.length > 1}
                                      index={ldli}
                                    />
                                  ))}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : (
                            <ListItem key={msl.id} button onClick={() => handleChangeSelectedMapServiceLegend(msl)}>
                              <ListItemText primary={translate(msl.label)} />
                              <IconButton edge="end">
                                <ChevronRight />
                              </IconButton>
                            </ListItem>
                          )
                        )}
                    </List>
                  </SwipeableTabPanel>
                </SwipeableTabs>
              )}
            </Popover>
          </>
        )}
      </div>
    </Slide>
  );
};

export default React.memo(Legend);
