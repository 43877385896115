import { Box, Typography, makeStyles } from '@material-ui/core';
import { CSSProperties, MouseEvent, useContext } from 'react';
import { colors } from '../../../styles/theme';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

export const ActionButton = ({
  action,
  label,
  icon,
  style,
}: {
  action: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  label?: string;
  style?: CSSProperties;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
}) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <Box className={classes.iconContainer} onClick={action}>
      <Box className={classes.actionIcon}>{icon}</Box>
      <Typography className={classes.title} style={style}>
        {translate(label || '')}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  iconContainer: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  },
  actionIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    backgroundColor: colors.white,
    '& div': {
      marginRight: '0px !important',
    },
    '& svg': {
      fontSize: 20,
      color: theme.palette.secondary.main,
    },
  },
  title: {
    fontSize: 13,
    fontFamily: 'Roboto',
    lineHeight: '17px',
    letterSpacing: -0.5,
    fontWeight: 400,
    textAlign: 'center',
  },
}));
