import { gql, useApolloClient } from '@apollo/client';
import turfBbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import * as MapboxGl from 'mapbox-gl';
import React, {
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { useAssetCards } from '../../../contexts/assetCardContext';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { cardViewSelector } from '../../../recoil/atoms';
import { cardIdsState, selectedItemsState } from '../../../recoil/atoms/mapMaintenance';
import { isLatitudeLongitudeValid } from '../../../utils/utilityHelper';
import MultipleAssetCardsContainer from './MultipleAssetCardsContainer';
import PositionedAssetCardContainer from './PositionedAssetCard';
import _ from 'lodash';

const DEFAULT_CLICK_ZOOM = 16;
const MAP_SYMBOL_SIZE = 50;
const MAP_CARD_BOTTOM_PADDING = 18;

type CardType = 'single' | 'cluster';

export interface AssetCardRendererProps {
  items?: MapAssetType[];
  mapInstance: MapboxGl.Map | null;
  selectedAssetId: string | undefined;
  selectedAssetType?: string;
  selectableAggregateTypes?: string[];
  onCardClose?: () => void;
  onCardDisplayed?: (assetId: string) => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  forceCards?: boolean;
  isMainMap?: boolean;
  selectedAggregateId?: string;
  onFormSubmit?: () => void;
  assetIds: string[];
  setAssetsIds: (ids: string[]) => void;
  setSelectedAssetId?: React.Dispatch<SetStateAction<string>>;
  isAssetDetailsOpen?: boolean;
}

export interface AssetCardRendererRef {
  handleClose: () => void;
}

const AssetCardRenderer: React.ForwardRefRenderFunction<AssetCardRendererRef, AssetCardRendererProps> = (
  props,
  ref
) => {
  const {
    mapInstance,
    selectedAssetId,
    onCardDisplayed,
    selectableAggregateTypes,
    onSelect,
    mapEditor,
    selectedAggregateId,
    forceCards,
    onFormSubmit,
    onCardClose,
    assetIds = [],
    setAssetsIds,
    isMainMap,
    items,
    setSelectedAssetId,
    isAssetDetailsOpen = false,
  } = props;

  const { mapCardHeight, isMobileView } = React.useContext(AssetsDashboardContext);
  const { setIsCardVisible, refresh, setRefresh, coordinates, areCardsDefined } = useAssetCards();

  const setCardIds = useSetRecoilState(cardIdsState);
  const [globalItems, setGlobalItems] = useRecoilState(selectedItemsState);
  const areCardsActive = useRecoilValue(cardViewSelector);
  const client = useApolloClient();
  const [assets, setAssets] = useState<MapAssetType[]>([]);
  const [cardType, setCardType] = React.useState<CardType>(assetIds.length > 0 ? 'cluster' : 'single');
  const [cardAssetId, setCardAssetId] = React.useState<string | null>(null);
  const [fetchingAssets, setFetchingAssets] = useState(false);

  const getSelectedCardType = (): CardType => {
    if (selectedAssetId) {
      return 'single';
    }
    if (assetIds.length > 0 && !isAssetDetailsOpen) {
      return 'cluster';
    }
    return 'single';
  };

  const checkShouldFetchAsset = () => {
    if (refresh) return true;
    if ((!selectedAssetId && assetIds.length > 0) || selectedAsset?.id === selectedAssetId) return false;
    if (globalItems.some(asset => asset.id === selectedAssetId)) {
      setAssets(globalItems);
      return false;
    } else if (items?.some(asset => asset.id === selectedAssetId)) {
      setAssets(items);
      return false;
    }
    return true;
  };

  const fetchAssets = useCallback(async () => {
    const shouldFetchAsset = checkShouldFetchAsset();
    const cardType: CardType = getSelectedCardType();
    setCardType(cardType);
    const ids = cardType === 'cluster' ? assetIds : selectedAssetId ? [selectedAssetId] : [];
    if (!ids.length || !shouldFetchAsset) return;
    try {
      setFetchingAssets(true);
      const { data } = await client.query({
        query: gql`
          query aggregatesById($ids: [String]!) {
            aggregatesById(ids: $ids)
          }
        `,
        variables: { ids },
        fetchPolicy: 'no-cache',
      });

      if (isMainMap) {
        setGlobalItems(data.aggregatesById);
      }
      setAssets(data.aggregatesById);
      setFetchingAssets(false);
      setRefresh(false);
    } catch (error) {
      setFetchingAssets(false);
    }
  }, [assetIds, isMainMap, refresh, selectedAssetId]);

  useEffect(() => {
    fetchAssets();
  }, [assetIds, isMainMap, setGlobalItems, setRefresh, setAssetsIds, selectedAssetId, refresh]);

  const centerMapToCard = useCallback(
    (coords: { lng: number; lat: number }) => {
      if (!mapInstance || !isLatitudeLongitudeValid(coords.lat, coords.lng)) return;

      const maxZoom = Math.max(mapInstance.getZoom(), DEFAULT_CLICK_ZOOM);
      const padding = {
        top: 0,
        bottom: isMobileView ? mapCardHeight + MAP_CARD_BOTTOM_PADDING + MAP_SYMBOL_SIZE: 0,
        left: 0,
        right: 0,
      };

      mapInstance.easeTo({ center: [coords.lng, coords.lat], zoom: maxZoom, padding });
    },
    [mapInstance, isMobileView, mapCardHeight]
  );
  const selectedAsset = useMemo(() => {
    return assets.find(asset => asset.id === selectedAssetId);
  }, [assets]);

  useEffect(() => {
    if (selectedAsset && selectedAssetId) {
      setCardAssetId(selectedAssetId as string);
    }
    let initialCoords = coordinates ?? null;
    switch (selectedAsset?.primaryLocation.type) {
      case 'Point':
        initialCoords = {
          lng: selectedAsset.lon,
          lat: selectedAsset.lat,
        };
        break;
      case 'LineString':
        let coords = selectedAsset.primaryLocation.coordinates;
        if (coords && coords.length >= 2) {
          let line = lineString(coords);
          let box = turfBbox(line);
          if (mapInstance) {
            mapInstance.fitBounds([
              [box[0], box[1]],
              [box[2], box[3]],
            ]);
            // data structure format
            // [
            //   [lowerLon, lowerLat],
            //   [upperLon, upperLat],
            // ]
          }
        }
        break;
      // TODO: need to implement this with bounding box, maybe use part of current function to just find y offset
      // setCenterToBelowCard();
      // if we cannot determine the type, try lat/lon
      default:
        if (selectedAsset && selectedAsset.lat && (selectedAsset.lon || selectedAsset.lon)) {
          initialCoords = {
            lng: selectedAsset.lon,
            lat: selectedAsset.lat,
          };
        }
        break;
    }
    initialCoords && centerMapToCard(initialCoords);
    setIsCardVisible(true);
  }, [selectedAsset, coordinates, mapInstance]);

  useEffect(() => {
    setCardIds(prev => {
      const newIds = (assets || []).map(a => a.id);
      return !_.isEqual(prev, newIds) ? newIds : prev;
    });
  }, [assets]);

  const handleClose = useCallback(() => {
    setIsCardVisible(false);
    setAssets([]);
    setCardAssetId(null);
    onCardClose?.();
    setSelectedAssetId?.('');
    if (isMainMap) {
      setGlobalItems([]);
      setAssetsIds([]);
    }
  }, [setAssetsIds, onCardClose, isMainMap, setGlobalItems, setIsCardVisible]);

  useImperativeHandle(ref, () => ({ handleClose }));

  if (!areCardsDefined && !areCardsActive && !forceCards && fetchingAssets) return null;

  return cardType === 'cluster' && assetIds.length > 1 ? (
    <MultipleAssetCardsContainer
      assets={globalItems}
      onCloseClick={handleClose}
      onCardChanged={onCardDisplayed}
      selectableAggregateTypes={selectableAggregateTypes}
      onSelect={onSelect}
      mapEditor={mapEditor}
      selectedAggregateId={selectedAggregateId}
      relative={forceCards}
      onFormSubmit={onFormSubmit}
      isMainMap={isMainMap}
    />
  ) : selectedAssetId && cardType === 'single' && cardAssetId === selectedAssetId ? (
    <PositionedAssetCardContainer
      onFormSubmit={onFormSubmit}
      asset={selectedAsset as MapAssetType}
      assetId={selectedAssetId}
      mapEditor={mapEditor}
      onCloseClick={handleClose}
      fetchingAssets={!globalItems.length}
      isMainMap={isMainMap}
    />
  ) : null;
};

export default React.memo(forwardRef(AssetCardRenderer));
