import React, { useEffect, useMemo, useState, useContext } from 'react';
import {
  Dialog,
  makeStyles,
  DialogContent,
  Typography,
  Button,
  Box,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from '@material-ui/core';
import * as Yup from 'yup';
import { makeValidate, makeRequired, TextField, Checkboxes, RadioData } from 'mui-rff';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import SelectorModal from './SelectorModal';
import PasswordField from './PasswordField';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { colors } from '../../styles/theme';
import BottomBar from '../Common/BottomBar';
import _ from 'lodash';
import { ActionType } from '../../containers/UsersContainer/commonUserAndRoles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface AddNewFormBaseAddNewFormField<InitialValueType> {
  name: string;
  label: string;
  initialValue?: InitialValueType;
  allOption?: {
    title: string;
    onClick: (onChange: (value: unknown) => void) => void;
  };
  formField?: string;
  options?: RadioData[];
}

export interface AddNewFormCheckboxField extends AddNewFormBaseAddNewFormField<boolean> {
  type: 'checkbox';
  value: string;
}
export interface AddNewFormRadioField extends AddNewFormBaseAddNewFormField<boolean> {
  type: 'radio';
  options: { label: string; value: boolean }[];
  value: boolean;
}
export interface AddNewFormSelectModalField extends AddNewFormBaseAddNewFormField<boolean> {
  type: 'selectModal';
  values: { label: string; value: string }[];
  multiSelect?: boolean;
  allSelectedMessage: string;
}

export interface AddNewFormTextField extends AddNewFormBaseAddNewFormField<string> {
  type: 'text';
  required: boolean;
}

export type AddNewFormEmailField = Omit<AddNewFormTextField, 'type'> & { type: 'email' };
export type AddNewFormPasswordField = Omit<AddNewFormTextField, 'type'> & { type: 'password' };

export type AddNewFormField =
  | AddNewFormRadioField
  | AddNewFormCheckboxField
  | AddNewFormTextField
  | AddNewFormSelectModalField
  | AddNewFormEmailField
  | AddNewFormPasswordField;

export interface AddNewDialogProps {
  title: string;
  onDone?: (type: ActionType, value: unknown) => void;
  onCancel?: () => void;
  open: boolean;
  fields: AddNewFormField[];
  message?: string;
}
interface CustomRadiosProps {
  name: string;
  data: RadioData[];
  onChange: (value: boolean) => void;
  required: boolean;
  value: boolean;
}

const Radios: React.FC<CustomRadiosProps> = ({ name, data, onChange, required, value }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    onChange(value);
  };
  const classes = radioStyles();
  return (
    <FormControl component="fieldset">
      <RadioGroup name={name} value={value.toString()} onChange={handleChange} className={classes.radioGroup}>
        {data.map((option, index) => (
          <FormControlLabel
            key={index}
            value={String(option.value)}
            control={<Radio required={required} style={styles.radioBtn} color="primary" />}
            label={option.label}
            className={classes.radioBtn}
            style={styles.customRadio}
            defaultValue={String(option.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
const getYupField = (field: AddNewFormField) => {
  if (field.type === 'text') {
    let result = Yup.string();
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'password') {
    let result = Yup.string().min(6);
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'email') {
    let result = Yup.string().email();
    if (field.required) {
      result = result.required();
    }
    return result;
  } else if (field.type === 'checkbox') {
    return Yup.boolean().required();
  } else if (field.type === 'selectModal') {
    return Yup.array();
  } else if (field.type === 'radio') {
    return Yup.boolean().required();
  }
};

const getLabel = (fields: AddNewFormField[], path: string): string =>
  fields.find(({ name }) => name === path)?.label ?? path;

const AddNewDialog = (props: AddNewDialogProps) => {
  const [selectedValue, setSelectedValue] = useState<ActionType>('addUser');
  const [touchedFields, setTouchedFields] = useState<Set<string | undefined>>(new Set());
  const isRolesCheck = _.isEqual(selectedValue, 'addRoles');
  const classes = useStyles({ isRolesCheck });
  const radioClasses = radioStyles();
  const filteredFields = useMemo(() => {
    return props.fields.filter(field => {
      if (selectedValue === 'addUser' && field.formField === 'Users') return true;
      if (selectedValue === 'addRoles' && field.formField === 'Roles') return true;
      return false;
    });
  }, [selectedValue, props.fields]);
  useEffect(() => {
    setTouchedFields(new Set());
    setFormValues(initializeFormValues(props.fields));
    setSelectedValue('addUser');
  }, [props.open]);
  const initializeFormValues = (fields: AddNewFormField[]) => {
    return fields.reduce((result, field) => {
      if (!field.name || !field.type) {
        return result;
      }
      return {
        ...result,
        [field.name]:
          field.initialValue ??
          (field.type === 'checkbox' ? false : field.type === 'selectModal' ? [] : field.type === 'radio' ? false : ''),
      };
    }, {});
  };

  const initialValues = useMemo(() => initializeFormValues(props.fields), [props.fields]);
  const [formValues, setFormValues] = useState(initialValues);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectValue = (event.target as HTMLInputElement).value as ActionType;
    setSelectedValue(selectValue);
    setTouchedFields(new Set());
  };
  const schema = useMemo(
    () =>
      Yup.object().shape(
        filteredFields.reduce(
          (result, field) => ({
            ...result,
            [field.name]: getYupField(field),
          }),
          {}
        )
      ),
    [filteredFields]
  );
  const validate = makeValidate(schema as any, (error: any) => 
    translate(error.message.replace(error.path, getLabel(filteredFields, error.path)))
  );
  const required = makeRequired(schema as any);
  const SelectAction = () => {
    const classes = radioStyles();
    const { translate } = useContext(LanguageContext);
    return (
      <Box className={classes.root}>
        <div className={classes.actionContainer}>
          <Typography className={classes.label}>{translate('Select Action')}</Typography>
          <Typography className={classes.RequiredRoot}>{translate('Required')}</Typography>
        </div>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={selectedValue} onChange={handleChange} className={classes.radioGroup}>
            <FormControlLabel
              value="addUser"
              control={<Radio color="primary" style={styles.radioBtn} />}
              label={translate('Add User')}
              className={classes.radioBtn}
            />
            <FormControlLabel
              value="addRoles"
              control={<Radio color="primary" style={styles.radioBtn} />}
              label={translate('Add Role')}
              className={classes.radioBtn}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };
  const isRequiredFieldEmpty = (fieldProps: FieldRenderProps<any, HTMLElement>, field: any) => {
    return required[field.name] && !fieldProps.input.value;
  };
  const handleFieldChange = (name: string) => {
    setTouchedFields(prev => new Set(prev.add(name)));
  };
  const { translate, language } = useContext(LanguageContext);
  const controls = useMemo(
    () =>
      filteredFields.map((field, index) => {
        const control = (fieldProps: FieldRenderProps<any, HTMLElement>) => {
          switch (field.type) {
            case 'text':
            case 'email':
              return (
                <TextField
                  {...fieldProps}
                  placeholder={translate('Enter text')}
                  required={required[field.name]}
                  name={translate(field.name)}
                  className={classes.textField}
                  variant="outlined"
                  onBlur={() => handleFieldChange(field.name)}
                  FormHelperTextProps={{ style: styles.helperText }}
                />
              );
            case 'password':
              return (
                <PasswordField
                  {...fieldProps}
                  placeholder={translate('Enter text')}
                  required={required[field.name]}
                  name={translate(field.name)}
                  className={classes.textField}
                  onBlur={() => handleFieldChange(field.name)}
                  FormHelperTextProps={{ style: styles.helperText }}
                />
              );
            case 'checkbox':
              return <Checkboxes {...fieldProps} data={{ ...field }} name={translate(field.name)} />;
            case 'radio':
              return (
                <Radios
                  name={translate(field.name)}
                  data={field.options}
                  required={required[field.name]}
                  value={fieldProps.input.value || field.initialValue}
                  onChange={value => fieldProps.input.onChange(value)}
                />
              );
            case 'selectModal':
              return (
                <SelectorModal
                  onChange={fieldProps.input.onChange}
                  value={fieldProps.input.value}
                  options={field.values}
                  label={field.label}
                  multiSelect={field.multiSelect ?? true}
                  error={fieldProps.meta.error}
                  allSelectedMessage={field.allSelectedMessage}
                  isUsersAndRolesDialog
                />
              );
            default:
              return null;
          }
        };
        const allOption = field.allOption;
        return (
          <div
            key={index}
            className={classes.root}
            style={field.type === 'selectModal' ? styles.selectModalContainer : styles.container}>
            <div style={styles.innerContainer}>
              <Field name={field.name}>
                {(fieldProps: FieldRenderProps<any, HTMLElement, any>) => (
                  <>
                    <Box className={classes.labelContainer}>
                      <Typography
                        className={classes.formFields}
                        style={
                          touchedFields.has(field.name) && isRequiredFieldEmpty(fieldProps, field)
                            ? styles.errorText
                            : {}
                        }>
                        {translate(field.label)}
                      </Typography>
                      {required[field.name] && (
                        <Typography className={radioClasses.RequiredRoot}>{translate('Required')}</Typography>
                      )}
                    </Box>
                    {control(fieldProps)}
                    {allOption && (
                      <Typography className={classes.selectAllTypography}>
                        {translate('OR')}{' '}
                        <Button
                          onClick={() => allOption.onClick(fieldProps.input.onChange)}
                          className={classes.selectAllButton}>
                          {translate(allOption.title)}
                        </Button>
                      </Typography>
                    )}
                  </>
                )}
              </Field>
            </div>
          </div>
        );
      }),
    [filteredFields, required, classes, touchedFields, language]
  );

  const handleSubmit = async (values: unknown) => {
    props.onDone && (await props.onDone(selectedValue, values));
  };
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={formValues}
      render={({ handleSubmit, values }) => (
        <Dialog open={props.open} onClose={handleCancel} classes={{ paper: classes.paper }}>
          <Box component="div" className={classes.header}>
            <Typography className={classes.headerText}>{translate(props.title as string)}</Typography>
            <IconButton onClick={handleCancel} className={classes.closeRoot}>
              <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <DialogContent className={classes.bodyContent}>
            <SelectAction />
            {controls}
          </DialogContent>
          <BottomBar onCancelClick={handleCancel} onDoneClick={handleSubmit} type={selectedValue} />
        </Dialog>
      )}
    />
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  radioBtn: {
    padding: 0,
  },
  selectModalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: { flexDirection: 'column' },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  customRadio: {
    marginLeft: -2,
  },
  helperText: {
    color: colors.pureRed,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
    fontStyle: 'normal',
    marginTop: 10,
  },
  errorText: {
    color: colors.pureRed,
  },
};
const useStyles = makeStyles(theme => ({
  paper: (props: { isRolesCheck: boolean }) => ({
    width: 680,
    height: '90vh',
    padding: '6px 0px 6px 0px',
    boxShadow: 'none',
    maxWidth: '100%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      margin: 0,
      maxHeight: '100%',
      width: '100%',
      minWidth: 0,
      height: '99%',
    },
  }),
  header: {
    padding: '18px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '18px 22px',
    },
  },
  divider: {
    backgroundColor: `${colors.black10} !important`,
  },
  bodyContent: {
    padding: '22px 30px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '22px',
    },
  },
  labelContainer: { display: 'flex', gap: 6 },
  formFields: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.black0,
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 10,
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    background: colors.white,
    padding: '18px 20px',
  },
  headerText: {
    fontWeight: 500,
    fontSize: 19,
    lineHeight: 'normal',
    fontStyle: 'normal',
    color: colors.black0,
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: 0,
      fontSize: 16,
      fontFamily: 'Roboto',
      fontWeight: 400,
      '&::placeholder': {
        color: colors.black35,
        opacity: 1,
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: 5,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  control: {
    marginTop: 12.5,
    marginBottom: 17.5,
    height: 25,
  },
  selectorAll: {
    height: 60,
  },
  selectAllContainer: {},
  selectAllTypography: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  selectAllButton: {
    color: theme.palette.primary.main,
  },
  closeRoot: {
    width: 24,
    height: 24,
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
  },
}));
const radioStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 10,
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    background: colors.white,
    padding: '18px 20px',
  },
  formControl: {
    marginLeft: 8,
  },
  radioGroup: {
    display: 'flex',
    gap: 10,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  radioBtn: {
    display: 'flex',
    gap: 9,
    padding: 0,
    '& .MuiTypography-body1': {
      fontSize: '15px !important',
      lineHeight: 'normal !important',
      color: colors.black0,
    },
  },
  RequiredRoot: {
    display: 'flex',
    padding: '2px 5px',
    alignItems: 'center',
    gap: 10,
    borderRadius: 10,
    color: colors.coralRed,
    fontFamily: 'Roboto',
    fontSize: 9,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    background: colors.lavenderBlush,
  },
  actionContainer: {
    display: 'flex',
    gap: 6,
  },
}));
export default AddNewDialog;
