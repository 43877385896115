import { createStyles, makeStyles, Typography } from '@material-ui/core';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { ServerPublicMetaContext, useConfig } from '@terragotech/gen5-shared-components';
import logoIcon from '../../images/streetlightsLogo.svg';
import { useContext, useEffect } from 'react';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { useHistory } from 'react-router-dom'
interface LegalDisclaimerViewProps {
  onDisclaimerAccepted: () => void;
  containerStyle?: boolean
  redirectToMainPage?: boolean
}

const LegalDisclaimerView = (props: LegalDisclaimerViewProps) => {
  const { onDisclaimerAccepted, containerStyle } = props;
  const classes = useStyles();
  const uiConfig = useConfig();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { legalDisclaimer: legalDisclaimerText } = useContext(ServerPublicMetaContext)
  const history = useHistory()
  useEffect(() => {
    if (!legalDisclaimerText) {
      history.push('/')
    }
  }, [])
  return (
    <div className={containerStyle ? classes.containerStyle : ''}>
      <div className={isMobileView ? classes.root : ''}>
        <div className={classes.rootContainer}>
          <div className={classes.logoContainer}>
            <img
              src={(uiConfig.theme.logoUrl.length > 0 && uiConfig.theme.logoUrl) || logoIcon}
              className={classes.logo}
              alt="logoIcon"
            />
          </div>
        </div>
        <div className={classes.legalContainer}>
          <Typography className={classes.title}>{'Legal Disclaimer'}</Typography>
          <div className={classes.legalContainer}>
            <div className={classes.textContaier}>
              <div className={classes.legalText}>{legalDisclaimerText || ''}</div>
            </div>
          </div>
        </div>
        <div className={isMobileView ? classes.mobBtn : classes.btnContainer}>
          <TGLoginButton
            type={TGLoginButtonType.primary}
            onClick={onDisclaimerAccepted}
            style={isMobileView ? { padding: '10px 36px' } : {}}
          >
            <> {'Agree & Continue'}</>
          </TGLoginButton>
        </div>
      </div>
    </div>
  );
};
const FOOTER_HEIGHT = 61;
const useStyles = makeStyles(theme =>
  createStyles({
    containerStyle: {
      margin: 'auto',
      width: 400,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: 344,
      },
    },
    root: { height: `calc(100vh - ${FOOTER_HEIGHT}px)`, overflowY: 'auto' },
    title: {
      fontSize: '28px',
      color: colors.black85,
      fontWeight: 500,
      lineHeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      paddingTop: 18,
      paddingBottom: 20,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        paddingTop: 16,
        paddingBottom: 15,
        fontSize: '24px'
      },
    },
    legalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    legalText: {
      fontSize: '14px',
      color: colors.black75,
      fontWeight: 400,
      lineHeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      padding: '8px 22px',
      paddingRight: 14,
      minHeight: '50px',
      maxHeight: '450px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: colors.scrollBar,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: 0,
        paddingInline: 22,
        maxHeight: '100%',
        paddingBottom: 87,
      },
    },
    textContaier: {
      border: `1px solid ${colors.black10}`,
      background: colors.white,
      paddingRight: '8px',
      paddingTop: 9,
      paddingBottom: 9,
      borderRadius: 5,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        border: `none`,
        padding: 0,
      },
    },
    btnContainer: {
      width: '100%',
      paddingTop: 20,
    },
    mobBtn: {
      padding: '14px 22px',
      boxShadow: `0px -2px 4px 0px ${colors.black10}`,
      background: colors.white,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    logoContainer: {
      border: `1px solid ${colors.black10}`,
      borderRadius: 4,
      padding: 10,
      background: colors.white,
      marginTop: 40,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: 25,
      },
    },
    logo: {
      borderRadius: '50%',
      height: 55,
      width: 55,
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export default LegalDisclaimerView;
