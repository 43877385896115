import React, { useState, forwardRef, ForwardedRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import AssetCardContainer from '../AssetCardContainer';
import { MapAssetType } from '../../../../contexts/AggregatesContext/types';

type CardContainerProps = {
  setSize: (width: number, height: number) => void;
  style?: React.CSSProperties;
  classes?: {
    container?: string;
  };
  assetId: string;
  onCloseClick?: () => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  asset?: MapAssetType;
  onFormSubmit?: () => void;
  multiple?: boolean;
  setFirstCardLoaded?: (val: boolean) => void;
  index?: number;
};

const CardHolder = forwardRef((props: CardContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { index, setFirstCardLoaded, style, classes: customClasses } = props;
  const [currentCardVersion, setCurrentCardVersion] = useState(0);

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, customClasses?.container || '')} style={style} ref={ref}>
      <AssetCardContainer
        {...props}
        refreshCard={() => {
          if (index === 0 && setFirstCardLoaded) {
            setFirstCardLoaded(true);
          }
          setCurrentCardVersion(prevState => prevState + 1);
        }}
      />
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
  },
}));

export default CardHolder;
