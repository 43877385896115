import React, { useState, forwardRef, useContext } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import useStyles, { getClasses } from '../../StyledTable/Editors/Common';
import TGOptionSelectorModal from '../../Common/Modals/TGOptionSelectorModal';
import { Editor2Props } from '@terragotech/react-data-grid';
import { TableData } from '../../../hooks/useTable';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

export interface ModalAssetEditorProps {
  title: string;
  options: { value: string; label: string }[];
  initialValue: string;
}

export default forwardRef((props: ModalAssetEditorProps & Editor2Props<TableData>, ref) => {
  const { onRowChange, onClose, column, row } = props;
  const { options, title, initialValue } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [value, setValue] = useState(initialValue);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleDone = (value: unknown) => {
    setValue(value as string);
    setIsModalOpen(false);
    onRowChange({ ...row, [column.key]: value as string, __changes: { [column.key]: value as string } });
  };

  return (
    <div className={getClasses(props, classes).join(' ')} style={styles.Root}>
      <TextField
        value={value || ''}
        placeholder={translate('Edit')}
        onClick={handleOnClick}
        inputProps={{ 'aria-label': `change data`, className: classes.input }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown color="primary" className={classes.icon} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
      <TGOptionSelectorModal
        {...props}
        open={isModalOpen}
        filterPlaceHolder="Search"
        pathToLabel="label"
        pathToValue="value"
        // multiSelect={true}
        onDone={handleDone}
        onCancel={onClose}
        options={options || []}
        title={title}
        value={value}
        setOpen={setIsModalOpen}
      />
    </div>
  );
});
const styles = {
  Root: {
    display: 'flex',
    justifyContent: 'center',
  },
};
