import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import ActionsMenuUI from '../components/ActionsMenuUI';
import { useAssetsTableState } from '../contexts/assetsTableStateContext';
import { useConditionalMultiActions } from '../hooks/useConditionalMultiAction';
import useMultiSelectActionsMenu, { ActionsMultiSelectMenuButton } from '../hooks/useMultiSelectActionsMenu';
import MultiActionsDialog, { MultiActionDialogProps } from './MultiActionsDialog';
import { colors } from '../styles/theme';
import { useFilter } from '../contexts/FilterContext/filterContext';
import { useRecordType } from '../contexts/recordTypeContext';
import { useAlert } from '../contexts/AlertModalContext';
import { capitalize } from 'lodash';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';
import { MAX_ASSET_LIMIT_MULTISELECT } from '../contexts/AggregatesContext/queryUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsButton: {
      whiteSpace: 'nowrap',
      zIndex: 5,
      backgroundColor: colors.white,
      display: 'flex',
      alignItems: 'center',
      borderLeft: `1px solid ${colors.black10}`,
      borderRadius: '0px 5px 5px 0px',
      cursor: 'pointer',
      padding: '5px 8px',
    },
    actionLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    arrowIcon: {
      color: theme.palette.primary.main,
    },
  })
);

export type ActionsButtonProps = {};
/**
 * ActionsButton - Handles Mouse Activity (On Click, drops down. On Click off of div, hides menu)
 *
 * Creates assetData to pass to the Actions Menu Component
 * When clicked, displays ActionsMenu
 *
 * @param selected
 */
const ActionsButton: FunctionComponent<ActionsButtonProps> = props => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const { setLimitAlert, limitAlert, actionsMenuRefresh } = useAssetsTableState();
  const { multiSelectSelectedAssets } = useFilter();
  const showMenu = multiSelectSelectedAssets.current.length !== 0;
  const [, setRefresh] = useState(0);
  const { selectedRecordTypeDefinition } = useRecordType();
  //Actions Menu handling
  const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState<Element | null>(null);
  const handleActionsMenuClose = useCallback(() => {
    setActionsMenuAnchor(null);
  }, [setActionsMenuAnchor]);
  const { openConfirmation } = useAlert();
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setActionsMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    setRefresh(prev => prev + 1);
  }, [actionsMenuRefresh]);

  const [actionsMultiSelectMenuButton, setActionsMultiSelectMenuButton] =
    React.useState<ActionsMultiSelectMenuButton>();

  const [clickVersion, setClickVersion] = useState<number>(0);
  const { exceptionCount, validMultiAssets, version } = useConditionalMultiActions(
    clickVersion,
    actionsMultiSelectMenuButton
  );

  const [multiActionDialogProps, setMultiActionDialogProps] = React.useState<MultiActionDialogProps>(
    {} as MultiActionDialogProps
  );

  const actionCallback = (action: ActionsMultiSelectMenuButton) => {
    setClickVersion(pervious => pervious + 1);
    if (action.conditionalMap) {
      setActionsMultiSelectMenuButton(action);
    } else {
      setActionsMultiSelectMenuButton(undefined);
      action.onClick();
    }
  };

  useEffect(() => {
    if (!actionsMultiSelectMenuButton || multiActionDialogProps.show) {
      return;
    }
    if (exceptionCount === 0) {
      actionsMultiSelectMenuButton.onClick();
    } else {
      setMultiActionDialogProps({
        show: true,
        validRecords: validMultiAssets,
        exceptionCount: exceptionCount,
        actionsMultiSelectMenuButton: actionsMultiSelectMenuButton,
        reset: () => {
          setMultiActionDialogProps({} as MultiActionDialogProps);
        },
      } as MultiActionDialogProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const { statusText, multiSelectActions, selectedCount } = useMultiSelectActionsMenu({
    closeMenu: handleActionsMenuClose,
    target: multiSelectSelectedAssets.current,
    actionCallback: actionCallback,
  });
  const handleOpenDialog = useCallback(async () => {
    const status = await openConfirmation({
      title: `${capitalize(selectedRecordTypeDefinition.plural?.toLocaleString())} Selection Limit Exceeded`,
      question: `You currently have too many ${selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} selected, 
          you can only have a maximum of ${MAX_ASSET_LIMIT_MULTISELECT.toLocaleString()} records selected at a time. 
          Please select fewer ${selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} and try again.`,
      cancellationText: 'Ok',
      type: 'error',
      showSubmit: false,
    });
    if (status === 'cancel') {
      setLimitAlert(false);
    }
  }, [openConfirmation, setLimitAlert]);

  useEffect(() => {
    if (limitAlert) {
      handleOpenDialog();
    }
  }, [limitAlert, handleOpenDialog]);
  return (
    <React.Fragment>
      {showMenu && (
        <div className={classes.actionsButton} onClick={handleClick}>
          <Typography className={classes.actionLabel}>
            <>{translate('Actions')}</>
          </Typography>
          {Boolean(actionsMenuAnchor) ? (
            <ArrowDropUp className={classes.arrowIcon} />
          ) : (
            <ArrowDropDown className={classes.arrowIcon} />
          )}
        </div>
      )}
      {multiSelectSelectedAssets.current.length !== 0 && (
        <ActionsMenuUI
          statusText={statusText}
          selectedCount={selectedCount}
          actions={multiSelectActions}
          anchorEl={actionsMenuAnchor}
          onClose={handleActionsMenuClose}
        />
      )}
      {multiActionDialogProps.show && multiActionDialogProps.actionsMultiSelectMenuButton && (
        <MultiActionsDialog {...multiActionDialogProps} />
      )}
    </React.Fragment>
  );
};
export default ActionsButton;
