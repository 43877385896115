import { useContext } from 'react';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import { colors } from '../../styles/theme';
import { Theme, makeStyles, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface LanguageSectionProps {
  allowedLanguages: ReadonlyArray<string>;
  currentLanguage: string;
  onChange: (value: string) => void;
  theme?: Theme;
}

export const LanguageSelection = (props: LanguageSectionProps): JSX.Element => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <div className={classes.root}>
      <RadioGroup>
        {props.allowedLanguages.map((language) => (
          <div key={language} className={classes.childRoot}>
            <FormControlLabel 
              value={language} 
              control={
                <Radio 
                  key={language}
                  onClick={() => props.onChange(language)}
                  checked={props.currentLanguage === language}
                  color="primary"
                  classes={{ root: classes.menu }}
                />
              } 
              label={translate(language)} />
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};

export const languageSelection = (props: LanguageSectionProps): DrawerItem => {
  return {
    title: 'Languages',
    content: <LanguageSelection {...props} />,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 10px 20px 10px',
      position: 'relative',
    },
    childRoot: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    menuBtn: {
      width: 150,
      marginLeft: 5,
      justifyContent: 'flex-start',
      paddingLeft: 25,
      '&:hover': {
        backgroundColor: colors.snowWhite,
        '& .MuiButton-label': {
          color: theme.palette.primary.main,
          textTransform: 'none',
        },
      },
      '& .MuiButton-label': {
        textTransform: 'none',
      },
    },
    selected: {
      backgroundColor: colors.veryLightGrey,
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    menu: {
      textAlign: 'left',
      marginLeft: 10,
      fontSize: 14,
      fontWeight: 300,
      fontStyle: 'normal',
      color: colors.black54,
      fontFamily: 'Roboto',
    },
  }));