import React, { ReactElement, useContext, useMemo } from 'react';
import { gql } from '@apollo/client';
import { useConfig } from '@terragotech/gen5-shared-components';
import TGOptionQueryLazyModal from '../components/Common/Modals/TGOptionLazyModal/TGOptionQueryLazyModal';
import Alert from '@material-ui/lab/Alert';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

/* This container will be used by the ag-grid cellEditor to display the linked resource selection editor from  

This container will receive props with the asset type in question, and the currently selected asset ids. It should use the asset type to query graphql for all available Ids/labels of that type. This list should be passed to the dialog, along with which things are currently selected.

Note: we will eventually need to update this query to be lazy loaded and to coordinate with the search bar. */
// All of the various values that can occur with linked assets
type SingleValueType = { id: string; label: string };
export type SelectedLinkedAssetType = SingleValueType | SingleValueType[];

interface LinkedResourceSelectionContainerProps {
  aggregateTypeName: string;
  singleSelect?: boolean;
  open: boolean;
  selectedLinkedAssets?: SelectedLinkedAssetType;
  onSelectedLinkedAssetChanged: (value: SelectedLinkedAssetType | undefined) => void;
  onCancel: () => void;
  setOpen: (open: boolean) => void;
}

const TYPED_RECORDS_QUERY = (queryKey?: string) => gql`
  query TypedRecords ($cursor: Cursor, $limit: Int, $filterText: String) {
    ${queryKey}Connection(first: $limit,orderBy: LABEL_ASC, after: $cursor, filter: {label: {includesInsensitive: $filterText}}) {
      edges{
        node{
          id
          label
        }
      }
      pageInfo{
        hasNextPage
        endCursor
      }
    }
  }
`;

const LinkedResourceSelectionContainer: React.FC<LinkedResourceSelectionContainerProps> = (props): ReactElement => {
  const {
    open,
    onCancel,
    singleSelect,
    selectedLinkedAssets,
    aggregateTypeName,
    onSelectedLinkedAssetChanged,
    setOpen,
  } = props;
  const { aggregateDefinitions } = useConfig();
  const { translate } = useContext(LanguageContext);
  const queryKey = aggregateDefinitions.find(d => d.name === aggregateTypeName)?.queryKey;

  const value = useMemo(() => {
    if (selectedLinkedAssets) {
      if (Array.isArray(selectedLinkedAssets)) {
        return selectedLinkedAssets;
      }
      return [selectedLinkedAssets];
    }
    return [];
  }, [selectedLinkedAssets]);

  if (!queryKey) {
    return (
      <Alert severity="error">
        {translate('No aggregate with given name $__NAME__$', { NAME: aggregateTypeName })}
      </Alert>
    );
  }

  return (
    <TGOptionQueryLazyModal
      title={`${translate('$__NAME__$ Id', { NAME: aggregateTypeName })}`}
      filterPlaceHolder={`${translate('Search')} ${translate(aggregateTypeName)} ${translate('Ids')}`}
      onCancel={onCancel}
      onDone={value => onSelectedLinkedAssetChanged(value)}
      open={open}
      value={value}
      pathToValue={'id'}
      multiSelect={!singleSelect}
      query={TYPED_RECORDS_QUERY(queryKey)}
      queryKey={queryKey}
      recordsPerPage={50}
      setOpen={setOpen}
    />
  );
};

export default LinkedResourceSelectionContainer;
