import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AssetDataContainer from './AssetDataContainer';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { AssetDataEntry, AssetDataQueryResult } from './WorkflowPageContainer';
import { useConfig, getAggregateDataFields, graphqlToAssetTransform } from '@terragotech/gen5-shared-components';
import { useRecoilValue } from 'recoil';
import { aggregateUpdateCountState } from '../recoil/atoms';

export interface RecursiveAssetEditorProps {
  aggregateType: string;
  aggregateId: string;
  pathname: string;
  open: boolean;
  crumbs: { label: string; pathname: string; onClick: () => void }[];
}

const RecursiveAssetEditor: React.FunctionComponent<RecursiveAssetEditorProps> = props => {
  const { aggregateType, aggregateId, pathname, open, crumbs } = props;

  const history = useHistory();
  const configContext = useConfig();
  const aggregateDefinition = configContext.aggregateDefinitions.find(d => d.name === aggregateType);
  const aggregateTypeName = aggregateDefinition?.queryKey || '';
  const classes = useStyle();

  const { data, loading, error, refetch } = useQuery<AssetDataQueryResult>(
    gql`
      query AssetData($aggregateId: UUID!) {
        ${aggregateTypeName}(filter: {id: {equalTo: $aggregateId}}) {
          id
          label
          assetAttributes
          modifiedDateTime
          ${getAggregateDataFields(aggregateDefinition, configContext.aggregateDefinitions, undefined, undefined, true)}
        }
      }
    `,
    { variables: { aggregateId }, fetchPolicy: 'no-cache' }
  );

  const projectId = useMemo(() => (data?.[aggregateTypeName][0]?.projectId ?? '') as string, [data]);

  const aggregateUpdateCount = useRecoilValue(aggregateUpdateCountState({ project: projectId, aggregateType }));

  useEffect(() => {
    refetch();
  }, [aggregateUpdateCount]);

  // short circuit the recursion. an empty aggregate id means the end of the stack
  if (aggregateId === '') return null;
  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  const record: AssetDataEntry | null =
    graphqlToAssetTransform({
      flattenRelationships: false,
      result: data && data[aggregateTypeName][0],
      aggDef: aggregateDefinition,
    }) || null;
  if (!record || error) {
    throw new Error('Failed to load asset');
  }
  const newCrumbs = [
    ...crumbs,
    {
      label: `${record?.label}`,
      pathname,
      onClick: () => {
        history.replace(pathname);
      },
    },
  ];
  return (
    (open && (
      <Switch>
        <Route
          path={`${pathname}/:type/:id`}
          render={routeProps => {
            const aggregateType = routeProps.match.params['type'];
            const aggregateId = routeProps.match.params['id'];

            return (
              <RecursiveAssetEditor
                aggregateType={aggregateType || ''}
                aggregateId={aggregateId || ''}
                pathname={routeProps.match.url}
                open={open}
                crumbs={newCrumbs}
              />
            );
          }}
        />
        <Route
          children={() => {
            return (
              <AssetDataContainer
                aggregateId={aggregateId}
                aggregateType={aggregateType}
                aggregateData={record!}
                refetch={refetch}
                pathname={pathname}
                crumbs={crumbs}
              />
            );
          }}
        />
      </Switch>
    )) ||
    null
  );
};

const useStyle = makeStyles(theme => ({
  loadingContainer: {
    margin: 'auto',
  },
}));
export default RecursiveAssetEditor;
