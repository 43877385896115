import React, { useContext } from 'react';
import { userInfoSection } from '../components/MapRightDrawer/UserInfoSection';
import { OnOffSection } from '../components/MapRightDrawer/OnOffSection';
import { ClusteringIcon } from '../components/MapRightDrawer/ClusteringIcon';
import { CardIcon } from '../components/MapRightDrawer/CardIcon';
import { useAssetCards } from '../contexts/assetCardContext';
import { useSettingsSection } from './sectionsHooks/useSettingsSection';
import { DrawerItem } from '../components/TGDrawer/TGDrawer';
import { useConfig, useAuthContext } from '@terragotech/gen5-shared-components';
import { useMapTypeSection } from './sectionsHooks/useMapTypeSection';
import { useMapVisibilitySection, getSelectableAggregateDefinitions } from './sectionsHooks/useMapVisibilitySection';
import { useAggregates } from '../contexts/AggregatesContext';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MAP_LABEL_PREFERENCE_KEY,
  CARD_VIEW_PREFERENCE_KEY,
  PIN_CLUSTER_PREFERENCE_KEY,
  menuSelectionsState,
  forcedClusteringState,
} from '../recoil/atoms';
import { useMapServiceSection } from './sectionsHooks/useMapServiceSection';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export interface UseSections {
  items: DrawerItem[];
}

export const useSections = (): UseSections => {
  const { translate } = useContext(LanguageContext);
  const [menuSelectionsStateValue, setMenuSelectionsStateValue] = useRecoilState(menuSelectionsState);
  const forcedClustering = useRecoilValue(forcedClusteringState);

  const { visibleAggregateTypesNames, mapServices } = useAggregates();

  const { areCardsDefined } = useAssetCards();

  const { clearToken, familyName, givenName, email } = useAuthContext();

  const { section: settingsSection } = useSettingsSection({
    name: givenName && familyName ? `${givenName} ${familyName}` : '',
    email: email ?? '',
  });

  const { section: mapTypeSection } = useMapTypeSection();

  const { section: mapVisibilitySection } = useMapVisibilitySection();

  const { section: mapServiceSection } = useMapServiceSection();

  const config = useConfig();
  const aggregateDefinitions = getSelectableAggregateDefinitions(config);

  const items = [
    userInfoSection({
      firstName: givenName ?? '',
      lastName: familyName ?? '',
      email: email ?? '',
      clearToken: clearToken ?? '',
    }),
    settingsSection,
    mapTypeSection,
    OnOffSection({
      onIcon: <ClusteringIcon color="primary" />,
      offIcon: <ClusteringIcon color="disabled" />,
      title: 'Pin Clustering',
      onToggle: (_event, checked) => {
        setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [PIN_CLUSTER_PREFERENCE_KEY]: checked } });
      },
      isOn: menuSelectionsStateValue[PIN_CLUSTER_PREFERENCE_KEY] || forcedClustering,
      disable: forcedClustering || false,
      tooltip: forcedClustering || false ? 'Reduce the number of records in view to turn clustering off' : '',
      translate: translate,
    }),
  ];

  let showMapLabel = false;
  visibleAggregateTypesNames.forEach(visibleAggregateType => {
    const aggregate = aggregateDefinitions.find(val => {
      return val.name === visibleAggregateType;
    });
    if (aggregate && aggregate.mapLabelProperties && aggregate.mapLabelProperties.length > 0) {
      showMapLabel = true;
    }
  });

  if (showMapLabel) {
    items.push(
      OnOffSection({
        title: 'Map Labels',
        onToggle: (_event, checked) => {
          setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [MAP_LABEL_PREFERENCE_KEY]: checked } });
        },
        isOn: menuSelectionsStateValue[MAP_LABEL_PREFERENCE_KEY],
        translate: translate,
      })
    );
  }

  if (areCardsDefined) {
    items.push(
      OnOffSection({
        onIcon: <CardIcon color="primary" />,
        offIcon: <CardIcon color="disabled" />,
        title: 'Card View',
        onToggle: (_event, checked) => {
          setMenuSelectionsStateValue({ ...menuSelectionsStateValue, ...{ [CARD_VIEW_PREFERENCE_KEY]: checked } });
        },
        isOn: menuSelectionsStateValue[CARD_VIEW_PREFERENCE_KEY],
        translate: translate,
      })
    );
  }

  items.push(mapVisibilitySection);

  if (mapServices?.length) items.push(mapServiceSection);

  return { items };
};
