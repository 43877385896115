import React, { useState, useEffect, ReactElement, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../utils/layers';
import { CommandAction, AuthConnector } from '@terragotech/gen5-shared-components';
import useSubmitImport from '../hooks/useSubmitImport';
import { v4 } from 'uuid';
import { DOC_API_URL } from '../utils/docApiURL';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { usePusher } from '../contexts/pusherContext';
import _ from 'lodash';
import { UseConditionalImports, UseConditionalImport } from '../hooks/useConditionalImports';
import { getFileExtensionFromBlob } from '../utils/fileHelper';
import ImportFileScreen from '../components/Import/ImportFileScreen';
import ImportHistory from '../components/Import/ImportHistory';
import { FileWithPath } from 'react-dropzone';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import { useDataMapping } from '../hooks/useDataMapping';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import { colors } from '../styles/theme';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { useAlert } from '../contexts/AlertModalContext';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

export const QUERY = gql`
  {
    imports {
      id
      errorsWarnings {
        message
        is_error
        is_form_level
        row
        column
        sheet
        column_name
      }
      aggregateType
      commandName
      committed
      success
      isintegration
      filename
      metadata
      target
      timestamp
      type
      version
      commandError
      commandErrorMessage
    }
  }
`;

export interface QueryResult {
  imports: readonly {
    id: string;
    errorsWarnings: readonly {
      message: string;
      is_error: boolean;
      is_form_level: boolean;
      row: number;
      column: number;
      sheet: string;
      column_name: string;
    }[];
    aggregateType: string;
    commandName: string;
    committed?: boolean;
    success?: boolean;
    isintegration?: boolean;
    filename: string;
    metadata?: object;
    target: string;
    timestamp: Date;
    type: string;
    version: string;
    commandError?: number;
    commandErrorMessage?: string;
  }[];
}

interface TabPanelProps {
  children: ReactElement;
  value: number;
  index: number;
}

const PADDING_WIDTH = 171;
const HEIGHT_WITH_PADDING = 76;
const MOBILE_PADDING_HEIGHT = 60;
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    left: 70,
    width: `calc(100vw - ${PADDING_WIDTH}px)`,
    height: `calc(100% - ${HEIGHT_WITH_PADDING}px)`,
    backgroundColor: colors.white,
    padding: '42px 53px 41px 48px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      left: 0,
      width: `100vw`,
      height: `calc(100% - ${MOBILE_PADDING_HEIGHT}px)`,
      display:'block',
      overflowY: 'hidden',
      padding: 0,

    },
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 19,
    height: 28,
  },
  tabWrapper: {
    zIndex: 100,
  },
  tabContainer: {
    display: 'flex',
    minHeight: 0,
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${colors.black10}`,
      height: 45,
    },
  },
  tab: {
    padding: 0,
    width: 'fit-content',
    minWidth: 80,
    textTransform: 'none',
    color: colors.black0,
    fontSize: 16,
    fontWeight: 400,
    opacity: 1,
    marginRight: 10,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      fontSize: 14,
    },
  },
  tabIndicator: {
    height: 3,
  },
  
  displayRoot: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    overflowX: 'hidden',
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },

  uploadBtn: {
    marginTop: 19,
    fontSize: 16,
    fontWeight: 400,
    height: 39,
    textTransform: 'none',
    borderRadius: 5,
    width: 148,
    '&.Mui-disabled': {
      color: colors.white,
      backgroundColor: colors.black10,
    },
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      marginTop: 14,
      width: '100%',
      marginInline: 22,
    },
  },
  uploadBtnContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      width: '100%',
      position: 'fixed',
      bottom: 14,
      boxShadow: `0px -2px 4px 0px ${colors.black05}`,
      marginLeft: -18,
    },

  },
  uploadWrapper: {
    height: '100%',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    color: colors.black54,
    fontFamily: 'Roboto',
  },
  innerContainer: {
    display: 'contents',
    height: '100%',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        padding: '15px 18px',
        display: 'block',
        overflowY:  'hidden',
    },
  }
}));

interface Props {
  onBack: () => void;
}
const tabStyles = makeStyles(theme => ({
  tabDetail: {
    overflow: 'auto',
    paddingTop: 19,
    flex: 1,
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      height: '100%',
      overflow: 'hidden',
    },
  },
  tabDetailRoot: {
    overflow: 'hidden',
    height: '100%',
  },
  tabChildren: {
    height: '100%',
  },
}))
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  const classes = tabStyles();
  return (
    <Box
      className={index === 1 ? classes.tabDetail : [classes.tabDetail, classes.tabDetailRoot].join(' ')}
      role="tabpanel"
      hidden={value !== index}
      key={index}
    >
      {value === index && <Box className={classes.tabChildren}>{children}</Box>}
    </Box>
  );
};

const Import = (props: Props) => {
  const { translate } = useContext(LanguageContext);
  const pusher = usePusher();
  const impSubmit = useSubmitImport();
  const { openSnackbar } = useInfoContext();
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<FileWithPath | undefined>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [index, setIndex] = useState('');
  const [imports, setImports] = useState<QueryResult>();
  const { buttons } = UseConditionalImports();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { data, refetch, loading } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'no-cache' });
  if (!_.isEqual(data, imports)) {
    setImports(data);
  }
  const [picked, setPicked] = useState<UseConditionalImport | undefined>();
  const { openConfirmation } = useAlert();

  const changeHandler = (acceptedFiles: FileWithPath[]) => {
    setSelectedFile(acceptedFiles[0]);
    setIsSelected(true);
  };

  const clearSelection = () => {
    setSelectedFile(undefined);
    setIsSelected(false);
  };

  useEffect(() => {
    const debounceRefetch = _.debounce(refetch, 100);
    pusher.subscribe('import', 'update', (m: any) => {
      console.log('Import update pusher message', m);
      openSnackbar({
        title: m.message,
        type: m.level || 'SUCCESS',
        autoHideDuration: m.level === 'ERROR' ? 30000 : undefined,
      });
      debounceRefetch();
    });
    return () => {
      pusher.unSubscribe('import', 'update');
    };
  }, []);

  const downloadFile = async (fileUrl: string, fileName: string) => {
    const token = await AuthConnector.getToken();
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    });
    const blob = await response.blob();
    const fileExtension = (await getFileExtensionFromBlob(blob)) || 'csv';
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const apolloClient = useApolloClient();
  const contextGetter = useDataMapping();
  const downloadTemplate = async (aggregateType: string, commandName: string, version: number, fileName: string) => {
    const context = contextGetter();
    const metadata = context.accessors.METADATA ? context.accessors.METADATA() : {};
    const response = await apolloClient.query({
      query: gql`
        query importTemplate($METADATA: JSON) { importTemplate(aggregateType: "${aggregateType}", command: "${commandName}", version: ${version}, metadata: $METADATA){
          file
        } }
        `,
      fetchPolicy: 'no-cache',
      variables: {
        METADATA: metadata,
      },
    });
    const blob = new Blob([new Uint8Array(response.data.importTemplate.file.data).buffer]);
    const fileExtension = (await getFileExtensionFromBlob(blob)) || 'csv';
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const uploadFile = async (fileName: string) => {
    const id = v4();
    if (!picked) {
      return;
    }
    const fileUrl = DOC_API_URL + id;
    const token = await AuthConnector.getToken();
    const formData = new FormData();

    if (!selectedFile) {
      return;
    }

    formData.append('file', selectedFile);

    impSubmit
      .stageImport({
        target: id,
        version: (picked.action as CommandAction).commandVersion,
        aggregateType: picked.aggregate.name,
        commandName: (picked.action as CommandAction).commandName,
        fileName: fileName,
        isSynchronous: false, // Don't need high polling rate for this
        hasNoAggregateIdCommand: false,
      })
      .then(() => {
        refetch();
      });
    pusher.subscribe('import', id, (m: any) => {
      console.log('Pusher message from specific import', m);
      openSnackbar({
        title: m.message,
        type: m.level || 'SUCCESS',
        autoHideDuration: m.level === 'ERROR' ? 30000 : undefined,
      });
      pusher.unSubscribe('import', id);
    });

    fetch(fileUrl, {
      method: 'POST',
      body: formData,
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    })
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          impSubmit.submitImport({
            target: id,
            version: (picked.action as CommandAction).commandVersion,
            aggregateType: picked.aggregate.name,
            commandName: (picked.action as CommandAction).commandName,
            fileName: id,
            isSynchronous: false, // Don't need high polling rate for this
            hasNoAggregateIdCommand: false,
          });
        }
      })
      .then(result => {
        console.log('Success:', result);
        clearSelection();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const commit = async (id: string) => {
    console.log('Committing import with id ', id);
    impSubmit.commitImport(id);
  };

  const [fileIdToView, setFileIdToView] = useState<string | undefined>();
  const tabs = ['Upload', 'History'];

  const onConfirmUpload = async () => {
    const status = await openConfirmation({
      title: 'Confirm',
      question: 'You need to review the records to import once it uploads to the server. Do you want to proceed with uploading?',
      confirmationText: 'Proceed',
      description: (
        <Typography className={classes.description}>{translate('*You will get a notification when the file is uploaded. You can also check the status on the ‘Import History’ page.')}</Typography>
      ),
    });
    if (status === 'confirm' && selectedFile?.path) {
      uploadFile(selectedFile.path);
    }
  };

  return (
    <Box className={classes.root}>
      {!isMobileView && <Typography className={classes.pageTitle}>{translate('Import')}</Typography>}
      <div className={classes.innerContainer} style={activeTab === 0 ? styles.innerContainer as React.CSSProperties : {}}>
      <Box className={classes.tabWrapper}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => {
            e.stopPropagation();
            setActiveTab(newValue);
          }}
          indicatorColor="primary"
          aria-label="tabs"
          className={classes.tabContainer}
          TabIndicatorProps={{ className: classes.tabIndicator }}
        >
          {tabs.map(tab => (
            <Tab label={translate(tab)} className={classes.tab} key={tab} />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <>
        <Box className={classes.uploadWrapper}>
          <ImportFileScreen
            downloadFile={downloadFile}
            picked={picked}
            changeHandler={changeHandler}
            buttons={buttons}
            setPicked={setPicked}
            uploadFile={uploadFile}
            clearSelection={clearSelection}
            downloadTemplate={downloadTemplate}
            index={index}
            setIndex={setIndex}
            selectedFile={selectedFile}
            isSelected={isSelected}
          />
          {!isMobileView && <Box className={classes.uploadBtnContainer}>
            <Button
              onClick={onConfirmUpload}
              variant="contained"
              color="primary"
              disabled={!isSelected || index === ''}
              className={classes.uploadBtn}
            >
            {translate('Upload')}
            </Button>
          </Box>}
          </Box>
          {isMobileView && <Box className={classes.uploadBtnContainer}>
            <Button
              onClick={onConfirmUpload}
              variant="contained"
              color="primary"
              disabled={!isSelected || index === ''}
              className={classes.uploadBtn}
            >
            {translate('Upload')}
            </Button>
          </Box>}
          </>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <div className={classes.displayRoot}>
          <ImportHistory
            data={data}
            commit={commit}
            fileIdToView={fileIdToView}
            setFileIdToView={setFileIdToView}
            buttons={buttons}
            loading={loading}
          />
        </div>
      </TabPanel>
      </div>
    </Box>
  );
};
const BODY_HEIGHT = 105;
const styles = {
  innerContainer: {
        height: `calc(100% - ${BODY_HEIGHT}px)`, 
        overflowY:  'scroll'
  }
}
export default Import;
