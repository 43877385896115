import { Box, Button, Dialog, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { colors } from '../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import _ from 'lodash';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

const MID_MOBILE_BREAKPOINT = 450;

interface Props {
  showModal: boolean;
  handleAction: (value: 'confirm' | 'cancel') => void;
  description?: JSX.Element;
  type: 'confirmation' | 'error';
  question?: string;
  title?: string;
  confirmationText?: string;
  cancellationText?: string;
  dialogContainerStyle?: React.CSSProperties;
  isMobileView: boolean;
  showSubmit?: boolean;
}

const ConfirmModal = ({
  showModal,
  handleAction,
  description,
  type,
  question,
  title = 'Are you sure?',
  confirmationText = 'Delete',
  cancellationText = 'Cancel',
  dialogContainerStyle,
  isMobileView,
  showSubmit = true,
}: Props) => {
  const classes = useStyles({ isMobileView, showSubmit });
  const { translate } = useContext(LanguageContext);

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleAction('confirm');
  };

  const onCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleAction('cancel');
  };
  return (
    <Dialog
      open={showModal}
      onClose={undefined}
      transitionDuration={{ exit: 0, enter: 100 }}
      maxWidth="xl"
      className={classes.mainDialog}
      PaperProps={{ style: dialogContainerStyle }}
      BackdropComponent={() => <Box className={classes.backdrop} onClick={e => e.stopPropagation()} />}
      onClick={e => e.stopPropagation()}
    >
      <Box className={classes.body}>
        <FontAwesomeIcon
          icon={(type === 'confirmation' ? faCircleQuestion : faCircleExclamation) as IconDefinition}
          className={`${classes.icon} ${type === 'error' && classes.error}`}
        />
        <Box>
          <Typography className={classes.header}>{translate(title)}</Typography>
          {_.size(question) > 0 && <Typography className={classes.question}>{translate(question || '')}</Typography>}
          {description}
        </Box>
      </Box>
      <Box className={classes.footerRow}>
        <Button
          variant="contained"
          onClick={onCancel}
          className={`${classes.button} ${classes.cancel}`}
          disableElevation
        >
        {translate(cancellationText)}
        </Button>
        {showSubmit && (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleSubmit}
            style={styles.saveButton}
            className={classes.button}
          >
            {translate(confirmationText)}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

const styles = {
  saveButton: {
    color: colors.white,
    fontWeight: 500,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  mainDialog: (props: { isMobileView: boolean; showSubmit: boolean }) => ({
    '& .MuiPaper-root': props.isMobileView
      ? {
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: 0,
          borderRadius: 0,
        }
      : {
          width: 570,
          height: 'auto',
          boxShadow: `0px 2px 10px 0px ${colors.black10}`,
          borderRadius: 4,
        },
  }),
  body: props => ({
    padding: '30px 28px 34px',
    display: 'flex',
    flexDirection: props.isMobileView ? 'column' : 'row',
    gap: props.isMobileView ? 12 : 18,
    height: props.isMobileView ? '100%' : 'auto',
  }),
  header: {
    fontSize: 20,
    color: colors.black0,
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: 3,
  },
  content: {
    paddingTop: 0,
    paddingBottom: 20,
    overflow: 'hidden',
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    paddingBottom: 26,
    lineHeight: 'normal',
  },
  footerRow: props => ({
    display: 'flex',
    justifyContent: props.isMobileView ? (props.showSubmit ? 'flex-end' : 'center') : 'flex-end',
    gap: 10,
    padding: '10px 25px 12px',
    backgroundColor: colors.lotion,
    borderTop: `1px solid ${colors.black05}`,
    [theme.breakpoints.down(MID_MOBILE_BREAKPOINT)]: {
      justifyContent: 'space-between',
    },
  }),
  button: props => ({
    width: props.isMobileView ? (props.showSubmit ? 166.5 : '100%') : 99.5,
    height: 32,
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    textTransform: 'none',
  }),
  cancel: {
    color: colors.black0,
    backgroundColor: colors.whisperGray,
  },
  discardButton: {
    fontSize: 13,
    color: colors.white,
    fontWeight: 500,
    borderRadius: 2,
    height: 32,
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.black35,
  },
  icon: {
    width: 50,
    height: 50,
    color: theme.palette.primary.main,
  },
  question: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal',
    fontFamily: 'Roboto',
    color: colors.semiTransparentBlack,
  },
  error: {
    color: colors.red,
  },
}));

export default ConfirmModal;
