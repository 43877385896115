import { AssetType } from '../contexts/AggregatesContext/types';
import { ExportToCsv } from 'ts-export-to-csv';
import jsPDF from 'jspdf';
import _ from 'lodash';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateFormat, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import { colors } from '../styles/theme';
import { Column } from './tableHooks/useColumns';

interface BuiltInAction {
  icon: string;
  label: string;
  onClick: (
    target: AssetType | Array<AssetType>,
    columnState: Column<AssetType>[],
    onDone?: () => void,
    config?: any
  ) => void;
  isSingleSelectOnly?: boolean;
}
const buildOutputData = (
  target: AssetType | Array<AssetType>,
  columnState: Column<AssetType>[],
  defaultDateTimeFormat: {
    dateFormatType?: string | undefined;
    dateFormat?: string | undefined;
    dateSeperator: string;
    timeFormat: string;
    isSwitch: boolean;
  }
): Array<Record<string, string>> => {
  //don't love this, but gotta take things in order, but the sticky stuff first
  const visibleColumns = columnState
    .filter(col => col.hidden !== true)
    .filter(cur => !['symbolKey', 'gear'].includes(cur.key)); //exclude the symbolKey and gear
  const stickyColumns = visibleColumns.filter(col => col.sticky);
  const otherColumns = visibleColumns.filter(col => !col.sticky);
  const columnList = [...stickyColumns, ...otherColumns].map(col => ({
    key: col.key || '',
    label: col.name || '',
    type: col?.dataType || '',
  }));
  //build data structure for exporter
  return (Array.isArray(target) ? target : [target]).map(item => {
    return columnList.reduce((acc, cur: { key: string; type: string; label: string }) => {
      var keyVal = item[cur.key];
      const keyType = cur.type;
      if (keyVal && keyType === 'DateTime') {
        keyVal = moment(String(keyVal)).format(
          getDateTimeFormat(
            defaultDateTimeFormat?.dateFormatType,
            defaultDateTimeFormat?.dateFormat,
            defaultDateTimeFormat?.dateSeperator,
            defaultDateTimeFormat?.timeFormat,
            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
            defaultDateTimeFormat?.isSwitch
          )
        );
      } else if (keyVal && keyType === 'Date') {
        keyVal = moment
          .utc(String(keyVal))
          .format(
            getDateFormat(
              defaultDateTimeFormat?.dateFormatType,
              defaultDateTimeFormat?.dateFormat,
              defaultDateTimeFormat?.dateSeperator
            )
          );
      }
      if (keyVal && typeof keyVal === 'object') {
        //current key value must be a relationship key so get label instead
        keyVal = (Array.isArray(keyVal) ? keyVal : [keyVal]).map(val => (typeof val === 'object' ? val.label : ''));
        keyVal = (keyVal as Array<string>).toString();
      }
      return { ...acc, [cur.label]: keyVal };
    }, {});
  });
};

const getLatLonFromAssetType = (asset: AssetType): { lat?: number; lon?: number } => {
  let { lat, lon } = asset;
  if ((!lat || !lon) && asset.primaryLocation) {
    [lon, lat] =
      asset.primaryLocation.type === 'LineString'
        ? asset.primaryLocation.coordinates[0]
        : asset.primaryLocation.coordinates;
  }
  return { lat, lon };
};

const builtInActions: { [index: string]: BuiltInAction } = {
  csvExport: {
    label: 'Export to .CSV',
    icon: 'fa-file-export',
    onClick: (target, columnState, onDone, config) => {
      const data = buildOutputData(target, columnState, config);
      const options = {
        keySeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        title: 'csvExport',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(data);
      onDone && onDone();
    },
  },
  pdfExport: {
    label: 'Export to PDF',
    icon: 'fa-file-export',
    onClick: _.debounce((target, columnState, onDone, config) => {
      // this is lazy download to make sure the pdf rendering would not block the spinner
      let pdfTitle: string = '';
      // cycle through all of the data
      const data = (Array.isArray(target) ? target : [target]).map(item => {
        const attributes = buildOutputData(item, columnState, config);
        // There should only be one set of attributes in the output array, since we only send one item
        pdfTitle = item.label;
        return { attributes: attributes[0], title: item.label };
      });
      if (data.length > 1) {
        pdfTitle = 'pdfExport';
      }
      /*** START: PDF Generator */
      const doc = new jsPDF();
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width;
      const chunkSize = 3;
      data.forEach((item, index) => {
        let rowHeight = 10;
        let y = 20;
        let x = 10;
        const contentWidth = pageWidth - x;
        doc.text(item.title, x, y);
        doc.setDrawColor(colors.grayPlaceholder);
        y += 5;
        doc.line(x, y, contentWidth, y);
        y += 5;
        doc.setDrawColor(colors.grayLine);
        doc.rect(x, y, contentWidth - x, rowHeight, 'S');
        doc.setFontSize(12);
        doc.text('Attributes', x + 5, y + rowHeight / 2 + 2);
        const attributes = _.chain(item.attributes)
          .map((value, key) => ({ key, value }))
          .chunk(chunkSize)
          .value();
        rowHeight += 4;
        attributes.forEach((value, i) => {
          y = y + rowHeight - (i === 0 ? 4 : 0);
          value.forEach((v, ind) => {
            const xValue = x + (ind * (contentWidth - x)) / chunkSize;
            doc.rect(xValue, y, (contentWidth - x) / chunkSize, rowHeight, 'S');
            doc.setFontSize(9);
            doc.setTextColor(colors.monsoon);
            const xpos = xValue + 5;
            const ypos = y + rowHeight / 2 - 1;
            doc.text(v.key, xpos, ypos);
            doc.setTextColor(colors.black0);
            doc.setFontSize(10);
            doc.text(v.value ? v.value.toString() : '', xpos, ypos + 5);
          });
        });
        doc.setFontSize(16);
        if (index !== data.length - 1) {
          doc.addPage();
        }
      });
      const datapdf = doc.output('datauristring');
      /*** END: PDF Generator */
      const tempLink = document.createElement('a');
      tempLink.href = datapdf;
      tempLink.setAttribute('download', `${pdfTitle}.pdf`);
      tempLink.click();
      tempLink.remove();
      onDone && onDone();
    }),
  },
  delete: {
    label: 'Delete',
    icon: 'fa-lock',
    onClick: (target, columnState, onDone) => {
      onDone && onDone();
    },
  },
  directions: {
    label: 'Directions',
    icon: 'fa-directions',
    onClick: (target, columnState, onDone) => {
      if (!Array.isArray(target)) {
        let { lat, lon } = getLatLonFromAssetType(target);
        if (lat && lon) {
          window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`, '_blank');
        }
      }
      onDone && onDone();
    },
    isSingleSelectOnly: true,
  },
  streetView: {
    label: 'Street View',
    icon: 'fa-street-view',
    onClick: (target, columnState, onDone) => {
      if (!Array.isArray(target)) {
        let { lat, lon } = getLatLonFromAssetType(target); // S5-2551 TODO: How to test alert? What instead of js alert (freezes app)?
        if (lat && lon) {
          window.open(`http://www.google.com/maps?layer=c&cbll=${lat},${lon}`, '_blank');
        }
      }
      onDone && onDone();
    },
    isSingleSelectOnly: true,
  },
};
export default builtInActions;
