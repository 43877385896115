import { Input, InputAdornment, Typography, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FilterContext, useFilter } from '../contexts/FilterContext/filterContext';
import RefreshDataButton from './RefreshDataButton';
import { colors } from '../styles/theme';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { useDebounce } from './Map/component/ControlsOverlay/useDebouncedRecordSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faTableColumns,
  faTableRows,
  faTablePivot,
  faArrowUpToLine,
  faArrowDownToLine,
  faPenSwirl, 
  faMapLocationDot, 
  faLineColumns,
} from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { LayoutType, useLayoutContext } from './LayoutContext';
import DrawButton from './TGButton';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import MobileHeader from './MobileHeaders';
import AntSwitch from './TGAntSwitch';
import InfoSection from './UsersAndRoles/SearchBar/InfoSection';
import ActionsButton from './ActionsButton';
import { useUtils } from "../contexts/utilContext";
import { useCommonStyles } from "../styles/commonStyles";
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

const styles = (theme: Theme) =>
  createStyles({
    mainDiv: {
      display: 'flex',
      backgroundColor: colors.lotion,
      gap: 20,
      alignItems: 'center',
      padding: '10px 18px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        display: 'block',
        padding: '10px 15px !important',
      },
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      height: 34,
      gap: 8,
    },
    buttonSectionRoot: {
      flex: 1,
      display: 'flex',
      gap: 8,
    },
    buttonSectionInner: {
      gap: 0,
    },
    assetSearchBar: {
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      background: colors.white,
      padding: '5px 10px',
      height: 24,
      display: 'inline-flex',
      flexGrow: 1,
      maxWidth: 332,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        paddingTop: 5,
        maxWidth: '100%',
        display: 'flex',
      },
    },
    searchIcon: {
      height: 20,
      width: 20,
      color: theme.palette.grey[400],
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 14,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    input1: {
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    buttonDiv: {
      padding: '0px 14px',
      display: 'flex',
      alignItems: 'center',
    },
    mapExtentButton: {
      marginLeft: 'auto',
    },
    filterButton: {
      display: 'flex',
      backgroundRepeat: 'no-repeat',
      borderRadius: 2,
      cursor: 'pointer',
      width: 24,
      height: 24,
      alignItems: 'center',
    },
    mapFold: {
      background: '#FFECB3',
      width: 8,
      height: 16,
      transform: 'skew(0deg, -20deg)',
    },
    centerMapFold: {
      background: '#FFE082',
      transform: 'skew(0deg, 20deg)',
    },
    filterIcon: {
      marginLeft: -22,
      zIndex: 2,
      width: 21,
      height: 10,
      cursor: 'pointer',
      '& path': {
        fill: '#FF9800',
      },
    },
    filterOnIcon: {
      marginLeft: -22,
      zIndex: 2,
      width: 21,
      height: 20,
      cursor: 'pointer',
    },
    popper: {
      top: '15px !important',
    },
    tableButton: {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
    selectSwitch: {
      color: colors.black54,
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      display: 'flex',
      height: 34,
      backgroundColor: colors.white,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        marginLeft: 0,
        marginRight: 0,
        height: 33,
      },
    },
    switchBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 4,
      padding: '5px 8px',
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 1,
    },
    buttonDescriptionText: {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 6,
      color: colors.black54,
      userSelect: 'none',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
    legendPopover: {
      '& > div.MuiPopover-paper': {
        transform: 'translate(0px, 40px) !important',
      },
    },
    legendMenuVertical: {
      '& > div.MuiPopover-paper': {
        transform: 'translate(0px, 34px) !important',
      },
    },
    menuRoot: {
      boxShadow: `0px 2px 4px 0px ${colors.black15}`,
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    arrow: {
      color: colors.black54,
      position: 'relative',
      left: 5,
    },
    selectIcon: {
      height: 22,
      width: 22,
      color: theme.palette.primary.main,
    },
    showInMobile: {
      justifyContent: 'flex-start',
    },
    menuItem: {
      padding: '8px 6px',
      borderRadius: 5,
    },
  });

interface SearchBarProps extends WithStyles<typeof styles> {
  height: number;
  showing: number;
  totalAssets: number;
  numberOfFilters: number;
  clearAllFilters: () => void;
  handleColumnFilterOpen: any;
  checked: boolean;
  setChecked: Function;
  isLoadingData: boolean;
  minimizeAndExpandTable?: (value: number) => void;
  isMobileView?: boolean;
  isVertical: boolean;
}
/**
 * SearchBar - Main function is acting as a searchBar inside AssetsTableContainer for searching the assets.
 *
 * Uses handleSearchText to change text filter.
 * Also displays filter information next to the searchBar.
 * Clearing all filter button available as well, with handleFilterReset.
 *
 * @param props
 */
export interface ButtonData {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  srcImage: IconProp;
  title: string;
  view: boolean;
  anchorEl?: boolean;
  enabled?: boolean;
}
const NORMAL_SPLIT_VIEW = 50;
const VERTICAL_SPLIT_VIEW = 28;

const SearchBar: FunctionComponent<SearchBarProps> = props => {
  const {
    classes,
    clearAllFilters,
    handleColumnFilterOpen,
    showing,
    totalAssets,
    numberOfFilters,
    checked,
    setChecked,
    isLoadingData,
    isMobileView,
    isVertical,
    minimizeAndExpandTable,
  } = props;

  const { setQuickSearch } = useFilter();
  const { isIOSSafari } = useUtils();
  const { translate } = useContext(LanguageContext);
  const filterContext = useContext(FilterContext);
  const extentFilteringEnabled = filterContext.mapFilterState.extentFilterEnabled;
  const { verticalPercentage, prevVerticalPercentage, setPrevVerticalPercentage,
    scrollBarHeight, minimizedHeight, setMinimizedHeight, minimizedTableView,
    setMinimizedTableView, tableTopSearchTxt, setTableTopSearchTxt } = useContext(AssetsDashboardContext);
  const { layout, setLayout } = useLayoutContext();
  const commonClasses = useCommonStyles();
  const setMapFilter = () => {
    filterContext.setMapExtentFiltering(!extentFilteringEnabled);
  };

  useEffect(() => {
    setMinimizedTableView(verticalPercentage <= minimizedHeight);
  }, [verticalPercentage]);

  // see here for implementation details https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  useDebounce(
    () => {
      setQuickSearch(tableTopSearchTxt);
    },
    200,
    [setQuickSearch, tableTopSearchTxt]
  );

  const minimizeAndMaximizeTable = (newMinimizedTableView: boolean) => {
    const newMinimizedHeight = ((210 + scrollBarHeight) / window.innerHeight) * 100;
    setMinimizedTableView(newMinimizedTableView);
    if (verticalPercentage && minimizeAndExpandTable) {
      // If previous vertical percentage decreases to or approaches the minimized value,
      // we will expand it to 50 as a normal split view.
      const maxVerticalPercentage =
        prevVerticalPercentage < VERTICAL_SPLIT_VIEW ? NORMAL_SPLIT_VIEW : prevVerticalPercentage;
      setPrevVerticalPercentage(verticalPercentage);
      minimizeAndExpandTable(newMinimizedTableView ? newMinimizedHeight : maxVerticalPercentage);
      setMinimizedHeight(newMinimizedHeight);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilterReset = () => {
    clearAllFilters();
    //TODO: add extent filtering to clear all
    filterContext.setMapExtentFiltering(false);
    setTableTopSearchTxt('');
  };
  const open = Boolean(anchorEl);
  const id = open ? 'Legend' : undefined;
  const paperMenuProps = {
    style: customStyles.paperMenu,
  };
  const type = 'Horizontal' as LayoutType;
  const menuItems = [
    { type: 'Horizontal' as LayoutType, icon: faTableRows },
    { type: 'Vertical' as LayoutType, icon: faTableColumns },
  ];
  const buttonsData: ButtonData[] = [
    {
      onClick: filterContext.enableDrawFilter,
      srcImage: faPenSwirl as IconProp,
      title: 'Draw',
      view: !isMobileView,
    },
    {
      onClick: setMapFilter,
      srcImage: faMapLocationDot as IconProp,
      title: 'Map Extent',
      enabled: extentFilteringEnabled,
      view: true,
    },
    {
      onClick: handleColumnFilterOpen,
      srcImage: faLineColumns as IconProp,
      title: 'Organize',
      view: true,
    },
    {
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => handleClick(e),
      srcImage: faTablePivot as IconProp,
      title: 'Layout',
      anchorEl: Boolean(anchorEl),
      view: !isMobileView,
    },
    {
      onClick: () => minimizeAndMaximizeTable(!minimizedTableView),
      srcImage: (minimizedTableView ? faArrowUpToLine : faArrowDownToLine) as IconProp,
      title: minimizedTableView ? 'Expand' : 'Minimize',
      view: !(isMobileView || !isVertical),
    },
  ];
  const tooltipTexts = [
    'Enable Draw Mode',
    'Filter Records to Visible Map Extent',
    'Displayed Column Filter',
    'Change Table Layout',
    'Modify Table Proportion',
  ];
  return (
    <div className={classes.mainDiv}>
      {!isMobileView ? (
        <>
          <div className={!isMobileView ? classes.searchSection : ''}>
            <div className={classes.selectSwitch}>
              <div className={classes.switchBtn}>
                <AntSwitch
                  disabled={isLoadingData}
                  checked={checked}
                  onChange={e => {
                    setChecked(e.target.checked);
                  }}
                  value="checked"
                  color="primary"
                />
                <Typography style={{ ...customStyles.menuType, ...customStyles.text }}>
                  <>{translate('Select')}</>
                </Typography>
              </div>
              {checked && <ActionsButton />}
            </div>
            <div className={classes.assetSearchBar}>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <Search className={classes.searchIcon} />
                  </InputAdornment>
                }
                placeholder={translate('Search')}
                value={tableTopSearchTxt}
                className={clsx(classes.input, layout !== type && classes.input1, isIOSSafari && commonClasses.safariDefaultFontSize)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTableTopSearchTxt(e.target.value);
                }}
              />
            </div>
            {!!numberOfFilters ? (
              <InfoSection
                isLoadingData={isLoadingData}
                handleFilterReset={handleFilterReset}
                numberOfFilters={numberOfFilters}
              />
            ) : null}
          </div>
          <div style={customStyles.buttonMainContainer}>
            <div className={classes.buttonSectionRoot}>
              {buttonsData.map((button, index) =>
                (isMobileView && button.view) || (!isMobileView && button.view) ? (
                  <Tooltip key={index} title={translate(tooltipTexts[index])} placement="top">
                    <div>
                      <DrawButton
                        onClick={button.onClick}
                        srcImage={button.srcImage as IconProp}
                        title={button.title}
                        anchorEl={button.anchorEl}
                        enabled={button.enabled}
                      />
                    </div>
                  </Tooltip>
                ) : null
              )}
            </div>
            <RefreshDataButton />
          </div>
          <Menu
            id={id}
            anchorEl={anchorEl}
            keepMounted
            defaultValue={layout}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: isVertical ? 'left' : 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: isVertical ? 'left' : 'right' }}
            PaperProps={paperMenuProps}
            className={[classes.legendPopover, !isVertical && classes.legendMenuVertical].join(' ')}
            classes={{ paper: classes.menuRoot }}
          >
            {menuItems.map((menuItem, index) => (
              <MenuItem
                key={index}
                className={classes.menuItem}
                onClick={() => {
                  setLayout(menuItem.type);
                  handleClose();
                }}
              >
                <div style={customStyles.menuContainer}>
                  <FontAwesomeIcon icon={menuItem.icon as IconProp} style={customStyles.iconLayout} />
                  <Typography style={customStyles.menuType}>{translate(menuItem.type)}</Typography>
                  {layout === menuItem.type && (
                    <FontAwesomeIcon
                      icon={faCheck as IconProp}
                      className={classes.selectIcon}
                      style={customStyles.selectIcon}
                    />
                  )}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <MobileHeader
          isLoadingData={isLoadingData}
          checked={checked}
          setChecked={setChecked}
          buttonsData={buttonsData}
          isMobileView={isMobileView}
          layout={layout}
          type={type}
          classes={classes}
          tableTopSearchTxt={tableTopSearchTxt}
          setTableTopSearchTxt={setTableTopSearchTxt}
          ShowingSection={() =>
            !!numberOfFilters ? (
              <InfoSection
                isLoadingData={isLoadingData}
                handleFilterReset={handleFilterReset}
                numberOfFilters={numberOfFilters}
              />
            ) : null
          }
        />
      )}
    </div>
  );
};
const customStyles: { [key: string]: React.CSSProperties } = {
  menuType: {
    marginLeft: 12,
    color: colors.black54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
  },
  selectIcon: { position: 'absolute', right: 6 },
  menuContainer: { display: 'flex', alignItems: 'center' },
  text: { marginLeft: 4, textTransform: 'capitalize' },
  iconLayout: { height: 20, width: 20, color: colors.black54 },
  buttonMainContainer: { display: 'flex', gap: 8 },
  paperMenu: {
    minHeight: 59,
    padding: 6,
    width: 183,
  },
};
export default withStyles(styles)(SearchBar);
