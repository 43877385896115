import gql from 'graphql-tag';

export const fetchDistinctColumnFilterValues = (
  tableName: string,
  field: string,
  fieldType: string,
  limitValue?: number,
  searchValue?: string,
  schema?: string
) => {
  const limit = limitValue ?? null;
  const searchText = searchValue ? searchValue : '';

  return gql`
    query GetDistinctValues {
      distinctValues(
        filter: { tableName: "${tableName}", columnName: "${field}" }
        limit: ${limit}
        searchText: "${searchText}"
        fieldType: "${fieldType}"
        schema: "${schema}"
      )
    }
  `;
};
