import React, { FunctionComponent, useContext } from 'react';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { EditModeContext } from '../../contexts/editModeContext';
import { useTheme } from '@material-ui/core';
import { downloadFile } from '../Import/FileToView';
import { DOC_API_URL } from '../../utils/docApiURL';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface FileCellRendererProps {
  images: any;
  handleFileClick: (value: string, editMode: boolean) => void;
  editable: boolean;
}

const FileCellRenderer: FunctionComponent<FileCellRendererProps> = props => {
  const { images: value, handleFileClick } = props;
  const classes = useStyles();
  const { editModeActive } = useContext(EditModeContext);
  const theme = useTheme();
  const { translate } = useContext(LanguageContext);

  const handleClick = () => {
    if (value && value.length) handleFileClick(value, editModeActive);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const isMultipleRecords = Array.isArray(value) && value.length > 1;

  return (
    <div
      className={classes.container}
      style={{ color: `${theme.palette.primary.main}`, width: '100%' }}
      onClick={editModeActive ? handleClick : undefined}>
      {value && !editModeActive && Array.isArray(value) && value.length > 0 && (
        <div
          style={{ color: `${theme.palette.primary.main}`, position: 'absolute', width: '95%' }}
          onClick={
            isMultipleRecords
              ? handleMenuClick
              : () =>
                  downloadFile(
                    DOC_API_URL + value[0].id,
                    value[0].name.substring(0, value[0].name.lastIndexOf('.')),
                    undefined,
                    value[0].name.substring(value[0].name.lastIndexOf('.') + 1)
                  )
          }>
          {value.length === 1 ? translate(value[0].name) : `${value.length} ${translate('files')}`}
        </div>
      )}
      {/* Dropdown menu for selecting a record when multiple exist in a cell */}
      {isMultipleRecords && (
        <Menu
          id="multi-record-menu"
          autoFocus={false}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          {Array.isArray(value) &&
            value.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  downloadFile(
                    DOC_API_URL + item.id,
                    item.name.substring(0, item.name.lastIndexOf('.')),
                    undefined,
                    item.name.substring(item.name.lastIndexOf('.') + 1)
                  )
                }>
                {translate(item.name)}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}));

export default FileCellRenderer;
