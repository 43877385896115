import { createStyles, makeStyles, Theme, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import LoginFields, { LoginField } from './LoginFields';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';
import { colors } from '../../styles/theme';
import ForgotPasswordFlow from './ForgotPassword/ForgotPasswordFlow';

interface Props {
  fields: Array<LoginField>;
  errorMessage?: string;
  onClick?: () => void;
}

const LoginEmailView: React.FC<Props> = (props: any) => {
  const { errorMessage, fields, onClick } = props;
  const classes = useStyles();
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  return (
    <>
      <LoginFields fields={fields} errorMessage={errorMessage} />
      {errorMessage && (
        <Typography variant="h3" className={classes.errorText}>
          <>{errorMessage}</>
        </Typography>
      )}
      <TGLoginButton type={TGLoginButtonType.primary} onClick={onClick}>
        <>{'Login'}</>
      </TGLoginButton>
      <Button
        variant="text"
        className={classes.forgotText}
        onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          setShowPasswordReset(true);
        }}>
        <Typography>{'Forgot Password?'}</Typography>
      </Button>
      {showPasswordReset && (
        <ForgotPasswordFlow title={'Forgot Password'} onClose={() => setShowPasswordReset(false)} />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: colors.danger,
      marginBottom: 15,
    },
    forgotText: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: 'normal',
      color: theme.palette.primary.main,
      marginTop: 25,
      textAlign: 'center',
      padding: 0,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

export default LoginEmailView;
