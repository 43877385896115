import React, { FunctionComponent, useCallback, useContext, useEffect, useState, createContext, useRef } from 'react';
import { withStyles, WithStyles, createStyles, useTheme, Theme, Typography, CircularProgress } from '@material-ui/core';
import { useMultiSelect } from 'use-multiselect';
import { FilterContext } from '../../contexts/FilterContext/filterContext';
import { colors } from '../../styles/theme';
import { ListOnScrollProps, VariableSizeList } from 'react-window';
import Row from './FilterRow';
import _, { isEqual } from 'lodash';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { FilterState } from '../../contexts/FilterContext/types';
import { ValueType } from '../../hooks/useTable';
import { AutoSizer } from 'react-virtualized';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import { FIELDS_FETCH_SIZE } from '../AssetTable/ColumnSearch';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const HEADER_FOOTER_HEIGHT = 198;
const HEADER_FOOTER_HEIGHT_MOB = 199;
const HEADER_FOOTER_PADDING = 12;
const FIELDS_FETCH_THRESHOLD = 25;
const ROW_HEIGHT = 42;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: `calc(100% - ${HEADER_FOOTER_PADDING}px)`,
      padding: '6px 0',
      overflow: 'hidden',
    },
    selectAll: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 'normal',
      color: theme.palette.primary.main,
      marginBottom: 18,
      cursor: 'pointer',
      marginTop: 22,
    },
    body: {
      height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: `calc(100% - ${HEADER_FOOTER_HEIGHT_MOB}px)`,
      },
    },
    inner: {
      height: '100%',
      overflow: 'hidden',
      padding: '22px 34px',
      paddingTop: 0,
      paddingRight: 0,
      '&::-webkit-scrollbar, & .FilterDialog::-webkit-scrollbar': {
        width: 15,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
          width: 7,
        },
      },
      '&::-webkit-scrollbar-track, & .FilterDialog::-webkit-scrollbar-track': {
        background: 'transparent',
        border: `1px solid ${colors.black10}`,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
          border: 'none',
        },
      },
      '&::-webkit-scrollbar-thumb, & .FilterDialog::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: colors.scrollBar,
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        boxShadow: `inset 0 0 0 1px ${colors.black10}`,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
          borderRadius: 5,
          boxShadow: 'none',
          border: 'none',
        },
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: 22,
        paddingTop: 0,
        paddingRight: 0,
      },
    },
    rows: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
    },

    bodyInnerContainer: {
      marginTop: 20,
    },
    rowContainer: {
      paddingTop: 18,
    },
    loadingContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    lazyLoadingIndicator:{
      position: 'absolute',
      bottom: 5,
      left: '50%',
      transform: 'translateX(-50%)'      
    }
  });
export interface SetFilterDialogProps extends WithStyles<typeof styles> {
  displayName: string;
  field: string;
  onClose: () => void;
  fieldData: string[] | ValueType[];
  valueLookupKey: string;
  loading: boolean;
  fetchMoreFields: (
    field: string,
    limit?: number,
    offset?: number,
    searchText?: string
  ) => Promise<string[]> | ValueType[];
}
export interface SizeMapArray {
  [index: number]: number;
}
export const SizeMapContext = createContext({
  setSize: (index: number, size: number) => {},
});
/**
 * SetFilterDialog -
 *
 * Basically the 'dialog' that the user interacts with when specifically seleting what filters to apply
 *
 * @param displayName
 * @param field
 * @param classes
 * @param onClose
 * @param uniqueFieldGetter
 * @param fetchMoreFields
 */
const BLANK_FIELD = '(Blanks)';
const getSafeValue = (ob: object | string | null | undefined | ValueType, valueLookupKey: string) => {
  if (_.isObject(ob)) {
    return _.get(ob, valueLookupKey, '');
  }
  return  (ob as string) || BLANK_FIELD;
};
const FETCHING_ASSETS_COUNT = 100;
const MARGIN_SPACE = 18;
const FIRST_ELEMENT_HEIGHT = 38;
const SetFilterDialog: FunctionComponent<SetFilterDialogProps> = props => {
  const { displayName, field, classes, onClose, fieldData, valueLookupKey, loading, fetchMoreFields } = props;
  const theme = useTheme();
  const { translate } = useContext(LanguageContext);
  const [fields, setFields] = useState(fieldData);
  const listRef = useRef<VariableSizeList>(null);
  const [hasMore, setHasMore] = useState(fieldData.length >= FIELDS_FETCH_SIZE);
  const { filterState, setFilterState } = useContext(FilterContext);
  const [allSelected, setAllSelected] = useState(false);
  const [currentDataSet, setCurrentDataSet] = useState(FIELDS_FETCH_SIZE);
  const [fetchingFields, setFetchingFields] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setFields(fieldData);
    setHasMore(fieldData.length >= FIELDS_FETCH_SIZE);
  }, [fieldData]);

  const getExceptions = React.useMemo(() => {
    const exceptions: string[] = [];
    if (filterState[field]) {
      filterState[field].forEach((value: string) => exceptions.push(getSafeValue(value, valueLookupKey)));
    } else {
      fieldData.forEach(field => exceptions.push(getSafeValue(field, valueLookupKey)));
    }
    return exceptions;
  }, [filterState, fieldData]);

  const { isSelected, setSelected, getAllSelectedKeys, selectAll, deSelectAll } = useMultiSelect({
    isMultiSelectActive: true,
    allSelected: false,
    exceptions: getExceptions,
  });

  const handleDone = () => {
    let values = getAllSelectedKeys(_.map(fields, f => getSafeValue(f, valueLookupKey)));
    if (values.length !== fields.length  || searchText.length !== 0) {
      setFilterState((prevState: FilterState) => {
        values.forEach(value => !value && values.push(' '));
        const selections = _.filter(fields, value => _.includes(values, getSafeValue(value, valueLookupKey)));
        return { ...prevState, [field]: selections };
      });
    } else {
      setFilterState((prevState: FilterState) => {
        if (prevState[field]) {
          delete prevState[field];
        }
        return { ...prevState };
      });
    }
    onClose();
  };

  const handleSelectAll = () => {
    if (allSelected) {
      deSelectAll();
    } else {
      selectAll();
    }
  };

  let selectedFields = getAllSelectedKeys(fields as string[]);

  useEffect(()=>{
    if (!filterState[field]) {
      selectAll();
    }
  },[])

  useEffect(() => {
    const selectedKeys = getAllSelectedKeys(fields.map((f: ValueType) => getSafeValue(f, valueLookupKey)));
    if (selectedKeys.length === fields.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedFields, getAllSelectedKeys, fields]);

  const handleFilterSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    const newFields = await fetchMoreFields(field, FIELDS_FETCH_SIZE, undefined, searchText);
    setFields(newFields);
    setHasMore(newFields.length >= FIELDS_FETCH_SIZE);
    setCurrentDataSet(FIELDS_FETCH_SIZE);
  };

  const sizeMap = useRef<SizeMapArray>({});

  const setSize = useCallback((index: number, size: number) => {
    listRef?.current?.resetAfterIndex(0);
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);

  const getSize = useCallback((index: number) => {
    const height = sizeMap.current[index] ? sizeMap.current[index] : 0;
    return height;
  }, []);

  const loadMoreItems = async () => {
    setFetchingFields(true);
    const newFields = await fetchMoreFields(field, FIELDS_FETCH_SIZE, currentDataSet);
    if (!_.isEmpty(newFields)) {
      setCurrentDataSet(currentValue => currentValue + FIELDS_FETCH_SIZE);
      setFields(prevFields => [...prevFields, ...newFields]);
    } else {
      setHasMore(false);
    }
    setFetchingFields(false);
  };

  const handleScroll = ({ scrollOffset }: ListOnScrollProps) => {
    const currentIndex = Math.floor(scrollOffset / ROW_HEIGHT);
    if (currentIndex >= currentDataSet - FIELDS_FETCH_THRESHOLD && !fetchingFields && hasMore) {
      loadMoreItems();
    }
  };

  const itemCount = fields.length + 1;
  return (
    <div className={classes.root}>
      <ModalHeader {...{ displayName, onClose, handleFilterSearch }} />
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <SizeMapContext.Provider value={{ setSize }}>
          <div className={classes.body}>
            <div className={classes.inner}>
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    ref={listRef}
                    height={height + MARGIN_SPACE}
                    itemCount={itemCount}
                    onScroll={handleScroll}
                    itemSize={index => (isEqual(index, 0) ? FIRST_ELEMENT_HEIGHT : getSize(index - 0))}
                    width={width}
                    className="FilterDialog"
                  >
                    {({ index, style }) => (
                      <div className={classes.bodyInnerContainer}>
                        <div style={style} key={index}>
                          {isEqual(index, 0) ? (
                            <Typography onClick={handleSelectAll} className={classes.selectAll}>
                              <>{translate(allSelected ? 'Deselect All' : 'Select All')}</>
                            </Typography>
                          ) : (
                            <div className={classes.rowContainer}>
                              <Row
                                index={index}
                                value={getSafeValue(fields[index - 1], valueLookupKey)}
                                style={style}
                                isSelected={isSelected}
                                setSelected={setSelected}
                                theme={theme}
                                classes={classes}
                                field={field}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </VariableSizeList>
                )}
              </AutoSizer>
              {fetchingFields && <div className={classes.lazyLoadingIndicator}><CircularProgress color="primary"/></div>}
            </div>
            <ModalFooter {...{ handleDone, handleCancel: onClose }} />
          </div>
        </SizeMapContext.Provider>
      )}
    </div>
  );
};

export default withStyles(styles)(SetFilterDialog);
