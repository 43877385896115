import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import SymbolCellRenderer from '../components/AssetTable/SymbolCellRenderer';
import LinkedCellRenderer, { LinkedCellRendererProps } from '../components/AssetTable/LinkedCellRenderer';
import { useRecordType } from '../contexts/recordTypeContext';
import DropdownEditor from '../components/AssetTable/DropdownEditor';
import { Column, CellRendererProps, FilterRendererProps } from '../hooks/tableHooks/useColumns';
import StyledTableLinkedAssetEditor from '../components/StyledTable/Editors/StyledTableLinkedAssetEditor';
import ModalAssetEditor from '../components/AssetTable/TableCellEditors/ModalAssetEditor';
import { ValueType } from '../hooks/useTable';
import gearColumn from '../utils/gearColumn';
import AssetThreeDotsMenuCell from '../components/AssetTable/AssetsThreeDotsMenuCell';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { AssetType } from './AggregatesContext/types';
import PhotoEditor from '../components/AssetTable/PhotoEditor';
import StyledTableDateEditor from '../components/StyledTable/Editors/StyledTableDateEditor';
import { usePhotoViewer } from '../hooks/usePhotoViewer';
import PhotoCellRenderer from '../components/AssetTable/PhotoCellRenderer';
import ColumnSearch from '../components/AssetTable/ColumnSearch';
import { EditModeContext } from '../contexts/editModeContext';
import { useConfig } from '@terragotech/gen5-shared-components';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateFormat, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import FileCellRenderer from '../components/AssetTable/FileCellRenderer';
import { useFileViewer } from '../hooks/useFileViewer';
import { isArray } from 'lodash';
import { colors } from '../styles/theme';
import { useMultiSelectWithProvider } from 'use-multiselect';
import { Typography } from '@material-ui/core';
import { useAggregates } from './AggregatesContext';
import SelectAllCheckbox from '../components/SelectAllCheckbox';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedItemsState } from '../recoil/atoms/mapMaintenance';
import { LanguageContext } from './LanguageContext/languageContext';
import { columnsSelector } from '../recoil/atoms';

const PREFERENCE_KEY = 'columnState';
const scrollbarWidth = '16';
export interface SingleColumnState {
  key: string;
  hidden: boolean;
  sticky: boolean;
}
export type ColumnsState = Array<SingleColumnState>;
type AssetInfo = { id: string; location: 'map' | 'table' | 'tab' };
interface UseTableColumnsProps {
  recordType: string;
  children?: React.ReactNode;
}
interface TableColumnContextType {
  columns: Column<AssetType>[];
  defaultColumnsState: ColumnsState;
  setColumnOrder: (columns: Pick<SingleColumnState, 'key' | 'sticky'>[]) => void;
  setColumnState: (newState: ColumnsState) => void;
  currentAssetId: AssetInfo;
  setCurrentAssetId: Dispatch<SetStateAction<AssetInfo>>;
}
const defaultContext = {
  columns: [],
  defaultColumnsState: [],
  setColumnOrder: () => {},
  setColumnState: () => {},
  currentAssetId: { id: '', location: 'map' as const },
  setCurrentAssetId: () => {},
};
export const TableColumnContext = createContext<TableColumnContextType>(defaultContext);
export const castValueToLinkedCellValue = (value?: ValueType): LinkedCellRendererProps['value'] => {
  if (!value) {
    return '';
  }
  if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string') {
    return String(value);
  } else if (Array.isArray(value)) {
    return value.map(record =>
      typeof record === 'object'
        ? {
            id: record['id'] ?? '',
            label: record['label'] ?? '',
          }
        : { id: '', label: '' }
    );
  }
  return {
    id: value['id'] ?? '',
    label: value['label'] ?? '',
  };
};

export const TableColumnContextProvider: FunctionComponent<UseTableColumnsProps> = props => {
  const history = useHistory();
  const { defaultDateTimeFormat } = useConfig();
  const { translate, language } = useContext(LanguageContext);
  const [columnState, setColumnState] = useRecoilState(columnsSelector);
  const setGlobalItems = useSetRecoilState(selectedItemsState)
  const { fullscreen, setFullscreen, setVerticalPercentage, toggleMapView, setVisibilityModal } = useContext(
    AssetsDashboardContext
  );
  const [defaultColumnsState, setDefaultColumnsState] = useState<ColumnsState>([]);
  const [currentType, setCurrentType] = useState<String>('');
  const { editModeActive, editModeData } = useContext(EditModeContext);
  const [currentAssetId, setCurrentAssetId] = useState<AssetInfo>({ id: '', location: 'map' as const });
  const assetInfo = useAggregates();
  const { selectedRecordType, selectedRecordTypeDefinition } = useRecordType();
  const match = useRouteMatch<{ assetId: string }>(`/${selectedRecordType}/:assetId`);
  
  useEffect(() => { 
    setCurrentAssetId(o => ({ ...o, id: match?.params.assetId || '' }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRowEditActive = useCallback(
    (row: AssetType) =>
      isArray(editModeData) ? editModeData.some(x => x.id === row['id']) : row['id'] === editModeData?.id,
    [editModeData]
  );

  const photoViewer = usePhotoViewer();
  const fileViewer = useFileViewer();
  const rendererWrapper = <P extends unknown>(
    { row }: CellRendererProps<AssetType>,
    Component: (props: P & { value: ValueType; data: AssetType }) => JSX.Element,
    rendererProps: any,
    key: string,
    editable: boolean,
    columnType: string
  ) => {
    return row?.id ? (
      <div
        style={{
          width: '95%',
          paddingRight: 10,
          overflow: 'hidden',
          paddingLeft: editable && editModeActive && isRowEditActive(row) ? '2px' : undefined,
        }}
      >
        <Component {...rendererProps} value={row[key]} data={row} />
      </div>
    ) : (
      <></>
    );
  };

  const localSetColumnState = useCallback(
    (newState: ColumnsState) => {
      setColumnState(newState);
    },
    [setColumnState]
  );
  /**
   * convenience function for setting just the column order of the columnState
   *   This will leave hidden alone, but will update order and pinned values
   */
  const { isMultiSelectActive } = useMultiSelectWithProvider();

  const setColumnOrder = useCallback(
    (columns: Array<Omit<SingleColumnState, 'hidden'>>) => {
      setColumnState(currentState => {
        const newState = columns.map(column => {
          const hidden = currentState.find(col => col.key === column.key)?.hidden || false;
          return {
            ...column,
            hidden,
          };
        });
        return newState;
      });
    },
    [setColumnState]
  );

  const columns = useMemo(() => {
    const handleTitleClick = (
      rowAssetId: string,
      rowRecordType: string,
      clickedAssetId: string,
      clickedRecordType: string
    ) => {
      // TODO: Add support for going back
      if (rowAssetId !== clickedAssetId) {
        history.push(`/${rowRecordType}/${rowAssetId}/edit/${clickedRecordType}/${clickedAssetId}`);
      } else {
        history.push(`/${rowRecordType}/${rowAssetId}/edit`);
      }
    };

    const handleSymbolClick = (assetId: string, recordType: string, record:any) => {
      setVisibilityModal(false);
      toggleMapView(true);
      if (history.location.pathname === `/${recordType}/${assetId}`) {
        history.push(`/${recordType}`);
        setGlobalItems([]);
        setCurrentAssetId(o => ({ ...o, id: '' }));
      } else {
        const path = `/${recordType}/${assetId}`;
        setCurrentAssetId({ id: assetId, location: 'table' as const });
        setGlobalItems([record]);
        history.push(path);
      }
      if (fullscreen) {
        setFullscreen(false);
        setVerticalPercentage(50);
      }
    };

    const handlePhotoClick = (images: any, editMode: boolean) => {
      photoViewer.open({
        images: images,
        editMode: editMode,
      });
    };

    const handleFileClick = (images: any, editMode: boolean) => {
      fileViewer.open({
        images: images,
        editMode: editMode,
      });
    };

    const blankOptionValue = '';
    const blankOptionLabel = '(Blank)';
    const enumOptionsMapper = (option: unknown) => {
      if (option === '' || option === null || option === undefined) {
        return {
          value: blankOptionValue,
          label: blankOptionLabel,
        };
      } else {
        return {
          value: option as string,
          label: option as string,
        };
      }
    };

    const visiblePropertyCount = selectedRecordTypeDefinition.propertyDefinitions.filter(
      c => !['Hidden', 'Geography', 'JSON', 'Symbol Key'].includes(c.uiType)
    ).length;
    const defs = selectedRecordTypeDefinition.propertyDefinitions
      .filter(c => c.uiType !== 'Hidden') // Eventually this should be filtered at the server instead
      .map(column => {
        const outColumn: Column<AssetType> = {
          name: column.label,
          key: column.field,
          editable: column.isEditable,
          minWidth: 200,
          width: `calc(${100 / Math.max(visiblePropertyCount, 1)}% - ${scrollbarWidth}px)`,
          dataType: column.type === 'String' && column.validOptions !== null ? 'Select' : column.type,
        };

        switch (column.uiType) {
          case 'DateTime':
            outColumn.cellEditor = StyledTableDateEditor;
            outColumn.dataType = 'DateTime';
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                props => {
                  return (
                    <div>
                      {props.value &&
                        moment(String(props.value)).format(
                          getDateTimeFormat(
                            defaultDateTimeFormat?.dateFormatType,
                            defaultDateTimeFormat?.dateFormat,
                            defaultDateTimeFormat?.dateSeperator,
                            defaultDateTimeFormat?.timeFormat,
                            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                          )
                        )}
                    </div>
                  );
                },
                {},
                column.field,
                column.isEditable,
                column.uiType
              );
            break;
          case 'Date':
            outColumn.cellEditor = StyledTableDateEditor;
            outColumn.dataType = 'Date';
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                props => {
                  return (
                    <div>
                      {props.value &&
                        moment
                          .utc(String(props.value))
                          .format(
                            getDateFormat(
                              defaultDateTimeFormat?.dateFormatType,
                              defaultDateTimeFormat?.dateFormat,
                              defaultDateTimeFormat?.dateSeperator
                            )
                          )}
                    </div>
                  );
                },
                {},
                column.field,
                column.isEditable,
                column.uiType
              );
            break;
          case 'Hidden':
          case 'Geography':
          case 'JSON':
            outColumn.hidden = true;
            break;
          case 'Float':
          case 'String':
          case 'Boolean':
          case 'Int':
          case 'Link':
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                props => {
                  return (
                    <div>
                      <>{props.value}</>
                    </div>
                  );
                },
                { handleTitleClick, aggregateTypeName: column.type, editable: column.isEditable },
                column.field,
                column.isEditable,
                column.uiType
              );
            break;
          case 'Title':
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                (props: any) => <LinkedCellRenderer {...props} value={castValueToLinkedCellValue(props.value)} />,
                { handleTitleClick, aggregateTypeName: column.type, editable: column.isEditable },
                column.field,
                column.isEditable,
                column.uiType
              );
            outColumn.sticky = true;
            break;
          case 'Symbol Key':
            outColumn.name = 'Map';
            outColumn.filterRenderer = false;
            outColumn.sortable = false;
            outColumn.editable = false;
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                (props: any) => <SymbolCellRenderer {...props} value={String(props.value)} />,
                { handleSymbolClick: (id:string,type:string)=>handleSymbolClick(id,type,formatterProps.row) },
                column.field,
                column.isEditable,
                column.uiType
              );
            outColumn.sticky = true;
            outColumn.maxWidth = 50;
            outColumn.width = 40;
            outColumn.minWidth = undefined;
            break;
          case 'PhotoCollection':
            outColumn.cellEditor = React.forwardRef((props, ref) => <PhotoEditor {...props} ref={ref} />);
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                props => {
                  const value = formatterProps.row.__photos || props.value;
                  return (
                    <PhotoCellRenderer
                      handlePhotoClick={handlePhotoClick}
                      images={value}
                      editable={!!outColumn.editable}
                    />
                  );
                },
                { handlePhotoClick },
                column.field,
                false,
                column.uiType
              );
            break;
          case 'FileCollection':
            outColumn.cellEditor = React.forwardRef((props, ref) => <PhotoEditor {...props} ref={ref} />);
            outColumn.cellRenderer = formatterProps =>
              rendererWrapper(
                formatterProps,
                props => {
                  const value = formatterProps.row.__files || props.value;
                  return (
                    <FileCellRenderer
                      handleFileClick={handleFileClick}
                      images={value}
                      editable={!!outColumn.editable}
                    />
                  );
                },
                { handleFileClick },
                column.field,
                false,
                column.uiType
              );
            break;          
        }

        //if a relationship type, choose different type of selector
        if (column.isRelationship) {
          outColumn.cellRenderer = formatterProps =>
            rendererWrapper(
              formatterProps,
              (props: any) => <LinkedCellRenderer {...props} value={castValueToLinkedCellValue(props.value)} />,
              { handleTitleClick, aggregateTypeName: column.type, editable: column.isEditable },
              column.field,
              column.isEditable,
              column.uiType
            );
          outColumn.cellEditor = React.forwardRef((props, ref) => (
            <StyledTableLinkedAssetEditor
              {...props}
              aggregateTypeName={column.type}
              singleSelect={column.relationshipType === 'ONE_TO_ONE' || column.relationshipType === 'ONE-TO-ONE'}
              ref={ref}
            />
          ));
          // we don't currently support filtering one to many relationships, so for now filtering is disabled on those fields
          if (column.relationshipType === 'ONE_TO_MANY' || column.relationshipType === 'ONE-TO-MANY') {
            outColumn.filterRenderer = false;
          } else {
            outColumn.filterRenderer = ({ column }: FilterRendererProps<AssetType>) => {
              return <ColumnSearch displayName={column.name} field={column.key} />;
            };
          }
        }

        if (column.validOptions && column.validOptions.enum) {
          const options = [...column.validOptions.enum];
          if (options[0] !== blankOptionValue) {
            options.unshift(blankOptionValue);
          }
          const optionsWithLabels = options.map(x => enumOptionsMapper(x));

          const smallNumberOfOptions = 5;
          if (options.length <= smallNumberOfOptions) {
            outColumn.cellEditor = React.forwardRef((props, ref) => (
              <DropdownEditor {...props} options={optionsWithLabels} ref={ref} />
            ));
          } else {
            outColumn.cellEditor = React.forwardRef((props, ref) => (
              <ModalAssetEditor
                {...props}
                ref={ref}
                initialValue={enumOptionsMapper(props.row[props.column.key]).value}
                options={optionsWithLabels}
                title={column.type}
              />
            ));
          }
        }
        return outColumn;
      });

    //add in the leftmost gear column
    defs.unshift(
      gearColumn(
        props => <AssetThreeDotsMenuCell data={props.row} isSummaryRow={props.isSummaryRow} />,
        () => <></>,
        () =>
          isMultiSelectActive ? (
            <SelectAllCheckbox
              forceSelect={false}
              loading={assetInfo.multiSelectCommandsLoading}
            />
          ) : (
            <Typography style={styles.header}>{translate('Action')}</Typography>
          )
      )
    );


    // This is needed to check if columns have changed in ColumnFilter component
    // Default state assigned before order + visibility preferences are applied
    // Recalculated when record type changes or length is 0
    if (defaultColumnsState.length === 0 || currentType !== selectedRecordType) {
      const state = defs.map(col => ({
        key: col.key,
        hidden: !!col.hidden,
        sticky: !!col.sticky,
      }));
      setDefaultColumnsState(state);
      setColumnState(prev=>prev || state);
      setCurrentType(selectedRecordType);
    }

    //apply the current order and visibility settings
    if (columnState) {
      const localColOrder = columnState.map((pref: SingleColumnState) => pref.key);
      defs.sort((a, b) => {
        return localColOrder.indexOf(a.key || '') - localColOrder.indexOf(b.key || '');
      });
      columnState.forEach((preference: SingleColumnState) => {
        const colDef = defs.find(column => column.key === preference.key);
        if (colDef) {
          if (preference && preference.key) {
            colDef.sticky = preference.sticky;
          }
          if (preference && preference.hidden) {
            colDef.hidden = preference.hidden;
          }
        }
      });
    }

    return defs;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRecordTypeDefinition.propertyDefinitions,
    defaultColumnsState.length,
    currentType,
    selectedRecordType,
    columnState,
    history,
    fullscreen,
    setFullscreen,
    setVerticalPercentage,
    isMultiSelectActive,
    editModeData,
    editModeActive,
    language,
  ]);

  const styles: { [key: string]: React.CSSProperties } = {
    header: {
      fontWeight: 400,
      fontSize: 13,
      color: colors.black0,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      zIndex: 50,
      backgroundColor: colors.lightGray,
    },
  };
  const value = useMemo(() => {
    return {
      columns,
      defaultColumnsState,
      setColumnOrder,
      setColumnState: localSetColumnState,
      currentAssetId,
      setCurrentAssetId,
    };
  }, [columns, defaultColumnsState, setColumnOrder, localSetColumnState, currentAssetId, setCurrentAssetId]);
  return <TableColumnContext.Provider value={value}>{props.children}</TableColumnContext.Provider>;
};
export const useTableColumns = () => useContext(TableColumnContext);
