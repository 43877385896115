import { IconName, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { BuiltInAction, CommandAction } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';
import { useContext } from 'react';

interface ActionData {
  label?: string | undefined;
  icon?: string | undefined;
  onClick: () => void;
  state: 'hidden' | 'disabled' | 'enabled' | 'loading';
  disabledMessage?: string | undefined;
  action: BuiltInAction | CommandAction;
  conditionalMap?: NodeMapDefinition | undefined;
}

interface Props {
  workflowElements: null | HTMLElement;
  moreButtons: (ActionData | null)[];
  onClickMore: (event: React.MouseEvent<HTMLElement>) => void;
  closeMore: () => void;
  isMobileView: boolean;
}

const MoreMenu = ({ moreButtons, workflowElements, onClickMore, closeMore, isMobileView }: Props) => {
  const open = Boolean(workflowElements);
  const { translate } = useContext(LanguageContext);
  const classes = useStyles();
  const openMenu = (action: ActionData) => {
    closeMore();
    action?.onClick();
  };
  return (
    <>
      <IconButton className={classes.moreButtton} onClick={onClickMore}>
        {isMobileView && <Typography className={classes.label}>{translate('Actions')}</Typography>}
        <FontAwesomeIcon icon={faEllipsisV} className={classes.moreIcon} />
      </IconButton>

      <Menu
        anchorEl={workflowElements}
        keepMounted
        open={open}
        onClose={closeMore}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.menuRoot,
        }}
      >
        {_.compact(moreButtons).map(action => (
          <MenuItem key={action?.label} onClick={() => openMenu(action)}>
            <CardLabel action={action!} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CardLabel = ({ action }: { action: ActionData }) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <Button
      size="small"
      startIcon={action.icon && <FontAwesomeIcon icon={['fas', action.icon as IconName]} className={classes.icon} />}
    >
      <Typography className={classes.title}>{translate(action.label || '')}</Typography>
    </Button>
  );
};

const useStyles = makeStyles(theme => ({
  moreButtton: {
    height: 34,
    width: 34,
    boxShadow: `0px 1px 4px 0px ${colors.black15}`,
    borderRadius: 5,
    [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
      width: 'auto',
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black75,
    lineHeight: 'normal',
    fontStyle: 'normal',
    marginRight: 10,
  },
  moreIcon: {
    fontSize: 20,
  },
  icon: {
    color: colors.black54,
  },
  title: {
    fontSize: 13,
    lineHeight: 'normal',
    fontWeight: 400,
    color: colors.black75,
  },
  menuRoot: {
    marginTop: 5,
  },
}));

export default MoreMenu;
