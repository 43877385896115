import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
} from '@material-ui/core';
import { useStyles } from './Common';
import clsx from 'clsx';
import AggregateSelectorMapContainer, { AggregateSelection } from '../../containers/AggregateSelectorMapContainer';
import DialogueWrapper, { ModalProps } from '../Common/DialogueWrapper';
import { useAggregates } from '../../contexts/AggregatesContext';
import HideNonSelectableButtons from '../Map/component/HideNonSelectableButton';
import { TGLabelWrapperWeb, TGLabelProps, FieldHeader } from '@terragotech/gen5-shared-components';
import { colors } from '../../styles/theme';
import { useLocationStyles as locationStylesRoot } from '../../components/FormFields/TGLocationField/CommonEditorUtils';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import TGTabs from './TGTab';
import { isUndefined } from 'lodash';
import useHideNonSelectable from '../Map/component/useHideNonSelectable';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface TGMapAggregateSelectorFieldProps extends TGLabelProps {
  value: AggregateSelection | null | undefined;
  label: string;
  placeholder?: string;
  onChange: (newValue: AggregateSelection | null) => void;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  helperText?: string;
  selectableAggregateTypes?: string[];
  valueLabel?: string | undefined;
}
const SelectableAggregateTypesToast = (props: { isOpen: boolean; setTypesToastIsShowing: (arg0: boolean) => void }) => {
  const { isOpen, setTypesToastIsShowing } = props;
  const toastStyles = useAggregateTypesToastStyles();
  const { translate } = useContext(LanguageContext);

  const Markup = () => (
    <div className={toastStyles.container}>
      <span className={toastStyles.text}>
        {translate(
          'You have selectable record types that are currently hidden from this view. To view all available records, adjust record visibility on the home screen.'
        )}
      </span>
      <span onClick={() => setTypesToastIsShowing(false)} className={toastStyles.close}>
        X
      </span>
    </div>
  );

  return isOpen ? <Markup /> : <></>;
};
const TGMapAggregateSelectorField: React.FC<
  TGMapAggregateSelectorFieldProps & { toggleModal: ModalProps['toggleModal'] }
> = props => {
  const { value, label, onChange, toggleModal, selectableAggregateTypes, valueLabel } = props;
  const { translate } = useContext(LanguageContext);
  const [capturedAggregate, setCapturedAggregate] = useState<AggregateSelection | null | undefined>(value);
  const locationStyles = useLocationStyles();
  const locStyles = locationStylesRoot();
  const { isMobileView } = useContext(AssetsDashboardContext);

  const { assetData, handleHidingNonSelectableRecords, hiding, visibleAggregateTypesNames } = useHideNonSelectable();
  const [typesToastIsShowing, setTypesToastIsShowing] = useState(
    !!selectableAggregateTypes && selectableAggregateTypes.some(type => !visibleAggregateTypesNames.includes(type))
  );
  const onDonePress = () => {
    if (capturedAggregate) {
      onChange(capturedAggregate);
    }
    toggleModal && toggleModal();
  };
  const onCancelPress = () => {
    toggleModal && toggleModal();
  };
  const [close, setClose] = useState<boolean>(true);
  const [mapLabel, setMapLabel] = useState<string | undefined>(undefined);
  const handleClose = () => {
    setClose(false);
  };
  useEffect(() => {
    if (isUndefined(mapLabel)) {
      setMapLabel(valueLabel);
    }
  }, [valueLabel, mapLabel]);
  const RenderFieldHeader = ({ style }: { style?: string }) => (
    <FieldHeader
      type="location"
      title={label}
      canSave
      onDonePress={onDonePress}
      onCancelPress={onCancelPress}
      style={style}
      doneButtonText={'Add'}
      cancelButtonText={'Cancel'}
      canSaveActive
      translate={translate}
    />
  );
  const renderValue = () => (
    <Box className={locationStyles.rootContainer}>
      <Box className={locationStyles.pointContainer}>
        <Typography className={locationStyles.label}>{translate('Point')}</Typography>
        {<Typography className={locationStyles.value}>{`${translate(mapLabel || '')}`}</Typography>}
      </Box>
    </Box>
  );
  const AggregateSelector = () => (
    <Grid item xs={12} sm={12} className={locationStyles.mapContainer}>
      <SelectableAggregateTypesToast isOpen={typesToastIsShowing} setTypesToastIsShowing={setTypesToastIsShowing} />
      <HideNonSelectableButtons
        {...{
          handleHidingNonSelectableRecords: () => handleHidingNonSelectableRecords(selectableAggregateTypes),
          hiding,
        }}
        paddingBottom={isMobileView ? 14 : 34}
        paddingRight={13}
      />
      <AggregateSelectorMapContainer
        assetData={assetData}
        selectableAggregateTypes={selectableAggregateTypes}
        onAggregateSelected={aggregate => {
          setCapturedAggregate(curAggregate => (curAggregate?.id === aggregate?.id ? null : aggregate));
        }}
        selectedAggregate={capturedAggregate}
        getValueFromSymbolClick={value => {
          setMapLabel(value);
        }}
      />
      {close && (
        <Box className={[locStyles.centerModal, locationStyles.container2].join(' ')}>
          <Typography className={locationStyles.bodyTxt}>{translate('Tap a point to make a selection')}</Typography>
          <Button variant="text" className={locStyles.closeBtn} onClick={handleClose}>
            {translate('Close')}
          </Button>
        </Box>
      )}
    </Grid>
  );
  return (
    <FormControl className={locationStyles.fcRoot}>
      <Grid container spacing={2} className={locationStyles.gridContainer}>
        {isMobileView ? (
          <Grid item xs={12} sm={4} className={locationStyles.gridItem}>
            <RenderFieldHeader style={locationStyles.footContainer} />
            <TGTabs
              showDivider
              label={['Map', 'Point']}
              firstTabContent={AggregateSelector()}
              secondTabContent={renderValue()}
            />
          </Grid>
        ) : (
          <>
            {AggregateSelector()}
            <Grid item xs={12} sm={4} className={locationStyles.gridItem}>
              <RenderFieldHeader />
              {renderValue()}
            </Grid>
          </>
        )}
      </Grid>
    </FormControl>
  );
};

const DefaultView: React.FC<TGMapAggregateSelectorFieldProps> = props => {
  const { label, value, required, readOnly, error, warning, helperText, info, onChange } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [tooltip, setTooltip] = useState(false);
  const aggregates = useAggregates();
  const valueLabel = useMemo(() => {
    if (value?.label) {
      return value.label;
    } else {
      if (value?.id) {
        const linkedAsset = aggregates.assets.find(agg => agg.id === value.id);
        return linkedAsset?.label;
      } else {
        return value?.id;
      }
    }
  }, [aggregates.assets, value]);
  const getLabel = useMemo(() => (isUndefined(valueLabel) ? 'Undefined' : valueLabel), [valueLabel]);
  return (
    <div onMouseEnter={() => readOnly && setTooltip(true)} onMouseLeave={() => readOnly && setTooltip(false)}>
      <DialogueWrapper
        {...props}
        clearValue={() => onChange(null)}
        type="aggregateSelector"
        position={'top'}
        renderHeader={() => {
          return (
            <InputLabel error={error} className={clsx(warning && classes.warningText)}>
              <TGLabelWrapperWeb
                required={required}
                readOnly={readOnly}
                label={label}
                info={info}
                tooltip={tooltip}
                error={error}
                translate={translate}
              />
            </InputLabel>
          );
        }}
        renderValue={() => {
          return (
            <>
              {!!value && <Typography className={classes.value}>{`${valueLabel}`}</Typography>}
              <FormHelperText error={error} className={clsx(warning && classes.warningText)}>
                {translate(helperText || '')}
              </FormHelperText>
            </>
          );
        }}
        renderComponent={toggleModal => {
          return <TGMapAggregateSelectorField {...props} toggleModal={toggleModal} valueLabel={getLabel} />;
        }}
      />
    </div>
  );
};

export default DefaultView;
const MAP_CONTAINER_HEADER = 166;
const MOBILE_POINT_HEADER_HEIGHT = 176;
const useLocationStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '0px !important',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        position: 'relative',
        padding: '0px !important',
        height: `calc(100% - ${MAP_CONTAINER_HEADER}px)`,
      },
    },
    footContainer: {
      width: '-webkit-fill-available',
      padding: '14px 30px',
      display: 'flex',
      flexDirection: 'row',
      gap: 14,
      alignItems: 'center',
      justifyContent: 'end',
      height: 39,
      position: 'absolute',
      bottom: 6,
      right: 0,
      borderTop: `1px solid ${colors.black10}`,
      background: colors.white,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '14px 22px',
        boxShadow: ` 0px -2px 4px 0px ${colors.black05}`,
        borderTop: 'none',
        background: colors.white,
        zIndex: 99,
        position: 'fixed',
        bottom: 0,
      },
    },
    fcRoot: {
      height: '100%',
      flex: 1,
    },
    gridContainer: {
      display: 'flex',
      boxSizing: 'border-box',
      flexWrap: 'nowrap',
      height: '100%',
      width: '100%',
      margin: 0,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        flexWrap: 'wrap',
      },
    },
    gridItem: {
      position: 'relative',
      padding: '0px !important',
      maxWidth: 400,
      flexBasis: '50%',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        position: 'unset',
        padding: '0px !important',
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    body: {
      padding: '22px 34px',
    },
    bodyTxt: {
      color: `var(--Schemes-Shadow, ${colors.black0})`,
      fontStyle: 'normal',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
    },
    container2: {
      alignItems: 'center',
      width: 'auto',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        position: 'absolute',
        top: 19,
      },
    },
    pointContainer: {
      display: 'flex',
      padding: '9px 16px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 8,
      border: `1px solid ${colors.black10}`,
      background: colors.white,
    },
    rootContainer: {
      padding: '22px 30px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: `calc(100% - ${MOBILE_POINT_HEADER_HEIGHT}px)`,
        padding: '22px',
      },
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: `var(--Schemes-Shadow, ${colors.black0})`,
    },
    value: {
      display: 'flex',
      padding: '3px 10px',
      alignItems: 'center',
      gap: 10,
      borderRadius: 10,
      border: `1px solid ${colors.black10}`,
      background: colors.ghostWhite,
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 'normal',
      color: `var(--Schemes-Shadow, ${colors.black0})`,
    },
  })
);

const useAggregateTypesToastStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      zIndex: 100,
      cursor: 'pointer',
      borderRadius: '4px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
      margin: '0 10px 10px 0',
      color: 'white',
      backgroundColor: 'rgb(47, 51, 55, 1)',
      width: '55%',
      left: '22%',
      bottom: '-35px',
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
    },
    text: { flex: '15', display: 'flex', alignItems: 'center', fontSize: '10px' },
    close: { flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' },
  })
);
