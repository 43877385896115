import React, { forwardRef, useState, useCallback, useContext } from 'react';
import { ThemeProvider, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import useStyles, { getClasses } from './Common';
import { StyledTableEditorProps, StyledTableEditorRef } from '../../../hooks/tableHooks/useColumns';
import { useConfig } from '@terragotech/gen5-shared-components';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import { calendarTheme } from '../../Common/CalendarTheme';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

/** StyleTableDateEditor - Editor for selecting dates for cells inside date columns */
export default forwardRef<StyledTableEditorRef, StyledTableEditorProps>((props, ref) => {
  const { row, column, onRowChange, onClose } = props;
  const value = row[column.key] as Date;
  const [open, setOpen] = useState(false);
  const { translate } = useContext(LanguageContext);
  const { defaultDateTimeFormat } = useConfig();
  const [date, setDate] = useState<any>(
    value
      ? moment(String(value)).format(
          getDateTimeFormat(
            defaultDateTimeFormat?.dateFormatType,
            defaultDateTimeFormat?.dateFormat,
            defaultDateTimeFormat?.dateSeperator,
            defaultDateTimeFormat?.timeFormat,
            { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
          )
        )
      : ''
  );
  const classes = useStyles();
  const handleOnClick = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    handleOnClick();
  }, []);

  const closePicker = async () => {
    await setOpen(false);
  };
  const handleCancel = async () => {
    onClose();
  };
  const handleOk = useCallback(
    async (date: any): Promise<void> => {
      setDate(
        date
          ? moment(String(date)).format(
              getDateTimeFormat(
                defaultDateTimeFormat?.dateFormatType,
                defaultDateTimeFormat?.dateFormat,
                defaultDateTimeFormat?.dateSeperator,
                defaultDateTimeFormat?.timeFormat,
                { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
              )
            )
          : ''
      );
      let isoDate = date ? date.toISOString() : null;
      onRowChange({ ...row, [column.key]: isoDate, __changes: { [column.key]: isoDate } });
      closePicker();
    },
    [column.key, onRowChange, row]
  );

  return (
    <div className={getClasses(props, classes).join(' ')}>
      <TextField
        value={date}
        placeholder={translate('Search')}
        onClick={handleOnClick}
        inputProps={{ 'aria-label': `change data`, className: classes.input }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
      <ThemeProvider theme={calendarTheme}>
        <DateTimePicker
          innerRef={ref}
          open={open}
          variant="dialog"
          onChange={handleOk}
          value={date}
          // this is ignored because it's missing types in the package
          // @ts-ignore
          onDismiss={handleCancel}
          allowKeyboardControl
          inputProps={{ style: { display: 'hidden' } }}
          InputProps={{ style: { display: 'hidden' } }}
          TextFieldComponent={() => {
            return null;
          }}
          clearable
        />
      </ThemeProvider>
    </div>
  );
});
