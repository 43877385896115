import { makeStyles, Paper, Button, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext/languageContext';

interface BottomBarProps {
  width: number;
  left: number;
  currentIndex: number;
  numberOfElements: number;
  hasNext: boolean;
  hasPrev: boolean;
  goToNext: () => void;
  goToPrev: () => void;
}

const BottomBar = (props: BottomBarProps) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  return (
    <Paper elevation={2} className={classes.bottomBar} style={{ width: props.width, left: props.left }}>
      <Button startIcon={<NavigateBeforeIcon />} onClick={props.goToPrev} color="primary" disabled={!props.hasPrev}>
        {translate('BACK')}
      </Button>
      <Typography>
        {props.currentIndex + 1}/{props.numberOfElements}
      </Typography>
      <Button endIcon={<NavigateNextIcon />} onClick={props.goToNext} color="primary" disabled={!props.hasNext}>
        {translate('NEXT')}
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    position: 'relative',
    height: 37,
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
  },
}));

export default BottomBar;
