import React, { useState, useEffect, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../utils/layers';
import SplitterLayout from 'react-splitter-layout';
import clsx from 'clsx';
import { ListItem, ListItemText } from '@material-ui/core';
import openFolder from '../images/iconFolderOpen.png';
import closedFolder from '../images/iconFolderClosed.png';
// @ts-ignore
import TableauReport from 'tableau-react';
import { useConfig, AuthConnector } from '@terragotech/gen5-shared-components';
import UnauthorizedDisplay from '../components/UnauthorizedDisplay';
import NoDashboardDisplay from '../components/NoDashboardDisplay';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import _ from 'lodash';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

const ANIMATION_TIME_IN_SECONDS = 0.3;
const TOKEN_LENGTH = 18000; //3 min.
const TOKEN_INVALID_VALUE = '-1';
const LEFT_NAVIGATION_BAR_WIDTH = 70;
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    left: 70,
    width: `calc(100vw - ${LEFT_NAVIGATION_BAR_WIDTH}px)`,
    height: '100vh',
    backgroundColor: '#fff',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      left: 0,
      width: '100vw',
    },
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  splitter2: {
    '& > .layout-pane:nth-child(1)': {
      transition: `width ${ANIMATION_TIME_IN_SECONDS}s`,
    },
  },
  folder: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 0,
  },
  openFolder: {
    background: 'linear-gradient(#e0e0e0, #e0e0e0) 26px 100% / 2px 50%  no-repeat, #fff',
  },
  iconSection: {
    display: 'flex',
    width: 25,
  },
  folderName: {
    fontWeight: 410,
    paddingLeft: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
  dashboard: {
    marginLeft: 26,
    paddingLeft: 18,
    height: '100%',
    overflow: 'hidden',
    background:
      'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 100%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 30% / 16px 2px no-repeat',
  },
  lastDashboard: {
    background:
      'linear-gradient(#e0e0e0, #e0e0e0) 0px 0% / 2px 30%  no-repeat, linear-gradient(#e0e0e0, #e0e0e0) 0px 30% / 16px 2px no-repeat',
  },
  dashboardListItem: {
    padding: 0,
    height: 45,
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
      fontWeight: 410,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 14,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.grey[700],
      fontSize: 13,
    },
  },
  selectedDashboardListItem: {
    background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) 0px 0% / 3px 100%  no-repeat, ${theme.palette.grey[100]}`,
    '& .MuiListItemText-primary': {
      color: 'black',
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.grey[800],
    },
  },
  dashboardListItemText: {
    marginTop: 3,
    marginBottom: 3,
  },
  folderIcon: {
    width: '21px',
    height: '16px',
  },
  folderWithSelectedDashboard: {
    fontWeight: 410,
  },
  sideBarTitleWrapper: {
    backgroundColor: '#f8f8f8',
    paddingLeft: '15px',
  },
}));

interface Props {
  onBack: () => void;
}

interface NestedFolderStructure {
  [string: string]: Array<Dashboards>;
}

export interface Dashboards {
  viewTitle: string;
  viewUrl: string;
  folderName: string;
}

const Analytics = (props: Props) => {
  const classes = useStyles();
  const uiConfig = useConfig();

  const analyticsTrustedServerUrl = useMemo(() => uiConfig.analyticsTrustedServerUrl, [uiConfig]);
  const [dashboards, setDashboards] = useState<Array<Dashboards>>([]);
  const { translate } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState('');
  const [token, setToken] = useState('');
  const [folderOpen, setFolderOpen] = useState<string[]>([]);
  const [width, setWidth] = useState(85);
  const handleBackClick = () => {
    props.onBack();
  };

  const nestedFolderStructure = useMemo(() => {
    let nested: NestedFolderStructure = {};
    if (!(dashboards || []).length) {
      return nested;
    }
    dashboards.forEach(dashboard => {
      if (!nested[dashboard.folderName]) {
        nested[dashboard.folderName] = [];
      }
      nested[dashboard.folderName].push(dashboard);
    });
    return nested;
  }, [dashboards]);

  const toggleFolder = (folderName: string) => {
    let obj = [...folderOpen];
    if (obj && obj.indexOf(folderName) === -1) {
      obj.push(folderName);
    } else {
      obj.splice(obj.indexOf(folderName), 1);
    }
    setFolderOpen(obj);
  };
  const folderIsOpen = (folderName: string) => {
    return folderOpen.indexOf(folderName) !== -1;
  };

  const refreshToken = async (): Promise<Boolean> => {
    try {
      const response = await fetch(`${analyticsTrustedServerUrl}/tableau/token`, {
        method: 'POST',
        headers: await AuthConnector.getTrustedApiHeaders(),
      });

      if (!response.ok) {
        setToken(TOKEN_INVALID_VALUE);
        console.error(`An error has occurred: ${response.status}`);
        return false;
      } else {
        setToken(await response.json());
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const retrieveUserDashboards = async (): Promise<Array<Dashboards>> => {
    try {
      const response = await fetch(`${analyticsTrustedServerUrl}/tableau/dashboards`, {
        method: 'POST',
        headers: await AuthConnector.getTrustedApiHeaders(),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return Array<Dashboards>();
    }
  };

  useEffect(() => {
    (async function () {
      const isValid = await refreshToken();
      if (isValid) {
        const usersDashboards = await retrieveUserDashboards();
        setDashboards(usersDashboards);
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    let refreshTokenTimer = setTimeout(() => {
      refreshToken();
    }, TOKEN_LENGTH);
    return () => {
      clearTimeout(refreshTokenTimer);
    };
  }, []);

  useEffect(() => {
    if (dashboards && dashboards.length > 0) {
      setReportUrl(dashboards[0].viewUrl);
      setFolderOpen([dashboards[0].folderName]);
    }
  }, [dashboards]);

  return (
    <div className={classes.root}>
      {(dashboards || []).length > 0 && (
        <SplitterLayout
          customClassName={clsx('custom-splitter2', classes.splitter2)}
          percentage={true}
          secondaryInitialSize={width}
          onSecondaryPaneSizeChange={setWidth}
        >
          <div className={classes.bottomSection}>
            <div className={classes.sideBarTitleWrapper}>
              <h4>{translate('DASHBOARDS')}</h4>
            </div>
            <div>
              {Object.keys(nestedFolderStructure).map((folder: string, folderKey: number) => {
                return (
                  <div key={folderKey}>
                    <ListItem
                      button
                      onClick={() => {
                        toggleFolder(folder);
                      }}
                      className={clsx(classes.folder, folderIsOpen(folder) && classes.openFolder)}
                    >
                      <div className={classes.iconSection}>
                        <img
                          src={(folderIsOpen(folder) && openFolder) || closedFolder}
                          alt="Folder"
                          className={classes.folderIcon}
                        />
                      </div>
                      <ListItemText
                        classes={{
                          primary: clsx(classes.folderName, classes.folderWithSelectedDashboard),
                        }}
                        primary={folder}
                        style={{ paddingLeft: '10px', width: '100%' }}
                      />
                    </ListItem>
                    {folderIsOpen(folder) &&
                      nestedFolderStructure[folder].map((dashboard, dashboardKey) => {
                        return (
                          <ListItem
                            key={dashboardKey}
                            button
                            onClick={() => {
                              setReportUrl(dashboard.viewUrl);
                            }}
                            className={clsx(
                              classes.dashboardListItem,
                              dashboard.viewUrl === reportUrl && classes.selectedDashboardListItem
                            )}
                          >
                            <div
                              className={clsx(
                                classes.dashboard,
                                dashboards.length - 1 === dashboardKey && classes.lastDashboard
                              )}
                            >
                              <ListItemText
                                title={translate(dashboard.viewTitle)}
                                className={classes.dashboardListItemText}
                                primary={dashboard.viewTitle}
                              />
                            </div>
                          </ListItem>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ width: `${width}vw` }}>
            {reportUrl && !isLoading && token !== TOKEN_INVALID_VALUE && (
              <TableauReport
                url={reportUrl}
                onLoad={() => {}}
                filters={null}
                parameters={{ toolbar: 'yes' }}
                options={{
                  height: '100vh',
                  width: '100%',
                  hideTabs: false,
                }}
                token={token}
                query="?:embed=yes&:comments=no&:refresh=yes"
              />
            )}
          </div>
        </SplitterLayout>
      )}
      {token !== TOKEN_INVALID_VALUE && !isLoading && _.isEmpty(dashboards) ? <NoDashboardDisplay /> : null}
      {token === TOKEN_INVALID_VALUE && <UnauthorizedDisplay />}
    </div>
  );
};

export default Analytics;
