//@ts-ignore
import { V2AggregateLoader } from '@terragotech/form-renderer';
import LabelWrapper, {
  TGLabelWrapperProps,
} from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageLabelWrapper';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MapItemsContainer from '../../containers/MapItemsContainer';
import { MapAssetType } from '../../contexts/AggregatesContext/types';
import { Symbol } from '../../components/Legend';
import { gql, useApolloClient } from '@apollo/client';
import { useFilter } from '../../contexts/FilterContext/filterContext';
import _ from 'lodash';
import { useAssetCards } from '../../contexts/assetCardContext';
import { useSelectedProject } from '../../contexts/selectedProjectContext';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { useRecoilState } from 'recoil';
import { forceMapReloadState } from '../../recoil/atoms/mapMaintenance';
import { v4 } from 'uuid';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export type TGMapFieldProps = TGLabelWrapperProps & {
  value?: any;
  aggregates: V2AggregateLoader[];
  height?: string;
  symbols?: Symbol[];
  includeWMS?: boolean;
  latSplit?: number;
  lonSplit?: number;
  limitPerSection?: number;
  showLocationSearch?: boolean;
  hideVisibility?: boolean;
  mapParams?: any;
  setMapAssets?: Dispatch<SetStateAction<MapAssetType[]>>;
  mapBounds?: { minLat: number; minLon: number; maxLat: number; maxLon: number } | null;
  setMapBounds?: (input: any) => void;
  setItems?: (input: any) => void;
};

const SUBMIT_REFRESH_TIME = 2 * 1000; //2 seconds

const TGPageMapField: React.FC<TGMapFieldProps> = props => {
  const { aggregates: inputAgg, setMapAssets, mapBounds, setMapBounds } = props;
  const client = useApolloClient();
  const filter = useFilter();
  const { mapFilterState, drawFilter } = filter;
  const { translate } = useContext(LanguageContext);
  const isMapExtentEnabled = mapFilterState.extentFilterEnabled;
  const filterRef = useRef(filter);
  const [assets, setAssets] = useState<MapAssetType[]>([]);
  const [forceMapReload, setForceMapReload] = useRecoilState(forceMapReloadState);
  const { assetIds } = useAssetCards();
  const assetIdsRef = useRef(assetIds);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [aggregates, setAggregates] = useState(inputAgg);
  const [mapBoundsLocal, setMapBoundsLocal] = useState<{
    minLat: number;
    minLon: number;
    maxLat: number;
    maxLon: number;
  } | null>(null);
  const { selectedProjects } = useSelectedProject();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const currentLoad = useRef<number>(0);
  const prevFetchInfo = useRef<{
    minLat?: number;
    minLon?: number;
    maxLat?: any;
    maxLon?: any;
    aggregates?: string;
    hasClusters?: boolean;
  }>({});
  const maxLimitRef = React.useRef<number | undefined>(undefined);
  useEffect(() => {
    if (JSON.stringify(aggregates) !== JSON.stringify(inputAgg)) {
      setAggregates(inputAgg);
    }
  }, [inputAgg]);

  useEffect(() => {
    assetIdsRef.current = assetIds;
  }, [assetIds]);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const getMaxLimit = (propsLimit: number | undefined) => {
    if (isMapExtentEnabled || (drawFilter.apply && !_.isEmpty(drawFilter.features))) {
      return maxLimitRef.current;
    }
    return propsLimit;
  };

  const updateBoundedAssets = useCallback(
    (
      loadCount: number,
      fetchedAssets: MapAssetType[],
      bounds: {
        minLat: number;
        minLon: number;
        maxLat: number;
        maxLon: number;
        aggregates: string;
      }
    ) => {
      if (loadCount === currentLoad.current) {
        prevFetchInfo.current = { ...bounds, hasClusters: fetchedAssets.some(a => a.id?.match(/^Cluster-/)) };
        setIsDataLoading(false);
        setMapAssets && setMapAssets(fetchedAssets);
        setAssets(fetchedAssets);
      }
    },
    [setIsDataLoading, isMapExtentEnabled, drawFilter, setAssets]
  );

  const boundsQueryController = useRef<AbortController>();

  const abortPreviousBoundsQuery = useCallback(() => {
    boundsQueryController.current?.abort();
    const newController = new AbortController();
    boundsQueryController.current = newController;
    return newController;
  }, []);

  const mapInstanceUuid = useMemo(() => v4(), []); // Unique identifier for this map instance; used for queries requiring supersedure.

  const debouncedMapReload = useCallback(
    _.debounce((mapBounds, mapBoundsLocal, aggregates, force, hasSelectedProjects) => {
      const bounds = mapBounds || mapBoundsLocal;
      if (!bounds) return;

      const latSplit = props.latSplit || 5;
      const lonSplit = props.lonSplit || 5;
      const prevData = prevFetchInfo.current;
      const latRange = bounds.maxLat - bounds.minLat;
      const lonRange = bounds.maxLon - bounds.minLon;
      if (
        bounds &&
        (force ||
          JSON.stringify(aggregates) !== prevData.aggregates ||
          bounds.minLat < prevData.minLat! ||
          bounds.minLon < prevData.minLon! ||
          bounds.maxLat > prevData.maxLat! ||
          bounds.maxLon > prevData.maxLon! ||
          (prevData.hasClusters &&
            ((latRange + (2 * latRange) / latSplit) / (prevData.maxLat! - prevData.minLat!) < 0.9 ||
              (lonRange + (2 * lonRange) / lonSplit) / (prevData.maxLon! - prevData.minLon!) < 0.9)))
      ) {
        setForceMapReload(false);

        const expandedBounds = {
          minLat: bounds.minLat - latRange / latSplit,
          minLon: bounds.minLon - lonRange / lonSplit,
          maxLat: bounds.maxLat + latRange / latSplit,
          maxLon: bounds.maxLon + lonRange / lonSplit,
        };
        prevFetchInfo.current = { ...expandedBounds, hasClusters: true, aggregates: JSON.stringify(aggregates) };
        const thisLoad = ++currentLoad.current;
        const newAbortController = abortPreviousBoundsQuery();
        if (aggregates.length === 0 || (props.mapParams && !hasSelectedProjects)) {
          updateBoundedAssets(thisLoad, [], { ...expandedBounds, aggregates: JSON.stringify(aggregates) });
          return;
        }
        const maxCountInCluster = _.maxBy(
          assets.filter(asset => asset.count),
          'count'
        );
        if (maxCountInCluster) {
          maxLimitRef.current = Number(maxCountInCluster?.count) + 1;
        }
        setIsDataLoading(true);
        client
          .query<{ aggregateBounds: MapAssetType[] }>({
            query: gql`
              query aggregateBounds(
                $queryDef: JSON!
                $minLon: Float!
                $maxLon: Float!
                $minLat: Float!
                $maxLat: Float!
                $latSplit: Float
                $lonSplit: Float
                $limitPerSection: Float
                $value: String
                $values: [String]
                $supersedureId: String!
                $supersedureIteration: Int!
              ) {
                aggregateBounds(
                  filter: {
                    minLat: $minLat
                    minLon: $minLon
                    maxLat: $maxLat
                    maxLon: $maxLon
                    queryDef: $queryDef
                    value: $value
                    values: $values
                    latSplit: $latSplit
                    lonSplit: $lonSplit
                    limitPerSection: $limitPerSection
                  }
                  supersedureOptions: { supersedureId: $supersedureId, iteration: $supersedureIteration }
                )
              }
            `,
            fetchPolicy: 'no-cache',
            variables: {
              ...expandedBounds,
              queryDef: aggregates,
              value: props.value?.value,
              values: props.value?.values,
              latSplit: latSplit + 2,
              lonSplit: lonSplit + 2,
              limitPerSection: getMaxLimit(props.limitPerSection),
              supersedureId: mapInstanceUuid,
              supersedureIteration: thisLoad,
            },
            context: {
              fetchOptions: {
                signal: newAbortController.signal, // https://stackoverflow.com/questions/78139168/aborting-long-running-apollo-client-queries-in-react-component
              },
            },
          })
          .then(result => {
            updateBoundedAssets(thisLoad, result.data.aggregateBounds, {
              ...expandedBounds,
              aggregates: JSON.stringify(aggregates),
            });
          })
          .catch(e => {
            console.log('fetch error', e);
          });
      }
    }, 500),
    []
  );

  useEffect(() => {
    debouncedMapReload(mapBounds, mapBoundsLocal, aggregates, forceMapReload, !!selectedProjects.length);
  }, [mapBounds, mapBoundsLocal, aggregates, drawFilter, isMapExtentEnabled, forceMapReload, selectedProjects.length]);

  const handleFormSubmit = useCallback(() => {
    setTimeout(() => {
      setForceMapReload(true);
    }, SUBMIT_REFRESH_TIME);
  }, []);
  const MAP_HEIGHT = 400;
  const rootStyles = useMemo(() => {
    const styles = {
      height:
        props.height?.endsWith('vh') && isMobileView
          ? `${props.height.replace('vh', '')}%`
          : Number(props.height) || props.height || MAP_HEIGHT,
      width: '100%',
      position: props.height?.endsWith('vh') && isMobileView ? 'fixed' : 'relative',
      overflow: props.height ? '' : 'hidden',
    };
    return styles;
  }, [props.height, isMobileView]);
  return (
    <>
      <LabelWrapper
        required={props.required}
        readOnly={true}
        label={props.label || ''}
        info={props.info || ''}
        oneLine={false}
        translate={translate}
      />
      <div style={rootStyles as React.CSSProperties}>
        <MapItemsContainer
          onFormSubmit={handleFormSubmit}
          setItems={props.setItems}
          zoom={props.value?.zoom}
          lat={props.value?.lat}
          lon={props.value?.lon}
          symbols={props.symbols}
          assets={assets}
          includeWMS={props.includeWMS}
          setMapBounds={setMapBounds || setMapBoundsLocal}
          showLoading={isDataLoading}
          showLocationSearch={props.showLocationSearch}
          mapParams={props.mapParams}
          hideVisibility={props.hideVisibility}></MapItemsContainer>
      </div>
    </>
  );
};

export default TGPageMapField;
