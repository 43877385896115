import { makeStyles } from '@material-ui/core';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';

export const useRowStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 19px',
    borderRadius: 4,
    whiteSpace: 'nowrap',
  },
  tableRow: {
    backgroundColor: colors.lotion,
    borderBottom: `1px solid ${colors.black10}`,
  },
  fileCell: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    maxWidth: 300,
    padding: '10px 18px',
    paddingRight: 0,
    fontSize: 14,
    lineHeight: 'normal',
    border: 'none',
    color: colors.black0,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      paddingLeft: 12,
    },
    textAlign: 'left',
  },
  cellFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: '10px 0',
    minWidth: 170,
    height: 16,
    fontSize: 14,
    lineHeight: 'normal',
    border: 'none',
    color: colors.black0,
  },
  cellItem: {
    padding: '10px 12px',
  },
  btnCellStyle: {
    padding: '10px 18px',
    paddingLeft: 0,
    fontSize: 14,
    lineHeight: 'normal',
    border: 'none',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 20px 0px 12px',
    alignItems: 'center',
    gap: 10,
  },
  fileNameRow: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    overflow: 'hidden',
    minWidth: 170,
    color: colors.black0,
  },
}));
