import { Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { colors } from '../styles/theme';
import { useSections } from '../hooks/useSections';
import AppLogo from './AppLogo';
import { LEFT_PANEL_WIDTH } from '../utils/utilityHelper';

const LeftPanel = ({ isAboutOpen, closeAbout }: { isAboutOpen: boolean; closeAbout: () => void }) => {
  const classes = useStyles();
  const { items } = useSections();
  const navigationItem = _.first(items.filter(o => o.title === 'Navigation'));
  const authItem = _.first(items.filter(o => o.title === 'authSection'));

  return (
    <Box className={`${classes.container} ${classes.borderRight}`}>
      <Box>
        <Box className={classes.logContainer}>
          <AppLogo className={classes.logo} />
        </Box>
        {navigationItem?.content}
      </Box>
      <Box className={classes.userContainer}>{authItem?.content}</Box>
    </Box>
  );
};

const centerFlex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles(theme => ({
  container: {
    width: LEFT_PANEL_WIDTH,
    flexShrink: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  borderRight: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '1px',
      height: '100%',
      backgroundColor: colors.black10,
    },
  },
  logContainer: {
    width: LEFT_PANEL_WIDTH,
    height: 90,
    ...centerFlex,
  },
  logo: {
    width: 40,
    height: 40,
  },
  userContainer: {
    height: 80,
    width: LEFT_PANEL_WIDTH,
    ...centerFlex,
  },
}));

export default LeftPanel;
