import React, { useMemo, useContext } from 'react';
import { Snackbar, SnackbarProps } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface TGSnackBarProps {
  title: string | undefined;
  type: 'ERROR' | 'SUCCESS' | 'WARN' | 'INFO' | undefined;
  isOpen: boolean;
  close: () => void;
  autoHideDuration?: SnackbarProps['autoHideDuration'];
  horizontalPosition?: 'left' | 'right' | 'center';
  verticalPosition?: 'bottom' | 'top';
  disableClickAway?: boolean;
}

const TGSnackBar: React.FunctionComponent<TGSnackBarProps> = props => {
  const {
    title,
    type,
    isOpen,
    close,
    autoHideDuration,
    horizontalPosition,
    verticalPosition,
    disableClickAway,
  } = props;
  const { translate } = useContext(LanguageContext);
  const mappedType = useMemo(() => {
    switch (type) {
      case 'ERROR':
        return 'error';
      case 'SUCCESS':
        return 'success';
      case 'WARN':
        return 'warning';
      case 'INFO':
        return 'info';
    }
  }, [type]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition || 'bottom',
        horizontal: horizontalPosition || 'left',
      }}
      open={isOpen}
      autoHideDuration={autoHideDuration === undefined ? 5000 : autoHideDuration}
      onClose={close}
      ClickAwayListenerProps={{
        touchEvent: !disableClickAway && undefined,
        mouseEvent: !disableClickAway && undefined,
      }}
    >
      <Alert onClose={close} severity={mappedType} variant="filled">
        {translate(title || '')}
      </Alert>
    </Snackbar>
  );
};
export default TGSnackBar;
