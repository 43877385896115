import { Box, CircularProgress, Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import React, { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Suggestion } from 'react-places-autocomplete';
import { colors } from '../../../../styles/theme';
import { MapAssetType } from '../../../../contexts/AggregatesContext/types';
import { useAggregates } from '../../../../contexts/AggregatesContext';
import { LocationOn } from '@material-ui/icons';
import _ from 'lodash';
import { MOBILE_BREAKPOINT, SEARCH_BAR_WIDTH } from '../../../../utils/utilityHelper';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import RecordItem from './RecordItem';
import useUserPreferences from '../../../../hooks/useUserPreferences';
import { AssetsDashboardContext } from '../../../../contexts/assetsDashboardContext';
import { LanguageContext } from '../../../../contexts/LanguageContext/languageContext';

type ReactMouseEvent = React.MouseEventHandler;
type ReactTouchEventHandler = React.TouchEventHandler;

type SuggestionItemProps = {
  key: number;
  id: string | undefined;
  role: 'option';
  onMouseEnter: ReactMouseEvent;
  onMouseLeave: ReactMouseEvent;
  onMouseDown: ReactMouseEvent;
  onMouseUp: ReactMouseEvent;
  onTouchStart: ReactTouchEventHandler;
  onTouchEnd: ReactTouchEventHandler;
  onClick: ReactMouseEvent;
};

interface GlobalSearchSuggestionProps {
  suggestions: readonly Suggestion[];
  searchText: string;
  getSuggestionItemProps: (suggestion: Suggestion) => SuggestionItemProps;
  containerHeight?: number;
  inputRef: HTMLInputElement | null;
  setSearchText: Dispatch<SetStateAction<string>>;
  toggleMapView: (value: boolean) => void;
}
interface TabPanelProps {
  children: ReactElement;
  value: number;
  index: number;
  resultCount?: number;
  emptyDescription?: string;
  isLoading?: boolean;
}
interface AutoSizerProps {
  height: number;
  width: number;
}

const ROW_HEIGHT = 54;

const topSpaceAboveSuggestion = 80;
const recordsNotFound = 'No matching records found';
const locationsNotFound = 'No matching location found';
const MOBILE_HEADER_HEIGHT = 210;
const searchPreference = 'last_search_tab';

const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const { children, value, index, resultCount, emptyDescription, isLoading } = props;
  return (
    <Box
      className={`${classes.tabDetail} ${index === 1 && classes.recordsTab} ${resultCount === 0 && classes.pt10}`}
      role="tabpanel"
      hidden={value !== index}
      key={index}
    >
      {value === index && (
        <Box>
          {isLoading && (
            <Box className={classes.loadingSpinner}>
              <CircularProgress />
            </Box>
          )}
          {resultCount === 0 && !isLoading && (
            <Typography className={classes.emptyResult}>{translate(emptyDescription || '')}</Typography>
          )}
          <Box className={classes.panelContent}>{children}</Box>
        </Box>
      )}
    </Box>
  );
};

const GlobalSearchSuggestion = (props: GlobalSearchSuggestionProps) => {
  const {
    suggestions,
    searchText,
    getSuggestionItemProps,
    containerHeight,
    inputRef,
    setSearchText,
    toggleMapView,
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [filteredAssets, setFilteredAssets] = useState<MapAssetType[]>([]);
  const assetInfo = useAggregates();
  const { aggregateDefinitions } = useConfig();
  const { getPreferenceValue, setPreferenceValue } = useUserPreferences();
  const [activeTab, setActiveTab] = useState(getPreferenceValue(searchPreference) || 0);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const getRecordType = (key: string) => {
    const recordType = aggregateDefinitions.find(def => def.queryKey === key)?.name;
    return recordType ?? key;
  };
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  const location = useLocation();
  const { pageName } = useParams() as {
    pageName: string;
  };
  const { isMobileView } = useContext(AssetsDashboardContext);
  const isPageContainer = location.pathname.includes('/page/') && pageName;
  const tabs = isPageContainer ? ['Location'] : ['Location', 'Records'];
  useEffect(() => {
    if (isPageContainer) {
      setActiveTab(0);
    }
  }, []);

  const handleAssetClick = (targetAsset: MapAssetType, recordType: string) => {
    toggleMapView(true);
    if (history.location.pathname === `/${recordType}/${targetAsset.id}`) {
      history.push(`/${recordType}`);
    } else {
      history.push(`/${recordType}/${targetAsset.id}`);
    }
    setSearchText('');
    blurInput();
  };

  const blurInput = () => {
    if (inputRef) {
      inputRef.blur();
    }
  };

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setSearchResultsLoading(true);
      assetInfo.getSearchResults(searchText).then(data => {
        setFilteredAssets(data);
        setSearchResultsLoading(false);
      });
    }, 300);
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [searchText]);

  const onChangeTab = (value: number) => {
    setPreferenceValue(searchPreference, value);
    setActiveTab(value);
  };

  const Row = (innerProps: ListRowProps) => {
    const { index, style } = innerProps;
    const asset = filteredAssets[index];
    return (
      <RecordItem
        {...{
          asset,
          handleAssetClick,
          getRecordType,
          classes,
          style,
        }}
      />
    );
  };
  const boxHeight = (defaultValue: string, reduceValue: number = 0) =>
    containerHeight ? containerHeight - topSpaceAboveSuggestion - reduceValue : defaultValue;
  const headerHeight = 110;
  const elementHeight = 54;
  const paddingVertical = 25;
  const getHeight = () => headerHeight + paddingVertical + elementHeight * filteredAssets.length;
  const MULTIPLE_ASSET = 120;
  const styles = {
    width: isPageContainer && !isMobileView ? '100%' : 153,
    minWidth: isPageContainer && !isMobileView ? '100%' : 0,
    ...(isPageContainer &&
      isMobileView && {
        flexBasis: '100%',
      }),
  };
  return (
    <Box
      className={classes.resultContainer}
      style={{
        maxHeight: boxHeight('none'),
        ...(activeTab === 1 && filteredAssets.length > 1 && !searchResultsLoading
          ? { height: containerHeight && getHeight() < containerHeight ? getHeight() : containerHeight }
          : {}),
      }}
      onMouseDown={e => e.preventDefault()}
    >
      <Box className={classes.header}>
        <Box className={classes.tabWrapper}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => onChangeTab(newValue)}
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.tabIndicator }}
          >
            {tabs.map((tab, index) => (
              <Tab
                onMouseDown={e => e.preventDefault()}
                key={index}
                label={translate(tab)}
                style={styles}
                className={`${classes.tab} ${activeTab === index ? classes.selectedTab : ''}`}
              />
            ))}
          </Tabs>
        </Box>
        {!searchResultsLoading && (
          <Box className={classes.resultWrapper}>{activeTab === 1 && filteredAssets.length} {translate('Results')}</Box>
        )}
      </Box>
      <TabPanel value={activeTab} index={0} resultCount={suggestions.length} emptyDescription={locationsNotFound}>
        {_.isEmpty(suggestions) ? (
          <></>
        ) : (
          <Box className={classes.placeSuggestionContainer}>
            {suggestions.map((suggestion, index) => (
              <Box {...getSuggestionItemProps(suggestion)} key={index} className={classes.placeSuggestion}>
                <LocationOn className={classes.locationIcon} />
                <Typography className={classes.suggestionLabel}>{translate(suggestion.description)}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </TabPanel>
      <TabPanel
        value={activeTab}
        index={1}
        resultCount={filteredAssets.length}
        emptyDescription={recordsNotFound}
        isLoading={searchResultsLoading}
      >
        {_.isEmpty(filteredAssets) ? (
          <></>
        ) : !searchResultsLoading ? (
          <div
            style={{
              height:
                filteredAssets.length > 1 ? boxHeight(`calc(100vh - ${MOBILE_HEADER_HEIGHT}px)`, MULTIPLE_ASSET) : 54,
            }}
          >
            <AutoSizer>
              {({ height, width }: AutoSizerProps) => (
                <List
                  rowCount={filteredAssets.length}
                  rowHeight={ROW_HEIGHT}
                  rowRenderer={Row}
                  height={height}
                  width={width}
                  className="RecordList"
                  data={filteredAssets}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <></>
        )}
      </TabPanel>
    </Box>
  );
};
const ITEM_WIDTH = 19;
const useStyles = makeStyles(theme => ({
  resultContainer: {
    position: 'absolute',
    width: SEARCH_BAR_WIDTH,
    boxShadow: `0px 2px 4px 0px ${colors.black15}`,
    top: '43px',
    backgroundColor: colors.white,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT)]: {
      height: '90%',
      top: 0,
      width: '100%',
      boxShadow: 'none',
    },
  },
  header: {
    backgroundColor: colors.white,
    zIndex: 10,
    padding: '22px 17px 0px 17px',
  },
  placeSuggestionContainer: {
    paddingRight: 0,
    paddingBottom: 15,
  },
  locationIcon: {
    color: colors.black54,
    height: 24,
    width: 24,
  },
  suggestionLabel: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black54,
  },

  placeSuggestion: {
    display: 'flex',
    color: colors.black54,
    gap: 14,
    alignItems: 'center',
    cursor: 'pointer',
    padding: '12.5px 19px',
    '&:hover': {
      backgroundColor: colors.whiteSmoke,
    },
  },
  tabWrapper: {
    height: 36,
    width: 311,
    backgroundColor: colors.whiteSmoke,
    padding: 4,
    borderRadius: 5,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT)]: {
      width: '98%',
    },
  },
  tabs: {
    fontSize: 14,
    fontWeight: 400,
    minHeight: 0,
    color: colors.black0,
    '& .MuiTabs-flexContainer': {
      gap: 7,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT)]: {
        gap: 0,
      },
    },
  },
  tab: {
    minHeight: 0,
    minWidth: 0,
    textTransform: 'none',
    fontSize: 14,
    color: colors.black0,
    fontWeight: 400,
    opacity: 1,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT)]: {
      maxWidth: 'none',
    },
  },
  selectedTab: {
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    borderRadius: 4,
    backgroundColor: colors.white,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  tabDetail: {
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar, & .RecordList::-webkit-scrollbar': {
      width: 7,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        width: 12,
      },
    },
    '&::-webkit-scrollbar-thumb, & .RecordList::-webkit-scrollbar-thumb': {
      backgroundColor: colors.scrollBar,
      borderRadius: 3,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        borderRadius: 6,
        border: `3px ${colors.white} solid`,
      },
    },
  },
  tabIndicator: {
    display: 'none',
  },
  assetIcon: {
    width: 26,
    height: 26,
    borderRadius: 20,
    boxShadow: `0px 2px 4px ${colors.black15} `,
    backgroundColor: colors.white,
  },
  suggestion: {
    display: 'flex',
    color: colors.black54,
    gap: 12,
    alignItems: 'center',
    cursor: 'pointer',
    padding: '9px 0px 9px 19px',
    width: `calc(100% - ${ITEM_WIDTH}px) !important`,
    '&:hover': {
      backgroundColor: colors.whiteSmoke,
    },
  },
  emptyResult: {
    color: colors.black35,
    fontSize: 15,
    fontWeight: 400,
    paddingBottom: 15,
    paddingLeft: 19,
  },
  resultWrapper: {
    color: colors.black0,
    fontSize: 14,
    fontWeight: 300,
    marginTop: 18,
    backgroundColor: colors.white,
    padding: '5px 0',
  },
  panelContent: {
    flex: 1,
  },
  recordType: {
    fontSize: 13,
    fontWeight: 300,
    color: colors.black0,
  },
  assetLabel: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black0,
  },
  recordsTab: {
    overflow: 'hidden',
    paddingTop: 10,
    paddingBottom: 15,
  },
  pt10: {
    paddingTop: 10,
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    padding: '4px 0px',
  },
}));

export default GlobalSearchSuggestion;
