import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTableColumns } from '../../../contexts/TableColumnContext';
import ColumnFilterHeaders from './ColumnFiltersRoot';
import ColumnFilterInstructionBox from './ColumnFilterInstructionBox';
import ColumnFilterLists from './ColumnFilterLists';
import { useColumnFilterState } from '../../../hooks/useColumnFilterState';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import BottomBarButtons from './columnFilterBottomBar';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { colors } from '../../../styles/theme';

interface ColumnFilterProps extends WithStyles<typeof styles> {
  open: boolean;
  setColumnFilterOpen: (value: boolean) => void;
  isMobileView: boolean;
}
const HEADER_HEIGHT = 110;
const VERTICAL_PADDING = 22;

const ColumnFilter: FunctionComponent<ColumnFilterProps> = props => {
  const { classes, setColumnFilterOpen } = props;
  const { setColumnState } = useTableColumns();

  const {
    columns,
    areAllSelected,
    defaultColumns,
    isSelected,
    getAllSelectedKeys,
    setColumns,
    setIsSelected,
    setSearchText,
    filterColumnsWithSearchText,
  } = useColumnFilterState();

  // sort selected columns first
  useEffect(() => {
    columns.sort((a: Column<AssetType>, b: Column<AssetType>) => Number(isSelected(b.key)) - Number(isSelected(a.key)));
  }, [columns, isSelected]);

  const selectedKeyCount = useMemo(() => getAllSelectedKeys().length, [columns]);

  const handleDone = () => {
    const selectedColumns = getAllSelectedKeys();
    const columnsState = columns.map(column => ({
      key: column.key,
      hidden: !selectedColumns.includes(column.key),
      sticky: !!column.sticky,
    }));
    setColumnState(columnsState);
    setColumnFilterOpen(false);
  };
  const handleClose = () => {
    setColumnFilterOpen(false);
  };
  const setColumnVisibility = useCallback(
    (key: string, isVisible: boolean) => {
      const index = columns.findIndex(column => column.key === key);
      setColumns([...columns.slice(0, index), { ...columns[index], hidden: !isVisible }, ...columns.slice(index + 1)]);
      setIsSelected(key, isVisible);
    },
    [columns, setColumns, setIsSelected]
  );

  const handleSelectAll = () => {
    const filteredKeys = filterColumnsWithSearchText(columns).map(column => column.key);
    if (areAllSelected) {
      filteredKeys.forEach((key: string) => {
        setColumnVisibility(key, false);
      });
    } else {
      filteredKeys.forEach((key: string) => {
        setColumnVisibility(key, true);
      });
    }
  };

  const handleReset = () => {
    setColumns(defaultColumns);
    defaultColumns.forEach(column => {
      setIsSelected(column.key, !column.hidden);
    });
  };

  return (
    <>
      <div className={classes.root}>
        <ColumnFilterHeaders
          handleSearchText={setSearchText}
          handleClose={handleClose}
          subChildren={
            <>
              <div className={classes.filterItems}>
                <ColumnFilterInstructionBox
                  handleSelectAll={handleSelectAll}
                  areAllSelected={areAllSelected}
                  totalKeyCount={columns.length}
                  selectedKeyCount={selectedKeyCount}
                />
                <ColumnFilterLists
                  columns={columns}
                  isSelected={isSelected}
                  setIsSelected={setColumnVisibility}
                  setColumns={setColumns}
                  filterColumnsWithSearchText={filterColumnsWithSearchText}
                />
              </div>
            </>
          }
          bottomBar={<BottomBarButtons onReset={handleReset} onCancel={handleClose} onDone={handleDone} />}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        position: 'relative',
        zIndex: 99999,
        background: colors.white,
      },
    },
    filterItems: {
      height: `calc(100% - ${VERTICAL_PADDING * 2}px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: `${VERTICAL_PADDING}px 30px`,
      paddingRight: 23,
      '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: colors.scrollBar,
      },
    },
  });

export default withStyles(styles)(ColumnFilter);
