import { useContext, useMemo } from 'react';
import { settingsSection, SettingsItem } from '../../components/MapRightDrawer/SettingsSection';
import { DrawerItem } from '../../components/TGDrawer/TGDrawer';
import { useAccountModal } from '../../contexts/accountModalContext';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';
import { useAnalyticsModal } from '../../contexts/analyticsModalContext';
import { useImportModal } from '../../contexts/importModalContext';
import { UseConditionalImports } from '../useConditionalImports';
import { colors } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPieSimple, faCloudArrowUp, faCircleInfo, faHouse, faUserGroup, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { AssetsDashboardContext, pages } from '../../contexts/assetsDashboardContext';
import { useTheme } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

export interface UseSettingsSection {
  section: DrawerItem;
}

export interface UseSettingsSectionArgs {
  name: string;
  email: string;
}

export const useSettingsSection = (props: UseSettingsSectionArgs): UseSettingsSection => {
  const { openUserAndRoles } = useAccountModal();
  const { translate, language } = useContext(LanguageContext);
  const { openImport } = useImportModal();
  const { openAnalytics } = useAnalyticsModal();
  const { buttons } = UseConditionalImports();
  const theme = useTheme();
  const { isAnalyticsUser, isAdmin, hasAdvancedLoggingAccess } = useUserInfo();
  const { isMobileView, setCurrentPage, currentPage, setOpenDrawer } = useContext(AssetsDashboardContext);
  const uiConfig = useConfig();
  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);
  const styles = {
    menuIcon: {
      color: colors.black25,
      fontSize: 20,
    },
    activeMenuIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
  };
  const handleMenuClick = (page: pages) => {
    setCurrentPage(page);
    setOpenDrawer(false);
  };
  const items: SettingsItem[] = useMemo(() => {
    const renderItems = [
      {
        title: 'Home',
        key: 'home',
        visible: true,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faHouse} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: 'Users & Roles',
        key: 'usersAndRoles',
        visible: isAdmin,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faUserGroup as IconProp} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: 'Analytics',
        key: 'analytics',
        visible: isAnalyticsUser && analyticsEnabled && !isMobileView,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faChartPieSimple} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: 'Import',
        key: 'import',
        visible: buttons && buttons.length && !isMobileView,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faCloudArrowUp} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: 'About',
        key: 'about',
        visible: true,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faCircleInfo} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: 'Logs',
        key: 'apiMessages',
        visible: hasAdvancedLoggingAccess,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faFileLines} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      }
    ];
    return renderItems
      .filter(item => item.visible)
      .map(item => ({
        title: translate(item.title),
        icon: item.icon(currentPage === item.key),
        clickHandler: () => handleMenuClick(item.key as pages),
        isActive: item.key === currentPage,
      }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buttons,
    isAdmin,
    isAnalyticsUser,
    analyticsEnabled,
    openUserAndRoles,
    openAnalytics,
    openImport,
    currentPage,
    language,
    hasAdvancedLoggingAccess
  ]);

  const section = settingsSection({
    ...props,
    items,
  });
  return {
    section,
  };
};
