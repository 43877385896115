import React, { useContext } from 'react';
import {
    makeStyles,
    Tabs,
    Tab, Divider,
} from '@material-ui/core';
import { colors } from '../../styles/theme';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const styles = {
    container: {
      height: '100%',
    },
  };
  export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    if (value !== index) {
      return null;
    }

    return (
      <div
        role="tabpanel"
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
        style={styles.container}
      >
        {children}
      </div>
    );
  };

interface TGTabProps {
    label: string[];
    firstTabContent: React.ReactNode;
    secondTabContent: React.ReactNode;
    showDivider?: boolean;
}
  const TGTabs: React.FC<TGTabProps> = (props) => {
    const { label, firstTabContent,secondTabContent, showDivider } = props;
    const [tabValue, setValue] = React.useState<number>(0);
    const tabStyle = TabStyles();
    const { translate } = useContext(LanguageContext);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
         };
    return(
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                classes={{ root: tabStyle.root, indicator: tabStyle.indicator }}
            >
                {label.map((label: string, index: number) => {
                    return (
                        <Tab
                            key={index}
                            label={translate(label)}
                            classes={{ root: tabStyle.tabRoot, selected: tabStyle.selected }}
                        />
                    )
                })}
            </Tabs>
            {showDivider && <Divider className={tabStyle.divider} />}
            <>
                <TabPanel value={tabValue} index={0}>
                    {firstTabContent}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {secondTabContent}
                </TabPanel>
            </>
        </>
    )
  };
export const TabStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      background: colors.veryLightGrey,
      height: 42,
      minHeight: 42,
    },
    indicator: {
      top: 0,
      backgroundColor: theme.palette.primary.main,
    },
    tabRoot: {
      flex: '1 0 0',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.black75,
      padding: '12px 10px',
      background: colors.veryLightGrey,
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 'normal',
      textTransform: 'capitalize',
      minHeight: 42,
      '&$selected': {
        color: theme.palette.primary.main,
        background: colors.white,
        fontWeight: 500,
      },
    },
    selected: {},
    divider: {
        borderColor: colors.black10,
    },
  }));

  export default TGTabs;