import React, { CSSProperties, useContext, useEffect } from 'react';
import { createStyles, Button, makeStyles, Theme, Slide, useTheme, Box, Typography } from '@material-ui/core';
import { EditModeContext } from '../../contexts/editModeContext';
import { colors } from '../../styles/theme';
import { BOTTOM_BAR_Z_INDEX } from '../../utils/layers';
import TGAlertDialog from '../TGAlertDialog';
import { useTableColumns } from '../../contexts/TableColumnContext';
import { useAlert } from '../../contexts/AlertModalContext';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomButtonBar: {
      display: 'flex',
      width: '100%',
      height: 40,
      position: 'absolute',
      bottom: 0,
      flexDirection: 'column',
      fontSize: 14,
      backgroundColor: colors.white,
      color: colors.black,
      zIndex: BOTTOM_BAR_Z_INDEX,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 38,
    },
    divider: {
      height: 5,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.25)',
    },
    button: {
      height: 25,
      fontWeight: 500,
    },
    saveButton: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      marginLeft: 10,
    },
    disabledButton: {
      backgroundColor: '#E6E6E6',
      color: `${colors.white} !important`,
    },
    discardButton: {
      backgroundColor: colors.white,
      color: colors.black,
      border: `1px solid ${colors.grayPlaceholder}`,
      marginLeft: 10,
    },
    rootDialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 700,
        width: 550,
        paddingBottom: 10,
      },
    },
    itemBox: {
      marginTop: 3,
    },
    description: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black54,
      lineHeight: 'normal',
      fontFamily: 'Roboto',
      marginBottom: 3,
    },
    list: {
      margin: 0,
      marginLeft: -18,
    },
  })
);

const styles = {
  titleStyle: {
    fontSize: 24,
    color: 'gray',
    cursor: 'pointer',
    position: 'absolute',
    right: '15px',
  },
  mainTitlePadding: {
    padding: '0px',
  },
  rootStyle: {
    display: 'flex',
    padding: '8px 25px',
    alignItems: 'center',
  },
  centerStyle: {
    marginBottom: 0,
    padding: '8px 24px 0px 24px',
  },
  buttonStyle: {
    padding: '2px 30px',
    textTransform: 'none',
  },
  alertTitle: {
    fontWeight: 'bold',
  },
  alertSubTitle: {
    fontSize: 15,
    marginTop: 5,
  },
  alertItems: {
    margin: 0,
    fontSize: 15,
    marginLeft: -20,
  },
};

export interface BottomActionBarProps {
  onDiscardClick: () => void;
  onSaveClick: () => void;
  label?: string;
  bottomBarHeight?: number;
  changedRecords?: Array<string>;
}

/**
 * BottomActionBar Decides whether to SAVE or DELETE updated assets
 *
 * Has functions that links to the buttons it displays...
 *
 * @param props
 */
const BottomActionBar: React.FC<BottomActionBarProps> = props => {
  const { onDiscardClick, onSaveClick, label, bottomBarHeight, changedRecords } = props;
  const classes = useStyles();
  const { translate } = useContext(LanguageContext);
  const [changedNames, setChangedNames] = React.useState<string[] | undefined>([]);
  const { columns } = useTableColumns();
  const theme = useTheme();
  const { openConfirmation } = useAlert();

  useEffect(() => {
    const changedColumnNames =
      changedRecords &&
      changedRecords.map(changedRecord => {
        const foundColumn = columns.find(column => column.key === changedRecord);
        return foundColumn ? foundColumn?.name : '';
      });
    setChangedNames(changedColumnNames);
  }, [columns, changedRecords]);
  const { assetEditModeActive } = useContext(EditModeContext);
  const [alert, setAlert] = React.useState(false);

  const renderItem = () => {
    return (
      <Box className={classes.itemBox}>
        <Typography className={classes.description}>
          {translate('The following attribute(s) will be updated') + ':'}
        </Typography>
        {changedNames && (
          <ul className={classes.list}>
            {changedNames &&
              changedNames.map((changedRecordname: string, i) => (
                <li className={classes.description} key={`${changedRecordname}-${i}`}>
                  {translate(changedRecordname)}
                </li>
              ))}
          </ul>
        )}
      </Box>
    );
  };

  const onConfirm = async () => {
    const status = await openConfirmation({
      title: 'Confirm',
      question: `${translate('Would you like to update $__LABEL__$?', { LABEL: label || '' })}`,
      confirmationText: 'Update',
      description: <>{renderItem()}</>,
    });
    if (status === 'confirm') {
      onSaveClick();
    }
  };

  return (
    <>
      {alert && (
        <TGAlertDialog
          className={classes.rootDialog}
          title={'Confirm'}
          onConfirm={() => {
            onSaveClick();
            setAlert(!alert);
          }}
          onCancel={() => setAlert(false)}
          open={alert}
          titleStyle={styles.titleStyle as CSSProperties}
          maintitleStyle={{ ...styles.mainTitlePadding, color: theme?.palette?.primary?.main }}
          alertIcon="fa-solid fa-circle-question"
          closeIcon="fa-solid fa-xmark"
          rootStyle={styles.rootStyle}
          buttonStyle={{
            ...(styles.buttonStyle as CSSProperties),
            border: `1px solid ${theme?.palette?.primary?.main}`,
          }}
          contentStyle={styles.centerStyle}
          onClose={() => setAlert(!alert)}
          primaryColor={theme?.palette?.primary?.main}
          divider>
          <>
            <div style={styles.alertTitle as CSSProperties}>{`${translate('Would you like to update $__LABEL__$?', {
              LABEL: label || '',
            })}`}</div>
            <div style={styles.alertSubTitle as CSSProperties}>
              {translate('The following attribute(s) will be updated') + '.'}
            </div>
            {changedNames && (
              <ul style={styles.alertItems}>
                {changedNames &&
                  changedNames.map((changedRecordname: string, i) => (
                    <li key={`${changedRecordname}-${i}`}>{translate(changedRecordname)}</li>
                  ))}
              </ul>
            )}
          </>
        </TGAlertDialog>
      )}

      <Slide in={assetEditModeActive} direction="up">
        <div className={classes.bottomButtonBar} style={{ height: bottomBarHeight ? bottomBarHeight : 40 }}>
          <div className={classes.divider} />
          <div className={classes.buttonContainer}>
            <p>
              <span style={styles.alertTitle as CSSProperties}>
                {translate('$__LABEL__$ will be updated', { LABEL: label || '' })}
              </span>
            </p>
            <span>
              <Button
                disabled={!(changedRecords && changedRecords.length > 0)}
                onClick={onConfirm}
                classes={{ disabled: classes.disabledButton }}
                className={`${classes.button} ${classes.saveButton}`}>
                <>{translate('SAVE')}</>
              </Button>
            </span>
            <span>
              <Button onClick={onDiscardClick} className={`${classes.button} ${classes.discardButton}`}>
                <> {translate('DISCARD')}</>
              </Button>
            </span>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default BottomActionBar;
