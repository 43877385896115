import React, { createContext, useCallback, FunctionComponent, useState, useEffect } from 'react';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import { useConfig } from '@terragotech/gen5-shared-components';

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
  translate: (key: string, params?: Record<string, string | number | boolean>) => string;
  supportedLanguages: string[];
}

export const LanguageContext = createContext<LanguageContextType>({
  language: 'English',
  setLanguage: () => {},
  translate: (key: string, params?: Record<string, string | number | boolean>) => key,
  supportedLanguages: ['English'],
});

export const LanguageProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {  
  const { languages, translations } = useConfig();
  const [language, setLanguage] = useState<string>(
    languages?.default ?? 'English'
  );
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>(
    languages?.supportedLanguages ?? ['English']
  );

  const translate = useCallback(
    (key: string, params?: Record<string, string | number | boolean>): string => {
      if (language === 'English' && !params) return key;

      const translation = translations[key]?.[language] ?? key;

      if (params) {
        return Object.keys(params).reduce(
          (result, param) =>
            result.replace(new RegExp(`\\$__${param}__\\$`, 'g'), translate(params[param].toLocaleString())),
          translation
        );
      }

      return translation;
    },
    [language, translations]
  );

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate, supportedLanguages }}>
      {children}
    </LanguageContext.Provider>
  );
};
