import { useState, useCallback, useMemo, useEffect } from 'react';

const useCarousel = <T extends unknown>(elements: T[]) => {
  const size = elements.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (currentIndex >= size) {
      setCurrentIndex(size - 1);
    }
  }, [size, currentIndex]);
  const goToNext = useCallback(
    () =>
      setCurrentIndex(currentIndex => {
        if (currentIndex + 1 < size) return currentIndex + 1;
        return currentIndex;
      }),
    [size]
  );
  const goToPrev = useCallback(
    () =>
      setCurrentIndex(currentIndex => {
        if (currentIndex - 1 >= 0) return currentIndex - 1;
        return currentIndex;
      }),
    []
  );
  const hasPrev = useMemo(() => currentIndex > 0, [currentIndex]);
  const hasNext = useMemo(() => currentIndex < size - 1, [currentIndex, size]);
  const numberOfPrevElements = useMemo(() => (hasPrev ? currentIndex : 0), [currentIndex, hasPrev]);
  const numberOfNextElements = useMemo(() => (hasNext ? size - currentIndex - 1 : 0), [currentIndex, hasNext, size]);
  const getPosition = useCallback((index: number) => index - currentIndex, [currentIndex]);

  return {
    currentIndex,
    numberOfPrevElements,
    numberOfNextElements,
    goToNext,
    goToPrev,
    hasPrev,
    hasNext,
    getPosition,
    size,
  };
};

export default useCarousel;
