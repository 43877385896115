import React, { useContext } from 'react';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import RefreshDataButton from './RefreshDataButton'; // Assuming you have these components
import { colors } from '../styles/theme';
import clsx from 'clsx';
import DrawButton from './TGButton';
import AntSwitch from './TGAntSwitch';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ButtonData } from './SearchBar';
import ActionsButton from './ActionsButton';
import { useCommonStyles } from "../styles/commonStyles";
import {useUtils} from "../contexts/utilContext";
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

interface MobileHeaderProps {
  isLoadingData: boolean;
  checked: boolean;
  setChecked: Function;
  buttonsData: ButtonData[];
  isMobileView: boolean;
  layout: string;
  type: string;
  classes: ClassNameMap<string>;
  tableTopSearchTxt: string;
  setTableTopSearchTxt: React.Dispatch<React.SetStateAction<string>>;
  ShowingSection: () => React.ReactNode | undefined;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  isLoadingData,
  checked,
  setChecked,
  buttonsData,
  isMobileView,
  layout,
  type,
  classes,
  tableTopSearchTxt,
  setTableTopSearchTxt,
  ShowingSection,
}) => {
  const commonClasses = useCommonStyles();
  const { isIOSSafari } = useUtils();
  const { translate } = useContext(LanguageContext);
  return (
    <>
      {ShowingSection()}
      <div style={customStyles.MobileHeaderContainer}>
        <div className={classes.selectSwitch}>
          <div className={classes.switchBtn}>
            <AntSwitch
              disabled={isLoadingData}
              checked={checked}
              onChange={(e: { target: { checked: boolean } }) => {
                setChecked(e.target.checked);
              }}
              value="checked"
              color="primary"
            />
            <Typography style={{ ...customStyles.menuType, ...customStyles.text }}>
              <>{translate('Select')}</>
            </Typography>
          </div>
          {checked && <ActionsButton />}
        </div>
        <div style={customStyles.buttonMainContainer}>
          <div className={classes.buttonSectionRoot}>
            {buttonsData.map((button: ButtonData, index: number) =>
              (isMobileView && button.view) || (!isMobileView && button.view) ? (
                <DrawButton
                  key={index}
                  onClick={button.onClick}
                  srcImage={button.srcImage}
                  title={button.title}
                  anchorEl={button.anchorEl}
                  isMobileView={isMobileView}
                  enabled={button.enabled}
                />
              ) : null
            )}
          </div>
          <RefreshDataButton isMobileView={isMobileView} />
        </div>
      </div>
      <div className={classes.assetSearchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          placeholder={translate('Search')}
          value={tableTopSearchTxt}
          className={clsx(classes.input, layout !== type && classes.input1, isIOSSafari && commonClasses.safariDefaultFontSize)}
          onChange={e => {
            setTableTopSearchTxt(e.target.value.toLowerCase());
          }}
        />
      </div>
    </>
  );
};
const customStyles: { [key: string]: React.CSSProperties } = {
  menuType: {
    marginLeft: 12,
    color: colors.black54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  selectIcon: { position: 'absolute', right: 0 },
  menuContainer: { display: 'flex', alignItems: 'center' },
  text: { marginLeft: 4, textTransform: 'capitalize' },
  switchContainer: { display: 'flex', padding: '5px 8px' },
  iconLayout: { height: 20, width: 20, color: colors.black54 },
  buttonMainContainer: { display: 'flex', gap: 8 },
  MobileHeaderContainer: {
    display: 'flex',
    gap: 8,
    paddingBottom: 10,
  },
};
export default MobileHeader;
