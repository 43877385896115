import React, { useContext, useState } from 'react';

interface IntegrationContextType {
    openIntegrations: () => void;
    closeIntegrations: () => void;
    isIntegrationsOpen: boolean;
}

const IntegrationContext = React.createContext<IntegrationContextType>({
    openIntegrations: () => { return },
    closeIntegrations: () => { return },
    isIntegrationsOpen: false,
});

const useIntegrationsModal = () => {
    const context = useContext(IntegrationContext);
    if (!context) {
        throw new Error('useIntegrations must be used within an IntegrationProvider');
    }
    return context;
};

const IntegrationProvider = (props: { children: React.ReactNode }) => {
    const [isIntegrationsOpen, setIsIntegrationsOpen] = useState(false);

    const openIntegrations = () => setIsIntegrationsOpen(true);
    const closeIntegrations = () => setIsIntegrationsOpen(false);

    return (
        <IntegrationContext.Provider
            value={{
                isIntegrationsOpen,
                openIntegrations,
                closeIntegrations,
            }}
            {...props}
        >
            {props.children}
        </IntegrationContext.Provider>
    );
};

export { useIntegrationsModal, IntegrationProvider };
