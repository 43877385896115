import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { colors } from '../../../styles/theme';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext/languageContext';

const HideNonSelectableButtons = ({
  buttonCaption = 'non selectable records',
  paddingRight = false,
  paddingBottom = false,
  handleHidingNonSelectableRecords,
  hiding,
}: {
  buttonCaption?: string;
  paddingRight?: boolean | number;
  paddingBottom?: boolean | number;
  handleHidingNonSelectableRecords?: () => void;
  hiding?: boolean;
}) => {
  const classes = useStyles({ paddingRight, paddingBottom });
  const { translate } = useContext(LanguageContext);

  return (
    <Button onClick={() => handleHidingNonSelectableRecords?.()} className={classes.Root}>
      {translate(hiding === true ? 'Show $__CAPTION__$' : 'Hide $__CAPTION__$', { CAPTION: buttonCaption })}
    </Button>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Root: (props: { paddingRight: number | boolean; paddingBottom: number | boolean }) => ({
      position: 'absolute',
      zIndex: 10,
      right: _.isNumber(props.paddingRight) ? `${props.paddingRight}px` : !!props.paddingRight ? '25px' : '27px',
      bottom: _.isNumber(props.paddingBottom) ? `${props.paddingBottom}px` : !!props.paddingBottom ? '25px' : '30px',
      cursor: 'pointer',
      borderRadius: '3px',
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      background: colors.alabaster,
      padding: '8px 18px',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '26px',
      color: colors.black0,
      letterSpacing: 0.46,
      '&:hover': {
        background: colors.alabaster,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        whiteSpace: 'nowrap',
        height: 38,
      },
    }),
  })
);
export default HideNonSelectableButtons;
