import React, { useState, useCallback, useMemo, useRef, useContext } from 'react';
import { makeStyles, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import ActiveFilterIcon from './columnSearchFilter';
import FilterDialogWrapper from './FilterDialogWrapper';
import { TableDataContext } from '../../hooks/useTableData';
import { useQuery } from '@apollo/client';
import { fetchDistinctColumnFilterValues } from './queries';
import { useDialog } from '../../hooks/useDialog';
import { LanguageContext } from '../../contexts/LanguageContext/languageContext';
import { colors } from '../../styles/theme';

const useStyles = makeStyles(theme => ({
    filterInput: {
        width: '100%',
        height: '39px',
        boxSizing: 'content-box',
        '& input': {
            fontSize: '13px',
            height: '100%',
            boxSizing: 'content-box',
            lineHeight: '100%',
            color: colors.black0,
        },
        '& .MuiInput-root': {
            height: 39,
        },
        backgroundColor: colors.white,
        color: colors.black0,
        marginLeft: '-16px',
        padding: '0 16px',
    },
    filterInputIcon: {
        width: '14px',
        height: '14px',
        '& path': {
            fill: colors.black35,
        },
    },
    focusButton: {
        padding: 0,
    },
}));

interface StyledTableFilterInputProps {
    tableName: string;
    columnName: string;
    fieldType: string;
    limit?: number;
    schema: string;
}

const StyledTableFilterInputFromQuery: React.FC<StyledTableFilterInputProps> = ({
    tableName,
    columnName,
    fieldType,
    limit = 500,
    schema
}) => {
    const classes = useStyles();
    const { setFilter, filters, filtersApplied, partialFilters, setPartialFilter } = useContext(TableDataContext);
    const { translate } = useContext(LanguageContext);
    const dialog = useDialog();
    const initialSearchText = useMemo(() => partialFilters[columnName] as string || '', [partialFilters, columnName]);
    const searchTextRef = useRef(initialSearchText);
    const lookupRef = useRef<string[]>([]);
    const [, setIsFetching] = useState(false);

    const showIndicator = useMemo(() => {
        return !!filters[columnName];
    }, [columnName, filters]);


    const { refetch } = useQuery(
        fetchDistinctColumnFilterValues(tableName, columnName, fieldType, limit, searchTextRef.current, schema),
        {
            skip: true,
        }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateFilter = useCallback(
        debounce((text: string) => {
            if (text === '') {
                setPartialFilter(columnName, text, true);
            }
            setPartialFilter(columnName, text);
        }, 300),
        [setFilter, columnName]
    );

    const handleSearchChange = useCallback(
        (text: string) => {
            searchTextRef.current = text;
            updateFilter(text);
        },
        [updateFilter]
    );


    const fetchLookupValues = useCallback(async () => {
        setIsFetching(true);
        if (fieldType === 'DateTime') return;
        try {
            const { data: fetchedData } = await refetch();
            const values = fetchedData?.distinctValues || [];
            lookupRef.current = values.map((value: { [key: string]: any }) => value[columnName]) as string[];
        } catch (err) {
            console.error('Error fetching distinct values:', err);
        } finally {
            setIsFetching(false);
        }
    }, [refetch, columnName]);

    const handleDialogClose = useCallback(
        (selectedValues: readonly string[], isDateTime?: boolean) => {
            if (!isDateTime) {
                if (selectedValues.length === lookupRef.current.length) {
                    setFilter(columnName, [] as string[], true);
                }
                else {
                    setFilter(columnName, selectedValues as string[]);
                }
            } else {
                if (selectedValues.length > 0) {
                    const startDate = new Date(selectedValues[0]).toISOString();
                    const endDate = new Date(selectedValues[1]).toISOString();
                    setFilter(columnName, ['startDate', startDate, 'endDate', endDate]);
                } else {
                    setFilter(columnName, ['startDate', '', 'endDate', ''], true);
                }
            }
            dialog.closeDialog();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [columnName, dialog]
    );

    const handleClose = useCallback(() => {
        dialog.closeDialog();
    }, [dialog]);

    const handleIconClick = useCallback(() => {
        fetchLookupValues().then(() => {
            dialog.openDialog(
                <FilterDialogWrapper
                    title={`${translate('$__NAME__$ Dialog', { NAME: columnName })}`}
                    elements={lookupRef.current}
                    selectedElements={
                        Array.isArray(filters[columnName]) && filters[columnName].length > 0
                            ? (filters[columnName] as string[])
                            : filters.hasOwnProperty(columnName) &&
                                Array.isArray(filters[columnName]) &&
                                filters[columnName].length === 0
                                ? []
                                : lookupRef.current
                    }
                    onClose={handleDialogClose}
                    inputOptions={undefined}
                    onCancel={handleClose}
                    fieldType={fieldType}
                    columnName={columnName}
                />
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fetchLookupValues,
        dialog,
        translate,
        columnName,
        filters,
        handleDialogClose,
        handleClose,
        filtersApplied,
        fieldType,
    ]);

    const endAdornment = useMemo(
        () => (
            <Tooltip title={translate('Filter')}>
                <InputAdornment position="end">
                    <ActiveFilterIcon onClick={handleIconClick} showIndicator={showIndicator} />
                </InputAdornment>
            </Tooltip>
        ),
        [translate, handleIconClick, showIndicator]
    );

    return (
        <TextField
            className={classes.filterInput}
            value={searchTextRef.current}
            onChange={(event) => handleSearchChange(event.target.value)}
            placeholder={fieldType === 'String' ? translate('Search') : ''}
            disabled={fieldType !== 'String'}
            InputProps={{
                endAdornment,
                startAdornment: fieldType === 'String' ? (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ) : <></>,
                disableUnderline: true,
            }}
        />
    );
};

export default StyledTableFilterInputFromQuery;
