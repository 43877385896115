import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import LoginFields, { LoginField } from './LoginFields';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';
import { colors } from '../../styles/theme';

interface LoginCodeViewProps {
  fields: Array<LoginField>;
  errorMessage?: string | undefined;
  handleResend?: () => void;
}

const LoginCodeView: React.FC<LoginCodeViewProps> = (props: LoginCodeViewProps) => {
  const { errorMessage, fields, handleResend } = props;
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.descriptionText}>
        <>{'Check your device for an SMS code'}</>
      </Typography>
      <LoginFields fields={fields} errorMessage={errorMessage} />
      {errorMessage && (
        <Typography variant="h3" className={classes.errorText}>
          <> {errorMessage}</>
        </Typography>
      )}
      <span className={classes.buttonGroup}>
        <TGLoginButton type={TGLoginButtonType.primary}>
          <>{'CONTINUE'}</>
        </TGLoginButton>
        <TGLoginButton type={TGLoginButtonType.small} onClick={handleResend}>
          <>{'RESEND'}</>
        </TGLoginButton>
      </span>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    errorText: {
      color: colors.danger,
      marginBottom: 15,
    },
    buttonGroup: {
      display: 'inline-flex',
      width: '100%',
    },
    descriptionText: {
      fontSize: 12,
      fontWeight: 500,
      color: colors.black,
    },
  })
);

export default LoginCodeView;
