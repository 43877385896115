import { Box, Divider, IconButton, Tabs, Tab, Typography, Breadcrumbs, Link } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AssetData from '../components/AssetData';
import { useConfig } from '@terragotech/gen5-shared-components';
import { AssetDataEntry, AssetDataQueryResult } from './WorkflowPageContainer';
import WorkflowCommands from '../components/AssetEditor/WorkflowCommands';
import { ApolloQueryResult } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../styles/theme';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import TGAssetRecords from '../components/TGAssetRecords';
import _ from 'lodash';
import {
  DEFAULT_SYMBOL_OPTION,
  isValidLocation,
  itemHasValidPrimaryLocation,
  MOBILE_BREAKPOINT,
} from '../utils/utilityHelper';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTableColumns } from '../contexts/TableColumnContext';
import clsx from 'clsx';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}
const header = 121;
const extraSpace = 36;
const footer = 36;
const tabHeight = 41;
const bodyPadding = 40;
const HEADER_FOOTER_HEIGHT = header + footer;
const OTHERS_HEIGHT = tabHeight + bodyPadding;

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
const mobileExtraPaddingHeight = HEADER_FOOTER_HEIGHT - extraSpace;
const closingContainerHeight = 60;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      overflow: 'hidden',
    },
    body: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: colors.white,
      '& input': {
        color: theme.palette.primary.main,
      },
      justifySelf: 'end',
    },
    assetDataContainer: {
      overflow: 'hidden',
      height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
      '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: colors.scrollBar,
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: `calc(100% - ${mobileExtraPaddingHeight}px)`,
      },
    },
    tabContent: {
      overflow: 'auto',
      height: `calc(100% - ${tabHeight}px)`,
    },
    closeContainer: {
      display: 'flex',
      width: `calc(100% - ${closingContainerHeight}px)`,
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: 24,
      padding: '6px 30px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: 0,
        width: 24,
        alignSelf: 'flex-start',
      },
    },
    closeButton: {
      width: 24,
      height: 24,
    },
    close: {
      fontSize: 20,
      color: colors.black54,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 0',
    },
    headerInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 18px',
      paddingBottom: 5,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        padding: '10px 80px',
        paddingBottom: 5,
      },
    },
    leftContent: {
      display: 'flex',
      gap: 10,
      width: '50%',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: '100%',
      },
    },
    assetIcon: {
      width: 46,
      height: 46,
      borderRadius: 46 / 2,
      boxShadow: `0px 1px 4px ${colors.black20}`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: 45,
        height: 45,
        borderRadius: 45 / 2,
      },
    },
    aggregateInfo: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Roboto',
      lineHeight: 'normal',
      color: colors.black0,
      gap: 3,
    },
    singularName: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '17px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        fontSize: 14,
      },
    },
    label: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: 'normal',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        fontSize: 20,
      },
    },
    tabs: {
      height: 40,
      minHeight: 40,
      padding: '0 80px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '0 18px',
      },
    },
    divider: {
      backgroundColor: colors.black10,
    },
    tabText: {
      textTransform: 'capitalize',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      fontFamily: 'Roboto',
      padding: '12px 10px',
      minWidth: 0,
      width: 'auto',
      color: colors.black75,
      '&.Mui-selected': {
        color: colors.black0,
        fontWeight: 500,
      },
    },
    attachedRecordsActive: {
      marginTop: 38,
    },
    tabDetail: {
      padding: '20px 80px',
      height: `calc(100% - ${OTHERS_HEIGHT}px)`,
      '& > .MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        height: '100%',
      },
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '20px 18px',
      },
    },
    tabDetailWithTabs: {
      padding: '20px 0px',
    },
    footer: {
      padding: '10px 80px',
      borderTop: `1px solid ${colors.black10}`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '10px 18px',
      },
    },
    breadcrumb: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      '& .MuiLink-root': {
        cursor: 'pointer',
      },
      '& .MuiBreadcrumbs-separator': {
        color: theme.palette.primary.main,
        marginInline: 5,
      },
    },
    bLabel: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: colors.black0,
    },
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
  });

export interface AssetDataContainerProps extends WithStyles<typeof styles> {
  aggregateId: string;
  aggregateType: string;
  aggregateData: AssetDataEntry;
  refetch: () => Promise<ApolloQueryResult<AssetDataQueryResult>>;
  pathname: string;
  crumbs: { label: string; pathname: string; onClick: () => void }[];
}
type RelationType = { id: string; label: string; __typename: string };
/**
 * AssetDataContainer - Performs GraphQl fetch of data of prop assetId
 *
 * @param assetId
 */

interface TabData {
  label: string;
}

const AssetDataContainer: React.FunctionComponent<AssetDataContainerProps> = props => {
  const { classes, aggregateType, aggregateData, aggregateId, pathname, crumbs } = props;
  const configContext = useConfig();
  const { translate } = useContext(LanguageContext);
  const history = useHistory();
  const aggregateDefinition = configContext.aggregateDefinitions.find(d => d.name === aggregateType);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isSeeAll, setIsSeeAll] = useState('');
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { currentAssetId } = useTableColumns();

  // make a list of relationships to display on the left
  //TODO: See if we can move this into the child component <AttachedAssetsList />
  const relationships = aggregateDefinition?.propertyDefinitions.filter(property => property.isRelationship);
  const attributeLabels = aggregateDefinition?.propertyDefinitions.reduce<Record<string, string>>((acc, val) => {
    return { ...acc, [val.field]: val.label };
  }, {});

  const getAggregateDefinition = (aggrType: string) => {
    return configContext.aggregateDefinitions.find(d => d.name === aggrType);
  };

  const onSeeAll = (name: string) => {
    setIsSeeAll(name);
  };

  const onBack = () => {
    setIsSeeAll('');
  };
  const geoLocationFields = aggregateDefinition?.propertyDefinitions
    .filter(prop => ['Geography', 'AnchoredLine'].includes(prop.type))
    .map(t => t.field);

  const attachedAssets = _.compact(
    relationships?.map(relationship => {
      const field = aggregateData[relationship.field];
      if (relationship.field === 'project') return null;
      let output = {
        typeLabel: relationship.label,
        type: relationship.type,
        attachedItems: [] as MapAssetType[],
      };
      if (Array.isArray(field)) {
        output.attachedItems = field as MapAssetType[];
      } else {
        output.attachedItems = [field as MapAssetType];
      }
      output.attachedItems = _.chain(output.attachedItems)
        .compact()
        .map(o => {
          const def = getAggregateDefinition(o.__typename.toString());
          return { ...o, recordTypeKey: def?.queryKey, pageName: def?.cardDefinition.pageName };
        })
        .value() as MapAssetType[];
      return output;
    }) || []
  );

  const handleClose = () => {
    const path = `/${aggregateType}${currentAssetId?.id ? `/${currentAssetId.id}` : ''}`;
    history.replace(path);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const CloseButton = useCallback(
    () => (
      <Box className={classes.closeContainer}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <FontAwesomeIcon className={classes.close} icon={faClose as IconDefinition} />
        </IconButton>
      </Box>
    ),
    []
  );
  const tabData: TabData[] = [{ label: 'Details' }, { label: 'Attached Records'}];
  const symbolKey = aggregateData.symbolKey;
  const hasPrimaryLocation =
    (geoLocationFields || [])?.map(o => itemHasValidPrimaryLocation(aggregateData, o)).length > 0;
  const hasLocation = isValidLocation([aggregateData.lon, aggregateData.lat]) || hasPrimaryLocation;
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Box className={classes.headerContainer}>
          {!isMobileView && <CloseButton />}
          <Box className={classes.headerInfo}>
            <Box className={classes.leftContent}>
              {symbolKey && !!hasLocation && (
                <img className={classes.assetIcon} src={getSvgImageString(symbolKey, DEFAULT_SYMBOL_OPTION)} />
              )}
              <Box className={classes.aggregateInfo}>
                <Typography className={classes.singularName}>{translate(aggregateDefinition?.singular || '')}</Typography>
                <Typography className={classes.label}>{`${aggregateData ? aggregateData.label : translate('Editor')}`}</Typography>
              </Box>
            </Box>
            <Box className={isMobileView ? classes.hide : classes.show}>
              <WorkflowCommands aggregateType={aggregateType} aggregateId={aggregateId} asset={aggregateData} />
            </Box>
            {isMobileView && <CloseButton />}
          </Box>
        </Box>
        <div className={classes.assetDataContainer}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            className={classes.tabs}
          >
            {tabData.map((tab, index) => (
              <Tab key={index} label={translate(tab.label)} className={classes.tabText} />
            ))}
          </Tabs>
          <Divider className={classes.divider} />
          <Box className={classes.tabContent}>
            <TabPanel value={selectedTabIndex} index={0} className={classes.tabDetail}>
              <Box className={isMobileView ? classes.show : classes.hide}>
                <WorkflowCommands aggregateType={aggregateType} aggregateId={aggregateId} asset={aggregateData} isMobileView={isMobileView} />
              </Box>
              <AssetData
                assetData={aggregateData}
                attributeLabels={attributeLabels}
                aggregateDefinition={aggregateDefinition}
              />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} className={clsx(classes.tabDetail, classes.tabDetailWithTabs)}>
              <TGAssetRecords types={attachedAssets} onSeeAll={onSeeAll} onBack={onBack} isSeeAll={isSeeAll} />
            </TabPanel>
          </Box>
        </div>
        <Box className={classes.footer}>
          <Breadcrumbs className={classes.breadcrumb}>
            <Link color="primary" onClick={handleClose}>
              {translate('Home')}
            </Link>
            {crumbs.map((crumb, i) => {
              return (
                <Link key={i} color="primary" onClick={crumb.onClick}>
                  {crumb.label}
                </Link>
              );
            })}
            <Typography className={classes.bLabel}>{translate(aggregateData.label)}</Typography>
          </Breadcrumbs>
        </Box>
      </div>
    </div>
  );
};

export default withStyles(styles)(AssetDataContainer);
