import React, { useContext } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import magicText from 'i18next';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { colors } from '../styles/theme';
import { LanguageContext } from '../contexts/LanguageContext/languageContext';


interface LegendPopoverProps {
  handleLegendClose: () => void;
  symbolsOnly: boolean;
  symbolLegend: React.ReactNode;
  classes: ClassNameMap;
}
const POPOVER_WIDTH = 320;
const POPOVER_HEIGHT = 310;
const LegendPopover: React.FC<LegendPopoverProps> = ({ handleLegendClose, symbolsOnly, symbolLegend, classes }) => {
  const { translate } = useContext(LanguageContext);
  
  return (
    <div id="legend" style={styles.root}>
      <div className={classes.popoverHeader}>
        <Typography className={classes.mapHeader}>
          <>{translate('Map Legend')}</>
        </Typography>
        <IconButton onClick={handleLegendClose} className={classes.closeRoot}>
          <FontAwesomeIcon icon={faClose} className={classes.closeIcon} />
        </IconButton>
      </div>
      {symbolsOnly && <div style={styles.symbolLegend}>{symbolLegend}</div>}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    zIndex: 1000,
    display: 'block',
    background: colors.white,
    position: 'relative',
    right: 175,
    bottom: 41,
    width: POPOVER_WIDTH,
    maxHeight: POPOVER_HEIGHT,
    borderRadius: 4,
    boxShadow: `0px 1px 4px 2px ${colors.black25}`,
  },
  symbolLegend: {
    paddingRight: 5,
  },
};
export default LegendPopover;
